import { useEffect, useRef } from 'react';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const ClickAway = ({ children, onClickAway, className = '' }: TProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
      onClickAway(e);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClick);
    return () => {
      document.removeEventListener('mousedown', onClick);
    };
  }, []);

  return (
    <Wrapper className={className} ref={ref}>
      {children}
    </Wrapper>
  );
};
