import styled from 'styled-components';
import { RelativeSpinner } from './RelativeSpinner';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 102;
`;

type TProps = {
  overlay?: boolean;
  className?: string;
};

export const Spinner = ({ overlay = false, className = 'spinner-wrapper' }: TProps) => {
  return (
    <Wrapper className={className}>
      <RelativeSpinner className="relative-spinner" overlay={overlay} />
    </Wrapper>
  );
};

export const RelativeSpinner65 = styled(Spinner)`
  position: relative;
  width: 65px;
  height: 65px;
`;

export const AbsoluteSpinner = styled(Spinner)`
  top: 0;
  left: 0;
  position: absolute;
`;

export const AbsoluteSmallSpinner = styled(AbsoluteSpinner)`
  svg {
    width: 20px;
  }
`;
