import { routes } from '@routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { NotificationsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { useAuthContext } from '@providers/AuthProvider';
import { getShortName } from '@helpers/getShortName';

import { Button } from '@components/form-elements/buttons/Button';
import { GearSvg } from '@components/icons/Gear';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { TickingDateAgo } from '@components/Date/TickingDateAgo';

import { Wrapper } from './styles/Wrapper';
import { DoubleDone } from '@components/icons/DoubleDone';
import { CrossIcon } from '@components/Tag/icons/CrossIcon';

export const NotificationsPopover = () => {
  const navigate = useNavigate();
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const { me, updateMe } = useAuthContext();

  const notifications = me?.lastNotifications || [];

  const makeAllRead = async () => {
    setIsLoading(true);
    await call(NotificationsService.setAllNotificationsRead());
    await updateMe();
    setIsLoading(false);
  };

  let content: React.ReactElement | React.ReactElement[] = (
    <>
      <div className="no-notifications">
        {"You'll"} get notified here when someone mentions you, your job status updates, and more
      </div>
    </>
  );
  let footer = null;

  if (notifications.length) {
    content = (
      <div className="notifications-list">
        {notifications.map((n: any) => {
          const shortName = getShortName(n.Organization?.name || 'Auto Script');
          return (
            <RouterLink
              to={n.url}
              className="notification"
              key={n.id}
              onClick={() => {
                call(NotificationsService.setNotificationRead({ id: n.id })).then(() => {
                  updateMe();
                });
              }}
            >
              <div className="entity-name">
                {n.Organization?.image ? <img alt="" src={n.Organization?.image} /> : shortName}
              </div>
              <div className="content">
                {!n.seen ? <div className="unseen-dot"></div> : null}
                <Button
                  className="delete-button"
                  variant="outlined"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await call(NotificationsService.deleteNotification({ id: n.id }));
                    updateMe();
                  }}
                >
                  <CrossIcon />
                </Button>
                <p className="org-name">{n.Organization?.name || 'AutoScript'}</p>
                <p className="message">{n.message}</p>
                <p className="created">
                  <span className="comment-date">
                    <TickingDateAgo date={n.createdAt} />
                  </span>
                </p>
              </div>
            </RouterLink>
          );
        })}
      </div>
    );
    footer = (
      <>
        <Button
          variant="text"
          style={{
            padding: 0,
            color: '#858DBD',
            fontSize: '12px',
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            gap: '3px',
          }}
          onClick={() => makeAllRead()}
        >
          Mark all as read
          <DoubleDone />
        </Button>
        <Button
          variant="outlined"
          style={{ width: '70px', padding: 0, flex: '0 0 auto' }}
          onClick={() => {
            navigate(routes.notifications.make());
          }}
        >
          View All
        </Button>
      </>
    );
  }

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Notifications</h2>
      <RouterLink className="settings-link no-underline hover:no-underline" to={routes.notificationSettings.make()}>
        <GearSvg style={{ width: '14px', height: '18px', marginLeft: '10px' }} />
      </RouterLink>

      {content}

      <div className="notifications-footer">{footer}</div>
    </Wrapper>
  );
};
