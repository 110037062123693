import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { getAvailableWorkspaceTeams } from '@queries/workspaces/getAvailableTeams';
import { SelectableMiniTeamCards } from '@components/todo/TeamCards/SelectableMiniTeamCards';
import { GenericPageError } from '@components/GenericPageError';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { TRole } from 'app/types/entities/TRole';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  width: 490px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
    padding: 0;
  }

  input {
    height: 30px;
  }

  .buttonContainer {
    display: flex;
    gap: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  orgSlug: string;
  workspaceSlug: string;
  onCancel: () => void;
  onSuccess: (selectedTeams: string[]) => void;
};

export const AddTeamToWorkspaceModal = ({ orgSlug, workspaceSlug, onCancel, onSuccess }: TProps) => {
  const [availableTeams, setAvailableTeams] = useState<TRole[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAvailableWorkspaceTeams(orgSlug, workspaceSlug).then((data) => {
      setAvailableTeams(data.result);
      setIsLoading(false);
    });
  }, []);

  const handleSave = async () => {
    // console.log('save');
    onSuccess(selectedTeams);
  };

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h5>Assign Teams</h5>
      {!isLoading && !availableTeams.length ? <GenericPageError message="No teams available" /> : null}
      {!isLoading && !!availableTeams.length ? (
        <div className="max-h-96 overflow-y-auto">
          <ul className="flex flex-wrap">
            {availableTeams.map((team) => (
              <SelectableMiniTeamCards
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
                key={team.id}
                team={team}
              />
            ))}
          </ul>
        </div>
      ) : null}
      <div className="buttonContainer m-auto">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="bold" onClick={handleSave}>
          Add
        </Button>
      </div>
    </Wrapper>
  );
};
