import { client } from 'app/http';
import { FileIcon } from '@components/icons/File';
import { usePageContext } from '../../../../../../providers/PageProvider';

import { JobSection } from '../../styles/JobSection';
import { Divider } from '../../styles/Divider';
import { JobSectionLeft } from '../../styles/JobSectionLeft';
import { JobSectionRight } from '../../styles/JobSectionRight';
import { InfoBlockFullSize } from '../../styles/InfoBlockFullSize';
import { InfoBlockTitle } from '../../styles/InfoBlockTitle';
import { InfoBlockContent } from '../../styles/InfoBlockContent';

import { FileIcons } from './styles/FileIcons';
import { FileIconLink } from './styles/FileIconLink';
import { AudioWithQualityScore } from './components/AudioWithQualityScore';

export const MaterialsSection = () => {
  const { job, showMaterials, showDownloads, manualReload } = usePageContext();

  const downloadFile = async (jobId: string, fileName: string) => {
    await client
      .get(`${window.location.origin}/api/media/${jobId}/transcript/${fileName}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  if (!job || !showMaterials) {
    return null;
  }

  return (
    <>
      <JobSection>
        <JobSectionLeft>Materials</JobSectionLeft>
        <JobSectionRight>
          {showDownloads ? (
            <InfoBlockFullSize>
              <InfoBlockTitle>Speech-to-Text Transcript</InfoBlockTitle>
              <InfoBlockContent>
                <FileIcons>
                  {job.results?.map((file, index) => (
                    <FileIconLink
                      data-testid={`jobDownloadItem${job.name}`}
                      key={file + index}
                      onClick={() => downloadFile(job.id, file)}
                      className="downloadLink"
                    >
                      <FileIcon />
                      {file}
                    </FileIconLink>
                  ))}
                </FileIcons>
              </InfoBlockContent>
            </InfoBlockFullSize>
          ) : null}

          {job.media?.length ? (
            <InfoBlockFullSize className="my-4">
              <InfoBlockTitle>Audio Files</InfoBlockTitle>
              <InfoBlockContent>
                {job.media.map((media: any) => (
                  <AudioWithQualityScore key={media.id} media={media} job={job} manualReload={manualReload} />
                ))}
              </InfoBlockContent>
            </InfoBlockFullSize>
          ) : null}

          {job.notes ? (
            <InfoBlockFullSize>
              <InfoBlockTitle>Notes</InfoBlockTitle>
              <InfoBlockContent>{job.notes}</InfoBlockContent>
            </InfoBlockFullSize>
          ) : null}
        </JobSectionRight>
      </JobSection>
      <Divider />
    </>
  );
};
