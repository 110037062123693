import { Helmet } from 'react-helmet-async';

import { PageProvider } from './providers/PageProvider';
import { Content } from './components/Content';

export const AdminJobDetailsPage = () => {
  return (
    <PageProvider>
      <Helmet>
        <title>Job Details - AutoScript</title>
      </Helmet>
      <Content />
    </PageProvider>
  );
};
