import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Divider } from '@components/Divider';

import { UserDetails } from './components/UserDetails';
import { Teams } from './components/Teams';
import { Workspaces } from './components/Workspaces';
import { Organizations } from './components/Organizations';

import { usePageContext } from '../../providers/PageProvider';

import { Wrapper } from './styles/Wrapper';

export const Content = () => {
  const { user, isLoading, fetchData } = usePageContext();

  if (!user || isLoading) {
    return (
      <Wrapper>
        <AbsoluteSpinner overlay={true} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <UserDetails user={user} reload={fetchData} />
      <Divider />
      <Organizations user={user} />
      <Divider />
      <Workspaces user={user} />
      <Divider />
      <Teams user={user} />
    </Wrapper>
  );
};
