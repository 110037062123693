import toast from 'react-hot-toast';

import { Share } from '@components/icons/ShareIcon';
import { usePageContext } from '../../../../../../providers/PageProvider';

import { ShareIconButton } from '../../styles/ShareIconButton';
import { getLinkPrefix } from '@helpers/jobLinkHelpers';
import { jobCanBeShared } from '@helpers/jobStatus/jobCanBeShared';

export const ShareLink = () => {
  const { job } = usePageContext();

  const handleCopyButtonClick = (link: string) => {
    navigator.clipboard.writeText(link);
  };

  if (!job || !job.status) {
    return null;
  }

  const showShareButton = jobCanBeShared(job.status);

  if (!showShareButton) {
    return null;
  }

  return (
    <ShareIconButton
      title="Copy link to clipboard"
      onClick={() => {
        toast.success('The link has been copied to the clipboard', {
          duration: 2000,
        });
        handleCopyButtonClick(`${getLinkPrefix()}${job?.id}`);
      }}
    >
      <Share />
      Share job link
    </ShareIconButton>
  );
};
