import { useState } from 'react';
import styled from 'styled-components';

import { Input } from '@components/form-elements/Input';
import { Select } from '@components/form-elements/Select';
import { Switch } from '@components/form-elements/Switch';
import { ColourPicker } from '@components/ColourPicker';
import { QuestionIcon } from '@pages/User/TeamDetailsPage/icons/Question';
import { MiniTeamIcon } from '@components/todo/Teams/MiniTeamIcon';

import { TUpdateTeamDto } from 'app/types/API/TUpdateTeamDto';
import { TRole } from 'app/types/entities/TRole';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #858dbd;
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div``;

const RightSideColumns = styled.div`
  display: flex;
  gap: 10px;

  .team-name {
    padding: 20px 0 0;
  }
`;

const QuestionIconWrapper = styled.div`
  padding: 32px 0 0 5px;
  position: relative;

  .question-content {
    display: none;
  }

  :hover .question-content {
    display: block;
  }
`;
const QuestionContent = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #858dbd;
  padding: 20px;
  width: 350px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
  margin: 0 0 0 4px;

  :before {
    content: '';
    position: absolute;
    margin-left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: rgb(255, 255, 255) transparent transparent;
    top: -25px;
    left: 50%;
    z-index: 10000;
    transform: rotate(180deg);
  }

  ul {
    padding: 15px 0 0 17px;
    list-style: disc;

    li {
      padding: 0 0 0 2px;
    }
  }
`;
const InputBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  width: 270px;

  label {
    padding: 0 0 5px;
    display: block;
  }
`;

const PermissionLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #858dbd;
  padding: 20px 0 0;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledSelect = styled(Select)`
  .select {
    background: #ffffff;
  }
`;

const toggleOrgPermissionsGroups: (keyof TUpdateTeamDto)[][] = [
  ['manageOrganization', 'manageWorkspaces', 'manageMembers', 'managePermissions'],

  ['manageBilling', 'viewBilling', 'manageLicenses', 'viewLicenses'],

  ['viewReports', 'editJobs', 'submitJobs', 'reassignJobs'],

  ['editRepositories', 'viewRepositories', 'viewJobCost'],
];

const toggleWSPermissionsGroups: (keyof TUpdateTeamDto)[][] = [
  ['editJobTranscript', 'proofreadJobTranscript'],
  ['finalizeJobTranscript', 'markJobComplete'],
];

const camelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1').replace('Repositories', 'Cases');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const getInitialValues = (team: TUpdateTeamDto) => {
  return {
    name: team.name,
    slug: team.slug,
    colour: team.colour,
    owner: team.owner,

    viewRepositories: team.viewRepositories,
    editRepositories: team.editRepositories,

    viewBilling: team.viewBilling,
    manageBilling: team.manageBilling,

    viewLicenses: team.viewLicenses,
    manageLicenses: team.manageLicenses,

    viewJobs: team.viewJobs,
    editJobs: team.editJobs,
    reassignJobs: team.reassignJobs,
    submitJobs: team.submitJobs,
    viewJobCost: team.viewJobCost,

    editJobTranscript: team.editJobTranscript,
    proofreadJobTranscript: team.proofreadJobTranscript,
    finalizeJobTranscript: team.finalizeJobTranscript,
    markJobComplete: team.markJobComplete,

    viewReports: team.viewReports,

    manageMembers: team.manageMembers,
    managePermissions: team.managePermissions,
    manageWorkspaces: team.manageWorkspaces,

    manageOrganization: team.manageOrganization,
  };
};

type TProps = {
  team: TRole;
};

export const TeamInformation = ({ team }: TProps) => {
  const [form, setForm] = useState<TUpdateTeamDto>(getInitialValues(team));

  return (
    <>
      <Wrapper>
        <LeftSide>
          <h3>General</h3>
        </LeftSide>

        <RightSide>
          <RightSideColumns>
            <MiniTeamIcon team={form} />
            <div>
              <ColourPicker colour={form.colour} setColour={(colour) => setForm({ ...form, colour })} />
              <p className="text-xs">This icon color will be used to identify your team.</p>
            </div>
          </RightSideColumns>
          <RightSideColumns>
            <div className="team-name flex flex-row gap-4">
              <InputWrapper>
                <label htmlFor="name">Team name</label>
                <Input
                  className="whiteInput"
                  disabled={true}
                  onChange={() => {}}
                  name="name"
                  value={form.name}
                  label="Team name"
                />
              </InputWrapper>
              <InputWrapper>
                <label htmlFor="slug">Team URL</label>
                <Input
                  className="whiteInput"
                  disabled={true}
                  onChange={() => {}}
                  name="slug"
                  value={form.slug}
                  label="Team URL"
                />
              </InputWrapper>
            </div>
          </RightSideColumns>
        </RightSide>
      </Wrapper>
      <Wrapper>
        <LeftSide>
          <h3>Permissions</h3>
        </LeftSide>
        <RightSide>
          <RightSideColumns>
            <InputBlockWrapper>
              <InputWrapper>
                <label htmlFor="viewJobs">View jobs</label>
                <StyledSelect
                  name="viewJobs"
                  disabled={true}
                  onChange={() => {}}
                  label=""
                  value={form.viewJobs}
                  options={[
                    { value: 'assigned', label: 'Assigned' },
                    { value: 'assignedAndUnassigned', label: 'Assigned & Unassigned' },
                    { value: 'all', label: 'All' },
                    { value: 'none', label: 'None' },
                  ]}
                />
              </InputWrapper>
            </InputBlockWrapper>
            <QuestionIconWrapper>
              <QuestionIcon />
              <QuestionContent className="question-content">
                Choose the type of job list that fits this team&apos;s needs:
                <ul>
                  <li>All - view all jobs within an attached Workspace</li>
                  <li>Assigned - only view jobs which have been assigned to the current user</li>
                  <li>
                    Assigned and Unassigned - view jobs which have been assigned to the current user and jobs which are
                    available to be claimed (with the Edit Job Transcript or Proofread Job Transcript Permissions)
                  </li>
                  <li>None - disable the job list.</li>
                </ul>
              </QuestionContent>
            </QuestionIconWrapper>
          </RightSideColumns>

          <PermissionLabel>Organization level</PermissionLabel>
          <div className="flex mt-4 gap-16">
            {toggleOrgPermissionsGroups.map((group, i) => (
              <div key={i} className="mr-4 min-w-12">
                {group.map((permission, ii) => (
                  <div key={`${i}-${ii}`} className="flex mb-2">
                    <label htmlFor={permission} className="mr-4 whitespace-nowrap">
                      {camelCaseToTitleCase(permission)}
                    </label>
                    <div className="flex-grow grid justify-items-end">
                      <Switch checked={form[permission] as boolean} disabled={true} onChange={() => {}} />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <PermissionLabel>Workspace level</PermissionLabel>
          <div className="flex mt-4 gap-16">
            {toggleWSPermissionsGroups.map((group, i) => (
              <div key={i} className="mr-4 min-w-12">
                {group.map((permission, ii) => (
                  <div key={`${i}-${ii}`} className="flex mb-2">
                    <label htmlFor={permission} className="mr-4 whitespace-nowrap">
                      {camelCaseToTitleCase(permission)}
                    </label>
                    <div className="flex-grow grid justify-items-end">
                      <Switch checked={form[permission] as boolean} disabled={true} onChange={() => {}} />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </RightSide>
      </Wrapper>
    </>
  );
};
