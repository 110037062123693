import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';

export type RepositoryAttachment = {
  id: string;
  fileName: string;
  fileSize: number;
  fileExtension: string;
  createdAt: string;
  updatedAt: string;
  weight: number;
  isAudio: boolean;
  audioDuration?: number;
};

export const mapAttachmentsToIFileWithMeta = (attachments: RepositoryAttachment[]): TFileWithMeta[] => {
  return attachments.map((attachment) => {
    return {
      meta: {
        Id: attachment.id,
        name: attachment.fileName,
        size: Number(attachment.fileSize),
        status: 'done',
      },
      file: null,
      restart: () => {},
      cancel: () => {},
    };
  }) as never[];
};
