import { client } from 'app/http';

function dataURLtoBlob(dataurl: string) {
  const arr = dataurl.split(',');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const sendS3XHR = (url: string, file: Blob) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('ACL', 'public-read');
    xhr.timeout = 20000;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        resolve(xhr.response);
      }
    };
    xhr.send(file);
  });
};

export const uploadAvatar = async (image: string) => {
  const resp = await client.get(`/user/upload-params`);
  const { fields, url } = await resp.data;
  await sendS3XHR(url, dataURLtoBlob(image));
  await client.post(`user/avatar`, { avatar: `https://${fields.Bucket}.s3.amazonaws.com/${fields.Key}` });
};

export const uploadOrgImage = async (orgId: string, image: string) => {
  const resp = await client.get(`/organizations/${orgId}/signed-upload`);
  const { fields, url } = await resp.data;
  await sendS3XHR(url, dataURLtoBlob(image));
  await client.patch(`organizations/${orgId}`, { image: `https://${fields.Bucket}.s3.amazonaws.com/${fields.Key}` });
};
