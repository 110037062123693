export const getShortName = (input?: string) => {
  const name = input || '';
  const firstInitial = name[0] ?? '';
  let secondInitial = name[1] ?? '';

  if (name.includes(' ') && name.split(' ')[1]?.length) {
    let tempLastName: string | string[] = name.split(' ');
    tempLastName.shift();
    tempLastName = tempLastName.join('').replace(/[\s-]/g, '');
    secondInitial = tempLastName[0] ?? secondInitial;
  }
  return `${firstInitial}${secondInitial}`.toUpperCase();
};
