import React, { useEffect } from 'react';

import { Button } from '@components/form-elements/buttons/Button';
import { CustomTable } from '@components/Table';
import { getRepoAudioFiles } from '@queries/repositories/getRepoAudioFiles';
import { Spinner } from '@components/spinners/Spinner';
import { TAudioFile } from 'app/types/entities/TAudioFile';

import { FileCheckbox } from './components/FileCheckbox';
import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';

export const SelectCaseFiles = ({ repositoryId, loading, onCancel, onSuccess }: TProps) => {
  const [files, setFiles] = React.useState<TAudioFile[]>([]);
  const [selectedFiles, setSelectedFiles] = React.useState<TAudioFile[]>([]);

  const fetchAttachments = async () => {
    const data = await getRepoAudioFiles(repositoryId);
    setFiles(data);
  };

  useEffect(() => {
    fetchAttachments();
  }, [repositoryId]);

  const changeSelectedFiles = (checked: boolean, f: TAudioFile) => {
    if (checked) {
      setSelectedFiles([...selectedFiles, f]);
      return;
    }
    const newFiles = selectedFiles.filter((file) => file.id !== f.id);
    setSelectedFiles(newFiles);
  };

  const selectFile = (file: TAudioFile) => {
    const checked = selectedFiles.findIndex((f) => f.id === file.id) > -1;
    if (!checked) {
      setSelectedFiles([...selectedFiles, file]);
      return;
    }
    const newFiles = selectedFiles.filter((f) => f.id !== file.id);
    setSelectedFiles(newFiles);
  };

  const tableHead = [
    {
      headComponent: () => <td>Name</td>,
      render: (data: TAudioFile) => <p onClick={() => selectFile(data)}>{data?.fileName}</p>,
    },
    {
      headComponent: () => <td></td>,
      render: (data: TAudioFile) => (
        <FileCheckbox file={data} selectedFiles={selectedFiles} onChange={changeSelectedFiles} />
      ),
    },
  ];

  const handleSubmit = async () => {
    if (loading) {
      return;
    }
    onSuccess(selectedFiles);
  };

  return (
    <Wrapper>
      {loading && <Spinner overlay={true} />}
      <h2>Select audio files</h2>

      {files.length ? (
        <CustomTable data={files} head={tableHead} className="customTable" fullPage={false} whiteBackground={true} />
      ) : (
        <p className="text-sm">No audio files</p>
      )}

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Import files</Button>
      </div>
    </Wrapper>
  );
};
