import { TeamCard } from './TeamCard';
import styled from 'styled-components';
import { TRole } from 'app/types/entities/TRole';

const Wrapper = styled.div`
  margin: 20px 0 0;

  & > .list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

type TProps = {
  teams: TRole[];
};

export function TeamCards({ teams }: TProps) {
  return (
    <Wrapper>
      <ul role="list" className="list">
        {teams.map((team, idx) => (
          <TeamCard key={idx} team={team} />
        ))}
      </ul>
    </Wrapper>
  );
}
