import styled from 'styled-components';

export const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: center;
  max-width: 400px;

  & .content {
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0 0 20px;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 20px auto 0;
  }
`;
