import { useState } from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Button } from '@components/form-elements/buttons/Button';
import { Radio } from '@components/form-elements/Radio';
import { CustomTable } from '@components/Table';
import { Tooltip } from '@components/Tooltip';
import { TWorkspace } from 'app/types/entities/TWorkspace';
import { JobsService } from 'app/API';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  input {
    height: 30px;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
    cursor: pointer;
    margin: 12px 0;

    &.disabled {
      color: #d5def2;
      cursor: auto;
    }

    .text {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tbody td > * {
      width: 100%;
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  jobIds: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

export const MoveJobsToWSModal = ({ jobIds, onCancel, onSuccess }: TProps) => {
  const { me, workspace, organization } = useAuthContext();
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(workspace?.id ?? '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const workspaces = me.workspaces.filter((ws) => ws.organizationId === organization?.id);

  const tableHead = [
    {
      render: (data: TWorkspace) => {
        const disabled = workspace?.id === data.id || !organization?.permissions?.submitJobs;
        const disabledInfo =
          selectedWorkspaceId === data.id
            ? 'Cannot move jobs to the same Workspace'
            : `You don't have "Submit Jobs" permission on this Workspace`;
        if (disabled) {
          return (
            <Tooltip key={data.id} content={disabledInfo} placement="bottom">
              <div className="radioWrapper disabled">
                <Radio onChange={() => {}} rounded checked={selectedWorkspaceId === data.id} disabled={true} />
                <span className="text">{data?.name}</span>
              </div>
            </Tooltip>
          );
        }
        return (
          <div className="radioWrapper">
            <Radio onChange={() => setSelectedWorkspaceId(data.id)} rounded checked={selectedWorkspaceId === data.id} />
            <span className="text">{data?.name}</span>
          </div>
        );
      },
      headComponent: () => <td>Workspace Name</td>,
    },
  ];

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await JobsService.moveJobsToWs({
      wsid: workspace?.id ?? '',
      requestBody: { jobIds, workspaceId: selectedWorkspaceId },
    });
    setIsSubmitting(false);
    onSuccess();
    onCancel();
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Move Jobs to another workspace</h2>
      <CustomTable data={workspaces} head={tableHead} className="customTable" fullPage={false} whiteBackground={true} />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={selectedWorkspaceId === workspace?.id} onClick={handleSubmit}>
          Move
        </Button>
      </div>
    </ModalWrapper>
  );
};
