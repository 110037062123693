import { createContext, useContext, useEffect, useState } from 'react';
import { useAPI } from '@hooks/useAPI';

import { AdminService, getDashboardInfoResponse } from 'app/API';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

type TState = {
  dashboard: getDashboardInfoResponse | undefined;
  isLoading: boolean;
};

const PageContext = createContext<TState>({
  dashboard: {} as getDashboardInfoResponse,
  isLoading: false,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const [dashboard, setDashboard] = useState<getDashboardInfoResponse>();

  const { call, isLoading } = useAPI();

  useEffect(() => {
    call(AdminService.getDashboardInfo()).then((res) => {
      setDashboard(res);
    });
  }, []);

  return (
    <PageContext.Provider
      value={{
        dashboard,
        isLoading,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
