import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { getSettingsResponseSetting } from 'app/API';
import { useAuthContext } from '@providers/AuthProvider';

import { CustomTable } from '@components/Table';
import { UniversalCard } from '@components/UniversalCard';
import { getShortName } from '@helpers/getShortName';

import { usePageContext } from '../../providers/PageProvider';

import { Wrapper } from './styles/Wrapper';
import { PencilIcon } from './styles/PencilIcon';

export const Content = () => {
  const { me } = useAuthContext();
  const { isLoading, settings, openEditSettingModal } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>Name</td>,
      render: (data: getSettingsResponseSetting) => <p className="cell">{data?.name}</p>,
    },
    {
      headComponent: () => <td>Value</td>,
      render: (data: getSettingsResponseSetting) => {
        // const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;
        const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;

        return (
          <p className="cell">
            {data?.value}
            {!isVsBilling && data?.name === 'SIGNUP_CREDIT_AMOUNT' ? null : (
              <PencilIcon onClick={(e: React.MouseEvent<SVGElement>) => openEditSettingModal(e, data)} />
            )}
          </p>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <LinearProgressLoader active={isLoading} />
      <div className="line">
        <UniversalCard
          shortName={getShortName('Environment')}
          content={
            <>
              <p>Environment:</p>
              <p>{settings?.env.ENVIRONMENT}</p>
            </>
          }
        />
        <UniversalCard
          shortName={'AB'}
          content={
            <>
              <p>AWS Bucket:</p>
              <p>{settings?.env.AWSBUCKET}</p>
            </>
          }
        />
      </div>

      <CustomTable data={settings?.settings ?? []} head={tableHead} />
    </Wrapper>
  );
};
