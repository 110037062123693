import styled from 'styled-components';

export const ErrorMessage = styled.div`
  color: #ff2f2f;
  text-align: left;
  padding: 5px 0 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`;
