import { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { SubscriptionsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: center;
  max-width: 480px;

  & .content {
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0;
  }

  p.description {
    color: #00122d;
    margin: 30px 0;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
  }
`;

type TProps = {
  subscriptionId: string;
  planName: string;
  price: number;
  onSuccess: () => Promise<void>;
  onCancel: () => void;
  isSubmitting: boolean;
};

export const AdminConfirmSubscriptionCancellation = ({ subscriptionId, planName, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const innerSubmit = async () => {
    setIsSubmitting(true);
    const result = await call(SubscriptionsService.adminRemove({ id: subscriptionId }));
    if (!result) {
      setIsSubmitting(false);
      console.log('Error canceling subscription');
      return;
    }
    await onSuccess();
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 data-testid="purchasePlan">Confirm</h2>
      <p className="description">
        You are about to cancel the &quot;{planName}&quot; plan. All issued licenses will be disabled at the end of your
        billing period. Are you sure you want to cancel?
      </p>

      <div className="buttonContainer">
        <Button type="button" variant="outlined" onClick={onCancel}>
          No
        </Button>
        <Button
          data-testid="submitButton"
          type="submit"
          onClick={() => innerSubmit()}
          variant="contained"
          disabled={isSubmitting}
        >
          Yes
        </Button>
      </div>
    </ModalWrapper>
  );
};
