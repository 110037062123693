import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { formatDateMsDsYHI } from '@helpers/formatDateMsDsYHI';
import { routes } from '@routes';

import { Wrapper } from '@components/Modal/Search/components/Workspaces/styles/Wrapper';
import { StyledLink } from '@components/Modal/Search/components/Workspaces/styles/StyledLink';

import { TProps } from './types/TProps';

export const JobsList = ({ jobs }: TProps) => {
  const { organization } = useAuthContext();
  const { closeModal } = useModalContext();
  return (
    <Wrapper>
      <div className="header">Jobs</div>
      <div className="items">
        {jobs?.map((job) => (
          <div key={job.id} className="item">
            <div className="title">
              <StyledLink
                onClick={() => {
                  closeModal();
                }}
                to={routes.jobDetails.make(organization?.slug ?? '', job.workspace?.slug, job.id)}
              >
                {job.name}
              </StyledLink>
            </div>
            <div className="subtitle">
              {formatDateMsDsYHI(job.createdAt)} • {job.workspace.name}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};
