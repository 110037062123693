import { useAuthContext } from '@providers/AuthProvider';

import { NotificationsEmptyIcon } from './icons/notifications-empty';
import { NotificationsUnseenIcon } from './icons/notifications-unseen';
import { Popover } from '@components/Popover';
import { useState } from 'react';

import { ButtonWrapper } from './styles/ButtonWrapper';
import { NotificationsPopover } from '@components/Header/components/NotificationsIcon/components/NotificationsPopover';

export const NotificationsIcon = () => {
  const { me } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (!me) {
    return null;
  }

  const notifications = me.lastNotifications || [];
  const isUnseen = notifications.some((n: any) => !n.seen);

  return (
    <>
      <ButtonWrapper>
        <div
          className="button"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          {!isUnseen ? (
            <NotificationsEmptyIcon className="notificationIcon" />
          ) : (
            <NotificationsUnseenIcon className="notificationIcon" />
          )}
        </div>
      </ButtonWrapper>

      <Popover
        open={anchorEl !== null}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        styles="
            right: -100px;
            top: -30px;
            box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
            border-radius: 5px;"
      >
        <NotificationsPopover />
      </Popover>
    </>
  );
};
