export const AdminIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7987 23.4849H19.9101C19.9794 23.4845 20.0468 23.4621 20.1025 23.4209C20.1582 23.3796 20.1992 23.3217 20.2197 23.2555L20.7535 21.4529C21.1759 21.3293 21.5839 21.1613 21.9707 20.9515L23.631 21.8369C23.6929 21.8697 23.7637 21.8817 23.8329 21.8711C23.9022 21.8606 23.9662 21.828 24.0154 21.7782L25.2913 20.5035C25.3411 20.4544 25.3738 20.3904 25.3843 20.3213C25.3949 20.2521 25.3829 20.1814 25.35 20.1195L24.4638 18.4662C24.676 18.0783 24.846 17.6689 24.971 17.2449L26.7701 16.7116C26.8363 16.6911 26.8943 16.6501 26.9356 16.5945C26.9769 16.5388 26.9993 16.4715 26.9996 16.4022V14.5836C27.0028 14.5173 26.9856 14.4516 26.9503 14.3953C26.9149 14.3391 26.8633 14.295 26.8021 14.2689L25.0137 13.7356C24.8901 13.3043 24.72 12.8876 24.5065 12.4929L25.3821 10.8556C25.4149 10.7938 25.4269 10.7231 25.4164 10.6539C25.4058 10.5847 25.3732 10.5208 25.3233 10.4716L24.0474 9.1916C23.9982 9.14183 23.9342 9.10924 23.865 9.09867C23.7957 9.0881 23.7249 9.10012 23.6631 9.13294L22.0348 10.0023C21.6358 9.77862 21.2133 9.59975 20.7749 9.46893L20.241 7.70361C20.2185 7.63855 20.1758 7.58233 20.1192 7.54304C20.0626 7.50374 19.995 7.4834 19.9261 7.48494H18.1216C18.0523 7.48527 17.9849 7.50768 17.9292 7.54893C17.8735 7.59018 17.8325 7.6481 17.812 7.71428L17.2781 9.47427C17.0341 9.54611 16.7949 9.63281 16.5619 9.73382C16.8488 10.4795 17.0061 11.29 17.0061 12.1368C17.0061 12.6013 16.9587 13.0549 16.8686 13.4929C16.8944 13.465 16.9207 13.4376 16.9476 13.4107C17.3583 13.0005 17.8815 12.7211 18.451 12.6079C19.0206 12.4948 19.611 12.5529 20.1475 12.7749C20.684 12.9969 21.1426 13.3729 21.4652 13.8552C21.7878 14.3376 21.9601 14.9047 21.9601 15.4849C21.9601 16.2629 21.6507 17.009 21.1001 17.5591C20.5494 18.1092 19.8026 18.4182 19.0239 18.4182C18.4431 18.4182 17.8754 18.2462 17.3926 17.9239C17.3425 17.8904 17.2936 17.8556 17.2459 17.8193C18.0298 18.6482 18.6581 19.6137 19.1001 20.6691C19.4616 21.5299 19.6924 22.4316 19.7893 23.3569C19.7938 23.3999 19.7969 23.4426 19.7987 23.4849Z"
        fill="#858DBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4002 6.48486C7.30342 6.48486 4.79431 9.01458 4.79431 12.1368C4.79431 13.9489 5.64067 15.5629 6.95717 16.598C6.01459 17.0216 5.1658 17.6112 4.43208 18.353C3.66176 19.1281 3.04707 20.0468 2.62288 21.0573C2.29975 21.8245 2.09195 22.6349 2.00528 23.4613C1.94517 24.0344 2.40695 24.4849 2.93953 24.4849C3.46191 24.4849 3.86071 24.0718 3.91757 23.5865C4.08872 22.1256 4.73606 20.7732 5.78408 19.7166C7.01768 18.4729 8.65534 17.7887 10.4002 17.7887C12.1451 17.7887 13.7827 18.4729 15.0163 19.7166C16.0645 20.7733 16.7118 22.1259 16.8829 23.5869C16.9397 24.072 17.3383 24.4849 17.8605 24.4849C18.3934 24.4849 18.8547 24.0338 18.7947 23.461C18.708 22.6327 18.5015 21.8261 18.1777 21.0554C17.7543 20.0443 17.1399 19.1259 16.3688 18.3513C15.6403 17.6153 14.7839 17.0202 13.8433 16.596C15.1598 15.5629 16.0061 13.9488 16.0061 12.1368C16.0061 9.01458 13.497 6.48486 10.4002 6.48486ZM7.78984 9.505C8.48771 8.8014 9.41472 8.41416 10.4002 8.41416C11.3857 8.41416 12.3127 8.8014 13.0106 9.505C13.7084 10.2086 14.0925 11.1432 14.0925 12.1368C14.0925 13.1304 13.7084 14.065 13.0106 14.7686C12.3127 15.4722 11.3857 15.8594 10.4002 15.8594C9.41472 15.8594 8.48771 15.4722 7.78984 14.7686C7.09198 14.065 6.70789 13.1304 6.70789 12.1368C6.70789 11.1432 7.09198 10.2086 7.78984 9.505Z"
        fill="#858DBD"
      />
    </svg>
  );
};
