import { MINIMUM_ACCEPTED_AUDIO_DURATION } from '@components/Modal/PriceModal/constants';

export const errors: {
  [key: string]: string;
} = {
  'err.duration': `Minimum duration accepted is ${MINIMUM_ACCEPTED_AUDIO_DURATION} seconds`,
  'err.internal': 'Internal server error',
  'err.unauthenticated': 'Authentication required',
  'err.password.not_matches': 'Password not matches',
  'err.user.inactive': 'User blocked, contact administrator',
  'err.user.not_found': 'User not found, contact administrator',
  'err.user.password_invalid': 'Invalid password',
  'err.operation.forbidden': 'Forbidden',
  'err.property.restricted': "Property can't be modified",
  'err.item.not_found': 'Item not found',
  'err.registration.too_many_attempts': 'Too many attempts, please wait 1 hour',
  'err.registration.email_already_registered': 'Email already registered',
  'err.registration.email_not_registered': 'Email not registered',
  'err.recovery.too_many_attempts': 'Too many attempts, please wait 1 hour',
  'err.validation.invalid_email': 'Invalid email',
  'err.validation.invalid_account_type': 'Invalid account type',
  'err.password.to_small': 'Password must contain at least 8 characters',
  'err.password.to_large': 'Password must contain no more than 50 characters',
  'err.password.no_num': 'Password must contain at least one number',
  'err.password.no_uppercase': 'Password must contain at least one uppercase character',
  'err.password.no_letter': 'Password must contain at least one letter',
  'err.password.no_symbol': 'Password must contain at least one symbol (@!#$%^&*()_)',
  'Incorrect username or password': 'Incorrect username or password',
  NotAuthorizedException: 'Invalid username or password',
  UserNotConfirmedException: 'User email is not confirmed',
  UserNotFoundException: 'User not found, contact administrator',
  FeatureNotEnabled: 'The specified user does not have this product enabled. Contact the administrator',
  'An account with the given email already exists.': 'An account with the given email already exists.',
  'err.voicescriptEmail': 'Please use an email belonging to voicescript.ai',
  'err.legalproofsEmail':
    'Only @voicescript.ai and @legalproofs.com emails are allowed. For publicly available platform visit <a href="https://autoscript.ai" target="_blank" rel="noreferrer">autoscript.ai</a>',
  'err.agreement': 'Please agree to the terms and conditions',
  'err.ssoProvider': "Please use your company's SSO provider to login",
};
