import { UploadObjectType } from '@constants/enums/uploadObjectType';
import { MediaService, RepositoriesService } from 'app/API';
import { sanitizeFileName } from '@helpers/sanitizeFileName';

export function generateUploadParams(
  objectId: string,
  objectType: UploadObjectType,
  call: (v: Promise<any>) => Promise<any>,
) {
  return async ({
    meta: { name },
  }: {
    meta: {
      name: string;
    };
  }) => {
    const fileName = sanitizeFileName(name);
    const paramsFileName = encodeURIComponent(fileName);
    let resp;
    if (objectType == UploadObjectType.REPOSITORY) {
      resp = await call(RepositoriesService.generateUploadParams({ repositoryId: objectId, file: paramsFileName }));
    } else {
      resp = await call(MediaService.generateUploadParams({ jobId: objectId, file: paramsFileName }));
    }

    const { fields, url } = resp;

    return {
      fields,
      meta: {
        fileUrl: `https://${fields.bucket}.s3.amazonaws.com/${fields.key}`,
      },
      url,
    };
  };
}
