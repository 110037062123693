import styled from 'styled-components';

export const PopArrow = styled.div`
  content: ' ';
  position: absolute;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  top: 31px;
  left: 50%;
  z-index: 10000;
  transform: rotate(180deg);
`;
