import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';

import { Actions } from './components/Actions';

export const UniversalCard = ({
  image,
  shortName,
  shortNameBGColor,
  onClick,
  content,
  actions,
  className = 'universal-card',
}: TProps) => {
  return (
    <Wrapper className={className}>
      <div
        className="entity-name"
        style={{ backgroundColor: image ? '#ffffff' : shortNameBGColor ? shortNameBGColor : '#cadcf8' }}
        onClick={onClick}
      >
        {image ? <img alt="" src={image} /> : shortName}
      </div>
      <div className="card">
        <div className="card-text">{content}</div>
        {actions?.length && actions.length > 0 ? <Actions actions={actions} /> : null}
      </div>
    </Wrapper>
  );
};
