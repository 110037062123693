import styled from 'styled-components';

export const CellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  display: flex;
  align-items: center;
  min-height: 70px;

  .tag {
    display: inline-block;
    position: relative;
    padding: 4px 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #858dbd;
    background: #fff;
    border-radius: 5px;
    margin: 0 5px 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }
`;
