import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAPI } from '@hooks/useAPI';
import { BillingAccountService } from 'app/API';
import { SubscriptionCard } from './SubscriptionCard';
import { NoSubscriptionCard } from './Cards/NoSubscriptionCard';

import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';

const Wrapper = styled.div`
  padding: 20px 0 0;
  display: flex;
  gap: 30px;
`;

type TProps = {
  billingAccount?: TBillingAccount;
  reloadPage?: () => void;
  hasPaymentMethod?: boolean;
};

export const ASOneWrapper = ({ billingAccount, reloadPage, hasPaymentMethod }: TProps) => {
  const { call } = useAPI();
  const [plans, setPlans] = useState<TSubscriptionProduct[]>([]);

  useEffect(() => {
    if (!billingAccount?.id) {
      return;
    }
    (async () => {
      const plans = await call(BillingAccountService.getAsOnePlans());
      setPlans(plans);
    })();
  }, [billingAccount?.id]);

  if (!billingAccount || (billingAccount?.organization && !billingAccount.organization.asOneAllowed)) {
    return <></>;
  }

  if (!billingAccount?.subscriptions?.length) {
    return (
      <Wrapper>
        <NoSubscriptionCard billingAccount={billingAccount} onChange={reloadPage} hasPaymentMethod={hasPaymentMethod} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {(billingAccount.subscriptions ?? []).map((sub) => {
        const plan = plans.find((plan) => plan.id === sub.productId);
        if (!plan) {
          return null;
        }
        return <SubscriptionCard key={sub.id} subscription={sub} plan={plan} reloadPage={reloadPage} />;
      })}
    </Wrapper>
  );
};
