import { client } from 'app/http';
import { AnyMxRecord } from 'dns';

export const adminCreateInvitation = async (
  organizationId: string,
  email: string,
  teams: string[],
): Promise<AnyMxRecord> => {
  const teamIds = teams.filter((teamId: string) => teamId && teamId !== '');
  const { data } = await client.post(`/admin/${organizationId}/invitations`, { email, teamIds });
  return data;
};
