import { useContext, useEffect, useState } from 'react';
import { PermissionContext } from './PermissionContext';
import { EPermission } from 'app/types/enums/EPermission';

export const usePermission = (permission: EPermission) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState<boolean>();

  const { isAllowedTo } = useContext(PermissionContext);

  useEffect(() => {
    isAllowedTo(permission).then((_allowed) => {
      setLoading(false);
      setAllowed(_allowed);
    });
  }, [permission, isAllowedTo]);

  return [loading, allowed];
};
