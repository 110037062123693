import { Fragment } from 'react';
import { Props } from './types/Props';
import { intervalToDuration } from 'date-fns';

const convertSecondsToTime = (seconds: number) => {
  const { hours, minutes, seconds: sec } = intervalToDuration({ start: 0, end: seconds });
  return `${String(hours || 0).padStart(2, '0')}:${String(minutes || 0).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
};

const determineColor = (confidence: number) => {
  return confidence > 0.9
    ? 'transparent'
    : confidence > 0.75
      ? '#FFD5D5'
      : confidence > 0.7
        ? '#FFACAC'
        : confidence > 0.6
          ? '#FF8282'
          : confidence > 0.4
            ? '#FFA7A7'
            : '#FF9191';
};

export const ConfidenceHeatmap = ({ utterances }: Props) => {
  return (
    <div className="heatmap">
      {utterances.map((utterance, idx) => (
        <div className="flex" key={idx}>
          <span className="flex-shrink-0 min-w-36 inline-block mr-4 font-mono">
            <span>{convertSecondsToTime(utterance.start)}</span> | <span>Speaker {utterance.speaker}: </span>
          </span>
          <span className={`confidence-${utterance.confidence} inline-block`}>
            {utterance.words.map((word, idx) => (
              <Fragment key={idx}>
                <span
                  className={`confidence-${word.confidence} inline-block`}
                  style={{ backgroundColor: determineColor(word.confidence) }}
                >
                  {word.text}
                </span>
                &nbsp;
              </Fragment>
            ))}
          </span>
        </div>
      ))}
    </div>
  );
};
