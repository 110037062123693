import { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { TRepository } from 'app/types/entities/TRepository';
import { RepositoriesService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TProps = {
  repository: TRepository;
  onCancel: () => void;
  onSuccess: () => void;
};

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  width: 330px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .repositories-errorMessage {
    color: #ff2f2f;
    margin-top: -20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    color: #858dbd;
    margin: 0;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      width: 130px;
    }
  }
`;

export const DeleteRepositoryModal = ({ repository, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const deleteHandler = async () => {
    setIsSubmitting(true);
    const data = await call(RepositoriesService.remove({ id: repository.id }));
    if (!data.error) {
      onSuccess();
    } else {
      setError(data.error);
    }
    setIsSubmitting(false);
  };

  return (
    <ModalWrapper>
      <h2>Confirm</h2>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <div>
        Are you sure you want to delete &quot;{repository.name}&quot;? <br />
        Associated attachments will also be deleted.
      </div>
      {error && <div className="repositories-errorMessage">{error}</div>}
      <div className="buttonContainer">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={deleteHandler} disabled={isSubmitting}>
          Delete
        </Button>
      </div>
    </ModalWrapper>
  );
};
