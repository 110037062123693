import styled from 'styled-components';
import { useState } from 'react';

import { Button } from '@components/form-elements/buttons/Button';
import { Spinner } from '@components/spinners/Spinner';
import { PositiveIcon } from '@components/icons/Positive';

import { ContactSalesForm } from './ContactSalesForm';
import { ContactInfo } from './ContactInfo';
import { SupportService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const ToastContainer = styled.div`
  width: 340px;
  height: 174px;
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .iconToast {
    height: 60px;
    width: 60px;
  }

  .descriptionToast {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #00122d;
  }
`;
const TopSpinner = styled(Spinner)`
  top: 0;
  left: 0;
`;
const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 702px;
  height: 715px;
  position: relative;
  padding: 30px;

  text-align: center;

  & .content {
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  p.description {
    color: #858dbd;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    position: absolute;
    bottom: 30px;
  }
`;

type TProps = {
  onCancel: () => void;
};

export const initialState = {
  loading: false,
  success: false,
  name: '',
  email: '',
  firm: '',
  phone: '',
  location: '',
  numOfReporters: '',
  numOfTranscriptionists: '',
  additional: '',
};

export const ContactSales = ({ onCancel }: TProps) => {
  const { call } = useAPI();
  const [formState, setFormState] = useState<typeof initialState>(initialState);
  const isButtonDisabled = !formState.firm || !formState.name || !formState.email || !formState.phone;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormState({ ...formState, loading: true });
    await call(
      SupportService.sendSalesEmail({
        requestBody: {
          fullname: formState.name,
          email: formState.email,
          agency: formState.firm,
          phone: formState.phone,
          state: formState.location,
          reporters: formState.numOfReporters,
          transcriptionists: formState.numOfTranscriptionists,
          additional: formState.additional,
        },
      }),
    );
    setFormState({ ...formState, loading: false, success: true });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const updateForm = (name: string, value: string) => {
    setFormState({ ...formState, [name]: value });
  };

  if (formState.success) {
    return (
      <ToastContainer>
        <PositiveIcon className="iconToast" />
        <p className="descriptionToast">
          Thank you for the message!
          <br /> We will contact you soon
        </p>
      </ToastContainer>
    );
  }

  return (
    <Wrapper>
      {formState.loading && <TopSpinner overlay={true} />}
      <h2>Contact Our Sales Team</h2>
      <p className="description">
        Do you have questions regarding AutoScript services, products or anything else? <br /> Send us a note and our
        sales team will get back to you as soon as possible
      </p>
      <form onSubmit={handleSubmit}>
        <div className="content">
          <ContactSalesForm handleChange={handleChange} updateForm={updateForm} formState={formState} />
          <ContactInfo />
        </div>
        <div className="buttonContainer">
          <Button type="button" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isButtonDisabled}>
            Submit
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};
