import { usePageContext } from '../../providers/PageProvider';
import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { MainContent } from './components/MainContent';
import { Wrapper } from './styles/Wrapper';

export const Content = () => {
  const { isLoading } = usePageContext();

  return (
    <>
      <Header title="Job Details" />
      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <MainContent />
      </Wrapper>
    </>
  );
};
