import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { useAPI } from '@hooks/useAPI';
import { OrganizationsService } from 'app/API';
import { useAuthContext } from '@providers/AuthProvider';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

const Wrapper = styled.div`
  width: 300px;
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  position: relative;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #858dbd;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    padding: 20px 0 0;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    .select {
      height: 40px;
    }

    .add-more {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: #40608f;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .note-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: #575757;
    margin-top: 15px;
    margin-bottom: 10px;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  uid: string;
  concurrentJobsLimit: number;
};

export const EditConcurrentJobsLimitModal = ({ onCancel, onSuccess, uid, concurrentJobsLimit }: TProps) => {
  const { call } = useAPI();
  const { organization } = useAuthContext();
  const [newLimit, setNewLimit] = useState(concurrentJobsLimit);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    await call(
      OrganizationsService.changeConcurrentJobsLimit({
        oid: organization?.id ?? '',
        memberId: uid,
        requestBody: { concurrentJobsLimit: newLimit },
      }),
    );
    setIsSubmitting(false);
    onSuccess();
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Edit concurrent jobs limit</h2>
      <form action="#" onSubmit={submit}>
        <div className="inputWrapper">
          <Input
            required
            value={newLimit}
            label="Concurrent jobs limit"
            name="concurrentJobsLimit"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewLimit(Number(e.target.value ?? 0) || 0)}
          />
        </div>
        <div className="note-text">* Enter 0 to remove the limit.</div>
        <div className="buttonWrapper">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Wrapper>
  );
};
