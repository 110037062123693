import React, { useState } from 'react';

import { useAuthContext } from '@providers/AuthProvider';

import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

import { ModalWrapper } from './styles';
import { sanitizeSlug } from '@helpers/sanitizeSlug';
import { WorkspacesService } from '../../../API';
import { useAPI } from '@hooks/useAPI';

type TProps = {
  onCancel: () => void;
};

export const NewWorkspaceModal = ({ onCancel }: TProps) => {
  const { call } = useAPI();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSlugGenerated, setIsSlugGenerated] = useState(true);

  const { updateMe, organization } = useAuthContext();

  const [formValues, setFormValues] = useState<{ name: string; slug: string }>({
    name: '',
    slug: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    slug: '',
  });

  const [queryError, setQueryError] = useState('');

  const isSlugAvailable = async (value: string) => {
    setIsSubmitting(true);
    // only a-z 0-9 and - are allowed
    const regex = /^[a-z0-9-]+$/;
    if (!value.match(regex)) {
      setFormErrors({ ...formErrors, slug: 'Only a-z, 0-9 and - are allowed' });
      setIsSubmitting(false);
      return;
    }

    const data = await call(WorkspacesService.isSlugAvailable({ oid: organization?.id ?? '', wsSlug: value }));
    data.available
      ? setFormErrors({ ...formErrors, slug: '' })
      : setFormErrors({ ...formErrors, slug: 'This URL is already in use' });
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    if (!organization) {
      return;
    }
    setIsSubmitting(true);
    const data = await call(
      WorkspacesService.createWorkspace({
        requestBody: {
          name: formValues.name,
          slug: formValues.slug,
          organizationId: organization.id,
        },
      }),
    );

    if (data) {
      await updateMe();
      setIsSubmitting(false);
      onCancel();
      return;
    }

    setIsSubmitting(false);
    setQueryError(data.error);
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Create New Workspace</h2>

      <div className="workspaceSettings-inputWrapper">
        <div className="inputContainer">
          <label htmlFor="title">Workspace Name</label>
          <Input
            label="Name"
            name="title"
            value={formValues.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (isSlugGenerated) {
                const slug = sanitizeSlug(e.target.value);
                setFormValues({
                  ...formValues,
                  name: e.target.value,
                  slug,
                });
                isSlugAvailable(slug);
              } else {
                setFormValues({
                  ...formValues,
                  name: e.target.value,
                });
              }
            }}
            error={!!formErrors.name}
          />
        </div>

        <div className="inputContainer">
          <label htmlFor="slug">URL</label>
          <Input
            label="URL"
            name="slug"
            value={formValues.slug}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({ ...formValues, slug: e.target.value });
              setIsSlugGenerated(false);
              isSlugAvailable(e.target.value);
            }}
            error={!!formErrors.slug}
          />
        </div>

        <div className="workspaceSettings-errorMessage">{formErrors.slug}</div>
        {queryError && <div className="workspaceSettings-errorMessage largeText">{queryError}</div>}
      </div>
      <div className="buttonContainer">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          disabled={isSubmitting || !!(formErrors.slug || formErrors.name) || !(formValues.slug && formValues.name)}
          onClick={() => handleSubmit()}
        >
          Create
        </Button>
      </div>
    </ModalWrapper>
  );
};
