import React, { useEffect } from 'react';
import { debounce } from 'lodash-es';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { TrashBin } from '@components/icons/TrashBin';
import { CustomTable } from '@components/Table';
import { Textarea } from '@components/form-elements/Textarea';

import { Search } from '@components/icons/Search';
import { TUser } from 'app/types/entities/TUser';

import { TRole } from 'app/types/entities/TRole';
import { OrganizationsService, WorkspacesService } from 'app/API';

import TeamTag from './components/TeamTag';
import TeamEmptyWarningModal from './components/TeamsEmptyWarningModal';
import TeamsFilter from './components/TeamsFilter';
import { useAPI } from '@hooks/useAPI';

const StyledTextarea = styled(Textarea)`
  textarea {
    &::placeholder {
      color: #bcc4f0;
    }
  }
`;

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 600px;

  @media (max-height: 850px) {
    max-height: 90vh;
    overflow: auto;
  }

  table td {
    max-width: 150px;
  }

  .form-input {
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  .assignedTo {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 20px 0 10px;
  }

  .assignee-comment {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 20px 0 10px;
  }

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tr {
      cursor: pointer;
    }

    thead {
      tr {
        cursor: default;

        td,
        th {
          height: auto;
          padding: 10px 0;
        }
      }
    }

    tbody {
      td {
        padding: 10px 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }

  .assignTo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;

    svg {
      cursor: pointer;
    }

    .assignToText {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #00122d;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

type TProps = {
  initialValue: TUser | null;
  onCancel: () => void;
  onSuccess: (assignee: TUser | null, comment: string) => void;
};

export const GetAssigneeModal = ({ initialValue, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { organization, workspace } = useAuthContext();

  const [users, setUsers] = React.useState<TUser[]>([]);
  const [search, setSearch] = React.useState('');
  const [assignee, setAssignee] = React.useState<TUser | null>(initialValue);
  const [comment, setComment] = React.useState('');

  const [chosenTeamsFilter, setChosenTeamsFilter] = React.useState<string[]>([]);
  const [teamsFilterValues, setTeamsFilterValues] = React.useState<TRole[]>([]);
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);

  const userHasManageMembersPermissionForCurrentWorkspace = (organization?.userTeams ?? []).some(
    (userTeam) =>
      userTeam.manageMembers && (workspace?.Teams ?? []).some((workspaceTeam) => workspaceTeam.slug === userTeam.slug),
  );

  useEffect(() => {
    setIsFetchingData(true);

    const debouncedSearch = debounce(() => {
      if (userHasManageMembersPermissionForCurrentWorkspace) {
        call(
          OrganizationsService.getOrgMembers({
            oid: String(organization?.id),
            pageNo: '1',
            pageSize: '20',
            orderBy: '',
            orderType: '',
            search,
            teamsSlugFilter: chosenTeamsFilter.join('|'),
          }),
        )
          .then((result) => setUsers(result.data))
          .finally(() => setIsFetchingData(false));
        return;
      }

      call(
        OrganizationsService.getWorkspaceMembers({
          oid: String(organization?.id),
          workspaceId: String(workspace?.id),
          search,
          teamsSlugFilter: chosenTeamsFilter.join('|'),
        }),
      )
        .then((result) => setUsers(result))
        .finally(() => setIsFetchingData(false));
    }, 500);

    debouncedSearch();

    return () => debouncedSearch.cancel();
  }, [search, chosenTeamsFilter, userHasManageMembersPermissionForCurrentWorkspace]);

  useEffect(() => {
    setIsFetchingData(true);
    const getTeams = () => {
      if (!organization?.slug || !workspace?.slug) {
        return;
      }

      if (userHasManageMembersPermissionForCurrentWorkspace) {
        call(OrganizationsService.getOrgTeams({ oid: organization.id }))
          .then((response) => setTeamsFilterValues(response))
          .finally(() => setIsFetchingData(false));
        return;
      }

      call(WorkspacesService.getTeams({ orgSlug: organization?.slug, wsSlug: workspace?.slug }))
        .then((response) => setTeamsFilterValues(response.result))
        .finally(() => setIsFetchingData(false));
    };

    getTeams();
  }, [organization, workspace, userHasManageMembersPermissionForCurrentWorkspace]);

  const changeAssignee = (data: TUser | null) => {
    if (!data) {
      setAssignee(null);
      return;
    }
    setAssignee(data);
  };

  const tableHead = [
    {
      render: (data: TUser) => <p onClick={() => changeAssignee(data)}>{data?.name}</p>,
      headComponent: () => <td>Name</td>,
    },
    {
      render: (data: TUser) => <p onClick={() => changeAssignee(data)}>{data?.email}</p>,
      headComponent: () => <td>Email</td>,
    },
    {
      render: (data: TUser) => (
        <div className="flex">
          {data?.teams?.map((team) => (
            <TeamTag
              key={team.id}
              team={team}
              active={!chosenTeamsFilter.length || chosenTeamsFilter.includes(team.slug)}
              onClick={() => changeAssignee(data)}
            />
          ))}
        </div>
      ),
      headComponent: () => <td>Teams</td>,
    },
  ];

  const renderAssignee = () => {
    if (!assignee) {
      return;
    }
    return (
      <div className="assignTo">
        <div className="assignToText">
          <p>
            {assignee.name || ''} {assignee.lastname || ''}
          </p>
          {assignee.email ? <p>{assignee.email}</p> : ''}
        </div>
        <TrashBin onClick={() => changeAssignee(null)} />
      </div>
    );
  };

  const handleSubmit = async () => {
    onSuccess(assignee, comment);
  };

  return (
    <ModalWrapper>
      <h2>Set assignee</h2>

      {!teamsFilterValues.length && !isFetchingData ? (
        <TeamEmptyWarningModal
          linkToWorkspacesPage={`/${organization?.slug}/workspaces/${workspace?.slug}/details`}
          workspaceTitle={String(workspace?.name)}
        />
      ) : null}

      <p className="assignedTo">{assignee ? 'Currently assigned to' : 'Currently not assigned'}</p>
      {renderAssignee()}
      <Input
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        label="Search user to assign"
        name="assignUser"
        icon={Search}
        value={search}
      />

      <TeamsFilter
        teamsFilterValues={teamsFilterValues}
        chosenTeamsFilter={chosenTeamsFilter}
        setChosenTeamsFilter={setChosenTeamsFilter}
        isOrganizationTeams={userHasManageMembersPermissionForCurrentWorkspace}
      />

      <CustomTable
        data={users}
        isLoading={isFetchingData}
        head={tableHead}
        className="customTable"
        fullPage={false}
        whiteBackground={true}
      />

      <p className="assignee-comment">Add a comment for assignee</p>
      <StyledTextarea
        label="Type your message here"
        name="assigneeComment"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
        value={comment}
      />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button data-testid="saveButton" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </ModalWrapper>
  );
};
