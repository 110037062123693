import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { TJob } from 'app/types/entities/TJob';
import { jobCanBeDownloaded } from '@helpers/jobStatus/jobCanBeDownloaded';

const { getJobById } = AdminService;

type TState = {
  jobId: string;
  isLoading: boolean;
  job?: TJob;
  showMaterials: boolean;
  showSchedule: boolean;
  showDownloads: boolean;
  manualReload: any;
};

const PageContext = createContext<TState>({
  jobId: '',
  isLoading: false,
  job: undefined,
  showMaterials: false,
  showSchedule: false,
  showDownloads: false,
  manualReload: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const params = useParams() as { id: string };
  const jobId = params?.id ?? '';

  const [job, setJob] = useState<TJob>();
  const [isLoading, setIsLoading] = useState(true);
  const [manualTrigger, setManualTrigger] = useState(false);

  const showDownloads = !!(job?.status && job?.results?.length && jobCanBeDownloaded(job?.status));
  const showMaterials = !!(job?.notes || showDownloads);
  const showSchedule = !!job?.meetingType;

  const manualReload = () => setManualTrigger(!manualTrigger);

  useEffect(() => {
    load();
  }, [jobId, manualTrigger]);

  useEffect(() => {
    const interval = setInterval(async () => await load(), 3000);
    return () => clearInterval(interval);
  }, [jobId]);

  const load = async () => {
    setIsLoading(true);
    call(getJobById({ id: jobId })).then((job: TJob) => {
      setJob(job);
      setIsLoading(false);
    });
  };

  return (
    <PageContext.Provider
      value={{
        jobId,
        isLoading,
        job,
        showMaterials,
        showSchedule,
        showDownloads,
        manualReload,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
