import styled from 'styled-components';

export const Wrapper = styled.div`
  .dzu-dropzone:empty + div {
    margin-top: 0;
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    margin: 10px;
  }
`;
