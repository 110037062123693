export const NotificationsUnseenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9924 14.4042C22.1266 16.3969 22.5833 17.5891 22.9762 18.2645C23.1205 18.5145 23.1965 18.7981 23.1965 19.0868C23.1965 19.3755 23.1205 19.6591 22.9762 19.9091C22.8333 20.1582 22.6272 20.365 22.3786 20.5087C22.13 20.6524 21.8478 20.7278 21.5607 20.7273H8.63345C8.34612 20.7253 8.0643 20.6481 7.81609 20.5034C7.56787 20.3586 7.36191 20.1514 7.21873 19.9023C7.07554 19.6531 7.00013 19.3708 7 19.0835C6.99987 18.7962 7.07503 18.5138 7.218 18.2645C7.68436 17.4627 8.24072 15.9327 8.24072 13.2V12.8727C8.23857 11.971 8.41433 11.0776 8.75793 10.2439C9.10153 9.41014 9.60622 8.65237 10.2431 8.01396C10.88 7.37556 11.6366 6.86906 12.4695 6.52347C13.3024 6.17788 14.1953 6 15.0971 6H15.1462C15.1828 6.00024 15.2194 6.00077 15.2558 6.0016C14.7684 6.59229 14.4019 7.28648 14.1942 8.04626C13.861 8.10835 13.5343 8.20509 13.2196 8.33543C12.6245 8.58195 12.0839 8.94377 11.6292 9.40005C11.1744 9.85633 10.8144 10.3981 10.5699 10.9941C10.3253 11.5901 10.2011 12.2285 10.2044 12.8727V13.2C10.2044 14.7955 10.0162 16.9964 9.17345 18.7636H21.0207C20.4607 17.5892 20.1897 16.2232 20.0709 14.9707C20.7566 14.9 21.405 14.7033 21.9924 14.4042Z"
        fill="#858DBD"
      />
      <path
        d="M18.4095 23.7124C18.5936 23.5283 18.6971 23.2786 18.6971 23.0182C18.6971 22.7578 18.5936 22.5081 18.4095 22.3239C18.2254 22.1398 17.9757 22.0364 17.7153 22.0364H12.4789C12.2185 22.0364 11.9688 22.1398 11.7847 22.3239C11.6005 22.5081 11.4971 22.7578 11.4971 23.0182C11.4971 23.2786 11.6005 23.5283 11.7847 23.7124C11.9688 23.8966 12.2185 24 12.4789 24H17.7153C17.9757 24 18.2254 23.8966 18.4095 23.7124Z"
        fill="#858DBD"
      />
      <path
        className="red"
        d="M23 9.5C23 11.433 21.433 13 19.5 13C17.567 13 16 11.433 16 9.5C16 7.567 17.567 6 19.5 6C21.433 6 23 7.567 23 9.5Z"
        fill="#FF2F2F"
      />
    </svg>
  );
};
