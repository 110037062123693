import styled from 'styled-components';

export const OrgCell = styled.div`
  padding: 6px;
  background-color: #f1f5fb;
  color: #858dbd;
  font-weight: 500;
  font-size: 12px;
  line-height: 90%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  margin: 0 10px 10px 0;
`;
