import { useAuthContext } from '@providers/AuthProvider';
import { Switch } from '@components/form-elements/Switch';

import { TProps } from './types/TProps';
import { EditCellContent } from './styles/EditCellContent';
import { usePageContext } from '@pages/Admin/AdminsListPage/providers/PageProvider';

export const InlineEditRow = ({ data }: TProps) => {
  const { me } = useAuthContext();
  const { editId, editPermission } = usePageContext();

  if (data.id !== editId) {
    return null;
  }

  return (
    <tr>
      <td colSpan={6}>
        <EditCellContent>
          <div className="permissions-head">Permissions</div>
          <div className="permissions-list">
            <div className="permission">
              <Switch
                className="switch"
                checked={data.roles.ROLE_BILLING_ADMIN}
                onChange={() => editPermission(data.id, 'ROLE_BILLING_ADMIN', !data.roles.ROLE_BILLING_ADMIN)}
              />
              <div className="label">Finance management</div>
            </div>

            <div className="permission">
              <Switch
                className="switch"
                checked={data.roles.ROLE_ORG_ADMIN}
                onChange={() => editPermission(data.id, 'ROLE_ORG_ADMIN', !data.roles.ROLE_ORG_ADMIN)}
              />
              <div className="label">Manage Organizations</div>
            </div>

            <div className="permission">
              <Switch
                className="switch"
                checked={data.roles.ROLE_VS_ADMIN}
                disabled={me.id === data.id}
                onChange={() => editPermission(data.id, 'ROLE_VS_ADMIN', !data.roles.ROLE_VS_ADMIN)}
              />
              <div className="label">VS Admin</div>
            </div>

            <div className="permission">
              <Switch
                className="switch"
                checked={data.roles.ROLE_SUPER_ADMIN}
                disabled={me.id === data.id}
                onChange={() => editPermission(data.id, 'ROLE_SUPER_ADMIN', !data.roles.ROLE_SUPER_ADMIN)}
              />
              <div className="label">Super Admin</div>
            </div>

            <div className="permission">
              <Switch
                className="switch"
                checked={data.roles.ROLE_VS_DEV}
                disabled={me.id === data.id}
                onChange={() => editPermission(data.id, 'ROLE_VS_DEV', !data.roles.ROLE_VS_DEV)}
              />
              <div className="label">VS Dev</div>
            </div>
          </div>
        </EditCellContent>
      </td>
    </tr>
  );
};
