// import { useState } from 'react';
import { TProps } from './types/TProps';
import { formatTime } from './helpers/formatTime';

export const ProgressBar = ({ progressBarRef, audioRef, timeProgress, duration }: TProps) => {
  // const [seekableRange, setSeekableRange] = useState({ s: 0, e: 0, p: 0 });

  const handleProgressChange = () => {
    if (!audioRef?.current || !progressBarRef?.current) {
      return;
    }
    audioRef.current.currentTime = Number(progressBarRef.current.value);
  };

  return (
    <div className="progress">
      <div className="time">
        {formatTime(Math.floor(timeProgress))} / {formatTime(Math.floor(duration))}
      </div>
      <input
        className="caret"
        type="range"
        ref={progressBarRef}
        defaultValue="0"
        max={duration}
        onChange={handleProgressChange}
      />
      {/* <div className="caret-wrapper"> */}
      {/*  <div className="seekable" style={{ width: `${seekableRange.p}%` }}></div>*/}
      {/* </div> */}
    </div>
  );
};
