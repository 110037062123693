import { useState } from 'react';
import styled from 'styled-components';
import Avatar from '@rootfox/react-avatar';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { compressBase64Image } from '@helpers/compressBase64Image';

const StyledButton = styled(Button)`
  position: relative;
`;

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  & .buttonWrapper {
    display: flex;
    grid-gap: 10px;
    margin-top: 30px;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: (avatar: string) => void;
};

export function ImageUploadModal({ onCancel, onSuccess }: TProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [preview, setPreview] = useState<null | string>(null);

  const onEmptyUpload = () => {
    setPreview(null);
  };
  const onUploadCropped = async (preview: string) => {
    const compressedPreview = await compressBase64Image(preview);
    setPreview(compressedPreview);
  };

  const submit = () => {
    setIsSubmitting(true);
    onSuccess(preview ?? '');
  };

  return (
    <Wrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <Avatar
        onCrop={onUploadCropped}
        onClose={onEmptyUpload}
        width={400}
        height={400}
        imageWidth={400}
        cropColor="#ffffff"
        backgroundColor="#ffffff"
        label="Choose a file"
        exportAsSquare={true}
        exportQuality={0.8}
        cropShape="rect"
      />
      <div className="buttonWrapper">
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <StyledButton
          disabled={!preview || isSubmitting}
          data-testid="ImageUploadModalSubmitButton"
          variant="contained"
          onClick={submit}
        >
          Save
        </StyledButton>
      </div>
    </Wrapper>
  );
}
