import styled from 'styled-components';

export const Wrapper = styled('div')`
  display: flex;
  padding-bottom: 10px;
  gap: 12px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 32px;

  .audio {
    display: none;
  }

  .controls {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .play {
      svg {
        path {
          fill: #858dbd;
        }
      }

      &:hover svg {
        path {
          fill: #40608f;
        }
      }
    }

    .loading {
      svg {
        animation: rotation 0.3s infinite linear;
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
      }
    }
  }

  .progress {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .time {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #40608f;
    }

    //&:hover input[type='range'] {
    //  &::-webkit-slider-thumb {
    //    opacity: 1;
    //  }
    //
    //  &::-moz-range-thumb {
    //    opacity: 1;
    //  }
    //}

    .caret-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .seekable {
        position: absolute;
        height: 4px;
      }
    }

    input[type='range'] {
      border: none;
      --range-progress: 0;

      -webkit-appearance: none;
      position: relative;
      width: 400px;
      height: 4px;
      border-radius: 4px;
      cursor: pointer;
    }

    input[type='range']::-moz-range-track {
      position: relative;
      background: #e4ebf5;
      width: 400px;
      height: 4px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    input[type='range']::-webkit-slider-runnable-track {
      position: relative;
      background: #e4ebf5;
      width: 400px;
      height: 4px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    /* played progress length - Chrome & safari*/

    input[type='range']::before {
      content: '';
      height: 4px;
      background: #858dbd;
      width: var(--range-progress, 0);
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    /* played progress length - firefox */

    input[type='range']::-moz-range-progress {
      background: #858dbd;
      border-radius: 4px;
      height: 4px;
    }

    /* slider thumb - chrome and safari */

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: 3px solid #ffffff;
      background: #858dbd;
      width: 18px;
      height: 18px;
      margin-top: -7px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      transition: all 0.05s linear;
      box-shadow: 0 0 6px 0 #00000026;
      z-index: 10;
    }

    /* slider thumb - firefox */

    input[type='range']::-moz-range-thumb {
      //opacity: 0;
      -webkit-appearance: none;
      border: 3px solid #ffffff;
      background: #858dbd;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      transition: all 0.05s linear;
      box-shadow: 0 0 6px 0 #00000026;
      z-index: 10;
    }
  }

  .volume {
    height: 22px;

    svg {
      path {
        fill: #858dbd;
      }
    }

    &:hover svg {
      path {
        fill: #40608f;
      }
    }
  }

  .download-button {
    margin-left: 8px;
    cursor: pointer;

    path {
      stroke: #858dbd;
    }

    &:hover {
      path {
        stroke: #40608f;
      }
    }
  }
`;
