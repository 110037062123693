import { routes } from '@routes';

import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { Switch } from '@components/form-elements/Switch';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { PagePagination } from '@components/Pagination';

import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { formatPrice } from '@helpers/formatPrice';

import { TOrganization } from 'app/types/entities/TOrganization';

import { usePageContext } from '../../providers/PageProvider';

import { Plans } from './components/Plans';
import { OrganizationImage } from './components/OrganizationImage';
import { OrganizationInitials } from './components/OrganizationInitials';

import { Wrapper } from './styles/Wrapper';
import { PlusBalanceIcon } from './styles/PlusBalanceIcon';
import { PencilIcon } from './styles/PencilIcon';
import { StyledLink } from './styles/StyledLink';
import { CellContent } from './styles/CellContent';
import { useAuthContext } from '@providers/AuthProvider';

export const Content = () => {
  const { me } = useAuthContext();
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;
  const {
    plans,
    organizations,
    isLoading,
    search,
    setSearch,
    pageSize,
    setPageSize,
    page,
    setPage,
    reloadPage,
    openBalanceModal,
    openRateModal,
    switchASOne,
  } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>Organization</td>,
      render: (data: TOrganization) => (
        <CellContent>
          {data?.image ? <OrganizationImage org={data} /> : <OrganizationInitials org={data} />}
          <StyledLink to={routes.adminOrganizationDetails.make(data.id)}>{data.name}</StyledLink>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Members</td>,
      render: (data: TOrganization) => (
        <CellContent>
          <StyledLink to={routes.adminOrganizationUsersList.make(data.id)}>
            {data.membersCount} {data.membersCount === 1 ? 'member' : 'members'}
          </StyledLink>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Plan</td>,
      render: (data: TOrganization) => {
        if (!data.Subscriptions || !data.Subscriptions.length || !data.Subscriptions[0].Product) {
          return <CellContent></CellContent>;
        }
        return (
          <CellContent>
            {data.Subscriptions.map((subscription) => {
              const planName = (subscription.Product?.name ?? '').replace('AutoScriptOne', '');
              return (
                <div key={subscription.id} className="tag">
                  {planName}
                </div>
              );
            })}
          </CellContent>
        );
      },
    },
    {
      headComponent: () => <td>Subscriptions Allowed</td>,
      render: (data: TOrganization) => (
        <CellContent>
          <Switch
            disabled={isLoading || !isVsBilling}
            checked={data.asOneAllowed}
            onChange={() => switchASOne(data.id, !data.asOneAllowed)}
          />
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Balance</td>,
      render: (data: TOrganization) => (
        <CellContent>
          {formatCentsPrice(data.currentBalance ?? 0)}
          {isVsBilling && data.billingAccountId ? (
            <PlusBalanceIcon
              onClick={(e: React.MouseEvent<SVGElement>) => openBalanceModal(e, data.billingAccountId ?? '')}
            />
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Rate</td>,
      render: (data: TOrganization) => (
        <CellContent>
          {data.BillingAccount ? (
            <>
              {data.BillingAccount.fixedRate ? 'Fixed: ' : ''}
              {formatPrice(data.BillingAccount.postProcessingASRRate ?? 0)}/hr
              {isVsBilling ? (
                <PencilIcon
                  onClick={(e: React.MouseEvent<SVGElement>) => openRateModal(e, data.BillingAccount?.id ?? '')}
                />
              ) : null}
            </>
          ) : null}
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Header title="Subscriptions" setSearch={setSearch} search={search} />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <Plans
          plans={plans}
          onChange={() => {
            reloadPage();
          }}
        />
        <CustomTable head={tableHead} data={organizations.data} />
        <PagePagination
          totalItemsCount={organizations.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Wrapper>
    </>
  );
};
