import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  .audioFiles {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 20px 0 10px;
  }

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tr {
      cursor: pointer;
    }

    thead {
      tr {
        cursor: default;

        td,
        th {
          height: auto;
          padding: 10px 0;
        }
      }
    }

    tbody {
      td {
        padding: 10px 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }

  .assignTo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;

    svg {
      cursor: pointer;
    }

    .assignToText {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #00122d;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
