import styled from 'styled-components';

import { STATES } from '@constants/states';

import { Input } from '@components/form-elements/Input';
import { Select } from '@components/form-elements/Select';
import { Textarea } from '@components/form-elements/Textarea';

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  text-align: left;

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #40608f;
    margin: 0;
  }
`;

const StyledInput = styled(Input)`
  height: 30px;

  input {
    height: 30px;

    &::placeholder {
      color: #bcc4f0;
    }
  }
`;

const StyledTextarea = styled(Textarea)`
  textarea {
    &::placeholder {
      color: #bcc4f0;
    }
  }
`;

const StyledSelect = styled(Select)`
  .select {
    padding: 7px 10px;
  }

  .label {
    left: 16px;
    color: #bcc4f0;

    &--selected {
      color: #00122d;
    }
  }

  .optionsLabel {
    padding: 8px 0;
  }
`;

const stateOptions = STATES.map((state) => {
  return { value: state.value, label: state.name };
});

type TForm = {
  firm: string;
  name: string;
  email: string;
  phone: string;
  location: string;
  numOfReporters: string;
  numOfTranscriptionists: string;
  additional: string;
};

type TProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  updateForm: (name: string, value: string) => void;
  formState: TForm;
};

export const ContactSalesForm = ({ handleChange, formState, updateForm }: TProps) => (
  <Wrapper>
    <h3>Get in touch</h3>
    <StyledInput label="Firm/Agency" name="firm" onChange={handleChange} value={formState.firm} required />
    <StyledInput label="Full Name" name="name" onChange={handleChange} value={formState.name} required />
    <StyledInput label="Email" type="email" name="email" onChange={handleChange} value={formState.email} required />
    <StyledInput label="Phone" name="phone" onChange={handleChange} value={formState.phone} required />
    <StyledSelect
      options={stateOptions}
      label="State you are located"
      name="location"
      value={formState.location}
      onChange={(name, value) => updateForm(name, value)}
    />
    <StyledInput
      label="Number of Reporters you have"
      name="numOfReporters"
      onChange={handleChange}
      value={formState.numOfReporters}
    />
    <StyledInput
      label="Number of Transcriptionists you have"
      name="numOfTranscriptionists"
      onChange={handleChange}
      value={formState.numOfTranscriptionists}
    />
    <StyledTextarea
      label="Additional information"
      name="additional"
      onChange={handleChange}
      value={formState.additional}
    />
  </Wrapper>
);
