import styled from 'styled-components';
import { getShortName } from '@helpers/getShortName';
import { TRole } from 'app/types/entities/TRole';
import { TUser } from 'app/types/entities/TUser';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div`
  display: flex;
  gap: 9px;
  flex-wrap: wrap;

  .team {
    display: flex;
    gap: 10px;
    width: 265px;
    background: #ffffff;
    border-radius: 5px;

    .team-shortName {
      flex: 0 0 auto;
      width: 72px;
      height: 72px;
      background: #cadcf8;
      border-radius: 5px;
      font-style: normal;
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      line-height: 72px;
      letter-spacing: 0.01em;
      color: #ffffff;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .team-right {
      padding: 10px 0;

      .team-name {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #40608f;
        width: 173px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .team-org {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        width: 173px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

type TProps = {
  user: TUser;
};

export const Teams = ({ user }: TProps) => {
  if (!user) {
    return null;
  }

  const renderTeam = (team: TRole) => {
    const teamShortname = getShortName(team.name || team.slug);
    const org = (user.orgRelations ?? []).find((organization) => organization.id === team.organizationId);
    return (
      <div className="team" key={team.id}>
        <div className="team-shortName" style={{ backgroundColor: team.colour || '#cadcf8' }}>
          {teamShortname}
        </div>
        <div className="team-right">
          <div className="team-name">{team.name}</div>
          {!!org && <div className="team-org">{org.name}</div>}
        </div>
      </div>
    );
  };

  return (
    <>
      <Wrapper>
        <LeftSide>
          <h3>Teams</h3>
        </LeftSide>

        <RightSide>{(user.teams ?? []).map((team) => renderTeam(team))}</RightSide>
      </Wrapper>
    </>
  );
};
