import styled from 'styled-components';

export const PickerHeader = styled.div`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;

  .current-month {
    font-size: 11px;
    font-weight: 500;
    line-height: 26px;
    color: #40608f;
  }

  svg {
    cursor: pointer;
  }
`;
