export const DesktopIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 15.8V4.6C2 4.17565 2.16857 3.76869 2.46863 3.46863C2.76869 3.16857 3.17565 3 3.6 3H16.4C16.8243 3 17.2313 3.16857 17.5314 3.46863C17.8314 3.76869 18 4.17565 18 4.6V15.8C18 16.2243 17.8314 16.6313 17.5314 16.9314C17.2313 17.2314 16.8243 17.4 16.4 17.4H3.6C3.17565 17.4 2.76869 17.2314 2.46863 16.9314C2.16857 16.6313 2 16.2243 2 15.8Z"
        stroke="#858DBD"
        strokeWidth="1.2"
      />
      <path
        d="M2 6.20001H18M4.4 4.60801L4.408 4.59921M6.8 4.60801L6.808 4.59921M9.2 4.60801L9.208 4.59921"
        stroke="#858DBD"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
