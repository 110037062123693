import { usePermission } from './usePermission';
import { EPermission } from 'app/types/enums/EPermission';

type RestrictedProps = {
  to: EPermission;
  fallback?: JSX.Element | string;
  loadingComponent?: JSX.Element | string;
  children: JSX.Element | string;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
export const Restricted = ({ to, fallback, loadingComponent, children }: RestrictedProps) => {
  const [loading, allowed] = usePermission(to);

  if (loading) {
    return <>{loadingComponent}</>;
  }

  if (allowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
