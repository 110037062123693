export enum EPipelineTimestampsNamesByKey {
  submitJob = 'Submit job',
  preprocessorPreprocessingConsumerStarted = '[Preprocessor] started',
  preprocessorDownloadingFilesStarted = '[Preprocessor] downloading files started',
  preprocessorFilesSentToDolbyApiDiagnosis = '[Preprocessor] files successfully sent to dolby api diagnosis',
  preprocessorFfmpegProcessingStarted = '[Preprocessor] ffmpeg started',
  preprocessorUploadOutputWavStarted = '[Preprocessor] started uploading wav',
  preprocessorUploadOutputMp3Started = '[Preprocessor] started uploading mp3',
  preprocessorCleanTemporaryFilesStarted = '[Preprocessor] cleaning files',
  preprocessorPreprocessingFinished = '[Preprocessor] finished',
  preprocessorSentToNextService = '[Preprocessor] sent to next service',
  submitterStartedProcessing = '[Submitter] started processing',
  submitterGotResultFromTranscriptService = '[Submitter] got result with transcription',
  submitterSentToNextService = '[Submitter] finished and sent to next service',
}
