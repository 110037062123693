import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { usePageContext } from '../../providers/PageProvider';
import { ConfidenceHeatmap } from './components/ConfidenceHeatmap';
import { LineHeatmap } from './components/LineHeatmap';

import { Wrapper } from './styles/Wrapper';
import { Tabs } from './components/Tabs';

export const Content = () => {
  const { isLoading, utterances, tab, setTab } = usePageContext();

  return (
    <Wrapper>
      <Tabs tab={tab} setTab={setTab} />
      {isLoading && <LinearProgressLoader active={isLoading} />}
      {tab === 'words' && utterances.length > 0 && <ConfidenceHeatmap utterances={utterances} />}
      {tab === 'lines' && utterances.length > 0 && <LineHeatmap utterances={utterances} />}
    </Wrapper>
  );
};
