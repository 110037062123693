import styled from 'styled-components';

type TWrapperProps = {
  styles: string;
};

export const Wrapper = styled.div<TWrapperProps>`
  width: max-content;
  height: max-content;
  z-index: 10000;
  background: #ffffff;
  position: absolute;
  margin-top: 80px;
  margin-right: 20px;
  overflow: hidden;
  ${(props) => props.styles}
`;
