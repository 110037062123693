import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { routes } from '@routes';
import { RepositoriesService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { EACCEPTED_DOCUMENT_FORMATS } from 'app/types/enums/EACCEPTED_DOCUMENT_FORMATS';
import { EACCEPTED_AUDIO_FORMATS } from 'app/types/enums/EACCEPTED_AUDIO_FORMATS';

import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';

import { mapAttachmentsToIFileWithMeta, RepositoryAttachment } from './utils';
import { MainBlock, TForm } from './components/blocks/MainBlock';

const Content = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);
`;

const RootWrapperDiv = styled('div')`
  padding: 30px 30px 0;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  .uploadDescription {
    color: #575757;
  }

  .multipleFilesHandling {
    .description {
      padding: 10px 0 0;
      color: #878787;
    }
  }

  ul {
    margin: 0;
    color: #878787;
    padding: 0 0 0 18px;
  }

  .errorMessage {
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    color: #ff2f2f;
    height: 12px;
  }
`;

export const AdminRepositoryPage = () => {
  const { call } = useAPI();
  const params = useParams<{ id: string }>();
  const [form, setForm] = useState<TForm>({
    id: params.id ?? '',
    organizationId: '',
    name: '',
    slug: '',
    notes: '',
    audioFiles: [],
    documentFiles: [],
    assignee: null,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isFilesLoading, setIsFilesLoading] = useState(true);

  const [audioFilesFromServer, setAudioFilesFromServer] = useState<TFileWithMeta[]>([]);
  const [allAudioFiles, setAllAudioFiles] = useState<any[]>([]);

  const [documentFilesFromServer, setDocumentFilesFromServer] = useState<TFileWithMeta[]>([]);
  const [allDocumentFiles, setAllDocumentFiles] = useState<any[]>([]);

  useEffect(() => {
    call(RepositoriesService.adminFindOne({ id: form.id })).then((res) => {
      const audioFilesFromServer = res.Attachments
        ? res.Attachments.filter((file: RepositoryAttachment) =>
            EACCEPTED_AUDIO_FORMATS.includes('.' + file.fileName.split('.').pop()?.toLowerCase()),
          )
        : [];

      const documentFilesFromServer = res.Attachments
        ? res.Attachments.filter((file: RepositoryAttachment) =>
            EACCEPTED_DOCUMENT_FORMATS.includes('.' + file.fileName.split('.').pop()?.toLowerCase()),
          )
        : [];

      setForm({
        id: res.id,
        organizationId: res.organizationId,
        name: res.name,
        slug: res.slug,
        notes: res.notes,
        assignee: res.Assignee,
        audioFiles: audioFilesFromServer,
        documentFiles: documentFilesFromServer,
      });

      if (audioFilesFromServer) {
        setAudioFilesFromServer(mapAttachmentsToIFileWithMeta(audioFilesFromServer as any[]));
        setAllAudioFiles(mapAttachmentsToIFileWithMeta(audioFilesFromServer as any[]));
      }

      if (documentFilesFromServer) {
        setDocumentFilesFromServer(mapAttachmentsToIFileWithMeta(documentFilesFromServer as any[]));
        setAllDocumentFiles(mapAttachmentsToIFileWithMeta(documentFilesFromServer as any[]));
      }

      setIsLoading(false);
      setIsFilesLoading(false);
    });
  }, [params.id]);

  useEffect(() => {
    const removedFilter = (file: TFileWithMeta) => file.meta.status !== 'removed';
    setAllAudioFiles(
      [...audioFilesFromServer.filter(removedFilter)].map((f, idx) => {
        return { ...f, weight: idx };
      }),
    );
  }, [audioFilesFromServer]);

  useEffect(() => {
    const removedFilter = (file: TFileWithMeta) => file.meta.status !== 'removed';
    setAllDocumentFiles(
      [...documentFilesFromServer.filter(removedFilter)].map((f, idx) => {
        return { ...f, weight: idx };
      }),
    );
  }, [documentFilesFromServer]);

  return (
    <>
      <Helmet>
        <title>{form.name} - AutoScript</title>
      </Helmet>
      <Header title={form.name} backRoute={routes.adminOrganizationDetails.make(form?.organizationId)} />

      <LinearProgressLoader active={isLoading} />
      <Content>
        <form className="flex flex-col">
          <RootWrapperDiv>
            {form.name ? (
              <MainBlock
                repositoryId={params.id ?? ''}
                audioFiles={allAudioFiles}
                audioFilesFromServer={audioFilesFromServer}
                documentFiles={allDocumentFiles}
                documentFilesFromServer={documentFilesFromServer}
                isFilesLoading={isFilesLoading}
                form={form}
              />
            ) : null}
          </RootWrapperDiv>
        </form>
      </Content>
    </>
  );
};
