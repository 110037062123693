export const SupportIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 15.517C14.418 15.517 18 12.491 18 8.75902C18 5.02602 14.418 2.00002 10 2.00002C5.582 2.00002 2 5.02602 2 8.75902C2 10.216 2.546 11.566 3.475 12.669L2.5 17.25L6.416 14.803C7.55075 15.2788 8.76956 15.5216 10 15.517Z"
        stroke="#858DBD"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.999 10C10.499 10 10.999 9.50002 10.999 9.00002C10.999 8.50002 10.499 8.00002 9.999 8.00002C9.499 8.00002 9 8.50002 9 9.00002C9 9.50002 9.499 10 9.999 10ZM5.999 10C6.499 10 6.999 9.50002 6.999 9.00002C6.999 8.50002 6.499 8.00002 5.999 8.00002C5.499 8.00002 5 8.50002 5 9.00002C5 9.50002 5.499 10 5.999 10ZM13.999 10C14.499 10 15 9.50002 15 9.00002C15 8.50002 14.5 8.00002 14 8.00002C13.5 8.00002 13 8.50002 13 9.00002C13 9.50002 13.5 10 14 10H13.999Z"
        fill="#858DBD"
      />
    </svg>
  );
};
