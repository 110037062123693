import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { useState } from 'react';
import { JobsService } from 'app/API';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { useAuthContext } from '@providers/AuthProvider';
import { pluralize } from '@helpers/pluralize';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: center;
  max-width: 400px;

  & .content {
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0;
  }

  p.description {
    color: #00122d;
    margin: 30px 0;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
  }
`;

type TProps = {
  jobIds: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

export const DeleteJobsModal = ({ onCancel, onSuccess, jobIds }: TProps) => {
  const { workspace } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await JobsService.softDeleteJobs({
      wsid: workspace?.id ?? '',
      requestBody: { jobIds },
    });
    setIsSubmitting(false);
    onSuccess();
    onCancel();
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Confirm</h2>
      <p className="description">
        Are you sure you want to delete {pluralize(jobIds.length, 'this job', `these ${jobIds.length} jobs`)}?
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="buttonContainer">
          <Button type="button" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Delete
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
};
