import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TUser } from 'app/types/entities/TUser';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TState = {
  id: string;
  isLoading: boolean;
  users: TUser[];
  openInviteUserModal: () => void;
};

const PageContext = createContext<TState>({
  id: '',
  isLoading: false,
  users: [],
  openInviteUserModal: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const [users, setUsers] = useState<TUser[]>([]);
  const { openModal, closeModal } = useModalContext();

  const fetch = async () => {
    setIsLoading(true);
    const res = await call(AdminService.getInvitations({ oid: id ?? '' }));
    setUsers(res.result);
    setIsLoading(false);
  };

  const openInviteUserModal = () => {
    openModal(ModalNamesEnum.AdminInviteUserModal, {
      organizationId: id,
      onSuccess: () => {
        closeModal();
        setReloadPageTrigger((prevState) => !prevState);
      },
    });
  };

  useEffect(() => {
    fetch();
  }, [id, reloadPageTrigger]);

  return (
    <PageContext.Provider
      value={{
        id: id ?? '',
        isLoading,
        users,
        openInviteUserModal,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
