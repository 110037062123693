import styled from 'styled-components';

const getTooltipPosition = (placement: 'top' | 'bottom' | 'left' | 'right') => {
  switch (placement) {
    case 'top':
      return { bottom: 'calc(100% + 10px)', left: '-50%' };
    case 'bottom':
      return { top: 'calc(100% + 10px)', left: '50%', transform: 'translate(-50%, 0)' };
    case 'left':
      return { right: 'calc(100% + 10px)', top: '20%' };
    case 'right':
      return { left: 'calc(100% + 10px)', top: '20%' };
    default:
      return { bottom: 'calc(100% + 10px)', left: '50%', transform: 'translate(-50%, 0)' };
  }
};

const getArrowPosition = (placement: 'top' | 'bottom' | 'left' | 'right') => {
  switch (placement) {
    case 'top':
      return { bottom: '-15px', left: '50%' };
    case 'bottom':
      return { top: '-15px', left: '50%', transform: 'rotate(180deg)' };
    case 'left':
      return { right: '-15px', top: '20%', transform: 'rotate(-90deg)' };
    case 'right':
      return { left: '-9px', top: '20%', transform: 'rotate(90deg)' };
    default:
      return { bottom: '-15px', left: '50%' };
  }
};

type TProps = {
  placement: 'top' | 'bottom' | 'left' | 'right';
};

export const Wrapper = styled.div<TProps>`
  position: relative;
  display: inline-block;
  overflow: visible !important;

  & .tooltiptext {
    visibility: hidden;
    width: auto;
    min-width: 150px;
    padding: 5px 10px;
    background-color: #00122d;
    text-align: center;
    border-radius: 5px;
    overflow: visible;

    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;

    position: absolute;
    z-index: 10000;
    ${(props) => getTooltipPosition(props.placement)}
  }

  &:hover .tooltiptext {
    visibility: visible;
  }

  & .tooltiptext::after {
    content: ' ';
    position: absolute;
    margin-left: -6px;
    border-width: 8px;
    border-style: solid;
    border-color: #00122d transparent transparent transparent;
    ${(props) => getArrowPosition(props.placement)}
  }
`;
