import { client } from 'app/http';
import { ElementRef, useCallback, useEffect, useRef, useState } from 'react';
import { fakeDownloadClick } from '@helpers/jobLinkHelpers';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { DownloadJob } from '@components/icons/DownloadJob';

import { Controls } from './components/Controls';
import { ProgressBar } from './components/ProgressBar';
import { Volume } from '@components/AudioPlayer/components/Volume';

export const AudioPlayer = ({ className = 'audio-player', src }: TProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<ElementRef<'audio'>>(null);
  const progressBarRef = useRef<ElementRef<'input'>>(null);
  const playAnimationRef = useRef<number>();

  const repeat = useCallback(() => {
    if (!progressBarRef?.current || !audioRef?.current) {
      return;
    }
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime.toString();
    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(Number(progressBarRef.current.value) / duration) * 100}%`,
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef]);

  useEffect(() => {
    if (!audioRef?.current) {
      return;
    }
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  const download = () => {
    client
      .get(src, {
        responseType: 'blob',
      })
      .then(fakeDownloadClick('output.mp3'));
  };

  const onLoadedMetadata = () => {
    if (!audioRef.current || !progressBarRef.current) {
      return;
    }
    const seconds = audioRef.current.duration;
    setDuration(seconds);
  };

  if (!src) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <audio
        className="audio"
        src={src}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onSeeking={() => setIsLoading(true)}
        onSeeked={() => setIsLoading(false)}
        onWaiting={() => setIsLoading(true)}
        onEnded={() => {
          setIsPlaying(false);
        }}
      />
      <Controls
        {...{
          disabled: !progressBarRef?.current || !duration,
          isPlaying,
          isLoading,
          setIsPlaying,
        }}
      />
      <ProgressBar {...{ progressBarRef, audioRef, timeProgress, duration }} />
      <Volume audioRef={audioRef} />
      <DownloadJob className="download-button" onClick={() => download()} />
    </Wrapper>
  );
};
