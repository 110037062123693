import styled from 'styled-components';
import { CircularProgress } from '@components/spinners/CircularProgress';

export const StyledCircularProgress = styled(CircularProgress)`
  position: relative;

  .svg-pi {
    transform: rotate(-90deg); /* Fix the orientation */
    height: 20px;
    width: 20px;
  }
`;
