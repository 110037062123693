import styled from 'styled-components';

export const Wrapper = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);

  & .line {
    padding: 20px 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .universal-card {
    flex: 1 1 auto;
  }

  .cell {
    display: flex;
    gap: 10px;
    padding: 20px 0;
  }
`;
