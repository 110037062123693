import React from 'react';

export const Warning = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.80005 14.4998C8.80005 14.1815 8.92648 13.8763 9.15152 13.6513C9.37656 13.4262 9.68179 13.2998 10 13.2998C10.3183 13.2998 10.6235 13.4262 10.8486 13.6513C11.0736 13.8763 11.2 14.1815 11.2 14.4998C11.2 14.8181 11.0736 15.1233 10.8486 15.3483C10.6235 15.5734 10.3183 15.6998 10 15.6998C9.68179 15.6998 9.37656 15.5734 9.15152 15.3483C8.92648 15.1233 8.80005 14.8181 8.80005 14.4998Z"
        fill="#FFAAAA"
      />
      <path
        d="M9.30005 5.01311L9.31356 4.8611C9.34477 4.70454 9.42861 4.56321 9.55133 4.46071C9.67722 4.35557 9.83603 4.29797 10 4.29797C10.1641 4.29797 10.3229 4.35557 10.4488 4.46071C10.5715 4.56321 10.6553 4.70454 10.6865 4.8611L10.7 5.01311V9.9865L10.6865 10.1385C10.6553 10.2951 10.5715 10.4364 10.4488 10.5389C10.3229 10.644 10.1641 10.7016 10 10.7016C9.83603 10.7016 9.67722 10.644 9.55133 10.5389C9.42861 10.4364 9.34477 10.2951 9.31356 10.1385L9.30005 9.9865V5.01311Z"
        fill="#FFAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7001 9.9998C19.7001 12.5724 18.6781 15.0396 16.859 16.8587C15.0399 18.6778 12.5726 19.6998 10 19.6998C7.42745 19.6998 4.96022 18.6778 3.14111 16.8587C1.32201 15.0396 0.300049 12.5724 0.300049 9.9998C0.300049 7.42721 1.32201 4.95997 3.14111 3.14087C4.96022 1.32177 7.42745 0.299805 10 0.299805C12.5726 0.299805 15.0399 1.32177 16.859 3.14087C18.6781 4.95997 19.7001 7.42721 19.7001 9.9998ZM18.3 9.9998C18.3 7.79851 17.4256 5.68737 15.869 4.13082C14.3125 2.57427 12.2013 1.6998 10 1.6998C7.79875 1.6998 5.68761 2.57427 4.13106 4.13082C2.57451 5.68737 1.70005 7.79851 1.70005 9.9998C1.70005 12.2011 2.57451 14.3122 4.13106 15.8688C5.68761 17.4253 7.79875 18.2998 10 18.2998C12.2013 18.2998 14.3125 17.4253 15.869 15.8688C17.4256 14.3122 18.3 12.2011 18.3 9.9998Z"
        fill="#FFAAAA"
      />
    </svg>
  );
};
