import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const OrganizationLink = styled(RouterLink)`
  /* height: 22px; */
  padding: 6px;
  background: white;
  color: #858dbd;
  font-weight: 500;
  font-size: 12px;
  line-height: 90%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  margin: 0 10px 10px 0;
  text-decoration: none;

  &:hover {
    color: #40608f;
  }
`;
