import { useRef } from 'react';

import { Button } from '@components/form-elements/buttons/Button';
import { Textarea } from '@components/form-elements/Textarea';

import { Props } from './types/Props';
import { UploadFile } from './icons/UploadFile';

export const FirstStage = ({ left, right, setLeft, setRight, setStage }: Props) => {
  const leftFileRef = useRef<HTMLInputElement | null>(null);
  const rightFileRef = useRef<HTMLInputElement | null>(null);

  const uploadLeft = (file: File) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      if (typeof e.target?.result === 'string') {
        setLeft(e.target.result || '');
      }
    };
    reader.readAsText(file);
  };

  const uploadRight = (file: File) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      if (typeof e.target?.result === 'string') {
        setRight(e.target.result || '');
      }
    };
    reader.readAsText(file);
  };

  const onLeftClick = () => {
    if (leftFileRef.current) {
      leftFileRef.current.click();
    }
  };

  const onRightClick = () => {
    if (rightFileRef.current) {
      rightFileRef.current.click();
    }
  };

  const onLeftFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) {
      return;
    }

    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    uploadLeft(fileObj);
    event.target.value = '';
  };

  const onRightFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) {
      return;
    }

    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    uploadRight(fileObj);
    event.target.value = '';
  };

  return (
    <div className="first-stage">
      <div className="flex-block">
        <div className="left-wrapper">
          <div className="head-line">
            <div className="title">Original text</div>
            <input style={{ display: 'none' }} type="file" ref={leftFileRef} onChange={onLeftFileChange} />
            <UploadFile onClick={() => onLeftClick()} />
          </div>
          <Textarea value={left} onChange={(e) => setLeft(e.target.value)} label="" />
        </div>
        <div className="right-wrapper">
          <div className="head-line">
            <div className="title">Changed text</div>
            <input style={{ display: 'none' }} type="file" ref={rightFileRef} onChange={onRightFileChange} />
            <UploadFile onClick={() => onRightClick()} />
          </div>
          <Textarea value={right} onChange={(e) => setRight(e.target.value)} label="" />
        </div>
      </div>
      <Button onClick={() => setStage(2)}>Find Differences</Button>
    </div>
  );
};
