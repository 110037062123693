import { useState } from 'react';
import { DatePicker } from '@components/form-elements/DatePicker';
import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { CloseIcon } from '@components/icons/Close';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';

const Close = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
    fill: #858dbd;
  }
`;

const Wrapper = styled.div`
  min-height: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: default;
`;

const FilterTitle = styled.h3`
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 0 15px;
  color: #858dbd;
  border-bottom: 1px solid #e1e1e1;
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
`;

const SuggestionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 5px;
`;

const FormItemLabel = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  padding: 0 0 15px;
  color: #858dbd;
`;

const DatePickerWrapper = styled.div`
  padding: 0 0 12px;
`;

const BottomButton = styled(Button)`
  width: 25%;
  margin-right: 10px;
`;

const firstDayOfCurrentWeek = () => {
  const dayOfWeek = new Date().getDay();
  const firstDayOfWeek = new Date();
  const diff = dayOfWeek >= 0 ? dayOfWeek : 6 - dayOfWeek;
  firstDayOfWeek.setDate(new Date().getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);
  return firstDayOfWeek;
};

const firstDayOfCurrentMonth = () => {
  const firstDayOfMonth = new Date();
  firstDayOfMonth.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDate() + 1);
  firstDayOfMonth.setHours(0, 0, 0, 0);
  return firstDayOfMonth;
};

const startOfCurrentDay = () => {
  const currentDay = new Date();
  currentDay.setHours(0, 0, 0, 0);
  return currentDay;
};

const getSelectedDateFilterValue = (jobsFilter: TJobsFilter) => {
  let to = jobsFilter.to || 0;
  let from = jobsFilter.from || 0;
  const today = startOfCurrentDay().getTime();
  const thisWeekStart = firstDayOfCurrentWeek().getTime();
  const thisMonthStart = firstDayOfCurrentMonth().getTime();
  if (from) {
    from = parseInt(new Date(from).setHours(0, 0, 0, 0).toString(), 10);
  }
  if (to) {
    to = parseInt(new Date(to).setHours(0, 0, 0, 0).toString(), 10);
  }
  if (from === today && to === today) {
    return 'today';
  }
  if (from === thisWeekStart && to === today) {
    return 'week';
  }
  if (from === thisMonthStart && to === today) {
    return 'month';
  }
  return '';
};

type TProps = {
  filter: TJobsFilter;
  filterOnChange: (arg: TJobsFilter) => void;
  handleSubmit: () => void;
  handleClose: () => void;
};

export const Filter = ({ filter, filterOnChange, handleSubmit, handleClose }: TProps) => {
  const [tempDateFilterSelected, setTempDateFilterSelected] = useState(getSelectedDateFilterValue(filter));
  const [tempFilter, setTempFilter] = useState(filter);
  const tempSubmit = () => {
    if (filterOnChange) {
      filterOnChange(tempFilter);
    }
    handleSubmit();
    handleClose();
  };
  const tempClose = () => {
    if (filterOnChange) {
      filterOnChange(filter);
    }
    handleClose();
  };

  return (
    <Wrapper>
      <FilterTitle>Filters</FilterTitle>
      <Close onClick={tempClose}>
        <CloseIcon />
      </Close>

      <FormItemLabel>Created</FormItemLabel>
      <DatePickerWrapper>
        <DatePicker
          label="From"
          minDate={new Date(0)}
          maxDate={tempFilter.to || new Date()}
          value={tempFilter.from}
          onChange={(date: Date | null) =>
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = date ?? undefined;
              return newVal;
            })
          }
        />
      </DatePickerWrapper>

      <DatePickerWrapper>
        <DatePicker
          label="To"
          minDate={tempFilter.from}
          maxDate={new Date()}
          value={tempFilter.to}
          onChange={(date: Date | null) =>
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              let newDate = null;
              if (date) {
                const dateObj = new Date(date);
                dateObj.setHours(23);
                dateObj.setMinutes(59);
                newDate = dateObj;
              }
              newVal.to = newDate ?? undefined;
              return newVal;
            })
          }
        />
      </DatePickerWrapper>

      <SuggestionButtonsWrapper>
        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'today'}
          onClick={() => {
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = startOfCurrentDay();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('today');
          }}
        >
          Today
        </Button>

        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'week'}
          onClick={() => {
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = firstDayOfCurrentWeek();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('week');
          }}
        >
          This week
        </Button>

        <Button
          variant="outlinedBold"
          size="small"
          disabled={tempDateFilterSelected === 'month'}
          onClick={() => {
            setTempFilter((v: TJobsFilter) => {
              const newVal = Object.assign({}, v);
              newVal.from = firstDayOfCurrentMonth();
              newVal.to = new Date();
              return newVal;
            });
            setTempDateFilterSelected('month');
          }}
        >
          This month
        </Button>
      </SuggestionButtonsWrapper>

      <ButtonsWrapper>
        <BottomButton
          variant="outlined"
          onClick={() => {
            setTempFilter({ from: undefined, to: undefined });
            setTempDateFilterSelected('');
            if (filterOnChange) {
              filterOnChange({ from: undefined, to: undefined });
            }
            handleSubmit();
            handleClose();
          }}
        >
          Reset
        </BottomButton>
        <BottomButton onClick={tempSubmit}>Apply</BottomButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};
