import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';

export const jobCanBeShared = (status: keyof typeof JOB_STATUSES_ENUM) => {
  return ![
    JOB_STATUSES_ENUM.JOB_STATUS_DRAFT,
    JOB_STATUSES_ENUM.JOB_STATUS_SCHEDULED,
    JOB_STATUSES_ENUM.JOB_STATUS_ERROR,
    JOB_STATUSES_ENUM.JOB_STATUS_PROCESSING,
  ].includes(JOB_STATUSES_ENUM[status]);
};
