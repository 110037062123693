import { convertDuration } from '@helpers/convertDuration';

export function PriceModalNoPriceDescription({
  price,
}: {
  price: { price: number; ASRRate: string; duration?: number };
}) {
  return (
    <div>
      <p className="description-text">
        <span>After submitting this job you will be charged</span>
      </p>
      <br style={{ display: 'block', margin: '10px 0 0' }} />

      <div className="dflex">
        <span>Duration:</span>
        {convertDuration(price?.duration ?? 0)}
      </div>
    </div>
  );
}
