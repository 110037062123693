import styled from 'styled-components';

export const CellContent = styled.div`
  margin: 0;
  padding: 10px 0;

  p {
    margin: 0;
  }
`;
