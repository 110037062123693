import styled from 'styled-components';
import { getShortName } from '@helpers/getShortName';

const TeamName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  text-transform: uppercase;
  border-radius: 5px;
`;

export type TProps = {
  team: { name: string; slug: string; colour: string };
};

export const MiniTeamIcon = ({ team }: TProps) => {
  const teamShortname = getShortName(team.name || team.slug);

  return (
    <TeamName
      className="flex-shrink-0 flex items-center justify-center w-12 h-12 text-white text-sm font-medium cursor-pointer"
      style={{ backgroundColor: team.colour }}
    >
      {teamShortname}
    </TeamName>
  );
};
