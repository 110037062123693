export const Attention = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5229 20 10C20 4.47705 15.5228 0 10 0C4.47714 0 0 4.47705 0 10C0 15.5229 4.47714 20 10 20ZM10 18C14.4183 18 18 14.4182 18 10C18 5.58179 14.4183 2 10 2C5.58173 2 2 5.58179 2 10C2 14.4182 5.58173 18 10 18Z"
        fill="white"
      />
      <path
        d="M9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6V11C11 11.5523 10.5523 12 10 12C9.44772 12 9 11.5523 9 11V6Z"
        fill="white"
      />
      <path
        d="M9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14Z"
        fill="white"
      />
    </svg>
  );
};
