import React from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';
import { formatDateMsDsYHI } from '@helpers/formatDateMsDsYHI';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { Button } from '@components/form-elements/buttons/Button';
import { CustomTable } from '@components/Table';
import { JobsService } from '../../API';
import { useAPI } from '@hooks/useAPI';
import { TUser } from '../../types/entities/TUser';

export type TAssignee = {
  createdAt: string;
  user?: TUser;
};

export type TStatus = {
  date: string;
  status: keyof typeof JOB_STATUSES_ENUM;
};

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 480px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  .switcher {
    display: flex;
    margin-top: 30px;
  }

  .customTable {
    width: 100%;
    height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 5px 0 20px;

    td p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      padding: 10px 0;
    }
  }

  .cancelBtn {
    width: 130px;
  }
`;

type TProps = {
  onCancel: () => void;
  jobId: string;
  workspaceId?: string;
};

type TData = TStatus & TAssignee;

export const JobHistory = ({ onCancel, jobId, workspaceId }: TProps) => {
  const { call } = useAPI();
  const { workspace } = useAuthContext();
  const [currentTab, setCurrentTab] = React.useState<'Status' | 'Assignee'>('Status');
  const [data, setData] = React.useState<{
    status: TStatus[];
    assignee: TAssignee[];
  }>({ status: [], assignee: [] });

  const jobWorkspaceId = workspaceId ?? workspace?.id ?? '';

  React.useEffect(() => {
    (async () => {
      const statuses = await call(JobsService.getJobStatusHistory({ wsid: jobWorkspaceId, id: jobId }));
      const assignees = await call(JobsService.getJobAssigneeHistory({ wsid: jobWorkspaceId, id: jobId }));
      setData({ status: statuses.result, assignee: assignees.result });
    })();
  }, []);

  const tableHead = [
    {
      render: (data: TData) => <p>{formatDateMsDsYHI(currentTab === 'Status' ? data.date : data.createdAt)}</p>,
      headComponent: () => <td>Date</td>,
    },
    {
      render: (data: TData) => {
        if (currentTab === 'Status') {
          return <p>{JOB_STATUSES_ENUM[data.status]}</p>;
        }
        if (!data.user?.name) {
          return <p>Unassigned</p>;
        }
        return <p>{`${data.user.name} ${data.user.lastname} (${data.user.email})`}</p>;
      },
      headComponent: () => <td>{currentTab === 'Status' ? 'Status' : 'Assignee'}</td>,
    },
  ];

  return (
    <ModalWrapper>
      <h2>History</h2>
      <div className="switcher">
        <Button
          bgColor={currentTab === 'Status' ? '#858DBD' : ''}
          variant={currentTab !== 'Status' ? 'outlined' : 'contained'}
          borderRadius={{
            topLeft: '5px',
            bottomLeft: '5px',
          }}
          type="button"
          onClick={() => setCurrentTab('Status')}
        >
          Status
        </Button>
        <Button
          bgColor={currentTab === 'Assignee' ? '#858DBD' : ''}
          variant={currentTab !== 'Assignee' ? 'outlined' : 'contained'}
          borderRadius={{
            topRight: '5px',
            bottomRight: '5px',
          }}
          type="button"
          onClick={() => setCurrentTab('Assignee')}
        >
          Assignee
        </Button>
      </div>

      <CustomTable
        data={currentTab === 'Status' ? data.status : data.assignee}
        head={tableHead}
        className="customTable"
        fullPage={false}
        whiteBackground={true}
      />

      <Button className="cancelBtn" onClick={onCancel} variant="outlined">
        Cancel
      </Button>
    </ModalWrapper>
  );
};
