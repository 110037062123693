export const Search = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.28 10.72C5.28 7.71557 7.71557 5.28 10.72 5.28C13.7244 5.28 16.16 7.71557 16.16 10.72C16.16 13.7244 13.7244 16.16 10.72 16.16C7.71557 16.16 5.28 13.7244 5.28 10.72ZM10.72 4C7.00865 4 4 7.00865 4 10.72C4 14.4314 7.00865 17.44 10.72 17.44C12.3479 17.44 13.8406 16.8612 15.0035 15.8981C15.0226 15.9241 15.0439 15.949 15.0675 15.9725L18.9075 19.8125C19.1574 20.0625 19.5626 20.0625 19.8125 19.8125C20.0625 19.5626 20.0625 19.1574 19.8125 18.9075L15.9725 15.0675C15.949 15.0439 15.9241 15.0226 15.8981 15.0035C16.8612 13.8406 17.44 12.3479 17.44 10.72C17.44 7.00865 14.4314 4 10.72 4Z"
        fill="#858DBD"
      />
    </svg>
  );
};
