import styled from 'styled-components';
import { PlusBalance } from '@components/icons/PlusBalance';

export const PlusBalanceIcon = styled(PlusBalance)`
  margin: 0 0 0 10px;
  cursor: pointer;

  &:hover {
    path {
      fill: #40608f;
    }
  }
`;
