import { Helmet } from 'react-helmet-async';

import { Header } from '@components/Header';

import { PageProvider } from './providers/PageProvider';
import { Content } from './components/Content';

export const AdminDashboardPage = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard - AutoScript Admin</title>
      </Helmet>
      <Header title={'Admin Dashboard'} />

      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
