import { getAmericanTime } from '@helpers/getAmericanTimezone';

export const dateToXAgo = (date: string | number | Date, showHHMM = true) => {
  const now = Date.now();
  const diff = Math.floor((now - new Date(date).getTime()) / 1000);
  // More than 7 days ago
  if (diff > 7 * 24 * 60 * 60) {
    return getAmericanTime(date, showHHMM);
  }
  // More than 1 day ago
  if (diff > 24 * 60 * 60) {
    const daysAgo = Math.floor(diff / (24 * 60 * 60));
    return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
  }
  // More than hour ago
  if (diff > 60 * 60) {
    const hoursAgo = Math.floor(diff / (60 * 60));
    return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
  }
  // More than minute ago
  if (diff > 60) {
    const minutesAgo = Math.floor(diff / 60);
    return `${minutesAgo} ${minutesAgo === 1 ? 'minute' : 'minutes'} ago`;
  }
  return `${diff} ${diff === 1 ? 'second' : 'seconds'} ago`;
};
