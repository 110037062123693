import { vitifyComponent } from '@helpers/vitifyComponent';

import { Dropzone } from './components/dropzone';
import { InputUpload } from './components/InputUpload';
import { FileUploadLayout } from './components/FileUploadLayout';
import { Loader } from './components/Loader';
import { Files } from './components/Files';

import { UploadObjectType } from '@constants/enums/uploadObjectType';
import { generateUploadParams } from './helpers/generateUploadParams';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { useAPI } from '@hooks/useAPI';

const ViteDropzone = vitifyComponent(Dropzone);

export const FileUpload = ({
  objectId,
  onChangeStatus,
  onNewFiles,
  files,
  setFiles,
  isFilesLoading,
  accept = 'audio/x-m4a, audio/mpeg, audio/wav, video/x-msvideo, video/trm,.trm',
  multiple = false,
  maxFiles = undefined,
  objectType = UploadObjectType.JOB,
  allowPlayback = false,
  timeout = 60000,
  onRemoveFile,
}: TProps) => {
  const { call } = useAPI();
  const getUploadParams = generateUploadParams(objectId, objectType, call);

  return (
    <Wrapper>
      <ViteDropzone
        multiple={multiple}
        accept={accept}
        getUploadParams={getUploadParams}
        onFileUpdated={onChangeStatus}
        onNewFiles={onNewFiles}
        InputComponent={InputUpload}
        LayoutComponent={FileUploadLayout}
        maxFiles={maxFiles}
        files={files}
        timeout={timeout}
        styles={{
          dropzone: {
            width: 'auto',
            margin: 0,
            height: 'auto',
            minHeight: 'unset',
            cursor: 'pointer',
            color: '#C1C1C1',
            fontWeight: '500',
            textAlign: 'center',
            overflow: 'unset',
            alignItems: 'flex-start',
            display: 'block',
            border: 'none',
          },
        }}
      />
      <div className="previews">
        <Files
          files={files}
          setFiles={setFiles}
          multiple={multiple}
          objectType={objectType}
          allowPlayback={allowPlayback}
          onRemoveFile={onRemoveFile}
        />
      </div>
      {isFilesLoading ? (
        <div className="loader-wrapper">
          <Loader size={40} />
        </div>
      ) : null}
    </Wrapper>
  );
};
