import { useState } from 'react';
import styled from 'styled-components';
import { DisclosureSection } from '@components/DisclosureSection';
import { TeamCards } from './TeamCards';
import { TRole } from 'app/types/entities/TRole';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  & > div {
    width: 100%;
  }
`;

type TProps = {
  teams: TRole[];
};

export const TeamsList = ({ teams }: TProps) => {
  const [teamsDisclosureOpen, setTeamsDisclosureOpen] = useState<boolean>(true);

  const teamsTitle = 'Teams' + (teams.length > 0 ? ` (${teams.length})` : '');

  return (
    <Wrapper className="flex flex-col">
      <div>
        <DisclosureSection
          title={teamsTitle}
          open={teamsDisclosureOpen}
          setOpenState={setTeamsDisclosureOpen}
          loading={false}
        >
          <TeamCards teams={teams} />
        </DisclosureSection>
      </div>
    </Wrapper>
  );
};
