import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { routes } from '@routes';
import { TWorkspace } from 'app/types/entities/TWorkspace';

import { CustomTable } from '@components/Table';
import { DisclosureSection } from '@components/DisclosureSection';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  & > div {
    width: 100%;
  }
  table {
    width: 100%;
    .theadTr {
      border-bottom: none;
    }
    thead tr td,
    thead tr th {
      height: 50px;
    }
  }
`;

const CellContent = styled.div`
  margin: 0;
  padding: 15px 0;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

type TProps = {
  workspaces: TWorkspace[];
};
export const WorkspacesTable = ({ workspaces }: TProps) => {
  const [workspacesDisclosureOpen, setWorkspacesDisclosureOpen] = useState<boolean>(true);

  const workspaceSectionTitle = 'Workspaces' + (workspaces?.length > 0 ? ` (${workspaces?.length})` : '');

  const tableHead = [
    {
      headComponent: () => <th />,
      render: (data: TWorkspace) => (
        <CellContent style={{ width: '250px' }}>
          <Link to={routes.adminWorkspaceDetails.make(data.id ?? '')} className="underline">
            {data.name}
          </Link>
        </CellContent>
      ),
    },
    {
      headComponent: () => <th />,
      render: (data: TWorkspace) => (
        <CellContent>
          {data.jobsProcessing !== undefined ? (
            <span style={{ display: 'block' }}>{data.jobsProcessing} jobs processing</span>
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <th />,
      render: (data: TWorkspace) => (
        <CellContent>
          {data.jobsReadyForTranscribing !== undefined ? (
            <span style={{ display: 'block' }}>{data.jobsReadyForTranscribing} jobs ready for editing</span>
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }} />,
      render: (data: TWorkspace) => (
        <CellContent>
          {data.jobsComplete !== undefined ? (
            <span style={{ display: 'block' }}>{data.jobsComplete} completed jobs</span>
          ) : null}
        </CellContent>
      ),
    },
  ];

  return (
    <Wrapper className="flex flex-col">
      <div>
        <DisclosureSection
          title={workspaceSectionTitle}
          open={workspacesDisclosureOpen}
          setOpenState={setWorkspacesDisclosureOpen}
          loading={false}
        >
          <CustomTable head={tableHead} data={workspaces} noHead={true} />
        </DisclosureSection>
      </div>
    </Wrapper>
  );
};
