import React, { useState } from 'react';
import styled from 'styled-components';

import { EmailIcon } from '@components/icons/Email';
import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { useAPI } from '@hooks/useAPI';
import { AdminService } from 'app/API';

const Wrapper = styled.div`
  width: 300px;
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  position: relative;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #858dbd;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    padding: 20px 0 0;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    .select {
      height: 40px;
    }

    .add-more {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: #40608f;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  id: string;
  email: string;
};

export const AdminEditUserEmailModal = ({ onCancel, onSuccess, id, email }: TProps) => {
  const { call } = useAPI();
  const [newEmail, setNewEmail] = useState(email);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    await call(AdminService.updateUserEmail({ id, requestBody: { email: newEmail } }));
    setIsSubmitting(false);
    onSuccess();
  };

  return (
    <Wrapper>
      <h2>Edit User Email</h2>
      <form action="#" onSubmit={submit}>
        <div className="inputWrapper">
          <Input
            icon={EmailIcon}
            required
            value={newEmail}
            type="email"
            label="Email"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewEmail(e.target.value.toLowerCase())}
            error={isSubmitting && !newEmail}
          />
        </div>
        <div className="buttonWrapper">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={!newEmail.length}>
            Save
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};
