import styled from 'styled-components';

import DownIcon from './down.svg';
import UpIcon from './up.svg';

const Wrapper = styled.span`
  display: inline-block;
  height: 15px;
  padding: 0 0 0 6px;
`;

type TProps = {
  reverse: boolean;
};

export const SortingDirection = ({ reverse }: TProps) => {
  return <Wrapper>{reverse ? <DownIcon className="mt-2" /> : <UpIcon className="mt-2" />}</Wrapper>;
};
