import { useAuthContext } from '@providers/AuthProvider';

import { TrainingIcon } from './icons/training';
import styled from 'styled-components';

export const StyledLink = styled.a`
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 15.6px */
  text-decoration: none;
  background: #8ebd84;
  padding: 5px 8px;
  border-radius: 5px;

  display: flex;
  gap: 6px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    background: #81b177;
  }
`;

export const WatchTraining = () => {
  const { me } = useAuthContext();

  if (!me) {
    return null;
  }

  return (
    <div className="flex justify-center">
      <StyledLink href="https://learning.autoscript.ai" target="_blank">
        <TrainingIcon fill={'#fff'} /> Training Videos
      </StyledLink>
    </div>
  );
};
