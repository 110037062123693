import styled from 'styled-components';
import { TUser } from 'app/types/entities/TUser';
import { Pencil as PencilIcon } from '@components/icons/Pencil';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div``;

const RightSideColumns = styled.div`
  display: flex;
  gap: 10px;
`;

const InputBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  width: 270px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #858dbd;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }

  .value {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #00122d;
    display: flex;
    gap: 10px;

    .pencil {
      flex: 0 0 auto;
      cursor: pointer;

      &:hover {
        path {
          fill: #40608f;
        }
      }
    }
  }
`;

const AvatarBlock = styled.div`
  padding: 0 0 20px;
  display: flex;
  gap: 20px;
  align-items: center;
`;
const Avatar = styled.img`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
`;
const AvatarName = styled.div`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  text-transform: capitalize;
`;

type TProps = {
  user: TUser;
  reload: () => void;
};

export const UserDetails = ({ user, reload }: TProps) => {
  const { openModal, closeModal } = useModalContext();
  if (!user) {
    return null;
  }

  const editPhone = () => {
    openModal(ModalNamesEnum.EditUserPhone, {
      onSuccess: () => {
        closeModal();
        reload();
      },
      id: user.id,
      phone: user.phone,
    });
  };

  const editEmail = () => {
    openModal(ModalNamesEnum.EditUserEmail, {
      onSuccess: () => {
        closeModal();
        reload();
      },
      id: user.id,
      email: user.email,
    });
  };

  const renderAvatar = () => {
    if (!user || !user.name) {
      return '';
    }
    const avatarName = `${user?.name[0]}${user?.lastname[0]}`;

    return (
      <AvatarBlock>
        {user?.avatar ? <Avatar src={user.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
      </AvatarBlock>
    );
  };

  return (
    <>
      <Wrapper>
        <LeftSide>
          <h3>General</h3>
        </LeftSide>

        <RightSide>
          {renderAvatar()}
          <RightSideColumns>
            <InputBlockWrapper>
              <InputWrapper>
                <div className="label">Name</div>
                <div className="value">
                  {user.name} {user.lastname}
                </div>
              </InputWrapper>
              <InputWrapper>
                <div className="label">Email</div>
                <div className="value">
                  {user.email}
                  <PencilIcon className="pencil" onClick={() => editEmail()} />
                </div>
              </InputWrapper>
            </InputBlockWrapper>
            <InputBlockWrapper>
              <InputWrapper>
                <div className="label">Phone</div>
                <div className="value">
                  {user.phone}
                  <PencilIcon className="pencil" onClick={() => editPhone()} />
                </div>
              </InputWrapper>
            </InputBlockWrapper>
          </RightSideColumns>
        </RightSide>
      </Wrapper>
    </>
  );
};
