import styled from 'styled-components';

export const CellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  display: flex;
  align-items: center;

  .as-one-rate {
    .as-one-partial-rate {
      padding: 5px 0 0;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 10px */
    }
  }
`;
