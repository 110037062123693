import { Warning } from '@components/icons/Warning';
import { useModalContext } from '@providers/ModalProvider';
import { Link } from 'react-router-dom';

type TeamEmptyWarningModalProps = { linkToWorkspacesPage: string; workspaceTitle: string };

export default function TeamEmptyWarningModal({ linkToWorkspacesPage, workspaceTitle }: TeamEmptyWarningModalProps) {
  const { closeModal } = useModalContext();

  return (
    <div
      className="no-teams-message p-2 flex items-center rounded-xl mt-3"
      style={{ color: '#FF9191', border: '1px solid #FF9191' }}
    >
      <Warning className="warning-icon" />
      <div className="ml-3">
        <p>{"Currently you don't have teams to assign."}</p>
        <p>
          Add teams to the{' '}
          <Link to={linkToWorkspacesPage} className="underline font-medium" onClick={closeModal}>
            {workspaceTitle}
          </Link>{' '}
          to continue
        </p>
      </div>
    </div>
  );
}
