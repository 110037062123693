import { useEffect, useState } from 'react';

import { useAuthContext } from '@providers/AuthProvider';
import { convertDuration } from '@helpers/convertDuration';
import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { getPaymentMethodsJobSubmit } from '@queries/billing-account/getPaymentMethodsJobSubmit';
import { TProps } from '@components/Modal/UnlockJobModal/types/TProps';
import { Wrapper } from '@components/Modal/UnlockJobModal/styles/Wrapper';
import { StyledButton } from '@components/Modal/UnlockJobModal/styles/StyledButton';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { RefreshBalance } from '@components/Modal/UnlockJobModal/icons/RefreshBalance';
import { UnlockModalErrors } from '@components/Modal/UnlockJobModal/components/errors';
import { useAPI } from '@hooks/useAPI';
import { BillingService, type GetManyForUserResponseDto, JobsService, LayoutsService } from 'app/API';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import toast from 'react-hot-toast';
import { useWebsocketContext } from '@providers/WebsocketProvider';

export function UnlockJobModal({ onCancel, onSuccess, id, name }: TProps) {
  const { updateMe, organization, workspace } = useAuthContext();
  const { openModal, closeModal } = useModalContext();
  const { call } = useAPI();

  const [price, setPrice] = useState<{
    duration?: number;
    alreadyPaid?: number;
    totalJobCost?: number;
    amount?: number;
  }>({ duration: 0, alreadyPaid: 0, totalJobCost: 0, amount: 0 });
  const [paymentMethodId, setPaymentMethodId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [balanceIsRefreshing, setBalanceIsRefreshing] = useState(false);
  const [selectedCloudLayout, setSelectedCloudLayout] = useState('null');
  const [layouts, setLayouts] = useState<GetManyForUserResponseDto[] | undefined>([]);
  const { onWSUpdate } = useWebsocketContext();

  const layoutsList = [
    {
      label: 'None',
      value: 'null',
    },
    ...(layouts || []).map((l) => ({
      value: l.id,
      label: l.name,
    })),
  ];

  const balance = organization?.billingAccount?.currentBalance ?? 0;
  const notEnoughFunds = (price?.amount ?? 0) > balance;
  const noBillingAccount = !organization?.billingAccount?.id;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!workspace || !organization) {
        return;
      }
      const methods = await getPaymentMethodsJobSubmit(organization?.billingAccount?.id || '');
      setPaymentMethodId(methods?.find((item) => item.default)?.id);
      await updateMe();
      const priceData = await call(BillingService.calculateJobUnlockPricing({ jobId: id }));
      if (priceData?.result) {
        setPrice(priceData?.result);
      }
      const jobData = await call(JobsService.getJobById({ id, wsid: workspace?.id ?? '' }));
      setSelectedCloudLayout(jobData?.layoutId || 'null');

      const layouts = await call(LayoutsService.getOrganizationLayouts({ oid: organization?.id ?? '' }));
      setLayouts(layouts);
      setIsLoading(false);
    })();
  }, [onWSUpdate]);

  const error = noBillingAccount || (!paymentMethodId && notEnoughFunds) || notEnoughFunds;

  const submit = async () => {
    setIsLoading(true);
    await call(
      JobsService.updateLayout({
        id,
        wsid: workspace?.id ?? '',
        requestBody: { layoutId: selectedCloudLayout ?? 'null' },
      }),
    );
    await call(BillingService.unlockJob({ wsid: workspace?.id ?? '', id }), {
      onError: (message) => toast.error(message),
    });
    openModal(ModalNamesEnum.ChooseCloudLayout, {
      id,
      name,
      onSuccess,
      onCancel: closeModal,
    });
    onSuccess();
  };

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 data-testid="priceModalH2">Unlock Job</h2>

      <div className="title">Job title</div>
      <div className="title-value">{name}</div>

      <div className="title">Layout</div>
      <SmallWhiteSelect
        name="layoutId"
        className="layout-select"
        label="Layout"
        value={selectedCloudLayout}
        disabled={layoutsList.length < 2 || isLoading}
        onChange={(_, value) => setSelectedCloudLayout(value)}
        options={layoutsList}
        required={false}
      />

      <div className="title">Current balance</div>
      <div className={`title-value ${balanceIsRefreshing ? 'balance-refresh' : ''}`}>
        {formatCentsPrice(balance)}
        <RefreshBalance
          onClick={() => {
            if (balanceIsRefreshing) {
              return;
            }
            setBalanceIsRefreshing(true);

            updateMe()
              .then(() => {
                setBalanceIsRefreshing(false);
                setIsLoading(false);
              })
              .catch(() => {
                setBalanceIsRefreshing(false);
                setIsLoading(false);
              });
          }}
        />
      </div>

      <div className="price-description">
        <div>
          <div className="dflex noBorder">
            <span>Duration:</span>
            {convertDuration(price?.duration ?? 0)}
          </div>

          <div className="dflex">
            <span>Total cost:</span>
            {formatCentsPrice(price?.totalJobCost ?? 0)}
          </div>

          <div className="dflex">
            <span>Already paid:</span>
            {formatCentsPrice(price?.alreadyPaid ?? 0)}
          </div>

          <div className="dflex bold">
            <span>In order to unlock this job you will be charged:</span> {formatCentsPrice(price?.amount ?? 0)}
          </div>
        </div>
      </div>

      <div className={`errorsWrapper ${error ? 'active' : ''}`}>
        <UnlockModalErrors
          setBalanceIsRefreshing={setBalanceIsRefreshing}
          price={price}
          onCancel={onCancel}
          dataLoaded={!isLoading}
          onSuccess={onSuccess}
          jobId={id}
          jobName={name}
          isSubmitting={isLoading}
          paymentMethodId={paymentMethodId}
        />
      </div>

      <div className="buttonWrapper">
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>

        <StyledButton variant="contained" disabled={isLoading || error} onClick={() => submit()}>
          Unlock
        </StyledButton>
      </div>
    </Wrapper>
  );
}
