import { useState } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { useModalContext } from '@providers/ModalProvider';
import { createASOnePlan } from '@queries/admin/asone/createASOnePlan';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Input } from '@components/form-elements/Input';
import { Textarea } from '@components/form-elements/Textarea';
import { Switch } from '@components/form-elements/Switch';
import { EErrorMessages } from '@constants/errorMessages';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 370px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0 0 20px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .input,
    .textarea {
      margin: 0 0 20px;
    }
  }

  .public {
    display: flex;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const NewASOnePlanModal = ({ onCancel, onSuccess }: TProps) => {
  const { closeModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [plan, setPlan] = useState({
    id: '',
    name: '',
    description: '',
    price: 125,
    public: true,
    trialPeriodDays: 0,
    freeSeats: 0,
  });

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await createASOnePlan(plan);
      setIsSubmitting(false);
      closeModal();
      onSuccess();
    } catch (error) {
      setIsSubmitting(false);
      toast.error(EErrorMessages.DEFAULT);
    }
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Create new plan</h2>

      <div className="input-wrapper">
        <div className="label">Plan name</div>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlan({ ...plan, name: e.target.value })}
          label="Name"
          value={plan.name}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Description</div>
        <Textarea
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPlan({ ...plan, description: e.target.value })}
          label="Description"
          value={plan.description}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Price per license</div>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, price: parseInt(e.target.value, 10) })
          }
          label="Monthly price"
          value={plan.price}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Free Seats</div>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, freeSeats: parseInt(e.target.value, 10) || 0 })
          }
          label="Number of free seats"
          value={plan.freeSeats}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Free trial days</div>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlan({ ...plan, trialPeriodDays: parseInt(e.target.value, 10) || 1 })
          }
          label="Number of free trial days"
          value={plan.trialPeriodDays}
        />
      </div>

      <div className="public">
        <div className="label">Public plan</div>
        <Switch checked={plan.public} onChange={() => setPlan({ ...plan, public: !plan.public })} />
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </ModalWrapper>
  );
};
