import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { TJob } from 'app/types/entities/TJob';
import { TUser } from 'app/types/entities/TUser';

import { usePageContext } from '../../../../../../providers/PageProvider';

import { JobSection } from '../../styles/JobSection';
import { Divider } from '../../styles/Divider';
import { JobSectionLeft } from '../../styles/JobSectionLeft';
import { FullWidthSectionRight } from '../../styles/FullWidthSectionRight';
import { InfoBlockFullSize } from '../../styles/InfoBlockFullSize';

import { CellContent } from './styles/CellContent';
import { AssigneeTable } from './styles/AssigneeTable';

export const AssigneeHistorySection = () => {
  const { job } = usePageContext();

  const assigneeTableHead = [
    {
      render: (data: TJob) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
      headComponent: () => <td style={{ width: '200px' }}>Change date</td>,
    },
    {
      render: (data: { user: TUser }) => (
        <CellContent>
          {data.user.name && `${data.user.name} ${data.user.lastname}`}
          {!data.user.name && 'Unassigned'}
        </CellContent>
      ),
      headComponent: () => <td>User assigned</td>,
    },
  ];

  if (!job || !job.assigneeHistory?.length) {
    return null;
  }

  return (
    <>
      <JobSection>
        <JobSectionLeft>Workflow</JobSectionLeft>
        <FullWidthSectionRight>
          <InfoBlockFullSize>
            <AssigneeTable data={job.assigneeHistory} head={assigneeTableHead} />
          </InfoBlockFullSize>
        </FullWidthSectionRight>
      </JobSection>
      <Divider />
    </>
  );
};
