import { useState } from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Button } from '@components/form-elements/buttons/Button';
import { Radio } from '@components/form-elements/Radio';
import { CustomTable } from '@components/Table';
import { Tooltip } from '@components/Tooltip';
import { TWorkspace } from 'app/types/entities/TWorkspace';
import { WorkspacesService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  input {
    height: 30px;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
    cursor: pointer;
    margin: 12px 0;

    &.disabled {
      color: #d5def2;
      cursor: auto;
    }

    .text {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tbody td > * {
      width: 100%;
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  teamId: string;
  workspaceIds: string[];
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddWSToTeamModal = ({ teamId, workspaceIds, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { organization, me } = useAuthContext();
  const [selectedWorkspaceSlug, setSelectedWorkspaceSlug] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const workspaces = me.workspaces.filter((ws) => ws.organizationId === organization?.id);

  const tableHead = [
    {
      render: (data: TWorkspace) => {
        const disabled = workspaceIds.includes(data.id);
        const disabledInfo = 'Cannot add already existent Workspace';
        if (disabled) {
          return (
            <Tooltip key={data.id} content={disabledInfo} placement="bottom">
              <div className="radioWrapper disabled">
                <Radio onChange={() => {}} rounded checked={selectedWorkspaceSlug === data.slug} disabled={true} />
                <span className="text">{data?.name}</span>
              </div>
            </Tooltip>
          );
        }
        return (
          <div className="radioWrapper">
            <Radio
              onChange={() => setSelectedWorkspaceSlug(data.slug)}
              rounded
              checked={selectedWorkspaceSlug === data.slug}
            />
            <span className="text">{data?.name}</span>
          </div>
        );
      },
      headComponent: () => <td>Workspace Name</td>,
    },
  ];

  const submit = async () => {
    setIsLoading(true);
    await call(
      WorkspacesService.updateWorkspaceByAddingTeam({
        orgSlug: organization?.slug || '',
        wsSlug: selectedWorkspaceSlug || '',
        requestBody: {
          teamIds: [teamId],
        },
      }),
    );
    setIsLoading(false);
    onSuccess();
  };

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Attach Workspace to Team</h2>
      <CustomTable data={workspaces} head={tableHead} className="customTable" fullPage={false} whiteBackground={true} />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={!selectedWorkspaceSlug} onClick={submit}>
          Attach
        </Button>
      </div>
    </ModalWrapper>
  );
};
