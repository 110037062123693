import { Button } from '@components/form-elements/buttons/Button';

import { TProps } from './types/TProps';
import { ModalWrapper } from './styles/ModalWrapper';

export const CustomConfirmationModal = ({
  onCancel,
  onSuccess,
  title = 'Confirm',
  description = 'Are you sure?',
  buttonText = 'Yes',
}: TProps) => {
  return (
    <ModalWrapper>
      <h2>{title}</h2>

      <p>{description}</p>
      <form>
        <div className="buttonContainer">
          <Button type="button" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="button" variant="contained" onClick={onSuccess}>
            {buttonText}
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
};
