import styled from 'styled-components';

import { Button } from '@components/form-elements/buttons/Button';
import { useAPI } from '@hooks/useAPI';
import { OrganizationsService } from 'app/API';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: center;
  max-width: 400px;

  & .content {
    display: flex;
    justify-content: space-between;
    grid-gap: 60px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0 0 20px;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 20px auto 0;
  }
`;

type TProps = {
  invitationId: string;
  organizationId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const DeleteInvitationModal = ({ invitationId, organizationId, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();

  const handleDeleteInvitation = async () => {
    await call(OrganizationsService.deleteWsInvitation({ oid: organizationId, iid: invitationId }));
    onSuccess();
  };

  return (
    <ModalWrapper>
      <h2>Confirm</h2>

      <p>Are you sure you want to delete invitation?</p>
      <form>
        <div className="buttonContainer">
          <Button type="button" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="button" onClick={handleDeleteInvitation} variant="contained">
            Delete
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
};
