import React from 'react';
import * as Sentry from '@sentry/react';
import './styles/styles.css';

import { Input as InputDefault } from './components/Input';
import {
  accepts,
  defaultClassNames,
  formatBytes,
  formatDuration,
  getFilesFromEvent as defaultGetFilesFromEvent,
  mergeStyles,
} from './helpers/utils';

import { TFileWithMeta } from './types/TFileWithMeta';
import { TExtra } from './types/TExtra';
import { TUploadParams } from './types/TUploadParams';
import { TExtraLayout } from './types/TExtraLayout';
import { TDropzoneProps } from './types/TDropzoneProps';
import { FileUploadLayout } from '@components/FileUpload/components/FileUploadLayout';
import { InputUpload } from '@components/FileUpload/components/InputUpload';
import { sanitizeFileName } from '@helpers/sanitizeFileName';

class Dropzone extends React.Component<
  TDropzoneProps,
  {
    active: boolean;
    dragged: (File | DataTransferItem)[];
  }
> {
  static defaultProps: TDropzoneProps;
  protected mounted: boolean;
  protected dropzone: React.RefObject<HTMLDivElement>;
  protected dragTimeoutId?: number;

  constructor(props: TDropzoneProps) {
    super(props);
    this.state = {
      active: false,
      dragged: [],
    };
    this.mounted = true;
    this.dropzone = React.createRef();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getUniqueFileName(initialName: string, suffix = 0): string {
    const nameArray = initialName.split('.');
    let name = initialName;
    if (suffix > 0) {
      if (nameArray.length < 2) {
        nameArray.push('mp3');
      }
      nameArray[nameArray.length - 2] = `${nameArray[nameArray.length - 2]}-${suffix}`;
      name = nameArray.join('.');
    }
    const isThereSameFile = this.props.files.find((f) => f.meta.name === name);
    if (isThereSameFile) {
      return this.getUniqueFileName(initialName, suffix + 1);
    }
    return name;
  }

  forceUpdate = () => {
    if (this.mounted) {
      super.forceUpdate();
    }
  };

  getFilesFromEvent = () => {
    return this.props.getFilesFromEvent || defaultGetFilesFromEvent;
  };

  getDataTransferItemsFromEvent = () => {
    return this.props.getDataTransferItemsFromEvent || defaultGetFilesFromEvent;
  };

  handleDragEnter = async (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const dragged = (await this.getDataTransferItemsFromEvent()(e)) as DataTransferItem[];
    this.setState({ active: true, dragged });
  };

  handleDragOver = async (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.dragTimeoutId) {
      clearTimeout(this.dragTimeoutId);
    }
    const dragged = (await this.getDataTransferItemsFromEvent()(e)) as DataTransferItem[];
    this.setState({ active: true, dragged });
  };

  handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // prevents repeated toggling of `active` state when file is dragged over children of uploader
    // see: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    this.dragTimeoutId = window.setTimeout(() => this.setState({ active: false, dragged: [] }), 150);
  };

  handleDrop = async (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ active: false, dragged: [] });
    const files = (await this.getFilesFromEvent()(e)) as File[];
    this.handleFiles(files);
  };

  handleDropDisabled = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ active: false, dragged: [] });
  };

  handleChangeStatus = (fileWithMeta: TFileWithMeta) => {
    // console.log('handleChangeStatus', fileWithMeta.file.name, fileWithMeta.meta.status);

    if (!this.props.onFileUpdated) {
      return;
    }
    this.props.onFileUpdated(fileWithMeta, fileWithMeta.meta.status);
  };

  handleCancel = (fileWithMeta: TFileWithMeta) => {
    if (fileWithMeta.meta.status !== 'uploading') {
      return;
    }
    fileWithMeta.meta.status = 'aborted';
    if (fileWithMeta.xhr) {
      fileWithMeta.xhr.abort();
    }
    this.handleChangeStatus(fileWithMeta);
    this.forceUpdate();
  };

  startUploading = (fileWithMeta: TFileWithMeta) => {
    if (!this.props.getUploadParams) {
      return;
    }

    fileWithMeta.meta.status = 'getting_upload_params';
    fileWithMeta.meta.percent = 0;
    this.handleChangeStatus(fileWithMeta);
    this.forceUpdate();
    this.uploadFile(fileWithMeta);
  };

  // expects an array of File objects
  handleFiles = async (files: File[]) => {
    const newFiles = files.map((f) => this.preProcessFile(f, `${new Date().getTime()}-${Math.random()}`));

    this.props.onNewFiles(newFiles);
    this.forceUpdate();

    setTimeout(() => {
      newFiles.forEach((f) => {
        if (f.meta.status === 'preparing') {
          this.processFile(f);
        }
      });
    }, 500);

    const { current } = this.dropzone;
    if (current) {
      setTimeout(() => current.scroll({ top: current.scrollHeight, behavior: 'smooth' }), 150);
    }
  };

  preProcessFile = (file: File, id: string): TFileWithMeta => {
    const { name: fileName, size, type, lastModified } = file;
    const { accept } = this.props;
    let { minSizeBytes, maxSizeBytes } = this.props;

    const name = this.getUniqueFileName(sanitizeFileName(fileName));

    if (!minSizeBytes) {
      minSizeBytes = 0;
    }
    if (!maxSizeBytes) {
      maxSizeBytes = 0;
    }

    const uploadedDate = new Date().toISOString();
    const lastModifiedDate = lastModified && new Date(lastModified).toISOString();
    const fileWithMeta = {
      file,
      meta: { name, size, type, lastModifiedDate, uploadedDate, percent: 0, id },
    } as TFileWithMeta;

    // firefox versions prior to 53 return a bogus mime type for file drag events,
    // so files with that mime type are always accepted
    if (file.type !== 'application/x-moz-file' && !accepts(file, accept)) {
      fileWithMeta.meta.status = 'rejected_file_type';
      return fileWithMeta;
    }

    fileWithMeta.cancel = () => this.handleCancel(fileWithMeta);
    fileWithMeta.upload = () => this.startUploading(fileWithMeta);

    if (size < minSizeBytes || size > maxSizeBytes) {
      fileWithMeta.meta.status = 'error_file_size';
      return fileWithMeta;
    }

    fileWithMeta.meta.status = 'preparing';
    return fileWithMeta;
  };

  processFile = async (fileWithMeta: TFileWithMeta) => {
    // fileWithMeta.meta.status = 'preparing';
    // await this.handleChangeStatus(fileWithMeta);
    // this.forceUpdate();

    await this.generatePreview(fileWithMeta);

    if (this.props.validate) {
      const error = this.props.validate(fileWithMeta);
      if (error) {
        fileWithMeta.meta.status = 'error_validation';
        fileWithMeta.meta.validationError = error; // usually a string, but doesn't have to be
        await this.handleChangeStatus(fileWithMeta);
        this.forceUpdate();
        return;
      }
    }

    if (this.props.getUploadParams) {
      await this.uploadFile(fileWithMeta);
    } else {
      fileWithMeta.meta.status = 'done';
    }
    await this.handleChangeStatus(fileWithMeta);
    this.forceUpdate();
  };

  generatePreview = async (fileWithMeta: TFileWithMeta) => {
    const {
      meta: { type },
      file,
    } = fileWithMeta;
    const isImage = type.startsWith('image/');
    const isAudio = type.startsWith('audio/');
    const isVideo = type.startsWith('video/');
    if (!isImage && !isAudio && !isVideo) {
      return;
    }

    const objectUrl = URL.createObjectURL(file);

    const fileCallbackToPromise = (fileObj: HTMLImageElement | HTMLAudioElement) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) {
            fileObj.onload = resolve;
          } else {
            fileObj.onloadedmetadata = resolve;
          }
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000);
        }),
      ]);
    };

    try {
      if (isImage) {
        const img = new Image();
        img.src = objectUrl;
        fileWithMeta.meta.previewUrl = objectUrl;
        await fileCallbackToPromise(img);
        fileWithMeta.meta.width = img.width;
        fileWithMeta.meta.height = img.height;
      }

      if (isAudio) {
        const audio = new Audio();
        audio.src = objectUrl;
        await fileCallbackToPromise(audio);
        fileWithMeta.meta.duration = audio.duration;
      }

      if (isVideo) {
        const video = document.createElement('video');
        video.src = objectUrl;
        await fileCallbackToPromise(video);
        fileWithMeta.meta.duration = video.duration;
        fileWithMeta.meta.videoWidth = video.videoWidth;
        fileWithMeta.meta.videoHeight = video.videoHeight;
      }
      if (!isImage) {
        URL.revokeObjectURL(objectUrl);
      }
    } catch (e) {
      URL.revokeObjectURL(objectUrl);
    }
    this.forceUpdate();
  };

  uploadFile = async (fileWithMeta: TFileWithMeta) => {
    // console.log('uploadFile', fileWithMeta);

    const { getUploadParams } = this.props;
    if (!getUploadParams) {
      return;
    }
    fileWithMeta.meta.status = 'getting_upload_params';
    fileWithMeta.meta.percent = 0;
    let params: TUploadParams | null = null;
    try {
      params = (await getUploadParams(fileWithMeta)) as TUploadParams;
    } catch (e: unknown) {
      console.error('Error Upload Params', e);
      Sentry.captureException(e);
    }
    if (!params || !params.url) {
      fileWithMeta.meta.status = 'error_upload_params';
      this.handleChangeStatus(fileWithMeta);
      this.forceUpdate();
      return;
    }
    const { url, method = 'PUT', body, headers = {}, meta: extraMeta = {} } = params;

    // Note: AWS SDK v3 encodes values in the URL string itself, so we don't need to encode them here

    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    for (const header of Object.keys(headers)) {
      xhr.setRequestHeader(header, headers[header]);
    }
    fileWithMeta.meta = { ...fileWithMeta.meta, ...extraMeta };

    // update progress (can be used to show progress indicator)
    xhr.upload.addEventListener('progress', (e) => {
      fileWithMeta.meta.percent = (e.loaded * 100.0) / e.total || 100;
      this.handleChangeStatus(fileWithMeta);
      this.forceUpdate();
    });

    xhr.addEventListener('readystatechange', () => {
      // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
      if (xhr.readyState !== 2 && xhr.readyState !== 4) {
        return;
      }

      if (xhr.status === 0 && fileWithMeta.meta.status !== 'aborted') {
        fileWithMeta.meta.status = 'error_upload';
        this.handleChangeStatus(fileWithMeta);
        this.forceUpdate();
        return;
      }

      if (xhr.status > 0 && xhr.status < 400) {
        fileWithMeta.meta.percent = 100;
        if (xhr.readyState === 2) {
          fileWithMeta.meta.status = 'headers_received';
        }
        if (xhr.readyState === 4) {
          fileWithMeta.meta.status = 'done';
        }
        this.handleChangeStatus(fileWithMeta);
        this.forceUpdate();
        return;
      }

      if (xhr.status >= 400 && fileWithMeta.meta.status !== 'error_upload') {
        fileWithMeta.meta.status = 'error_upload';
        this.handleChangeStatus(fileWithMeta);
        this.forceUpdate();
        return;
      }
    });

    // if (this.props.timeout) {
    //   xhr.timeout = this.props.timeout;
    // }
    xhr.send(fileWithMeta.file || body);
    fileWithMeta.xhr = xhr;
    fileWithMeta.meta.status = 'uploading';
    this.handleChangeStatus(fileWithMeta);
    this.forceUpdate();
  };

  render() {
    const {
      accept,
      multiple,
      maxFiles,
      minSizeBytes,
      maxSizeBytes,
      disabled,
      classNames,
      styles,
      addClassNames,
      InputComponent,
    } = this.props;

    const { active, dragged } = this.state;

    const reject = dragged.some((file) => file.type !== 'application/x-moz-file' && !accepts(file as File, accept));
    const extra = { active, reject, dragged, accept, multiple, minSizeBytes, maxSizeBytes, maxFiles } as TExtra;
    // const files = [...this.files];
    // const dropzoneDisabled = resolveValue(disabled, extra);

    const {
      classNames: {
        dropzone: dropzoneClassName,
        dropzoneActive: dropzoneActiveClassName,
        dropzoneReject: dropzoneRejectClassName,
        dropzoneDisabled: dropzoneDisabledClassName,
      },
      styles: {
        dropzone: dropzoneStyle,
        dropzoneActive: dropzoneActiveStyle,
        dropzoneReject: dropzoneRejectStyle,
        dropzoneDisabled: dropzoneDisabledStyle,
      },
    } = mergeStyles(classNames ?? {}, styles, addClassNames ?? {}, extra);

    const input =
      InputComponent !== null ? (
        <InputUpload
          accept={accept}
          multiple={multiple}
          onFiles={this.handleFiles} // see: https://stackoverflow.com/questions/39484895
        />
      ) : null;

    let className = dropzoneClassName;
    let style = dropzoneStyle;

    if (disabled) {
      className = `${className} ${dropzoneDisabledClassName}`;
      style = { ...(style || {}), ...(dropzoneDisabledStyle || {}) };
    } else if (reject) {
      className = `${className} ${dropzoneRejectClassName}`;
      style = { ...(style || {}), ...(dropzoneRejectStyle || {}) };
    } else if (active) {
      className = `${className} ${dropzoneActiveClassName}`;
      style = { ...(style || {}), ...(dropzoneActiveStyle || {}) };
    }

    return (
      <FileUploadLayout
        input={input}
        dropzoneProps={{
          ref: this.dropzone,
          className,
          style: style as React.CSSProperties,
          onDragEnter: this.handleDragEnter,
          onDragOver: this.handleDragOver,
          onDragLeave: this.handleDragLeave,
          onDrop: disabled ? this.handleDropDisabled : this.handleDrop,
        }}
        files={this.props.files}
        extra={
          {
            ...extra,
            onFiles: this.handleFiles,
            onCancelFile: this.handleCancel,
            onUploadFile: this.startUploading,
          } as TExtraLayout
        }
      />
    );
  }
}

Dropzone.defaultProps = {
  files: [],
  accept: '*',
  multiple: true,
  minSizeBytes: 0,
  maxSizeBytes: Number.MAX_SAFE_INTEGER,
  maxFiles: Number.MAX_SAFE_INTEGER,
  disabled: false,
  canCancel: true,
  canRemove: true,
  canRestart: true,
  inputContent: 'Drag Files or Click to Browse',
  inputWithFilesContent: 'Add Files',
  classNames: {},
  styles: {},
  addClassNames: {},
  onFileUpdated: async () => {},
  onNewFiles: async () => {},
};

export { Dropzone, InputDefault as Input, formatBytes, formatDuration, accepts, defaultClassNames };
