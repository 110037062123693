import { useAuthContext } from '@providers/AuthProvider';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { routes } from '@routes';

import { CustomTable } from '@components/Table';
import { SortableHead } from '@components/Table/SortableHead';
import { SortingDirection } from '@components/Table/SortingDirection';
import { Edit as EditIcon } from '@components/icons/Edit';
import { TrashBin } from '@components/icons/TrashBin';
import { Done as DoneIcon } from '@components/icons/Done/svg';

import { InlineEditRow } from './components/InlineEditRow';

import { usePageContext } from '../../../../providers/PageProvider';

import { OrganizationsCell } from './styles/OrganizationsCell';
import { CellContent } from './styles/CellContent';
import { FlexCellContent } from './styles/FlexCellContent';
import { StyledIconButton } from './styles/StyledIconButton';
import { DeleteButton } from './styles/DeleteButton';
import { Avatar } from './styles/Avatar';
import { AvatarName } from './styles/AvatarName';
import { StyledLink } from './styles/StyledLink';
import { TUser } from 'app/types/entities/TUser';

export const Table = () => {
  const { me } = useAuthContext();
  const { users, setSortingField, sortingReverse, setSortingReverse, editId, setEditId, sortingField, deleteUser } =
    usePageContext();

  const toggleSort = (field: string) => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse(!sortingReverse);
    }
  };

  const renderInlineEditRow = (data: TUser) => {
    return <InlineEditRow data={data} />;
  };

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '1%' }}></td>,
      render: (data: TUser) => {
        const avatarName = `${data?.name[0]}${data?.lastname[0]}`;
        return (
          <FlexCellContent>
            {data?.avatar ? <Avatar src={data.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('name')}>
          Name
          {sortingField === 'name' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => (
        <FlexCellContent>
          {data.id === me.id ? (
            <StyledLink to={routes.accountPage.make()}>
              {data.name} {data.lastname}
            </StyledLink>
          ) : (
            <StyledLink to={routes.adminUserDetails.make(data.id)}>
              {data.name} {data.lastname}
            </StyledLink>
          )}
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Contacts</td>,
      render: (data: TUser) => (
        <FlexCellContent>
          <CellContent>
            <div>{data.email}</div>
            <div>{data.phone}</div>
          </CellContent>
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          Created
          {sortingField === 'createdAt' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => <FlexCellContent>{getAmericanTime(data.createdAt)}</FlexCellContent>,
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Organizations</td>,
      render: (data: TUser) => {
        return (
          <FlexCellContent>
            <CellContent>
              {(data.organizations || []).map((item) => (
                <OrganizationsCell key={item.id}>
                  <StyledLink to={routes.adminOrganizationDetails.make(item.id)}>{item.name}</StyledLink>
                </OrganizationsCell>
              ))}
            </CellContent>
          </FlexCellContent>
        );
      },
    },
    {
      headComponent: () => <td style={{ width: '1%' }}></td>,
      render: (data: TUser) => {
        return (
          <FlexCellContent>
            <div className="icon-buttons">
              {data.id !== editId ? (
                <StyledIconButton title="Edit" onClick={() => setEditId(data.id)}>
                  <EditIcon />
                </StyledIconButton>
              ) : null}
              {data.id === editId ? (
                <StyledIconButton title="Done" onClick={() => setEditId('')}>
                  <DoneIcon width={18} height={14} />
                </StyledIconButton>
              ) : null}
              <DeleteButton disabled={me.id === data.id} title="Remove" onClick={() => deleteUser(data.id)}>
                <TrashBin />
              </DeleteButton>
            </div>
          </FlexCellContent>
        );
      },
    },
  ];

  return <CustomTable head={tableHead} data={users.data} renderInlineEditRow={renderInlineEditRow} />;
};
