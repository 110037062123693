import styled from 'styled-components';
import { IconButton } from '@components/form-elements/buttons/IconButton';

export const FileIconLink = styled(IconButton)`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  padding: 0 25px 0 0;
  color: #858dbd;
  align-self: center;

  svg {
    width: 17px;
    height: 20px;
    margin: 0 8px 0 0;
  }

  :hover {
    color: #858dbd;
  }
`;
