type TDataItem = {
  id: string;
  [key: string]: string | number | unknown[];
};

export const downloadCSV = async function (data: TDataItem[], header: string[]) {
  const separator = ',';
  if (!data.length) {
    return;
  }
  // let csvContent = `sep=${separator}\n`;
  let csvContent = ``;
  csvContent += header ? header.join(separator) : '';
  csvContent += '\n';
  data.forEach((infoArray: TDataItem) => {
    const dataString = Object.values(infoArray)
      .map((x: string | number | unknown[]) => (String(x).includes(separator) ? `"${x}"` : x))
      .join(separator);
    csvContent += dataString + '\n';
  });
  const a = document.createElement('a');
  if (URL && 'download' in a) {
    a.href = URL.createObjectURL(
      new Blob([csvContent], {
        type: 'text/csv;encoding:utf-8',
      }),
    );
    a.setAttribute('download', 'download.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href = 'data:application/octet-stream,' + encodeURIComponent(csvContent);
  }
};
