import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TSSOProvider } from 'app/types/entities/TSSOProvider';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';
import { SsoProviderService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TState = {
  ssoProviders: TPaginatedResponse<TSSOProvider>;
  page: number;
  setPage: (v: number) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  search: string;
  setSearch: (v: string) => void;
  isLoading: boolean;
  exportCSV: () => void;
};

const PageContext = createContext<TState>({
  ssoProviders: {
    data: [],
    count: 0,
  },
  page: 1,
  setPage: () => {},
  pageSize: 25,
  setPageSize: () => {},
  search: '',
  setSearch: () => {},
  isLoading: false,
  exportCSV: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [ssoProviders, setSSOProviders] = useState<TPaginatedResponse<TSSOProvider>>({
    data: [],
    count: 0,
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    call(
      SsoProviderService.findAll({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search: search ?? '',
      }),
    ).then((res) => {
      setSSOProviders(res);
      setIsLoading(false);
    });
  }, [page, pageSize, search]);

  const exportCSV = async () => {
    const items = await call(
      SsoProviderService.findAll({
        pageNo: '1',
        pageSize: ssoProviders.count.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search: search ?? '',
      }),
    );
    const headers = ['ID', 'Domain', 'Cognito Identity Provider', 'Created'];
    const array = items.data.map((data: TSSOProvider) => {
      return {
        id: data.id,
        domain: data.domain,
        cip: data.identityProvider,
        created: getAmericanTime(data.createdAt),
      };
    });
    return downloadCSV(array, headers);
  };

  return (
    <PageContext.Provider
      value={{
        ssoProviders,
        page,
        setPage,
        pageSize,
        setPageSize,
        search,
        setSearch,
        isLoading,
        exportCSV,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
