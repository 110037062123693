import { TProps } from '../types/TProps';

export const getVariant = (variant: TProps['variant']) => {
  switch (variant) {
    case 'outlined':
      return `
        border: 1px solid #D5DDF2;
        background: none;
        color: #858DBD;
        &:hover {
          border: 1px solid #2F5D87;
          color: #2F5D87;
        }
        &:disabled {
          border: 1px solid #D5DDF2;
          color: #858DBD;
        }
      `;
    case 'outlinedBold':
      return `
        border: 1px solid #858DBD;
        background: none;
        color: #858DBD;
        &:hover {
          border: 1px solid #CADCF8;
          background: #CADCF8;
          color: #ffffff;
        }
        &:disabled {
          border: 1px solid #858DBD;
          background: #858DBD;
          color: #ffffff;
        }
      `;
    case 'contained':
      return `
        background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
        border: none;
        color: #fff;
        &:hover {
          background: linear-gradient(90deg, #adc5e8 0%, #40608f 100%);
        }
        &:disabled {
          background: #F1F5FB;
          color: #858DBD;
        }
      `;
    case 'bold':
      return `
        background: #858DBD;
        border: none;
        color: #fff;
        &:hover {
          background: #2F5D87;
        }
        &:disabled {
          background: #858DBD;
        }
      `;
    case 'text':
      return `
        background: none;
        border: none;
        color: #7CB1FE;
        &:hover {
          text-decoration: underline;
        }
        &:disabled {
          color: #858DBD;
        }
      `;
  }
};
