import React from 'react';

export const PhoneIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94556 13.7218L4.43036 11.2024L6.92681 13.7031L6.94556 13.7218ZM13.5055 0.666016C13.8315 0.666016 14.1399 0.795042 14.3713 1.02653L16.6585 3.31152C17.1806 3.83567 17.4233 4.58399 17.3019 5.30812C16.8392 8.0949 15.3559 10.8697 13.1126 13.1172C10.8672 15.3604 8.09261 16.8456 5.30846 17.304L5.30668 17.3043C5.18462 17.3253 5.06397 17.335 4.93796 17.335C4.33204 17.335 3.74034 17.0895 3.31177 16.6609L1.02653 14.3756C0.793234 14.1423 0.666016 13.8362 0.666016 13.5076C0.666016 13.1812 0.795324 12.8708 1.02653 12.6396L3.49195 10.1806L3.4934 10.1792C3.60622 10.0648 3.74073 9.97414 3.88904 9.91244C4.03734 9.85075 4.19648 9.81928 4.3571 9.81987H4.35806C4.68399 9.81987 4.99246 9.94896 5.22393 10.1804L7.27211 12.2308L7.43296 12.1612C8.48484 11.706 9.43345 11.0627 10.2449 10.2469C11.0554 9.43916 11.7038 8.48375 12.1551 7.43224L12.2239 7.2719L10.1761 5.22179C9.9428 4.98849 9.81558 4.68237 9.81558 4.35376C9.81558 4.02738 9.94489 3.71694 10.1761 3.48574L12.6394 1.02669L12.6403 1.02583C12.8709 0.793467 13.1786 0.666016 13.5055 0.666016ZM10.7591 4.35607L13.419 7.01378L13.3043 7.32335C12.8005 8.6951 12.0045 9.9409 10.9714 10.9744C9.93821 12.0079 8.69212 12.8046 7.32055 13.3089L7.01168 13.4233L4.35383 10.761L1.60961 13.5077L4.03621 15.9343L4.03706 15.9351C4.3257 16.2211 4.73452 16.3575 5.13681 16.2898C7.72901 15.8611 10.3001 14.4701 12.384 12.3862C14.4679 10.3023 15.8588 7.72924 16.2876 5.13724C16.3555 4.73466 16.219 4.32518 15.93 4.03621L13.5032 1.60945L10.7591 4.35607Z"
        fill="#858DBD"
      />
    </svg>
  );
};
