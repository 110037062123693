import { usePageContext } from '../../../../../../../../providers/PageProvider';
import { InfoBlock } from '../../../../styles/InfoBlock';
import { InfoBlockTitle } from '../../../../styles/InfoBlockTitle';
import { InfoBlockContent } from '../../../../styles/InfoBlockContent';

export const AddressBlock = () => {
  const { job } = usePageContext();

  if (!job || job.meetingType === 'RECORDING_SESSION_REMOTE') {
    return null;
  }

  return (
    <InfoBlock>
      <InfoBlockTitle>Address</InfoBlockTitle>
      <InfoBlockContent>{job.meetingAddress?.length ? job.meetingAddress : 'Not set'}</InfoBlockContent>
    </InfoBlock>
  );
};
