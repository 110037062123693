import styled from 'styled-components';

export const Wrapper = styled.span`
  text-align: left;
  display: inline-flex;
  width: 100%;
  position: relative;

  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  textarea {
    color: #858dbd;
    background: #f8fafb;
    border-radius: 5px;
    border: none;
    flex: 1 1 auto;
    padding: 14px 16px;
    resize: none;
    height: 80px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;

    &::placeholder {
      color: #b4b9d9;
      opacity: 1;
      font-weight: 500;
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(202, 220, 248, 1);
    }

    &:hover {
      //background: #f8fafb;
      color: #b4b9d9;
    }

    &:focus {
      background: #ffffff;
    }

    &:disabled {
      background: #f0f9ff;
      color: #cadcf8;
      outline: none;
    }

    &.error {
      outline: none;
      box-shadow: 0 0 0 1px #ff2f2f !important;
    }
  }
`;
