import { useState } from 'react';

import { Wrapper } from './styles/Wrapper';
import { FirstStage } from './components/FirstStage';
import { SecondStage } from './components/SecondStage';

export const Content = () => {
  const [stage, setStage] = useState(1);
  const [left, setLeft] = useState('');
  const [right, setRight] = useState('');

  return (
    <Wrapper>
      {stage === 1 ? (
        <FirstStage left={left} setLeft={setLeft} right={right} setRight={setRight} setStage={setStage} />
      ) : null}

      {stage === 2 ? (
        <SecondStage left={left} setLeft={setLeft} right={right} setRight={setRight} setStage={setStage} />
      ) : null}
    </Wrapper>
  );
};
