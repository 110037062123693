export const NotificationsEmptyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_566_34694)">
        <path
          d="M18.6971 23.0182C18.6971 23.2786 18.5937 23.5283 18.4095 23.7124C18.2254 23.8966 17.9757 24 17.7153 24H12.4789C12.2185 24 11.9688 23.8966 11.7847 23.7124C11.6006 23.5283 11.4971 23.2786 11.4971 23.0182C11.4971 22.7578 11.6006 22.5081 11.7847 22.3239C11.9688 22.1398 12.2185 22.0364 12.4789 22.0364H17.7153C17.9757 22.0364 18.2254 22.1398 18.4095 22.3239C18.5937 22.5081 18.6971 22.7578 18.6971 23.0182ZM22.9762 19.9091C22.8334 20.1582 22.6272 20.365 22.3786 20.5087C22.13 20.6524 21.8479 20.7278 21.5608 20.7273H8.63348C8.34615 20.7253 8.06433 20.6481 7.81612 20.5034C7.5679 20.3586 7.36194 20.1514 7.21876 19.9023C7.07557 19.6531 7.00016 19.3708 7.00003 19.0835C6.9999 18.7962 7.07506 18.5138 7.21803 18.2645C7.68439 17.4627 8.24075 15.9327 8.24075 13.2V12.8727C8.2386 11.971 8.41436 11.0776 8.75796 10.2439C9.10156 9.41014 9.60625 8.65237 10.2431 8.01396C10.88 7.37556 11.6366 6.86906 12.4695 6.52347C13.3024 6.17788 14.1953 6 15.0971 6H15.1462C18.9017 6.02455 21.9535 9.14182 21.9535 12.9464V13.2C21.9535 15.9327 22.5098 17.4627 22.9762 18.2645C23.1205 18.5145 23.1965 18.7981 23.1965 19.0868C23.1965 19.3755 23.1205 19.6591 22.9762 19.9091ZM21.0208 18.7636C20.178 16.9964 19.9898 14.7955 19.9898 13.2V12.9464C19.9898 10.2136 17.8135 7.98 15.138 7.96364H15.0971C14.4529 7.96255 13.8149 8.0889 13.2197 8.33543C12.6245 8.58195 12.084 8.94377 11.6292 9.40005C11.1745 9.85633 10.8144 10.3981 10.5699 10.9941C10.3254 11.5901 10.2012 12.2285 10.2044 12.8727V13.2C10.2044 14.7955 10.0162 16.9964 9.17348 18.7636H21.0208Z"
          fill="#858DBD"
        />
      </g>
      <defs>
        <clipPath id="clip0_566_34694">
          <rect width="30" height="30" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
