import { routes } from '@routes';
import { usePageContext } from '../../../../../../../../providers/PageProvider';
import { InfoBlock } from '../../../../styles/InfoBlock';
import { InfoBlockTitle } from '../../../../styles/InfoBlockTitle';
import { InfoBlockContent } from '../../../../styles/InfoBlockContent';
import { StyledLink } from '../../../../styles/StyledLink';

export const MeetingAssignee = () => {
  const { job } = usePageContext();

  if (!job || !job.meetingAssigneeId) {
    return null;
  }

  return (
    <InfoBlock>
      <InfoBlockTitle>Court reporter assignee</InfoBlockTitle>
      <InfoBlockContent>
        <div className="assignee">
          <StyledLink to={routes.adminUserDetails.make(job?.meetingAssignee?.id ?? '')}>
            <p className="assignName">
              {job?.meetingAssignee?.name} {job?.meetingAssignee?.lastname}
            </p>
            <p className="assignEmail">{job?.meetingAssignee?.email || ''}</p>
          </StyledLink>
        </div>
      </InfoBlockContent>
    </InfoBlock>
  );
};
