import { AddBlue } from '@components/icons/AddBlue';

import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';

import { PlusOne } from './styles/PlusOne';
import { Wrapper } from './styles/Wrapper';

import { Plan } from './components/Plan';
import { TProps } from './types/TProps';
import { useAuthContext } from '@providers/AuthProvider';

export const Plans = ({ plans, onChange }: TProps) => {
  const { me } = useAuthContext();
  const { openModal } = useModalContext();
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;
  if (!plans) {
    return <></>;
  }

  const onAddOne = () => {
    openModal(ModalNamesEnum.AddNewASOnePlan, {
      onSuccess: () => onChange(),
    });
  };

  return (
    <Wrapper className="plans-wrapper">
      <div role="list" className="list">
        {plans.map((p, i) => (
          <Plan key={i} plan={p} onChange={() => onChange()} />
        ))}
        {isVsBilling ? (
          <li key="create-new" className="flex">
            <PlusOne onClick={onAddOne}>
              <AddBlue />
            </PlusOne>
          </li>
        ) : null}
      </div>
    </Wrapper>
  );
};
