import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { usePageContext } from '../../providers/PageProvider';

import { MainContent } from './styles/MainContent';
import { Table } from './components/Table';
import { Pagination } from './components/Pagination';

export const Content = () => {
  const { users, isLoading, search, setSearch, exportCSV } = usePageContext();

  return (
    <>
      <Header
        title="Admin Management"
        buttonLabel={users.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={users.data?.length ? () => exportCSV() : undefined}
        setSearch={setSearch}
        search={search}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <Table />
        <Pagination />
      </MainContent>
    </>
  );
};
