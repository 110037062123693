import { createContext, useContext, useEffect, useState } from 'react';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { SmsCountriesService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

export type SMSCountry = {
  code: string;
  prefix: string;
  status: string;
};

type TState = {
  smsCountries: SMSCountry[];
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  createCountry: () => void;
  removeCountry: (code: string) => Promise<void>;
};

const PageContext = createContext<TState>({
  smsCountries: [],
  isLoading: false,
  setIsLoading: () => null,
  createCountry: () => null,
  removeCountry: async () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [smsCountries, setSmsCountries] = useState<SMSCountry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const { openModal, closeModal } = useModalContext();

  const load = () => {
    setIsLoading(true);
    call(SmsCountriesService.findAll()).then((data) => {
      setSmsCountries(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    load();
  }, [reloadPageTrigger]);

  const createCountry = () => {
    openModal(ModalNamesEnum.CreateNewSMSCountry, {
      onSuccess: () => {
        closeModal();
        setReloadPageTrigger(!reloadPageTrigger);
      },
    });
  };

  const removeCountry = async (code: string) => {
    const confirmation = window.confirm('Are you sure you want to remove this country?');

    if (!confirmation) {
      return;
    }
    await call(SmsCountriesService.remove({ code }));
    setReloadPageTrigger(!reloadPageTrigger);
  };

  return (
    <PageContext.Provider
      value={{
        smsCountries,
        isLoading,
        setIsLoading,
        createCountry,
        removeCountry,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
