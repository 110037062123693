export const Share = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.14954 11.6767C3.93518 11.6744 4.69111 11.3761 5.26667 10.8414L10.8896 14.0544C10.7012 14.7906 10.7842 15.57 11.1235 16.2499C11.4628 16.9299 12.0357 17.4649 12.7372 17.757C13.4387 18.0492 14.222 18.0789 14.9436 17.8407C15.6653 17.6025 16.277 17.1124 16.6668 16.4601C17.0566 15.8077 17.1984 15.0368 17.0663 14.2885C16.9341 13.5401 16.5369 12.8644 15.9473 12.385C15.3577 11.9056 14.6151 11.6546 13.8556 11.6779C13.096 11.7013 12.3703 11.9974 11.8112 12.5121L6.18826 9.29913C6.24754 9.07457 6.27987 8.84193 6.28526 8.60929L11.8094 5.45201C12.3413 5.93595 13.0235 6.22224 13.7414 6.26286C14.4594 6.30347 15.1695 6.09593 15.7526 5.67505C16.3357 5.25418 16.7563 4.64558 16.9439 3.95135C17.1314 3.25713 17.0745 2.51951 16.7827 1.86226C16.4909 1.20501 15.982 0.668098 15.3412 0.341612C14.7005 0.0151252 13.967 -0.0810758 13.2637 0.0691474C12.5605 0.219371 11.9303 0.606878 11.4789 1.16666C11.0275 1.72644 10.7823 2.42444 10.7845 3.14356C10.7881 3.40225 10.8231 3.66004 10.8896 3.90975L5.78405 6.8263C5.48797 6.36826 5.07783 5.99512 4.59391 5.74355C4.10999 5.49198 3.56895 5.37063 3.02395 5.39142C2.47894 5.41222 1.94872 5.57444 1.48538 5.86215C1.02204 6.14986 0.641514 6.55317 0.381199 7.03244C0.120884 7.51171 -0.0102686 8.05046 0.000627588 8.59575C0.0115237 9.14105 0.164093 9.67412 0.443347 10.1426C0.7226 10.6111 1.11893 10.9989 1.59339 11.2679C2.06786 11.5368 2.60414 11.6777 3.14954 11.6767Z"
        fill="#858DBD"
      />
    </svg>
  );
};
