import { UniversalNoPictureCard } from '@components/UniversalNoPictureCard';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { useAuthContext } from '@providers/AuthProvider';

type TProps = {
  plan: TSubscriptionProduct;
  onChange: () => void;
};

export const Plan = ({ plan, onChange }: TProps) => {
  const { openModal } = useModalContext();
  const { me } = useAuthContext();
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;
  if (!plan) {
    return <></>;
  }

  const onEdit = () => {
    if (!isVsBilling) {
      return;
    }
    openModal(ModalNamesEnum.EditASOnePlan, {
      plan,
      onSuccess: () => onChange(),
    });
  };

  const content = (
    <>
      <p className="font-medium text-queen-blue">{plan.name}</p>
      <p className="text-primary text-xs">${plan.price} per license / month</p>
      <p className="text-primary text-xs">{plan.public ? 'Public' : 'Not public'}</p>
    </>
  );

  const actions = isVsBilling ? [{ label: 'Edit', onClick: onEdit }] : [];

  return <UniversalNoPictureCard content={content} actions={actions} />;
};
