import styled from 'styled-components';

import { TJob } from 'app/types/entities/TJob';
import { TCheckoutEvent } from 'app/types/entities/TCheckoutEvent';
import { formatDateMsDsYHI } from '@helpers/formatDateMsDsYHI';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';

import { Button } from '@components/form-elements/buttons/Button';
import { CustomTable } from '@components/Table';
import { StatusText } from '@components/Table/StatusText';

const StatusCell = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 80px;

  &.with-history {
    padding-right: 28px;
  }
`;

const StatusCellContent = styled.div`
  position: relative;

  p:first-child {
    font-size: 16px;
    line-height: 130%;
    margin: 10px 0 0;
  }
`;

export const CellContent = styled.div`
  margin: 0;
  padding: 20px 0;

  .assignee {
    text-overflow: ellipsis;
    overflow: hidden;

    .assignName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      margin: 0;
    }

    .assignEmail {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 100%;
      margin: 0;
    }
  }
`;

const StatusDate = styled.p`
  color: #b4b9d9;
  margin: 0 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
`;

const Wrapper = styled.div`
  width: 450px; // when status is long, we need 450px here
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  position: relative;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #858dbd;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .title {
    text-align: left;
    color: #858dbd;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    padding: 0 0 10px;
  }

  .title-value {
    text-align: left;
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    padding: 20px 0 0;
    justify-content: right;

    button {
      width: 50%;
    }
  }

  .events {
    overflow: auto;
    max-height: 40vh;

    table tr td:last-child {
      text-align: center;

      a {
        display: inline-flex;
      }
    }
  }
`;

type TProps = {
  onCancel: () => void;
  job: TJob;
};

export const OpenJobVersionModal = ({ onCancel, job }: TProps) => {
  const tableHead = [
    // {
    //   headComponent: () => <td style={{ width: '20%' }}>Assignee</td>,
    //   render: (data: TCheckoutEvent) => (
    //     <CellContent>
    //       <div className="assignee">
    //         <p className="assignName">
    //           {data.user.name} {data.user.lastname}
    //         </p>
    //         <p className="assignEmail">{data.user?.email || ''}</p>
    //       </div>
    //     </CellContent>
    //   ),
    // },
    {
      render: (data: TCheckoutEvent) => {
        return <>{formatDateMsDsYHI(data.createdAt ?? '')}</>;
      },
      headComponent: () => <td style={{ width: '1%' }}>Created</td>,
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Status</td>,
      render: (data: TCheckoutEvent) => {
        return (
          <StatusCell>
            <StatusCellContent>
              <StatusText status={JOB_STATUSES_ENUM[data.jobStatus]} />
              <StatusDate>{formatDateMsDsYHI(data.createdAt ?? '')}</StatusDate>
            </StatusCellContent>
          </StatusCell>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <h2>Choose File Version</h2>

      <div className="title">Job title</div>
      <div className="title-value">{job.name}</div>

      <div className="events">
        <CustomTable whiteBackground={true} fullPage={false} head={tableHead} data={job.checkoutEvents || []} />
      </div>
      <div className="buttonWrapper">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Wrapper>
  );
};
