import { Dispatch, SetStateAction } from 'react';

import { MiniTeamIcon } from '@components/todo/Teams/MiniTeamIcon';
import { TRole } from 'app/types/entities/TRole';

type TProps = {
  team: TRole;
  selectedTeams: string[];
  setSelectedTeams: Dispatch<SetStateAction<string[]>>;
};

export const SelectableMiniTeamCards = ({ team, selectedTeams, setSelectedTeams }: TProps) => {
  const selected = selectedTeams.includes(team.id);

  const handleClick = () => {
    if (selected) {
      setSelectedTeams(selectedTeams.filter((id) => id !== team.id));
    } else {
      setSelectedTeams([...selectedTeams, team.id]);
    }
  };

  return (
    <li
      key={team.name}
      className={`col-span-1 flex rounded-md shadow-sm w-5/12 m-1 hover:bg-alice-blue cursor-pointer ${
        selected ? 'bg-lavender-blue' : ''
      }`}
      onClick={handleClick}
      title={team.name}
    >
      <MiniTeamIcon team={team} />
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <div className="font-medium text-queen-blue text-ellipsis overflow-hidden">{team.name}</div>
        </div>
      </div>
    </li>
  );
};
