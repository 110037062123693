import { useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { Button } from '@components/form-elements/buttons/Button';
import { Dots } from '@components/icons/Dots';
import { DropDown } from '@components/form-elements/DropDown';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';

const Wrapper = styled.div`
  width: 210px;
  height: 135px;
  background: #ffffff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  .title {
    color: #858dbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
  }

  .content {
    padding: 20px 20px 4px;
  }

  .description {
    padding: 0 20px;

    .balance {
      color: #40608f;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
    }
  }

  .buttonContainer {
    height: 40px;
    width: calc(100% - 40px);
    margin: 0 20px;
    display: block;
    bottom: 0;
    position: absolute;
    border-top: 1px solid #d5ddf2;

    button {
      border-radius: 10px;
      padding: 0;
      height: 40px;
      width: 100%;
    }
  }

  .dots-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;

    :hover {
      & svg {
        circle {
          fill: #858dbd;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
`;

type TProps = {
  className?: string;
  billingAccount?: TBillingAccount;
  reloadCards: () => void;
  canManageBillingAccount: boolean;
};

export const CreditBalanceCard = ({ className = '', billingAccount, canManageBillingAccount, reloadCards }: TProps) => {
  const { openModal } = useModalContext();
  const [showDropDown, setShowDropDown] = useState(false);

  const onTopUp = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    e.stopPropagation();
    if (!canManageBillingAccount) {
      return;
    }
    openModal(ModalNamesEnum.TopUpCreditBalance, {
      billingAccountId: billingAccount?.id,
      onSuccess: reloadCards,
    });
  };

  const balance = billingAccount?.currentBalance ?? 0;
  const description = <div className="balance">{formatCentsPrice(balance)}</div>;

  const renderOptions = () => {
    const dropDownOptions: {
      title: string;
      onClick: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
    }[] = [];
    if (canManageBillingAccount) {
      dropDownOptions.push({
        title: 'Add Funds',
        onClick: onTopUp,
      });
    }
    if (!dropDownOptions.length) {
      return;
    }
    return (
      <>
        <div
          className="dots-button"
          onClick={(e) => {
            e.stopPropagation();
            setShowDropDown(true);
          }}
        >
          <Dots />
        </div>
        {showDropDown ? <DropDown close={() => setShowDropDown(false)} options={dropDownOptions} /> : null}
      </>
    );
  };

  return (
    <Wrapper className={className}>
      <div className="content">
        <h2 className="title">Credit Balance</h2>
        {renderOptions()}
      </div>

      <div className="description">{description}</div>

      {canManageBillingAccount ? (
        <div className="buttonContainer">
          <StyledButton size="medium" variant="text" onClick={onTopUp}>
            Add Funds
          </StyledButton>
        </div>
      ) : null}
    </Wrapper>
  );
};
