// const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getAmericanTime = (date: string | number | Date, showHHMM = true, withSeconds = false) => {
  const d = new Date(date);
  const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;
  const min = d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`;
  const hour = d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`;
  const sec = d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`;
  // return `${months[d.getMonth()]} ${day}, ${d.getFullYear()} ${hour}:${min}:${sec}`;
  if (showHHMM) {
    return `${months[d.getMonth()]} ${day}, ${d.getFullYear()} ${hour}:${min}${withSeconds ? `:${sec}` : ''}`;
  }
  return `${months[d.getMonth()]} ${day}, ${d.getFullYear()}`;
};
