import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const StyledLink = styled(RouterLink)`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #313131;
  padding: 0 0 5px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #40608f;
  }
`;
