import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import { Props } from './types/Props';

export const Diff = ({ left, right, method = DiffMethod.WORDS_WITH_SPACE }: Props) => {
  return (
    <ReactDiffViewer
      oldValue={left}
      newValue={right}
      splitView={true}
      showDiffOnly={true}
      useDarkTheme={false}
      compareMethod={method}
    />
  );
};
