import { UniversalCard } from '@components/UniversalCard';
import { getShortName } from '@helpers/getShortName';
import { TRole } from 'app/types/entities/TRole';
import { Link } from 'react-router-dom';
import { routes } from '@routes';

type TProps = {
  team: TRole;
};

export const TeamCard = ({ team }: TProps) => {
  const content = (
    <>
      <Link to={routes.adminTeamDetails.make(team.id)} className="font-medium text-queen-blue hover:text-primary">
        {team.name || team.slug}
      </Link>
      <p>&nbsp;</p>
      {team.members !== undefined ? (
        <p className="text-primary text-xs">
          {team.members} {team.members === 1 ? 'member' : 'members'}
        </p>
      ) : null}
    </>
  );

  const teamShortname = getShortName(team.name || team.slug);
  return <UniversalCard shortNameBGColor={team.colour} content={content} shortName={teamShortname} />;
};
