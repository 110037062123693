import styled from 'styled-components';

export const AvatarName = styled.div`
  width: 30px;
  height: 30px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
`;
