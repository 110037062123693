import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { refreshToken } from 'app/http';

import { PermissionProvider } from './PermissionProvider/PermissionProvider';

import { TMe } from 'app/types/entities/TMe';
import { TWorkspace } from 'app/types/entities/TWorkspace';
import { TOrganization } from 'app/types/entities/TOrganization';
import { EPermission } from 'app/types/enums/EPermission';
import { useAPI } from '@hooks/useAPI';
import { AuthService } from 'app/API';
import { subscribeUserToPush } from '@helpers/subscribeUserToPush';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj: (apiCall: string, id: string, opts: any) => void;
  }
}

type TProps = {
  children: ReactNode;
};

type TState = {
  me: TMe;
  isLoggedIn: boolean;
  updateMe: () => Promise<TMe | undefined>;
  clearMe: () => void;
  workspace: TWorkspace | undefined;
  organization: TOrganization | undefined;
  setMe: (arg: TMe) => void;
  notificationsReceiveDate: number;
};

const parsePermissions =
  (me: TMe, organization: TOrganization | undefined) =>
  async (permission: EPermission): Promise<boolean> => {
    const org = me.organizations?.find((org) => org.id === organization?.id);

    if (!org) {
      return false;
    }

    return org.permissions[permission];
  };

const AuthContext = createContext<TState>({
  me: {} as TMe,
  isLoggedIn: false,
  updateMe: () => new Promise(() => {}),
  clearMe: () => {},
  workspace: {} as TWorkspace,
  organization: {} as TOrganization,
  setMe: () => {},
  notificationsReceiveDate: 0,
});

export const AuthProvider = ({ children }: TProps) => {
  const { call } = useAPI();
  const location = useLocation();
  const [refreshInterval, setRefreshInterval] = useState<NodeJS.Timeout | string | number>();
  const [me, setMe] = useState<TMe>({} as TMe);
  const [notificationsReceiveDate, setNotificationsReceiveDate] = useState<number>(0);
  const [workspace, setWorkspace] = useState<TWorkspace>();
  const [organization, setOrganization] = useState<TOrganization>();

  const isLoggedIn = !!me.id;

  const updateMe = async () => {
    const data = (await call(AuthService.me())) as TMe;

    if (!data?.id) {
      console.error('Unable to retrieve user account (logged out)');
      return;
    }

    if (!data?.organizations) {
      throw new Error('Unable to retrieve user organization details');
    }

    if (window.hj) {
      window.hj('identify', data.id, { email: data.email, name: `${data.name} ${data.lastname}` });
    }

    setMe({ ...data, organizations: [...data.organizations], workspaces: [...data.workspaces] });
    subscribeUserToPush(updateMe).catch((error) => console.error('Error subscribing to push notifications:', error));
    setNotificationsReceiveDate(Date.now());
    return data;
  };

  useEffect(() => {
    updateMe();
  }, []);

  useEffect(() => {
    updateOrgFromSlug();
  }, [me, location.pathname]);

  useEffect(() => {
    updateWSFromSlug();
  }, [organization, location.pathname]);

  /**
   * Get initial org from slug (if present)
   * If not present and organization is not set then set first organization
   * If organization is the same, skip organization update to prevent reloading
   */
  const updateOrgFromSlug = () => {
    if (!me.organizations || !me.organizations.length) {
      return;
    }
    const organizationInSlug = getOrgFromSlug();
    if (!organizationInSlug && !organization) {
      setOrganization(me.organizations[0]);
      return;
    }
    if (!organizationInSlug) {
      return;
    }
    if (organization && organizationInSlug?.id === organization?.id) {
      if (
        organizationInSlug.billingAccount?.FreeCreditBalance?.currentBalance ===
          organization.billingAccount?.FreeCreditBalance?.currentBalance &&
        organizationInSlug.billingAccount?.MainBalance?.currentBalance ===
          organization.billingAccount?.MainBalance?.currentBalance
      ) {
        return;
      }
    }
    setOrganization(organizationInSlug);
  };

  const getOrgFromSlug = () => {
    if (!me.organizations || !me.organizations.length) {
      return;
    }
    return me.organizations.find((o) => location.pathname.startsWith(`/${o.slug}/`));
  };

  /**
   * Get initial workspace from slug (if present)
   * If not present and workspace set is not in current org set first org ws
   * If workspace is the same, skip workspace update to prevent reloading
   */
  const updateWSFromSlug = () => {
    if (!organization || !me.workspaces || !me.workspaces.length) {
      return;
    }
    const workspaceInSlug = getWSFromSlug();
    if (!workspaceInSlug && workspace?.organizationId !== organization.id) {
      const orgWorkspaces = me.workspaces.filter((w: TWorkspace) => w.organizationId === organization.id);
      setWorkspace(orgWorkspaces[0]);
      return;
    }
    if (!workspaceInSlug) {
      return;
    }
    setWorkspace(workspaceInSlug);
  };

  const getWSFromSlug = () => {
    if (!organization || !me.workspaces || !me.workspaces.length) {
      return;
    }
    const orgWorkspaces = me.workspaces.filter((w: TWorkspace) => w.organizationId === organization?.id);
    return orgWorkspaces.find(
      (w) =>
        location.pathname.startsWith(`/${organization?.slug}/workspaces/${w.slug}/`) &&
        w.organizationId === organization?.id,
    );
  };

  const clearMe = () => {
    setMe({} as TMe);
  };

  useEffect(() => {
    if (refreshInterval && !me.id) {
      clearInterval(refreshInterval);
      setRefreshInterval(undefined);
    }
    if (!refreshInterval && me.id) {
      const interval = setInterval(() => {
        refreshToken();
      }, 200000);
      setRefreshInterval(interval);
    }
  }, [me]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        me,
        updateMe,
        clearMe,
        workspace,
        organization,
        setMe,
        notificationsReceiveDate,
      }}
    >
      <PermissionProvider fetchPermission={parsePermissions(me, organization)}>{children}</PermissionProvider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
