import { useAuthContext } from '@providers/AuthProvider';
import { useModalContext } from '@providers/ModalProvider';
import { routes } from '@routes';

import { formatDateMsDsYHI } from '@helpers/formatDateMsDsYHI';
import { Wrapper } from '@components/Modal/Search/components/Workspaces/styles/Wrapper';

import { TProps } from './types/TProps';
import { StyledLink } from '@components/Modal/Search/components/Workspaces/styles/StyledLink';

export const UsersList = ({ users }: TProps) => {
  const { organization } = useAuthContext();
  const { closeModal } = useModalContext();
  return (
    <Wrapper>
      <div className="header">Users</div>
      <div className="items">
        {users?.map((user) => (
          <div key={user.id} className="item">
            <div className="title">
              <StyledLink
                onClick={() => {
                  closeModal();
                }}
                to={routes.userDetails.make(organization?.slug ?? '', user.userId)}
              >
                {user.name} {user.lastname}
              </StyledLink>
            </div>
            <div className="subtitle">
              {formatDateMsDsYHI(user.createdAt)} • {user.email}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};
