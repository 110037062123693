import styled from 'styled-components';
import { WhiteAutoCompleteSelect } from './WhiteAutoCompleteSelect';

export const SmallWhiteAutoCompleteSelect = styled(WhiteAutoCompleteSelect)`
  .select {
    padding: 7px 10px;
  }

  .label {
    left: 11px;
  }

  .optionsLabel {
    padding: 8px 0;
  }
`;
