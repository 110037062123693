import React from 'react';

export const LocationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.11732 7.33268C9.11732 7.89406 8.89431 8.43244 8.49736 8.82939C8.10041 9.22634 7.56203 9.44935 7.00065 9.44935C6.43928 9.44935 5.90089 9.22634 5.50394 8.82939C5.10699 8.43244 4.88398 7.89406 4.88398 7.33268C4.88398 6.77131 5.10699 6.23292 5.50394 5.83597C5.90089 5.43902 6.43928 5.21602 7.00065 5.21602C7.56203 5.21602 8.10041 5.43902 8.49736 5.83597C8.89431 6.23292 9.11732 6.77131 9.11732 7.33268Z"
        stroke="#858DBD"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0673 7.33268C13.0673 10.2868 11.5161 12.6443 9.90475 14.2941C9.10285 15.1152 8.29924 15.7468 7.69612 16.1729C7.41103 16.3743 7.17194 16.5289 7.00065 16.6351C6.82937 16.5289 6.59027 16.3743 6.30518 16.1729C5.70206 15.7468 4.89845 15.1152 4.09655 14.2941C2.48519 12.6443 0.933984 10.2868 0.933984 7.33268C0.933984 5.7237 1.57315 4.18062 2.71087 3.0429C3.84859 1.90518 5.39167 1.26602 7.00065 1.26602C8.60963 1.26602 10.1527 1.90518 11.2904 3.0429C12.4282 4.18062 13.0673 5.7237 13.0673 7.33268Z"
        stroke="#858DBD"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
