import styled from 'styled-components';

export const OrgInitialsIcon = styled.span`
  width: 30px;
  height: 30px;
  display: inline-block;
  flex: 0 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  border: 1px solid #858dbd;
  border-radius: 5px;
  text-align: center;
  margin: 0 15px 0 2px;
`;
