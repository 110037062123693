export const VolumeOffIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7073 14.5701L17.3049 13.1677C17.5392 12.5015 17.6667 11.7851 17.6667 11.0392C17.6667 8.13333 15.7325 5.67667 13.0833 4.88833V3C16.7592 3.83417 19.5 7.11583 19.5 11.0392C19.5 12.3028 19.2157 13.4999 18.7073 14.5701Z"
        fill="#40608F"
      />
      <path
        d="M15.3705 11.2333L13.0833 8.94613V7.345C14.44 8.02333 15.375 9.41667 15.375 11.0392C15.375 11.1043 15.3735 11.169 15.3705 11.2333Z"
        fill="#40608F"
      />
      <path d="M4.98125 8.28917H3V13.7892H6.66667L11.25 18.3725V14.5579L4.98125 8.28917Z" fill="#40608F" />
      <path
        d="M13.0833 17.19C13.2846 17.1301 13.4817 17.0606 13.6741 16.982L15.0391 18.347L15.0501 18.3579C14.4358 18.6769 13.7762 18.9211 13.0833 19.0783V17.19Z"
        fill="#40608F"
      />
      <path d="M9.54652 5.40931L11.25 7.11279V3.70583L9.54652 5.40931Z" fill="#40608F" />
      <path
        d="M3.70711 3.29289C3.31658 3.68342 3.31658 4.31658 3.70711 4.70711L17.7064 18.7064C18.0969 19.0969 18.7301 19.0969 19.1206 18.7064C19.5111 18.3159 19.5111 17.6827 19.1206 17.2922L5.12132 3.29289C4.7308 2.90237 4.09763 2.90237 3.70711 3.29289Z"
        fill="#40608F"
      />
    </svg>
  );
};
