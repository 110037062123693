import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const StyledLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;
