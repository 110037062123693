import styled from 'styled-components';

export const JobSection = styled.div`
  display: flex;
  align-items: start;
  padding: 20px 0;

  .enhance-button {
    margin-left: 8px;
    cursor: pointer;

    path {
      stroke: #858dbd;
    }

    &:hover {
      path {
        stroke: #40608f;
      }
    }
  }

  .enhance-disabled-button {
    margin-left: 8px;
    opacity: 0.3;

    path {
      stroke: #858dbd;
    }
  }
`;
