import styled from 'styled-components';

export const CommentsList = styled.div`
  margin: -20px 0 0;

  .comment {
    padding: 20px 0 0;
    display: flex;
    gap: 10px;

    .comment-right {
      .comment-title {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #858dbd;
        padding: 0 0 5px;
      }

      .comment-username a {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #858dbd;
        text-decoration: underline;
        margin: 0 10px 0 0;
      }

      .comment-date {
        font-weight: 400;
      }

      .comment-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #00122d;
      }
    }
  }
`;
