export const PlusBalance = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C8.7797 0 9.41177 0.632069 9.41177 1.41176V14.5882C9.41177 15.3679 8.7797 16 8 16C7.2203 16 6.58824 15.3679 6.58824 14.5882V1.41176C6.58824 0.632069 7.2203 0 8 0Z"
        fill="#D5DEF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C-3.40816e-08 7.2203 0.632068 6.58824 1.41176 6.58824L14.5882 6.58823C15.3679 6.58823 16 7.2203 16 8C16 8.7797 15.3679 9.41176 14.5882 9.41176L1.41176 9.41177C0.632069 9.41177 3.40816e-08 8.7797 0 8Z"
        fill="#D5DEF2"
      />
    </svg>
  );
};
