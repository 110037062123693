import { DiffMethod } from 'react-diff-viewer-continued';

import { pluralize } from '@helpers/pluralize';
import { Button } from '@components/form-elements/buttons/Button';

import { Diff } from './components/Diff';
import { Props } from './types/Props';

export const SecondStage = ({ left, right, setStage }: Props) => {
  const leftLines = left.split('\n').length ?? 0;
  const rightLines = right.split('\n').length ?? 0;
  return (
    <div className="second-stage">
      <div className="flex-block">
        <div className="left-wrapper">
          <div className="lines">
            {leftLines} {pluralize(leftLines, 'line', `lines`)}
          </div>
        </div>
        <div className="right-wrapper">
          <div className="lines">
            {rightLines} {pluralize(rightLines, 'line', `lines`)}
          </div>
        </div>
      </div>
      <div className="diff">
        <Diff left={left} right={right} method={DiffMethod.WORDS_WITH_SPACE} />
      </div>
      <Button
        onClick={() => {
          // setReference('');
          // setHypotesis('');
          setStage(1);
        }}
      >
        Try again
      </Button>
    </div>
  );
};
