import ReactDOM from 'react-dom';
import { ClickAway } from '@components/ClickAway';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const Popover = ({ children, onClose, open, anchorEl, styles = '', className = '' }: TProps) => {
  return open && anchorEl
    ? ReactDOM.createPortal(
        <ClickAway className={className} onClickAway={onClose}>
          <Wrapper styles={styles}>{children}</Wrapper>
        </ClickAway>,
        anchorEl,
      )
    : null;
};
