import { Helmet } from 'react-helmet-async';

import { PageProvider } from './providers/PageProvider';
import { Content } from './components/Content';

export const AdminWorkspacesListPage = () => {
  return (
    <>
      <Helmet>
        <title>Workspace list - AutoScript Admin</title>
      </Helmet>
      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
