import styled from 'styled-components';

export const Wrapper = styled.h1`
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #40608f;
  white-space: nowrap;
`;
