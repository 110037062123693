import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  margin: 0 10px 0 0;

  & .page-number {
    display: block;
    color: #858dbd;
    border-radius: 5px;
    margin: 0;
    padding: 2px 10px;
    cursor: pointer;
    text-align: center;

    &.current {
      background: #cadcf8;
      color: #40608f;
      margin: 0;

      &:hover {
        background: #cadcf8;
      }
    }

    &:disabled {
      opacity: 0.7;
    }

    &:hover {
      background: #fff;
    }
  }
`;
