import styled from 'styled-components';

import { Input } from '@components/form-elements/Input';

const Wrapper = styled.div`
  display: flex;

  .leftSide {
    width: 230px;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #878787;
    flex: 0 0 auto;

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #858dbd;
      padding: 0 0 10px;
    }
  }

  .rightSide {
    width: 100%;

    .rightSide-columns {
      display: flex;
      gap: 10px;
    }
  }
`;

const FirstFormRowWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 0 0 5px;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #858dbd;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 280px;
  flex: 0 0 auto;

  .whiteInput {
    padding: 4px 0 0;

    input {
      height: 30px;
      background: white !important;
    }
  }
`;

type TProps = {
  name: string;
  slug: string;
};

export const WorkspaceDetailsForm = ({ name, slug }: TProps) => {
  return (
    <Wrapper>
      <div className="leftSide">
        <h3>General</h3>
      </div>

      <div className="rightSide">
        <div className="rightSide-columns">
          <FirstFormRowWrapper>
            <InputWrapper>
              <label htmlFor="name">Workspace name</label>
              <Input
                className="whiteInput"
                disabled={true}
                onChange={() => {}}
                name="name"
                value={name}
                label="Workspace name"
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="slug">Workspace URL</label>
              <Input
                className="whiteInput"
                disabled={true}
                name="slug"
                value={slug}
                label="Workspace URL"
                onChange={() => {}}
              />
            </InputWrapper>
          </FirstFormRowWrapper>
        </div>
      </div>
    </Wrapper>
  );
};
