export const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2967 4H4.60288C4.17777 4 3.77007 4.16704 3.46947 4.46437C3.16887 4.7617 3 5.16497 3 5.58546V15.0982C3 15.5187 3.16887 15.922 3.46947 16.2193C3.77007 16.5167 4.17777 16.6837 4.60288 16.6837H12.2967M13.4642 7.17092L16.67 10.3418M16.67 10.3418L13.4642 13.5128M16.67 10.3418H5.73031"
        stroke="#858DBD"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
