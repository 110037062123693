import styled from 'styled-components';

export const HeaderSwitchWrapper = styled.div`
  display: flex;
  white-space: nowrap;

  .button {
    border-radius: 0;
    background: #f8fafb;
    color: #40608f;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
  }

  button.active {
    background: #d5def2;

    &:hover {
      background: #d5def2;
    }
  }

  button:first-child {
    border-radius: 5px 0 0 5px;
  }

  button:last-child {
    border-radius: 0 5px 5px 0;
  }

  button:hover {
    background: #d5def2;
  }
`;
