export const colourSet = [
  '#CADCF8',
  '#A3BAF4',
  '#FFD0D0',
  '#FFAAAA',
  '#BEE1C1',
  '#A8E3E4',
  '#DECAF8',
  '#FFC2F2',
  '#F8E0CA',
  '#FFC8A2',
];
