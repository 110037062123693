import { useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';
import { createSmsWhitelist } from '@queries/admin/sms-whitelist/adminCreateSmsWhitelist';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Input } from '@components/form-elements/Input';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 370px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0 0 20px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .input,
    .form-select,
    .textarea {
      margin: 0 0 20px;
    }
  }

  .public {
    display: flex;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const CreateNewWhitelistNumber = ({ onCancel, onSuccess }: TProps) => {
  const { closeModal } = useModalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [whitelist, setWhitelist] = useState({
    number: '',
  });

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await createSmsWhitelist(whitelist.number);
    setIsSubmitting(false);
    closeModal();
    onSuccess();
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Whitelist mobile number</h2>

      <div className="input-wrapper">
        <div className="label">Mobile number</div>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWhitelist({ ...whitelist, number: e.target.value })}
          label="Number with international prefix"
          placeholder='e.g. "+6588943230"'
          value={whitelist.number}
        />
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </ModalWrapper>
  );
};
