import { client } from 'app/http';

export const addBalanceToBillingAccount = async (baId: string, amount: number) => {
  try {
    const response = await client.post(`admin/billing-account/${baId}/balance`, {
      amount,
    });
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message, errorDetail: error?.response?.data?.error };
  }
};
