export const audioTypes = [
  '.mp3',
  '.mp2',
  '.mp4',
  '.wav',
  '.3ga',
  '.8svx',
  '.aac',
  '.ac3',
  '.aif',
  '.aiff',
  '.alac',
  '.amr',
  '.ape',
  '.au',
  '.dss',
  '.flac',
  '.flv',
  '.m4a',
  '.m4b',
  '.m4p',
  '.m4r',
  '.mpga',
  '.ogg',
  '.oga',
  '.mogg',
  '.opus',
  '.qcp',
  '.tta',
  '.voc',
  '.wma',
  '.wv',
  '.webm',
  '.mts',
  '.m2ts',
  '.ts',
  '.mov',
  '.m4p',
  '.m4v',
  '.mxf',
  '.trm',
];
