import { errors } from './errors';
import { TError } from '../types/TError';

export const translateError = (error: TError): string => {
  if (!error) {
    return '';
  }

  if (typeof error === 'string') {
    return errors[error] || error;
  }

  if (error?.isAxiosError) {
    return 'Network error, Check Internet connection';
  }

  const message =
    error?.code ??
    error?.response?.data?.error ??
    error?.response?.data?.message ??
    (error.isAxiosError ? 'Network error, Check Internet connection' : 'Unknown error');

  return errors[message] || message || 'Unknown error. Please try again later';
};
