import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Button } from '@components/form-elements/buttons/Button';

import { Wrapper } from './styles/Wrapper';
import { PositiveIcon } from '@components/icons/Positive';
import { PendingIcon } from './icons/Pending';
import { ErrorIcon } from './icons/Error';
import { useModalContext } from '@providers/ModalProvider';
import { useWebsocketContext } from '@providers/WebsocketProvider';

type TProps = {
  chargeId: string;
  pics: string;
  onSuccess: () => void;
};

export const TransactionStatusModalContent = ({ chargeId, onSuccess, pics }: TProps) => {
  const stripe = useStripe();
  const { closeModal } = useModalContext();
  const { transactionStatusDto } = useWebsocketContext();
  const [status, setStatus] = useState<'pending' | 'success' | 'error'>('pending');
  const [message, setMessage] = useState('Your payment is pending');
  const [buttonMessage, setButtonMessage] = useState('Close');

  useEffect(() => {
    if (!transactionStatusDto) {
      return;
    }
    if (transactionStatusDto?.id === chargeId) {
      setStatus(transactionStatusDto.status);
      if (transactionStatusDto.status === 'success') {
        setMessage('Your payment has been processed successfully');
        setButtonMessage('Ok');
      }
      if (transactionStatusDto.status === 'error') {
        setMessage('Something went wrong. Please try again later');
        setButtonMessage('Ok');
      }
    }
  }, [transactionStatusDto]);

  const loadStatus = async () => {
    if (!stripe) {
      console.error('Stripe is not loaded');
      return;
    }
    const result = await stripe?.retrievePaymentIntent(pics);
    if (result.paymentIntent?.status === 'succeeded') {
      setStatus('success');
      setMessage('Your payment has been processed successfully');
      setButtonMessage('Ok');
    }
    if (result.paymentIntent?.status === 'canceled') {
      setStatus('error');
      setMessage('Something went wrong. Please try again later');
      setButtonMessage('Ok');
    }
  };

  const onClick = () => {
    closeModal();
    onSuccess();
  };

  return (
    <Wrapper>
      <h2>Payment Status</h2>
      {status === 'success' && (
        <div className="icon">
          <PositiveIcon />
        </div>
      )}
      {status === 'pending' && (
        <div className="icon">
          <PendingIcon />
        </div>
      )}
      {status === 'error' && (
        <div className="icon">
          <ErrorIcon />
        </div>
      )}
      <p className="description">{message}</p>
      <div className="buttonContainer" style={{ width: status === 'pending' ? 'auto' : '130px' }}>
        {status === 'pending' ? (
          <Button variant="outlined" onClick={loadStatus}>
            Refresh
          </Button>
        ) : null}
        <Button variant="contained" onClick={onClick}>
          {buttonMessage}
        </Button>
      </div>
    </Wrapper>
  );
};
