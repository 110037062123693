import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledAdminLink = styled(RouterLink)`
  color: #00122d;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;
  border-radius: 5px;

  &:hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;
