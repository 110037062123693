export const Document = () => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.2 5.4V0H1.8C1.32261 0 0.864773 0.189642 0.527208 0.527208C0.189642 0.864773 0 1.32261 0 1.8V16.2C0 16.6774 0.189642 17.1352 0.527208 17.4728C0.864773 17.8104 1.32261 18 1.8 18H12.6C13.0774 18 13.5352 17.8104 13.8728 17.4728C14.2104 17.1352 14.4 16.6774 14.4 16.2V7.2H9C8.52261 7.2 8.06477 7.01036 7.72721 6.67279C7.38964 6.33523 7.2 5.87739 7.2 5.4ZM3.825 8.55H10.575C10.754 8.55 10.9257 8.62112 11.0523 8.7477C11.1789 8.87429 11.25 9.04598 11.25 9.225C11.25 9.40402 11.1789 9.57571 11.0523 9.7023C10.9257 9.82888 10.754 9.9 10.575 9.9H3.825C3.64598 9.9 3.47429 9.82888 3.3477 9.7023C3.22112 9.57571 3.15 9.40402 3.15 9.225C3.15 9.04598 3.22112 8.87429 3.3477 8.7477C3.47429 8.62112 3.64598 8.55 3.825 8.55ZM3.825 11.025H10.575C10.754 11.025 10.9257 11.0961 11.0523 11.2227C11.1789 11.3493 11.25 11.521 11.25 11.7C11.25 11.879 11.1789 12.0507 11.0523 12.1773C10.9257 12.3039 10.754 12.375 10.575 12.375H3.825C3.64598 12.375 3.47429 12.3039 3.3477 12.1773C3.22112 12.0507 3.15 11.879 3.15 11.7C3.15 11.521 3.22112 11.3493 3.3477 11.2227C3.47429 11.0961 3.64598 11.025 3.825 11.025ZM3.825 13.5H10.575C10.754 13.5 10.9257 13.5711 11.0523 13.6977C11.1789 13.8243 11.25 13.996 11.25 14.175C11.25 14.354 11.1789 14.5257 11.0523 14.6523C10.9257 14.7789 10.754 14.85 10.575 14.85H3.825C3.64598 14.85 3.47429 14.7789 3.3477 14.6523C3.22112 14.5257 3.15 14.354 3.15 14.175C3.15 13.996 3.22112 13.8243 3.3477 13.6977C3.47429 13.5711 3.64598 13.5 3.825 13.5ZM8.55 5.4V0.45L13.95 5.85H9C8.88065 5.85 8.76619 5.80259 8.6818 5.7182C8.59741 5.63381 8.55 5.51935 8.55 5.4Z"
        fill="white"
      />
    </svg>
  );
};
