export const AccountIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5581 16.886C17.3767 14.8508 15.4857 13.3233 13.2475 12.5963C14.3203 11.9584 15.154 10.9859 15.6205 9.82817C16.087 8.67049 16.1604 7.39166 15.8296 6.18816C15.4989 4.98465 14.7821 3.92304 13.7894 3.16642C12.7968 2.40979 11.5831 2 10.335 2C9.08686 2 7.87324 2.40979 6.88059 3.16642C5.88793 3.92304 5.17115 4.98465 4.84035 6.18816C4.50955 7.39166 4.58304 8.67049 5.04952 9.82817C5.516 10.9859 6.34968 11.9584 7.42247 12.5963C5.18433 13.3233 3.29327 14.8508 2.11185 16.886C2.0623 16.9579 2.02815 17.0392 2.01155 17.1249C1.99495 17.2107 1.99626 17.2989 2.01539 17.3841C2.03453 17.4693 2.07108 17.5496 2.12274 17.62C2.17441 17.6903 2.24007 17.7493 2.31561 17.7931C2.39115 17.8369 2.47493 17.8646 2.56168 17.8744C2.64844 17.8843 2.73629 17.8761 2.81973 17.8504C2.90316 17.8246 2.98038 17.7819 3.04651 17.7249C3.11265 17.6679 3.16629 17.5979 3.20405 17.5191C3.92773 16.2686 4.96754 15.2304 6.21916 14.5086C7.47077 13.7869 8.89018 13.4069 10.335 13.4069C11.7798 13.4069 13.1992 13.7869 14.4508 14.5086C15.7025 15.2304 16.7423 16.2686 17.466 17.5191C17.5037 17.5979 17.5573 17.6679 17.6235 17.7249C17.6896 17.7819 17.7668 17.8246 17.8503 17.8504C17.9337 17.8761 18.0216 17.8843 18.1083 17.8744C18.1951 17.8646 18.2789 17.8369 18.3544 17.7931C18.4299 17.7493 18.4956 17.6903 18.5473 17.62C18.5989 17.5496 18.6355 17.4693 18.6546 17.3841C18.6737 17.2989 18.6751 17.2107 18.6585 17.1249C18.6419 17.0392 18.6077 16.9579 18.5581 16.886ZM5.90289 7.70516C5.90289 6.82857 6.16283 5.97167 6.64983 5.24281C7.13684 4.51396 7.82904 3.94588 8.6389 3.61042C9.44877 3.27497 10.3399 3.1872 11.1997 3.35821C12.0594 3.52923 12.8491 3.95134 13.469 4.57119C14.0888 5.19103 14.5109 5.98075 14.682 6.8405C14.853 7.70025 14.7652 8.5914 14.4297 9.40126C14.0943 10.2111 13.5262 10.9033 12.7973 11.3903C12.0685 11.8773 11.2116 12.1373 10.335 12.1373C9.15953 12.1373 8.03221 11.6703 7.20102 10.8391C6.36984 10.008 5.90289 8.88063 5.90289 7.70516Z"
        fill="#858DBD"
      />
    </svg>
  );
};
