import { forwardRef, useEffect, useState } from 'react';

import ErrorIcon from './icons/error.svg';
import { StyledInput } from './styles/StyledInput';
import { TProps } from './types/TProps';

export const Input = forwardRef<HTMLInputElement, TProps>(function Input(
  {
    type = 'text',
    name = 'input',
    icon: Icon,
    label,
    onChange,
    onIconClick,
    value = '',
    placeholder = '',
    required = false,
    disabled = false,
    error = false,
    className = 'form-input',
    handleBlur,
    size,
    onKeyUp,
  }: TProps,
  ref,
) {
  const defaultTitle = `${label}${required ? ' *' : ''}`;
  const placeholderTitle = placeholder ? placeholder : defaultTitle;
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    onBlur();
  }, [required]);

  const onFocus = () => {
    setTitle(placeholderTitle);
  };

  const onBlur = () => {
    setTitle(defaultTitle);
    handleBlur && handleBlur(null);
  };

  const renderIcon = () => {
    if (!Icon) {
      return null;
    }
    if (error) {
      return <ErrorIcon onClick={onIconClick} className={`icon${onIconClick ? ' clickable' : ''}`} />;
    }
    return <Icon onClick={onIconClick} className={`icon${onIconClick ? ' clickable' : ''}`} />;
  };

  return (
    <StyledInput className={className}>
      <input
        placeholder={title}
        className={`input ${Icon || error ? 'with-icon' : ''} ${error ? 'error' : ''}`}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        required={required}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        size={size}
        ref={ref}
        onKeyUp={onKeyUp}
      />
      {renderIcon()}
    </StyledInput>
  );
});
