import { AxiosResponse } from 'axios';

export const getASDProtocolLink = (jobId: string, versionId?: string): string => {
  const location = window.location.hostname;

  let url = jobId;
  if (versionId) {
    url += `?version=${versionId}`;
  }

  console.log(`ASD version string: ${url}`);

  if (location === 'www.autoscript.ai') {
    return `autoscript://${url}`;
  }
  if (location === 'staging.autoscript.ai') {
    return `as://${url}`;
  }
  if (location === 'demo.autoscript.ai') {
    return `asd://${url}`;
  }
  // using staging for local development
  return `as://${url}`;
};

export const getLinkPrefix = (): string => {
  return `${window.location.origin}/share/job/`;
};

export const handleOpenDesktop = async (jobId: string, versionId?: string) => {
  let url = `${getLinkPrefix()}${jobId}`;
  if (versionId) {
    url += `?version=${versionId}`;
  }
  document.location = url;
  return;
};

export const fakeDownloadClick = (fileName: string) => (response: AxiosResponse<BlobPart>) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
