import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { TTransaction } from 'app/types/entities/TTransaction';

import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { CheckBox } from '@components/form-elements/CheckBox';
import { CustomTable } from '@components/Table';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

const CellContent = styled.div`
  margin: 10px 0;
`;

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  max-width: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;

  .content {
    padding: 30px 0 10px;
  }

  .input-wrapper {
    padding: 0 0 20px;
    &.reason {
      padding: 0;
    }

    .label {
      padding: 0 0 10px;
      color: #858dbd;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 12px */
    }

    input {
      height: 30px;
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    color: #858dbd;
    margin-top: 20px;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #858dbd;
    margin: 0;
    text-align: center;
  }

  .custom-table {
    margin: 0 0 20px;
  }

  p.description {
    color: #00122d;
    margin: 20px 0;
    text-align: center;
  }

  .buttonContainer {
    width: 300px;
    display: flex;
    grid-gap: 10px;
    margin: 0 auto;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  id: string;
};

export const AdminRefundJobModal = ({ onCancel, onSuccess, id }: TProps) => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldLockJob, setShouldLockJob] = useState(false);
  const [price, setPrice] = useState(0);
  const [jobTitle, setJobTitle] = useState('Loading...');
  const [isASOneJob, setIsASOneJob] = useState(false);
  const [reason, setReason] = useState('');
  const [transactionIds, setTransactionIds] = useState<string[]>([]);
  const [transactions, setTransactions] = useState([]);

  const isSubmitDisabled = !reason.length || !transactionIds.length || !price;

  const load = async () => {
    setIsLoading(true);
    const job = await call(AdminService.getJobById({ id }));
    setJobTitle(job.name || 'No title');
    if (job.meetingId) {
      setIsASOneJob(true);
      setShouldLockJob(true);
    }
    const transactions = await call(AdminService.getJobTransactions({ id }));
    setTransactions(transactions);
    setIsLoading(false);
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await call(AdminService.refundJob({ id, requestBody: { reason, transactionIds, shouldLockJob } }));
    setIsLoading(false);
    onSuccess();
  };

  useEffect(() => {
    load();
  }, []);

  const td = [
    {
      headComponent: () => <td></td>,
      render: (data: TTransaction) => (
        <CellContent>
          {data.amount < 0 ? (
            <CheckBox
              checked={transactionIds.includes(data.id)}
              onChange={(v) => {
                if (v) {
                  setTransactionIds([...transactionIds, data.id]);
                  setPrice(price + data.amount * -1);
                } else {
                  setTransactionIds(transactionIds.filter((id) => id !== data.id));
                  setPrice(price - data.amount * -1);
                }
              }}
            />
          ) : null}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Code</td>,
      render: (data: TTransaction) => <CellContent>{data.codeId}</CellContent>,
    },
    {
      headComponent: () => <td>Date</td>,
      render: (data: TTransaction) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td>Amount</td>,
      render: (data: TTransaction) => <CellContent>{Math.abs(data.amount / 100).toFixed(2)}</CellContent>,
    },
    {
      headComponent: () => <td>Type</td>,
      render: (data: TTransaction) => <CellContent>{data.amount < 0 ? 'Charge' : 'Refund'}</CellContent>,
    },
  ];

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Refund Job</h2>
      <div className="content">
        <div className="input-wrapper">
          <div className="label">Job Title</div>
          <p>{jobTitle}</p>
        </div>

        <div className="input-wrapper">
          <div className="label">Amount</div>
          <p>${(price / 100).toFixed(2)}</p>
        </div>

        <div className="input-wrapper reason">
          <div className="label">Reason</div>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReason(e.target.value)}
            label="Type the reason of refund"
            required={true}
            value={reason}
          />
        </div>

        <CustomTable
          data={transactions || []}
          head={td}
          className="custom-table"
          fullPage={false}
          whiteBackground={true}
        />

        {!isLoading && !transactions.length ? <p className="description">No transactions found</p> : null}

        {isASOneJob ? (
          <div className="input-wrapper checkbox-wrapper">
            <CheckBox checked={shouldLockJob} onChange={(v) => setShouldLockJob(v)} />
            <label>Lock the job after refunding</label>
          </div>
        ) : null}
      </div>
      <form onSubmit={(e) => submit(e)}>
        <div className="buttonContainer">
          <Button type="button" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitDisabled}>
            Refund
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
};
