export const TrashBin = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 1.8V0H13.5V1.8H18V3.6H16.2V17.1C16.2 17.3387 16.1052 17.5676 15.9364 17.7364C15.7676 17.9052 15.5387 18 15.3 18H2.7C2.46131 18 2.23239 17.9052 2.0636 17.7364C1.89482 17.5676 1.8 17.3387 1.8 17.1V3.6H0V1.8H4.5ZM3.6 3.6V16.2H14.4V3.6H3.6ZM6.3 6.3H8.1V13.5H6.3V6.3ZM9.9 6.3H11.7V13.5H9.9V6.3Z"
        fill="#858DBD"
      />
    </svg>
  );
};
