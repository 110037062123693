import { useMemo } from 'react';

import { TProps } from './types/TProps';
import { StyledParagraph } from './styles/StyledParagraph';
import { translateError } from './helpers/translateError';

export const Error = ({ error, testid }: TProps) => {
  const message = useMemo(() => translateError(error), [error]);
  return <StyledParagraph data-testid={testid} dangerouslySetInnerHTML={{ __html: message }} />;
};
