import { useNavigate } from 'react-router';

import { Button } from '@components/form-elements/buttons/Button';
import { routes } from '@routes';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';

type TProps = {
  currentTab: string;
};

export const Tabs = ({ currentTab }: TProps) => {
  const navigate = useNavigate();
  return (
    <HeaderSwitchWrapper key="tabSwitcher">
      <Button
        onClick={() => {
          navigate(routes.adminSMSCountries.make());
        }}
        className={`button ${currentTab === 'SMS Countries' ? 'active' : ''}`}
      >
        SMS Countries
      </Button>
      <Button
        onClick={() => {
          navigate(routes.adminSMSWhitelist.make());
        }}
        className={`button ${currentTab === 'SMS Whitelist' ? 'active' : ''}`}
      >
        SMS Whitelist
      </Button>
    </HeaderSwitchWrapper>
  );
};
