import { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { ClickAway } from '@components/ClickAway';

import { ClosedIcon } from './icons/closedIcon';
import OpenedIcon from './icons/opened.svg';
import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const Select = ({
  name = 'select',
  options,
  label,
  onChange,
  value,
  className = 'form-select',
  hideSelectedOption = false,
  required = false,
  disabled = false,
  error = false,
}: TProps) => {
  const [optionsVisible, setOptionsVisible] = useState(false);

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  const closeOptions = () => {
    setOptionsVisible(false);
  };

  const selectOptions = options
    .filter((option) => (hideSelectedOption ? option.value !== value : true))
    .map((option, k) => {
      const onClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        onChange(name, `${option.value}`, event);
        setOptionsVisible(!optionsVisible);
      };
      return (
        <span onClick={onClick} className={`option ${value === option.value ? 'option--selected' : ''}`} key={k}>
          {option.label}
        </span>
      );
    });

  const selectedOption = options.find((option) => option.value === value);

  const getCurrentLabel = () => {
    if (selectedOption) {
      return selectedOption.label;
    }
    return `${label}${required ? ' *' : ''}`;
  };

  return (
    <Wrapper className={className}>
      <ClickAway onClickAway={closeOptions}>
        <span
          className={`select ${error ? 'select--error' : ''} ${disabled ? 'select--disabled' : ''}`}
          onClick={toggleOptions}
        >
          <span className="heightHack">&nbsp;</span>
          <ClosedIcon onClick={toggleOptions} className="icon icon--closed" />
          <span className={`label ${selectedOption ? 'label--selected' : ''}`}>{getCurrentLabel()}</span>
        </span>

        {optionsVisible ? (
          <span className="optionsWrapper">
            <Scrollbars autoHeight autoHeightMin={24} autoHeightMax={150}>
              <span className="optionsLabel" onClick={toggleOptions}>
                {getCurrentLabel()}
                <OpenedIcon onClick={toggleOptions} className="icon icon--opened" />
              </span>
              <span className="options">{selectOptions}</span>
            </Scrollbars>
          </span>
        ) : null}
      </ClickAway>
    </Wrapper>
  );
};
