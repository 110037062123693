import { Helmet } from 'react-helmet-async';

import { Header } from '@components/Header';

import { PageProvider } from './providers/PageProvider';
import { Content } from './components/Content';

export const AdminUtteranceConfidencePage = () => {
  return (
    <>
      <Helmet>
        <title>Utterance Confidence - AutoScript Admin</title>
      </Helmet>
      <Header title="Utterance Confidence" />

      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
