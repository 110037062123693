import styled from 'styled-components';

export const Wrapper = styled('p')`
  display: inline-block;
  position: relative;
  padding: 8px 16px 8px 40px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--ff-9191, #ff9191);
  color: #ff9191;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 20px;
  text-align: left;

  .warning-icon {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;
