import styled from 'styled-components';

export const Wrapper = styled('div')`
  position: relative;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  display: flex;
  padding-bottom: 10px;

  .leftSide {
    padding-right: 30px;
    max-width: 260px;
    flex: 0 0 auto;
    width: 100%;

    .title {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #858dbd;
      padding: 5px 0 10px;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #878787;
      padding: 0 0 10px;

      p {
        margin: 0 0 12px;
      }
    }
  }

  .rightSide {
    width: 100%;

    & > * {
      margin-bottom: 10px;
    }
  }
`;
