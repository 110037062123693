import { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { ClickAway } from '@components/ClickAway';

import closedIcon from './icons/closed.svg';
import openedIcon from './icons/opened.svg';

import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const AutoCompleteSelect = ({
  name,
  options,
  label,
  onChange,
  value = '',
  className = 'form-autocomplete',
  hideSelectedOption = false,
  required = false,
  disabled = false,
  error = false,
  customOpenIcon: OpenIcon = openedIcon,
  customCloseIcon: CloseIcon = closedIcon,
}: TProps) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const filterInputRef = useRef<HTMLInputElement | null>(null);

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
    setFilteredOptions(options);
  };

  useEffect(() => {
    if (optionsVisible) {
      filterInputRef?.current?.focus();
    }
  }, [optionsVisible]);

  const closeOptions = () => {
    setOptionsVisible(false);
  };

  const setFilter = (value: string) => {
    setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase())));
    setSelectOptions();
  };

  let selectOptions: string | React.ReactNode[] = [];
  const setSelectOptions = () => {
    selectOptions = filteredOptions
      .filter((option) => (hideSelectedOption ? option.value !== value : true))
      .map((option, k) => {
        const onClick = () => {
          onChange(name, `${option.value}`);
          setOptionsVisible(!optionsVisible);
        };
        return (
          <span
            onClick={onClick}
            className={`option ${`${value}` === `${option.value}` ? 'option--selected' : ''}`}
            key={k}
          >
            {option.label}
          </span>
        );
      });
  };
  setSelectOptions();

  const selectedOption = options.find((option) => {
    return `${option.value}` === `${value}`;
  });

  const getCurrentLabel = () => {
    if (selectedOption) {
      return selectedOption.label;
    }
    return `${label}${required ? ' *' : ''}`;
  };

  return (
    <Wrapper className={className}>
      <ClickAway onClickAway={closeOptions}>
        <span
          className={`select ${error ? 'select--error' : ''} ${disabled ? 'select--disabled' : ''}`}
          onClick={toggleOptions}
        >
          <span className="heightHack">&nbsp;</span>
          <CloseIcon onClick={toggleOptions} className="icon icon--closed" />
          <span className={`label ${selectedOption ? 'label--selected' : ''}`}>{getCurrentLabel()}</span>
        </span>

        {optionsVisible ? (
          <span className="optionsWrapper">
            <span className="optionsLabel">
              <input ref={filterInputRef} onChange={(e) => setFilter(e.target.value)} placeholder={getCurrentLabel()} />
              <OpenIcon onClick={toggleOptions} className="icon icon--opened" />
            </span>
            <span className="scrollWrapper">
              <Scrollbars autoHeight autoHeightMin={24} autoHeightMax={150}>
                <span className="options">{selectOptions}</span>
              </Scrollbars>
            </span>
          </span>
        ) : null}
      </ClickAway>
    </Wrapper>
  );
};
