import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TeamsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { TRole, TTeamMemberItem } from 'app/types/entities/TRole';

import { Search as SearchIcon } from '@components/icons/Search';
import { AutoCompleteSelect } from '@components/form-elements/AutocompleteSelect';
import { CustomTable } from '@components/Table';
import { TrashBin } from '@components/icons/TrashBin';
import { Button } from '@components/form-elements/buttons/Button';
import { useAuthContext } from '@providers/AuthProvider';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
    padding: 0;
  }

  input {
    height: 30px;
  }

  .buttonContainer {
    display: flex;
    gap: 20px;

    button {
      width: 130px;
    }
  }
`;

const TableWrapper = styled.div`
  overflow: auto;
  max-height: 40vh;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 235px;
  }
`;

type TProps = {
  teamId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const EditTeamMembersModal = ({ teamId, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { me } = useAuthContext();
  const [team, setTeam] = useState<TRole | undefined>();
  const [teamMembers, setTeamMembers] = useState<TTeamMemberItem[]>([]);
  const [availableUsers, setAvailableUsers] = useState<TTeamMemberItem[]>([]);
  const [initialAvailableUserCount, setInitialAvailableUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const load = async () => {
    const t = await call(TeamsService.findOne({ id: teamId }));
    setTeamMembers(t?.Members || []);
    setTeam(t);
    const users = await call(TeamsService.findAvailableMembers({ id: teamId }));
    setAvailableUsers(users);
    setInitialAvailableUserCount(users.length);
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  const submit = async () => {
    await call(TeamsService.updateMembers({ id: teamId, requestBody: { memberIds: teamMembers.map((m) => m.id) } }));
    onSuccess();
  };

  const addUserToTeamList = async (userId: string) => {
    const newTeamMember = availableUsers.find((u) => u.id === userId);
    if (!newTeamMember) {
      return;
    }

    setTeamMembers([...teamMembers, newTeamMember]);
    setAvailableUsers(availableUsers.filter((u) => u.id !== userId));
  };

  const deleteUserFromTeamList = async (member: TTeamMemberItem) => {
    setTeamMembers(teamMembers.filter((u) => u.id !== member.id));
    setAvailableUsers([...teamMembers, member]);
    call(TeamsService.removeMember({ id: teamId, memberId: member.id }));
  };

  const tableHead = [
    {
      render: (membership: TTeamMemberItem) => (
        <p>
          {membership.User.name} {membership.User.lastname}
        </p>
      ),
      headComponent: () => <td>Name</td>,
    },
    {
      render: (membership: TTeamMemberItem) => <p>{membership.User.email}</p>,
      headComponent: () => <td>Email</td>,
    },
    {
      headComponent: () => <td></td>,
      render: (membership: TTeamMemberItem) => {
        if (team?.default) {
          if (membership?.User?.id === me?.id) {
            return null;
          }
          if (teamMembers.length < 2) {
            return null;
          }
        }
        return <TrashBin onClick={() => deleteUserFromTeamList(membership)} cursor="pointer" />;
      },
    },
  ];

  return (
    <Wrapper>
      <h5>Edit Team Members</h5>
      <AutoCompleteSelect
        customOpenIcon={SearchIcon}
        customCloseIcon={SearchIcon}
        name="search"
        label="Search users"
        onChange={(e, userId) => addUserToTeamList(userId)}
        options={availableUsers.map((u) => ({
          label: `${u.User.name} ${u.User.lastname} ${u.User.email}`,
          value: u.id,
        }))}
      />
      {!isLoading && initialAvailableUserCount === 0 && (
        <p>{isLoading ? '' : 'All members of this organization are already members of this team'}</p>
      )}

      <TableWrapper>
        <CustomTable data={teamMembers} head={tableHead} fullPage={false} whiteBackground={true} />
      </TableWrapper>

      <div className="buttonContainer m-auto">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="bold" onClick={submit}>
          Save
        </Button>
      </div>
    </Wrapper>
  );
};
