import styled from 'styled-components';
import { Input } from '@components/form-elements/Input';

export const SmallWhiteInput = styled(Input)`
  input {
    background: #ffffff;
    padding: 0 10px;
    height: 30px;
  }
`;
