import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Wrapper } from '@pages/Admin/DashboardPage/components/Content/styles/Wrapper';
import { usePageContext } from '@pages/Admin/DashboardPage/providers/PageProvider';

export const Content = () => {
  const { isLoading, dashboard } = usePageContext();

  return (
    <Wrapper>
      <LinearProgressLoader active={isLoading} />

      <div className="flex-block">
        <div className="left-wrapper">
          <div className="line">
            <div className="card">
              <div className="title">Organizations</div>
              <div className="subTitle">{dashboard?.organizationsCount} Total</div>
            </div>
            <div className="card">
              <div className="title">Workspaces</div>
              <div className="subTitle">{dashboard?.workspacesCount} Total</div>
            </div>
            <div className="card">
              <div className="title">Users</div>
              <div className="subTitle">{dashboard?.usersCount} Total</div>
            </div>
            <div className="card">
              <div className="title">Transactions</div>
              <div className="subTitle">{dashboard?.transactionsCount} Total</div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
