import React from 'react';
import styled from 'styled-components';
import { ClickAway } from '@components/ClickAway';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type TProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const Modal = ({ children, isOpen, onClose }: TProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Wrapper>
      <ClickAway onClickAway={onClose}>{children}</ClickAway>
    </Wrapper>
  );
};
