export const Done = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="10" height="8" {...props} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.44558 0.253688C8.61838 0.0891722 8.84834 -0.00177889 9.08693 2.63687e-05C9.32551 0.00183163 9.55407 0.0962523 9.72436 0.263364C9.89466 0.430476 9.99337 0.657209 9.99968 0.895719C10.006 1.13423 9.91939 1.36586 9.75816 1.54174L4.86357 7.66305C4.7794 7.7537 4.67782 7.82645 4.5649 7.87695C4.45198 7.92744 4.33003 7.95465 4.20636 7.95694C4.08268 7.95923 3.95981 7.93656 3.8451 7.89028C3.73038 7.84399 3.62618 7.77506 3.53872 7.68758L0.292827 4.44169C0.202435 4.35746 0.129933 4.25589 0.0796475 4.14304C0.0293621 4.03018 0.00232279 3.90835 0.000143182 3.78482C-0.00203643 3.66128 0.0206883 3.53857 0.0669613 3.42401C0.113234 3.30945 0.182108 3.20539 0.269473 3.11802C0.356838 3.03066 0.460905 2.96178 0.575465 2.91551C0.690026 2.86924 0.812734 2.84651 0.936267 2.84869C1.0598 2.85087 1.18163 2.87791 1.29449 2.9282C1.40734 2.97848 1.50892 3.05098 1.59315 3.14138L4.16189 5.70889L8.42227 0.280676C8.42994 0.271232 8.43813 0.262221 8.4468 0.253688H8.44558Z"
        fill="#858DBD"
      />
    </svg>
  );
};
