import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 20px 0 0;

  & > .list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;
