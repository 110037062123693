import styled from 'styled-components';

export const EditCellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  min-height: 88px;

  .permissions-head {
    color: #858dbd;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding: 0 0 22px;
  }

  .permissions-list {
    display: flex;
    align-items: center;
    gap: 30px;

    .permission {
      display: flex;
      gap: 10px;
      align-items: center;

      .label {
        color: #858dbd;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
      }
    }
  }
`;
