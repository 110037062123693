export const RefreshBalance = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.8814 0C6.55933 0 6.26843 0.108026 6.0402 0.336255C5.81197 0.564485 5.70395 0.855386 5.70395 1.17746C5.70395 1.49953 5.81197 1.79043 6.0402 2.01866L6.15528 2.13373C4.55317 2.28916 3.16477 2.95477 2.00526 4.12675C0.670397 5.47597 0.000158265 7.1212 9.68808e-06 9.0401C-0.000565246 9.38506 0.0244496 9.72978 0.0749547 10.0741C0.125872 10.4213 0.208951 10.7613 0.324067 11.0938C0.42416 11.4236 0.638287 11.6743 0.958691 11.8084C1.27755 11.9419 1.60603 11.9217 1.91416 11.7692C2.20583 11.6324 2.41654 11.4052 2.53287 11.1043C2.64682 10.8096 2.6535 10.5019 2.56344 10.1957L2.56147 10.189L2.55925 10.1824C2.49916 10.0026 2.45723 9.81984 2.43326 9.63389C2.40824 9.43971 2.39566 9.24204 2.39566 9.04074C2.39566 7.76889 2.8323 6.70199 3.71048 5.81729C4.38182 5.14096 5.15721 4.72532 6.04665 4.56702L6.0402 4.57347C5.81197 4.8017 5.70395 5.0926 5.70395 5.41467C5.70395 5.73674 5.81197 6.02764 6.0402 6.25587C6.26843 6.4841 6.55933 6.59212 6.8814 6.59212C7.20347 6.59212 7.49438 6.4841 7.7226 6.25587L9.84121 4.13726C10.0712 3.90731 10.1978 3.62157 10.1978 3.29606C10.1978 2.97056 10.0712 2.68482 9.84121 2.45486L7.7226 0.336255C7.49438 0.108026 7.20348 0 6.8814 0Z"
        fill="#858DBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8456 6.19273C12.5264 6.05829 12.1974 6.07814 11.8887 6.23116C11.5978 6.36892 11.3872 6.59579 11.2708 6.89618C11.1567 7.19066 11.1499 7.49828 11.2399 7.80437L11.2441 7.81706C11.3044 7.99834 11.3463 8.18158 11.3703 8.36704C11.3953 8.5606 11.4079 8.75796 11.4079 8.95926C11.4079 10.2323 10.9712 11.2994 10.0932 12.1834C9.4218 12.8594 8.64636 13.2748 7.75684 13.433L7.76335 13.4265C7.99158 13.1983 8.0996 12.9074 8.0996 12.5853C8.0996 12.2633 7.99158 11.9724 7.76335 11.7441C7.53512 11.5159 7.24422 11.4079 6.92215 11.4079C6.60008 11.4079 6.30918 11.5159 6.08095 11.7441L3.96234 13.8627C3.73238 14.0927 3.60571 14.3784 3.60571 14.7039C3.60571 15.0294 3.73238 15.3152 3.96234 15.5451L6.08095 17.6637C6.30917 17.892 6.60007 18 6.92215 18C7.24422 18 7.53512 17.892 7.76335 17.6637C7.99158 17.4355 8.0996 17.1446 8.0996 16.8225C8.0996 16.5005 7.99158 16.2096 7.76335 15.9813L7.64827 15.8663C9.25038 15.7108 10.6388 15.0452 11.7983 13.8732C13.1332 12.524 13.8034 10.8788 13.8035 8.9599C13.8041 8.61494 13.7791 8.27022 13.7286 7.92586C13.6777 7.57863 13.5946 7.23855 13.4794 6.90594C13.3791 6.57689 13.1649 6.32721 12.8456 6.19273ZM11.8887 6.23116L11.893 6.22913L11.8844 6.23328L11.8887 6.23116Z"
        fill="#858DBD"
      />
    </svg>
  );
};
