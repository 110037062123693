import { SortingDirection } from '@components/Table/SortingDirection';

import { TProps } from './types/TProps';
import { Spinner } from './components/Spinner';

export const DisclosureSection = ({ title, open, setOpenState, loading, children, className = '' }: TProps) => {
  return (
    <div className={`${className} mb-6`}>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-geyser" />
        </div>
        <div className="relative flex justify-start">
          <span
            className="bg-alabaster pr-2 font-[600] text-xs text-primary cursor-pointer"
            onClick={() => setOpenState(!open)}
          >
            {title} <SortingDirection reverse={open} />
          </span>
        </div>
      </div>
      <div className={open ? 'visible' : 'hidden'}>
        {loading && <Spinner />}
        {!loading && children}
      </div>
    </div>
  );
};
