import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .button {
    cursor: pointer;
    position: relative;
    border-radius: 5px;

    .notificationIcon:hover {
      background: #cadcf8;
      border-radius: 5px;

      path {
        fill: #fff;
        color: #fff;
      }
    }
  }
`;
