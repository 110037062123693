import { usePageContext } from '../../../../providers/PageProvider';
import { ShareLink } from './components/ShareLink';
import { GeneralSection } from './components/GeneralSection';
import { ScheduleSection } from './components/ScheduleSection';
import { MaterialsSection } from './components/MaterialsSection';
import { CommentsSection } from './components/CommentsSection';
import { AssigneeHistorySection } from './components/AssigneeHistorySection';

import { TopRightSection } from './styles/TopRightSection';
import { Divider } from './styles/Divider';
import { PipelineTimestamps } from './components/JobPipelineTimestamps';

export const MainContent = () => {
  const { job } = usePageContext();

  if (!job) {
    return null;
  }

  return (
    <>
      <TopRightSection>
        <ShareLink />
      </TopRightSection>

      <GeneralSection />

      <Divider />

      <ScheduleSection />

      <Divider />

      <MaterialsSection />

      <CommentsSection />

      <AssigneeHistorySection />

      <PipelineTimestamps data={job} />
    </>
  );
};
