type TProps = {
  alt?: string;
  title?: string;
  width?: string;
};

export const Desktop = ({ width = '20px' }: TProps) => {
  return (
    <svg width={width} height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.65306 1.8C2.38243 1.8 2.12289 1.91853 1.93152 2.1295C1.74016 2.34048 1.63265 2.62663 1.63265 2.925V12.0375C1.63265 12.3359 1.74016 12.622 1.93152 12.833C2.12289 13.044 2.38243 13.1625 2.65306 13.1625H17.3469C17.6176 13.1625 17.8771 13.044 18.0685 12.833C18.2598 12.622 18.3673 12.3359 18.3673 12.0375V2.925C18.3673 2.62663 18.2598 2.34048 18.0685 2.1295C17.8771 1.91853 17.6176 1.8 17.3469 1.8H2.65306ZM0.777064 0.856713C1.27461 0.308169 1.94943 0 2.65306 0H17.3469C18.0506 0 18.7254 0.308169 19.2229 0.856713C19.7205 1.40526 20 2.14924 20 2.925V12.0375C20 12.8133 19.7205 13.5572 19.2229 14.1058C18.7254 14.6543 18.0506 14.9625 17.3469 14.9625H2.65306C1.94943 14.9625 1.27461 14.6543 0.777064 14.1058C0.279518 13.5572 0 12.8133 0 12.0375V2.925C0 2.14924 0.279518 1.40526 0.777064 0.856713ZM5.5102 17.1C5.5102 16.6029 5.87569 16.2 6.32653 16.2H13.6735C14.1243 16.2 14.4898 16.6029 14.4898 17.1C14.4898 17.5971 14.1243 18 13.6735 18H6.32653C5.87569 18 5.5102 17.5971 5.5102 17.1Z"
        fill="#858DBD"
      />
    </svg>
  );
};
