import styled from 'styled-components';

export const JobSectionRight = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #00122d;
  word-break: break-word;
`;
