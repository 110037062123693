import styled from 'styled-components';

import { Card, TProps as CardProps } from './Card';

type TProps = {
  chosen?: boolean;
};

export const SubscriptionCard = styled(Card)<CardProps & TProps>`
  width: 245px;
  height: 250px;
  padding-bottom: 0;
  ${(props) => props.chosen && 'background: #CADCF8;'};

  & h2.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #40608f;
  }

  & .subTitle {
    ${(props) => props.chosen && 'color: #fff;'};
    font-size: 58px;
    line-height: 80px;
  }

  & .descriptionWrapper {
    flex-direction: column;
    grid-gap: 5px;

    & .description {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #858dbd;

      :nth-child(n + 3) {
        color: #40608f;
      }
    }

    & .strikeThroughPrice {
      text-decoration: line-through;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: rgb(133, 141, 189);
    }

    & .discountPrice {
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  & .buttonContainer {
    height: 40px;
    margin: 0 20px;
    display: block;
    bottom: 0;
    position: absolute;
    ${(props) => props.chosen && 'border-top: 1px solid #40608F;'};

    & button {
      border-radius: 10px;
      margin: 0 -20px;
      height: 40px;
      width: 244px;
      ${(props) => props.chosen && 'color: #40608F;'};

      :disabled {
        background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
        color: white;
      }
    }
  }
`;
