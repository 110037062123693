import styled from 'styled-components';

export const FlexCellContent = styled.div`
  margin: 0;
  padding: 20px 0;
  min-height: 88px;
  display: flex;
  align-items: center;

  .icon-buttons {
    display: flex;
    gap: 20px;
  }
`;
