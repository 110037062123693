export const UploadFile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 20L5.621 22.485C5.72915 22.9177 5.97882 23.3018 6.33033 23.5763C6.68184 23.8508 7.11501 23.9999 7.561 24H22.439C22.885 23.9999 23.3182 23.8508 23.6697 23.5763C24.0212 23.3018 24.2708 22.9177 24.379 22.485L25 20M15 6L15 18M15 6L19 10M15 6L11 10"
      stroke="#858DBD"
      strokeWidth="1.84"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
