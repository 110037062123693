import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { CurrencyInput } from '@components/form-elements/CurrencyInput';
import { useAPI } from '@hooks/useAPI';

import { AdminService } from 'app/API';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 400px;

  h2 {
    color: #858dbd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin: 0;
    padding: 0 0 30px;
    text-align: center;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .input,
    .switch,
    .select,
    .textarea {
      margin: 0 0 20px;
    }

    .name {
      color: #00122d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      padding: 0 0 20px;
    }

    input {
      height: 30px;
    }
  }

  .card,
  .rate {
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0 0 20px;

    &.red {
      color: red;
    }
  }

  .description {
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding: 0 0 10px;

    p {
      padding: 0 0 2px 8px;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  billingAccountId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const AdminBillingAccountASOneRateModal = ({ billingAccountId, onCancel, onSuccess }: TProps) => {
  const { closeModal } = useModalContext();
  const { call } = useAPI();
  const [rate, setRate] = useState<number | string>(10);
  const [partialRate, setPartialRate] = useState<number | string>(10);
  const [isLoading, setIsLoading] = useState(true);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const billingAccount = await call(AdminService.getBillingAccount({ baId: billingAccountId }));
      setBillingAccount(billingAccount);
      setRate(Number(billingAccount.autoscriptOneRealTimeRate ?? 0));
      setPartialRate(Number(billingAccount.autoscriptOnePartialRate ?? 0));
      setIsLoading(false);
    })();
  }, []);

  const onSubmit = async () => {
    setIsLoading(true);
    await call(
      AdminService.setBillingAccountAsOneRate({
        baId: billingAccountId,
        requestBody: {
          autoscriptOneRealTimeRate: Number(rate),
          autoscriptOnePartialRate: Number(partialRate),
        },
      }),
    );
    closeModal();
    onSuccess();
  };

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>AutoScript One Rate Editor</h2>

      <div className="input-wrapper">
        <div className="label">Billing Account</div>
        <div className="name">{billingAccount?.name}</div>
      </div>

      <div className="input-wrapper">
        <div className="label">Organization</div>
        <div className="name">{billingAccount?.organization?.name}</div>
      </div>

      <div className="input-wrapper">
        <div className="label">Price per transcription audio hour</div>
        <CurrencyInput
          onChange={(v) => {
            setRate(v);
          }}
          label="Price per transcription audio hour"
          value={rate}
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Partial price per transcription audio hour</div>
        <CurrencyInput
          onChange={(v) => {
            setPartialRate(v);
          }}
          label="Partial price per transcription audio hour"
          value={partialRate}
        />
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </ModalWrapper>
  );
};
