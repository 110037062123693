import styled from 'styled-components';
import { StyledIconButton } from './StyledIconButton';

export const DeleteButton = styled(StyledIconButton)`
  &[disabled] {
    cursor: auto;

    :hover {
      background: transparent;

      path {
        fill: #858dbd;
        color: #858dbd;
      }
    }
  }

  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;
