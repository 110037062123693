import styled from 'styled-components';

import { TProps } from './types/TProps';

export const Divider = styled.div<TProps>`
  width: ${(props) => (props.vertical ? '1px' : '100%')};
  height: ${(props) => (props.vertical ? '41px' : '1px')};
  border-right: ${(props) => props.vertical && '1px solid #858DBD'};
  background: ${(props) => !props.vertical && '#D5DDF2'};
  margin-top: ${(props) => (props.vertical ? '0px' : '5px')};
  margin-bottom: ${(props) => (props.vertical ? '0px' : '15px')};
  align-self: center;
`;
