import { Fragment } from 'react';

import { ClickAway } from '@components/ClickAway';

import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';

export const DropDown = ({ options, close }: TProps) => (
  <ClickAway onClickAway={close}>
    <Wrapper className="dropdown">
      {options?.map((option, index) => (
        <Fragment key={index}>
          <div className="option" onClick={option.onClick}>
            {option.title}
          </div>
          {index + 1 < options?.length && <div className="option-divider" />}
        </Fragment>
      ))}
    </Wrapper>
  </ClickAway>
);
