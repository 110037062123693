import styled from 'styled-components';

export const Wrapper = styled.span`
  text-align: left;
  display: inline-flex;
  width: 100%;
  position: relative;

  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  & > div {
    position: relative;
  }

  .select {
    display: inline-block;
    position: relative;
    line-height: 17px;
    color: #858dbd;
    background: #f8fafb;
    border-radius: 5px;
    border: none;
    flex: 1 1 auto;
    max-width: 100%;
    padding: 7px 16px;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(202, 220, 248, 1);
    }

    &:focus {
      background: #ffffff;
    }

    &--disabled {
      background: #f0f9ff;
      color: #cadcf8;
      outline: none;
      pointer-events: none;
    }

    &--error {
      outline: none;
      box-shadow: 0 0 0 1px #ff2f2f;
    }
  }

  .heightHack {
    font-size: 12px;
  }

  .label {
    color: #858dbd;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 16px;
    pointer-events: none;

    &--selected {
      color: #858dbd;
    }
  }

  .optionsWrapper {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 1000;
    top: 0;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 3px 8px rgba(133, 141, 189, 0.3);

    line-height: 17px;
    color: #858dbd;
  }

  .scrollWrapper {
    padding: 50px 0 0;
    display: block;
  }

  .optionsLabel {
    cursor: pointer;
    display: block;
    padding: 14px 0;
    margin: 0 15px;
    border-bottom: 1px solid #d5ddf2;
    position: absolute;
    width: calc(100% - 30px);
    z-index: 100;

    input {
      font-family: 'General Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      border: none;
      background: none;
      outline: none;
      padding: 0;
      width: 100%;
      height: 20px;
      color: #858dbd;

      &::placeholder {
        color: #858dbd;
        opacity: 0.5;
      }
    }
  }

  .options {
    display: block;
  }

  .option {
    display: block;
    cursor: pointer;
    padding: 10px 15px;

    &:hover {
      background: rgba(202, 220, 248, 0.3);
    }

    &--selected {
      background: rgba(202, 220, 248, 0.7);
    }
  }

  .icon {
    cursor: pointer;
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;

    &--closed {
      right: 20px;
    }

    &--opened {
      right: 5px;
    }
  }
`;
