import styled from 'styled-components';

export const StyledText = styled.p`
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #858dbd;
`;
