export const Calendar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.63636 2.90909C2.43138 2.90909 1.45455 3.88592 1.45455 5.09091V12.3636C1.45455 13.5686 2.43138 14.5455 3.63636 14.5455H12.3636C13.5686 14.5455 14.5455 13.5686 14.5455 12.3636V5.09091C14.5455 3.88592 13.5686 2.90909 12.3636 2.90909H3.63636ZM0 5.09091C0 3.0826 1.62806 1.45455 3.63636 1.45455H12.3636C14.3719 1.45455 16 3.0826 16 5.09091V12.3636C16 14.3719 14.3719 16 12.3636 16H3.63636C1.62806 16 0 14.3719 0 12.3636V5.09091Z"
        fill="#40608F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09091 0C5.49257 0 5.81818 0.325611 5.81818 0.727273V3.63636C5.81818 4.03803 5.49257 4.36364 5.09091 4.36364C4.68925 4.36364 4.36364 4.03803 4.36364 3.63636V0.727273C4.36364 0.325611 4.68925 0 5.09091 0ZM10.9091 0C11.3108 0 11.6364 0.325611 11.6364 0.727273V3.63636C11.6364 4.03803 11.3108 4.36364 10.9091 4.36364C10.5074 4.36364 10.1818 4.03803 10.1818 3.63636V0.727273C10.1818 0.325611 10.5074 0 10.9091 0ZM0 6.54545C0 6.14379 0.325611 5.81818 0.727273 5.81818H15.2727C15.6744 5.81818 16 6.14379 16 6.54545C16 6.94712 15.6744 7.27273 15.2727 7.27273H0.727273C0.325611 7.27273 0 6.94712 0 6.54545Z"
        fill="#40608F"
      />
    </svg>
  );
};
