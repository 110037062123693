export const formatPrice = (price: number) => {
  if (price < 0) {
    return `-$${numberWithCommas(((price * -1 * 10) / 10).toFixed(2))}`;
  }
  return `$${numberWithCommas(((price * 10) / 10).toFixed(2))}`;
};

function numberWithCommas(x: string) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
