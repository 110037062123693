import { PlayIcon } from './icons/Play';
import { PauseIcon } from './icons/Pause';
import { TProps } from './types/TProps';
// import { LoadingIcon } from '@components/AudioPlayer/components/Controls/icons/Loading';

export const Controls = ({ isPlaying, setIsPlaying, disabled }: TProps) => {
  return (
    <div className="controls">
      {/* {isLoading ? (*/}
      {/*  <button*/}
      {/*    className="loading"*/}
      {/*    disabled={false}*/}
      {/*    onClick={(e) => {*/}
      {/*      e.preventDefault();*/}
      {/*      setIsPlaying(!isPlaying);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <LoadingIcon />*/}
      {/*  </button>*/}
      {/* ) : (*/}
      <button
        className="play"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          setIsPlaying(!isPlaying);
        }}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </button>
      {/* )}*/}
    </div>
  );
};
