export const DownloadJob = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 13V1M12 13L8 9M12 13L16 9M2 15L2.621 17.485C2.72915 17.9177 2.97882 18.3018 3.33033 18.5763C3.68184 18.8508 4.11501 18.9999 4.561 19H19.439C19.885 18.9999 20.3182 18.8508 20.6697 18.5763C21.0212 18.3018 21.2708 17.9177 21.379 17.485L22 15"
        stroke="#858DBD"
        strokeWidth="1.84"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
