import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { EPipelineTimestampsNamesByKey } from 'app/types/enums/EPipelineTimestampsNamesByKey';

type PipelineTimestampProps = {
  data: any;
};

export const PipelineTimestamps = ({ data }: PipelineTimestampProps) => {
  if (!data.jobPipelineTimestamps) {
    return null;
  }

  return (
    <div className="w-full text-center mt-2 flex flex-col justify-center text-base">
      {Object.keys(EPipelineTimestampsNamesByKey).map((timestampKey) => (
        <div className="flex gap-x-3" key={timestampKey}>
          <div className="w-48 text-end">
            <span className="text-gray-500">
              {data.jobPipelineTimestamps[timestampKey]
                ? getAmericanTime(data.jobPipelineTimestamps[timestampKey], true, true)
                : '---'}
            </span>
          </div>

          <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200">
            <div className="relative z-10 size-7 flex justify-center items-center">
              <div
                className={`size-2 rounded-full ${data.jobPipelineTimestamps[timestampKey] ? 'bg-green-400' : 'bg-gray-400'}`}
              ></div>
            </div>
          </div>

          <div className="grow pt-0.5 pb-8">
            <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
              <svg
                className="flex-shrink-0 size-4 mt-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" x2="8" y1="13" y2="13"></line>
                <line x1="16" x2="8" y1="17" y2="17"></line>
                <line x1="10" x2="8" y1="9" y2="9"></line>
              </svg>
              {EPipelineTimestampsNamesByKey[timestampKey as keyof typeof EPipelineTimestampsNamesByKey]}
            </h3>
          </div>
        </div>
      ))}
    </div>
  );
};
