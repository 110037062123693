import { useState } from 'react';

import { FilterIcon } from '@components/icons/Filter/icon';
import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';

export const Filter = ({ onClick, filterCounter = 0 }: TProps) => {
  const [active, setActive] = useState(false);
  if (!onClick) {
    return null;
  }

  function mouseEnter() {
    setActive(true);
  }

  function mouseLeave() {
    setActive(false);
  }

  return (
    <Wrapper onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={onClick}>
      <FilterIcon color={active ? '#ffffff' : '#858DBD'} />
      {filterCounter ? (
        <div className="counter" color="red">
          {filterCounter}
        </div>
      ) : null}
    </Wrapper>
  );
};
