import { routes } from '@routes';

import { TWorkspace } from 'app/types/entities/TWorkspace';
import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { PagePagination } from '@components/Pagination';

import { usePageContext } from '../../providers/PageProvider';

import { Wrapper } from './styles/Wrapper';
import { CellContent } from './styles/CellContent';
import { StyledLink } from './styles/StyledLink';

export const Content = () => {
  const { workspaces, search, setSearch, page, setPage, pageSize, setPageSize, isLoading, exportCSV } =
    usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>#</td>,
      render: (data: TWorkspace, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => <td>Name</td>,
      render: (data: TWorkspace) => (
        <CellContent>
          <StyledLink to={routes.adminWorkspaceDetails.make(data.id)}>{data.name}</StyledLink>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>URL</td>,
      render: (data: TWorkspace) => <CellContent>{data.slug}</CellContent>,
    },
    {
      headComponent: () => <td>Organization</td>,
      render: (data: TWorkspace) => (
        <CellContent>
          <StyledLink to={routes.adminOrganizationDetails.make(data.Organization?.id)}>
            {data.Organization?.name}
          </StyledLink>
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Header
        title={'Workspaces'}
        buttonLabel={workspaces.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={workspaces.data?.length ? () => exportCSV() : undefined}
        setSearch={setSearch}
        search={search}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={workspaces.data} />
        <PagePagination
          totalItemsCount={workspaces.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Wrapper>
    </>
  );
};
