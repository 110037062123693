import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuthContext } from '@providers/AuthProvider';

import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { Search as SearchIcon } from '@components/icons/Search';

import { AdminLink } from './components/AdminLink';
import { Avatar } from './components/Avatar';
import { BackButton } from './components/BackButton';
import { Title } from './components/Title';
import { Filter } from './components/Filter';
import { WatchTraining } from './components/WatchTraining';
import { Search } from './components/Search';
import { NotificationsIcon } from './components/NotificationsIcon';

import { StyledHeader } from './styles/StyledHeader';
import { Divider } from './styles/Divider';
import { TProps } from './types/TProps';

export const Header = ({
  title,
  backRoute,
  setSearch,
  search,
  globalSearch,
  buttonLabel,
  buttonIcon: ButtonIcon,
  buttonOnClick,
  secondButtonLabel,
  secondButtonIcon: SecondButtonIcon,
  secondButtonOnClick,
  onFilterClick,
  filterCounter = 0,
  btnRef,
  leftSideContent,
}: TProps) => {
  const { me } = useAuthContext();
  const location = useLocation();
  const locationState = location?.state as {
    from?: string;
  };

  if (!me || !me.name) {
    return null;
  }

  return (
    <StyledHeader>
      <div className="header-wrapper">
        <div className="left-side">
          <BackButton backRoute={locationState?.from ? locationState.from : backRoute} />
          <Title title={title} />

          {title && (leftSideContent || buttonLabel) ? <Divider /> : null}
          {leftSideContent ? leftSideContent.map((item, index) => <Fragment key={index}>{item}</Fragment>) : null}
          {leftSideContent && buttonLabel ? <Divider /> : null}
          {buttonLabel || secondButtonLabel ? (
            <div className="button-wrapper" ref={btnRef}>
              {buttonLabel ? (
                <Button size="small" variant="bold" onClick={buttonOnClick}>
                  <div className="icon-content">
                    {ButtonIcon && <ButtonIcon height="12px" />}
                    {buttonLabel}
                  </div>
                </Button>
              ) : null}
              {secondButtonLabel ? (
                <Button size="small" variant="bold" onClick={secondButtonOnClick}>
                  <div className="icon-content">
                    {SecondButtonIcon && <SecondButtonIcon height="12px" />}
                    {secondButtonLabel}
                  </div>
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="right-side">
          <WatchTraining />
          <Filter onClick={onFilterClick} filterCounter={filterCounter} />
          {setSearch ? (
            <Input
              icon={SearchIcon}
              name="search"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value as string)}
              label="Search"
              value={search}
            />
          ) : null}
          {globalSearch ? <Search /> : null}
          <NotificationsIcon />
          <AdminLink />
          <Avatar />
        </div>
      </div>
    </StyledHeader>
  );
};
