import { TFile } from 'app/types/entities/TFile';

export const fileDataFormat = (file: TFile) => {
  return {
    meta: {
      Id: file.Id,
      name: file.Name,
      size: file.Size,
      percent: 100,
      status: 'done',
      duration: file.duration,
    },
  };
};
