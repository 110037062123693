import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAPI } from '@hooks/useAPI';
import { useAuthContext } from '@providers/AuthProvider';

import { TJob } from 'app/types/entities/TJob';
import { TRepository } from 'app/types/entities/TRepository';
import { JobsService, OrganizationsService } from 'app/API';

import { Button } from '@components/form-elements/buttons/Button';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';

type RepositoryItem = {
  label: string;
  value: string;
};

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0 0 20px;
  }

  .assignedTo {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 20px 0 10px;
  }

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tr {
      cursor: pointer;
    }

    thead {
      tr {
        cursor: default;

        td,
        th {
          height: auto;
          padding: 10px 0;
        }
      }
    }

    tbody {
      td {
        padding: 10px 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }

  .assignTo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;

    svg {
      cursor: pointer;
    }

    .assignToText {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #00122d;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

type TProps = {
  job: TJob;
  onCancel: () => void;
  onSuccess: () => void;
};

export const EditJobCaseModal = ({ job, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { organization } = useAuthContext();
  const [repositoryList, setRepositoryList] = useState<RepositoryItem[]>([]);
  const [repositoryId, setRepositoryId] = useState<string>(job?.repositoryId || job?.repository?.id || '');

  const handleSubmit = async () => {
    await call(JobsService.updateCase({ id: job.id, wsid: job.workspaceId ?? '', requestBody: { repositoryId } }));
    onSuccess();
  };

  useEffect(() => {
    if (!organization) {
      return;
    }
    call(
      OrganizationsService.getOrgReposList({ oid: organization.id }).then((res) => {
        setRepositoryList(
          [
            {
              value: '',
              label: 'No case selected',
            },
          ].concat(
            res.body.map((item: TRepository) => ({
              value: item.id,
              label: item.name,
            })),
          ),
        );
      }),
    );
  }, [organization, job?.repositoryId]);

  return (
    <ModalWrapper>
      <h2>Edit Job Case</h2>

      <SmallWhiteSelect
        name="repositoryId"
        label="Case"
        value={repositoryId}
        onChange={(_, value) => setRepositoryId(value)}
        options={repositoryList}
        required={false}
      />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </ModalWrapper>
  );
};
