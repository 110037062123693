import styled from 'styled-components';
import { IconButton } from '@components/form-elements/buttons/IconButton';

export const DeleteButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;
