import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';

export const Title = ({ title }: TProps) => {
  if (!title) {
    return null;
  }

  return <Wrapper>{title}</Wrapper>;
};
