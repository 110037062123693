import { useState } from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';

import { Popover } from '@components/Popover';

import { AccountIcon } from './icons/account';
import { SupportIcon } from './icons/support';
import { DesktopIcon } from './icons/desktop';
import { LogoutIcon } from './icons/logout';

import { AccountButtonWrapper } from './styles/AccountButtonWrapper';
import { PopArrow } from './styles/PopArrow';
import { List } from './styles/List';
import { useInitContext } from '@providers/InitProvider';

export const Avatar = () => {
  const navigate = useNavigate();
  const { me } = useAuthContext();
  const { logout } = useInitContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (!me || !me.name) {
    return null;
  }

  const avatarName = `${me.name[0]}${me.lastname[0]}`;

  return (
    <>
      <AccountButtonWrapper>
        <div
          className="account-button"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          {me?.avatar ? (
            <img className="avatar" src={me.avatar} alt="" />
          ) : (
            <div className="avatar-name">{avatarName}</div>
          )}
          {anchorEl !== null && <PopArrow />}
        </div>
      </AccountButtonWrapper>

      <Popover
        open={anchorEl !== null}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        styles="
            right: -20px;
            top: -30px;
            box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
            border-radius: 5px;"
      >
        <List>
          <li onClick={() => navigate(routes.accountPage.pattern)}>
            <AccountIcon />
            My Account
          </li>
          <li className="divider"></li>
          <li onClick={() => window && window.open('https://support.voicescript.ai/portal/', '_blank')}>
            <SupportIcon />
            Support
          </li>
          <li className="divider"></li>
          <li onClick={() => navigate(routes.desktopAppHelp.pattern)}>
            <DesktopIcon />
            Desktop App
          </li>
          <li className="divider"></li>
          <li onClick={logout}>
            <LogoutIcon />
            Logout
          </li>
        </List>
      </Popover>
    </>
  );
};
