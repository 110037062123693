import { ItemInterface, ReactSortable } from 'react-sortablejs';

import { PreviewFile } from '../PreviewFile';
import { UploadObjectType } from '@constants/enums/uploadObjectType';

import { TProps } from './types/TProps';

export const Files = ({
  files,
  setFiles,
  multiple = true,
  objectType = UploadObjectType.JOB,
  allowPlayback = false,
  onRemoveFile,
}: TProps) => {
  const sortableList: ItemInterface[] = (files ?? []).map((file, index) => {
    return {
      id: index,
    };
  });

  const setSortableList = (list: ItemInterface[]) => {
    const newFiles = [...files];
    list.forEach((item, index) => {
      newFiles[Number(item.id)].weight = index;
    });
    newFiles.sort((a, b) => (a.weight ?? 0) - (b.weight ?? 0));
    setFiles(newFiles);
  };

  if (!multiple || files.length < 2) {
    return (
      <>
        {files.map((item, index) => (
          <PreviewFile
            key={index}
            fileWithMeta={item}
            objectType={objectType}
            allowPlayback={allowPlayback}
            onRemoveFile={onRemoveFile}
          />
        ))}
      </>
    );
  }

  return (
    <ReactSortable list={sortableList} setList={setSortableList} animation={200} delay={1}>
      {files.map((item, index) => (
        <PreviewFile
          draggable={true}
          key={`${item.meta.name}-${index}`}
          fileWithMeta={item}
          objectType={objectType}
          allowPlayback={allowPlayback}
          onRemoveFile={onRemoveFile}
        />
      ))}
    </ReactSortable>
  );
};
