import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';
import { Button } from '@components/form-elements/buttons/Button';
import { TrashBin } from '@components/icons/TrashBin';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { uploadOrgImage } from '@queries/uploadS3Image';
import styled from 'styled-components';
import { TOrganization } from 'app/types/entities/TOrganization';
import { useAPI } from '@hooks/useAPI';
import { OrganizationsService } from 'app/API';

const OrgImageBlock = styled.div`
  padding: 0 0 20px;
  display: flex;
  gap: 20px;
  align-items: center;
`;
const UploadOrgImageButton = styled(Button)`
  width: 130px;
`;

const StyledIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;
const RemoveOrgImageButton = styled(StyledIconButton)`
  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;
const OrgImage = styled.img`
  height: 50px;
  border-radius: 5px;
`;
const OrgImageName = styled.div`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  text-transform: capitalize;
`;

type TProps = {
  org: TOrganization;
  viewOnly?: boolean;
};

export const OrganizationImage = ({ org }: TProps) => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();
  const { updateMe } = useAuthContext();

  const submitOrgImage = () => {
    openModal(ModalNamesEnum.ImageUploadModal, {
      onSuccess: async (orgImage: string) => {
        await uploadOrgImage(org.id, orgImage);
        await updateMe();
        closeModal();
      },
    });
  };

  const removeOrgImage = async () => {
    await call(OrganizationsService.removeImage({ oid: org.id }));
    await updateMe();
  };

  const orgIconName = `${org.name[0]}`;

  return (
    <OrgImageBlock>
      {org.image ? <OrgImage src={org.image} alt="" /> : <OrgImageName>{orgIconName}</OrgImageName>}
      <UploadOrgImageButton variant="outlined" onClick={submitOrgImage} type="button">
        Upload icon
      </UploadOrgImageButton>
      {org.image ? (
        <RemoveOrgImageButton title="Remove" onClick={removeOrgImage}>
          <TrashBin />
        </RemoveOrgImageButton>
      ) : null}
    </OrgImageBlock>
  );
};

export const OrganizationImageViewOnly = ({ org }: TProps) => {
  const orgIconName = `${org.name[0]}`;

  return (
    <OrgImageBlock>
      {org.image ? <OrgImage src={org.image} alt="" /> : <OrgImageName>{orgIconName}</OrgImageName>}
    </OrgImageBlock>
  );
};
