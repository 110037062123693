import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  margin: -6px -7px;
  padding: 6px 7px;

  &:hover {
    background: #cadcf8;
    border-radius: 5px;

    .counter {
      opacity: 0;
    }
  }

  svg {
    width: 16px;
  }

  .counter {
    display: block;
    position: absolute;
    top: 50%;
    right: 50%;
    color: #fff;
    background-color: #40608f;
    width: 16px;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    border-radius: 100%;
    font-weight: 600;
    font-family: Arial, serif;
    transform: translate(17px, -17px);
    transition: opacity ease 60ms;
  }
`;
