import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const TableStyledLink = styled(RouterLink)`
  color: #00122d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;
