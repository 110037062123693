import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;

  svg {
    animation: rotation 1s infinite linear;
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;

const SpinnerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.7);
`;

type TProps = {
  overlay?: boolean;
  className?: string;
};

export const RelativeSpinner = ({ overlay = false, className = 'relative-spinner' }: TProps) => {
  return (
    <div className={className}>
      {overlay && <SpinnerOverlay className="spinner-overlay" />}
      <SpinnerWrapper className="relative-spinner-wrapper">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60ZM30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z"
            fill="#CADCF8"
          />
          <path
            d="M51.2132 8.7868C45.7843 3.35786 38.2843 0 30 0V5C36.9036 5 43.1536 7.79822 47.6777 12.3223L51.2132 8.7868Z"
            fill="url(#paint0_linear_421_12384)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_421_12384"
              x1="30"
              y1="5.13431"
              x2="51.2132"
              y2="5.13431"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7AABF3" />
              <stop offset="1" stopColor="#2765BE" />
            </linearGradient>
          </defs>
        </svg>
      </SpinnerWrapper>
    </div>
  );
};
