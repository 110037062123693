import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TUser } from 'app/types/entities/TUser';

import { AdminService } from 'app/API';
import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';
import { useAPI } from '@hooks/useAPI';

type TState = {
  users: TPaginatedResponse<TUser>;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  sortingField: string;
  setSortingField: (v: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (v: boolean) => void;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  page: number;
  setPage: (v: number) => void;
  reloadPage: () => void;
  createUser: () => void;
  toggleSort: (field: string) => void;
  changeEnabled: (user: TUser) => Promise<void>;
  exportCSV: () => void;
};

const PageContext = createContext<TState>({
  users: { data: [], count: 0 },
  isLoading: false,
  setIsLoading: () => null,
  sortingField: 'createdAt',
  setSortingField: () => null,
  sortingReverse: true,
  setSortingReverse: () => null,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  page: 1,
  setPage: () => null,
  reloadPage: () => null,
  createUser: () => null,
  toggleSort: () => null,
  changeEnabled: async () => {},
  exportCSV: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const [users, setUsers] = useState<TPaginatedResponse<TUser>>({
    data: [],
    count: 0,
  });

  useEffect(() => {
    reloadPage();
  }, [page, pageSize, reloadPageTrigger, sortingField, sortingReverse, search]);

  const reloadPage = async () => {
    setIsLoading(true);
    const users = await call(
      AdminService.getAllUsers({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search,
      }),
    );
    setUsers(users);
    setIsLoading(false);
  };

  const createUser = () => {
    openModal(ModalNamesEnum.CreateUserModal, {
      onSuccess: () => {
        closeModal();
        setIsLoading(true);
        setReloadPageTrigger(!reloadPageTrigger);
        setIsLoading(false);
      },
      onCancel: () => {
        closeModal();
        setIsLoading(true);
        setReloadPageTrigger(!reloadPageTrigger);
        setIsLoading(false);
      },
    });
  };

  const changeEnabled = async (user: TUser) => {
    setIsLoading(true);
    const userIndex = users.data.findIndex((item: TUser) => item === user);
    const newData = Object.assign({}, users);
    const disabled = !user.disabled;
    await call(AdminService.setUserDisabled({ uid: user.id, requestBody: { disabled } }));
    newData.data[userIndex].disabled = disabled;
    setUsers(newData);
    setIsLoading(false);
  };

  const toggleSort = (field: string) => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse((prevState: boolean) => !prevState);
    }
  };

  const exportCSV = async () => {
    const exportData = await call(
      AdminService.getAllUsers({
        pageNo: '1',
        pageSize: users.count.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search,
      }),
    );
    const headers = [
      'ID',
      'Created',
      'Name',
      'Email',
      'Phone',
      'Organizations',
      'Enabled',
      'How heard about us',
      'Email Verified',
    ];
    const array = exportData.data.map((data: TUser) => {
      return {
        id: data.id,
        created: getAmericanTime(data.createdAt),
        name: `${data.name} ${data.lastname}`,
        email: data.email,
        phone: data.phone,
        organizations: (data.organizations || []).map((o) => `${o.name} <${o.id}>`).join(' / '),
        enabled: !data.disabled ? 'Y' : '',
        howDidYouHearAboutUs: data.howDidYouHearAboutUs ?? '',
        emailVerified: data.emailVerified ? 'Y' : '',
      };
    });
    return downloadCSV(array, headers);
  };

  return (
    <PageContext.Provider
      value={{
        users,
        isLoading,
        setIsLoading,
        sortingField,
        setSortingField,
        sortingReverse,
        setSortingReverse,
        search,
        setSearch,
        pageSize,
        setPageSize,
        page,
        setPage,
        reloadPage,
        createUser,
        toggleSort,
        changeEnabled,
        exportCSV,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
