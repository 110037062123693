import { createContext, useContext, useEffect, useState } from 'react';
import { useAPI } from '@hooks/useAPI';

import { CanvasService } from 'app/API';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { useParams } from 'react-router';

type TState = {
  students: any[];
  isLoading: boolean;
  reloadPage: () => void;
};

const PageContext = createContext<TState>({
  students: [],
  isLoading: false,
  reloadPage: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [students, setStudents] = useState<any[]>([]);

  const params = useParams() as { id?: string };

  const { call } = useAPI();

  const reloadPage = () => {
    setIsLoading(true);
    call(CanvasService.getCourseStudents({ courseId: (params.id || 0).toString() })).then((res) => {
      setStudents(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    reloadPage();
  }, []);

  return (
    <PageContext.Provider
      value={{
        students,
        isLoading,
        reloadPage,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
