import styled from 'styled-components';

export const AccountButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .account-button {
    cursor: pointer;
    margin: 0 -2px;
    position: relative;
    border-radius: 5px;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid #40608f;
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    background: #cadcf8;
    border-radius: 5px;
    max-width: 30px;
    max-height: 30px;
  }

  .avatar-name {
    width: 30px;
    height: 30px;
    background: #cadcf8;
    border-radius: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
  }
`;
