export enum EPermission {
  viewRepositories = 'viewRepositories',
  editRepositories = 'editRepositories',
  viewBilling = 'viewBilling',
  manageBilling = 'manageBilling',
  viewLicenses = 'viewLicenses',
  manageLicenses = 'manageLicenses',
  editJobs = 'editJobs',
  reassignJobs = 'reassignJobs',
  submitJobs = 'submitJobs',
  viewJobCost = 'viewJobCost',
  editJobTranscript = 'editJobTranscript',
  proofreadJobTranscript = 'proofreadJobTranscript',
  finalizeJobTranscript = 'finalizeJobTranscript',
  markJobComplete = 'markJobComplete',
  viewReports = 'viewReports',
  manageMembers = 'manageMembers',
  managePermissions = 'managePermissions',
  manageWorkspaces = 'manageWorkspaces',
  manageOrganization = 'manageOrganization',
}
