import { Helmet } from 'react-helmet-async';

import { Content } from './components/Content';
import { PageProvider } from './providers/PageProvider';

export const AdminOrganizationsListPage = () => {
  return (
    <>
      <Helmet>
        <title>Organizations - AutoScript Admin</title>
      </Helmet>

      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
