import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const isTestEnvironment =
  window.location.hostname === 'localhost' ||
  window.location.hostname === 'staging.autoscript.ai' ||
  window.location.hostname === 'demo.autoscript.ai';

const stripeKey = isTestEnvironment
  ? 'pk_test_51JMd0HK6PLb36tFiqfdtEiFseWqiSOzk1m9vmYLgo6vR4ceE4BsLU4DSLuhfeDig50YX1HmFPTCxADnJdWMZeXtq00SMqWPsIH'
  : 'pk_live_51JMd0HK6PLb36tFim4RLWzMJFsa1Op7KiHwv9rKqWz6WKtf8VFN997CfFCmwWJdd1kLCsgGs2Sd613unBUMAY5uv00bejEHItk';
const stripePromise = loadStripe(stripeKey);

export const StripeWrapper = ({ children }: { children: React.ReactNode }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
