import { client } from 'app/http';

export const createSubscription = async (
  billingAccountId: string,
  subscriptionProductId: string,
  licenseCount: number,
) => {
  const { data } = await client.post(`/billing-account/${billingAccountId}/subscription`, {
    subscriptionProductId,
    licenseCount,
  });
  return data;
};
