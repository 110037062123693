import { client } from 'app/http';

export const bulkJobSubmission = async (repositoryId: string, workspaceId: string, dualChannel: boolean) => {
  try {
    const jobDetails = await client.post(`repository/${repositoryId}/jobs/bulk`, {
      bulk: true,
      dualChannel,
      workspaceId,
    });
    return jobDetails.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message };
  }
};
