import styled from 'styled-components';

const Wrapper = styled.div`
  height: 5px;
  .active-background {
    background: #cadcf8;
  }
  .container {
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    height: 5px;
    min-width: 100%;
    .bar {
      background: linear-gradient(90deg, #40608f 0%, #7cb1fe 98.55%);
      border-radius: 5px;
      height: 6px;
      width: 300px;
      position: absolute;
      @keyframes loading {
        0% {
          left: 0;
        }
        100% {
          left: 100%;
        }
      }
      animation: loading 1s linear infinite;
    }
  }
`;

interface ILinearProgressLoader {
  active: boolean;
}

export const LinearProgressLoader = ({ active }: ILinearProgressLoader) => {
  return (
    <Wrapper>
      <div className={active ? 'container active-background' : 'container'}>
        <div className={active ? 'bar' : ''}></div>
      </div>
    </Wrapper>
  );
};
