import styled from 'styled-components';
import { InfoBlockContent } from '../../../styles/InfoBlockContent';

export const AssigneeBlockContent = styled(InfoBlockContent)`
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;

    :hover {
      path {
        fill: #858dbd;
      }
    }
  }

  .assignee {
    text-overflow: ellipsis;
    overflow: hidden;

    .assignName {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      margin: 0;
    }

    .assignEmail {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 100%;
      margin: 0;
    }
  }
`;
