import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { SortableHead } from '@components/Table/SortableHead';
import { SortingDirection } from '@components/Table/SortingDirection';
import { CustomTable } from '@components/Table';

import { routes } from '@routes';
import { TOrganization } from 'app/types/entities/TOrganization';
import { TTeamMemberItem } from 'app/types/entities/TRole';

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }

  table {
    width: 100%;

    thead tr td,
    thead tr th {
      height: 50px;
    }
  }
`;

const CellContent = styled.div`
  margin: 0;
  padding: 15px 0;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

type Team = {
  id: string;
  default: boolean;
  Members: TTeamMemberItem[];
  Organization: TOrganization;
};

type TProps = {
  team: Team;
  setSortingField: Dispatch<SetStateAction<string>>;
  setSortingReverse: Dispatch<SetStateAction<boolean>>;
  sortingField: string;
  sortingReverse: boolean;
  setReloadPageTrigger: Dispatch<SetStateAction<boolean>>;
};

export const TeamMembersTable = ({
  team,
  setSortingField,
  setSortingReverse,
  sortingField,
  sortingReverse,
}: TProps) => {
  const members = team.Members ?? [];

  const toggleSort = (field: string) => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse((prevState: boolean) => !prevState);
    }
  };

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '1%' }}>#</td>,
      render: (data: TTeamMemberItem, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '20%' }} onClick={() => toggleSort('name')}>
          Name
          {sortingField === 'name' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TTeamMemberItem) => (
        <CellContent>
          <Link to={routes.adminUserDetails.make(data.User.id)}>
            {data.User.name} {data.User.lastname}
          </Link>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Contact</td>,
      render: (data: TTeamMemberItem) => (
        <CellContent>
          <span style={{ display: 'block' }}>{data.User.email}</span>
          <span style={{ display: 'block' }}>{data.User.phone}</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('createdAt')}>
          Created
          {sortingField === 'createdAt' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TTeamMemberItem) => <CellContent>{getAmericanTime((data.User ?? {}).createdAt ?? 0)}</CellContent>,
    },
  ];

  return (
    <>
      <Wrapper>
        <CustomTable head={tableHead} data={members} />
      </Wrapper>
    </>
  );
};
