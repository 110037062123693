import { client } from 'app/http';

export const setBillingAccountFixedRate = async (baId: string, fixed: boolean, rate: number) => {
  try {
    const response = await client.patch(`admin/billing-account/${baId}/rate`, {
      fixed,
      rate,
    });
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message };
  }
};
