import { Button } from '@components/form-elements/buttons/Button';

import { Wrapper } from './styles/Wrapper';
import { PositiveIcon } from '@components/icons/Positive';
import { PendingIcon } from '@components/Modal/StatusModal/icons/Pending';
import { ErrorIcon } from '@components/Modal/StatusModal/icons/Error';
import { useModalContext } from '@providers/ModalProvider';

type TProps = {
  onSuccess: () => void;
  title: string;
  message: string | React.ReactNode;
  buttonMessage?: string;
  status: 'pending' | 'success' | 'error';
};

export const StatusModal = ({ onSuccess, message, title, buttonMessage = 'Ok', status }: TProps) => {
  const { closeModal } = useModalContext();

  const onClick = () => {
    closeModal();
    onSuccess();
  };

  return (
    <Wrapper>
      <h2>{title}</h2>
      {status === 'success' && (
        <div className="icon">
          <PositiveIcon />
        </div>
      )}
      {status === 'pending' && (
        <div className="icon">
          <PendingIcon />
        </div>
      )}
      {status === 'error' && (
        <div className="icon">
          <ErrorIcon />
        </div>
      )}
      <p className="description">{message}</p>
      <div className="buttonContainer" style={{ width: '100px' }}>
        <Button type="button" variant="contained" onClick={onClick}>
          {buttonMessage}
        </Button>
      </div>
    </Wrapper>
  );
};
