import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { CSSProperties, Fragment, ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  table {
    min-width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    /* font-family: 'General Sans', sans-serif; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #00122d;
    position: relative;

    & tr:not(:last-child) {
      border-bottom: 1px solid #d5ddf2;
    }

    thead th:empty,
    thead td:empty {
      height: 0;
    }

    & th,
    & td {
      max-width: 350px;
      vertical-align: middle;
      white-space: nowrap;

      & * {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: none;
        white-space: nowrap;
      }
    }

    & th:not(:last-child),
    & td:not(:last-child) {
      padding-right: 20px;
    }
  }

  thead.sticky {
    z-index: 1;
    background: #f9fafb;
    position: sticky;
    top: 0;

    &.fullPage {
      top: 95px;

      @media (max-width: 1060px) {
        top: 0;
      }
    }

    &.white {
      background: #ffffff;
    }
  }

  thead {
    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid #d5def2;
    }

    & tr {
      position: relative;
      color: #858dbd;
      height: 60px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      text-transform: uppercase;

      td,
      th {
        position: sticky;
        top: 0;
        height: 60px;
        vertical-align: middle;
        font-weight: 600;
        text-align: left;
        user-select: none;
      }
    }
  }
`;

type TProps = {
  head: {
    render: (data: any, index: number) => ReactElement | null;
    headComponent: () => ReactElement | null;
    renderStyles?: CSSProperties | undefined;
  }[];
  data: any[];
  className?: string;
  fullPage?: boolean;
  whiteBackground?: boolean;
  noHead?: boolean;
  renderInlineEditRow?: (data: any, index: number) => ReactElement | null;
  isLoading?: boolean;
};

export const CustomTable = ({
  data,
  head,
  className,
  fullPage = true,
  whiteBackground = false,
  noHead = false,
  renderInlineEditRow,
  isLoading = false,
}: TProps) => {
  return (
    <Wrapper className={className}>
      {isLoading ? <AbsoluteSpinner overlay className="mt-4 h-full" /> : null}
      <table>
        {!noHead ? (
          <thead className={`sticky ${fullPage ? 'fullPage' : ''}  ${whiteBackground ? 'white' : ''}`}>
            <tr className="theadTr">
              {head.map((headItem, headIndex) => (
                <Fragment key={headIndex}>{headItem.headComponent()}</Fragment>
              ))}
            </tr>
          </thead>
        ) : null}

        {!isLoading ? (
          <tbody>
            {data
              ? data.map((item, index) => (
                  <Fragment key={item.id || index}>
                    <tr className={item.className}>
                      {head.map((headItem, headIndex) => (
                        <td key={`${item.id || index}-${headIndex}`} style={headItem.renderStyles}>
                          {headItem.render(item, index)}
                        </td>
                      ))}
                    </tr>
                    {!!renderInlineEditRow && renderInlineEditRow(item, index)}
                  </Fragment>
                ))
              : null}
          </tbody>
        ) : null}
      </table>
    </Wrapper>
  );
};
