import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';
import { Warning } from '@components/icons/Warning';

export const GenericPageError = ({ message, className = 'page-error' }: TProps) => {
  if (!message) {
    return <></>;
  }
  return (
    <Wrapper className={className}>
      <Warning className="warning-icon" />
      {message}
    </Wrapper>
  );
};
