import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { routes } from '@routes';

import { Header } from '@components/Header';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { TeamInformation } from './TeamInformation';
import { TeamMembersTable } from './TeamMembersTable';
import { TRole } from 'app/types/entities/TRole';
import { TeamsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const MainContent = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;

export const AdminTeamDetailsPage = () => {
  const { call } = useAPI();
  const { id } = useParams<{ id: string }>();

  const [team, setTeam] = useState<TRole>();
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);

  const title = team?.name ? team.name : 'Team';

  useEffect(() => {
    setIsLoading(true);
    call(TeamsService.adminFindOne({ id: id ?? '' })).then((res) => {
      setTeam(res);
      setIsLoading(false);
    });
  }, [reloadPageTrigger]);

  return (
    <>
      <Helmet>
        <title>{title} - AutoScript</title>
      </Helmet>
      <Header title={title} backRoute={routes.adminOrganizationDetails.make(team?.organizationId)} />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        {team ? <TeamInformation team={team} /> : null}
        {team ? (
          <TeamMembersTable
            team={team}
            setSortingField={setSortingField}
            setSortingReverse={setSortingReverse}
            sortingField={sortingField}
            sortingReverse={sortingReverse}
            setReloadPageTrigger={setReloadPageTrigger}
          />
        ) : null}
      </MainContent>
    </>
  );
};
