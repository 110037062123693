export const PauseIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 6C7 5.44772 7.44772 5 8 5V5C8.55228 5 9 5.44772 9 6V16C9 16.5523 8.55228 17 8 17V17C7.44772 17 7 16.5523 7 16V6Z"
        fill="#40608F"
      />
      <path
        d="M13 6C13 5.44772 13.4477 5 14 5V5C14.5523 5 15 5.44772 15 6V16C15 16.5523 14.5523 17 14 17V17C13.4477 17 13 16.5523 13 16V6Z"
        fill="#40608F"
      />
    </svg>
  );
};
