import React from 'react';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { SmallWhiteTextarea } from '@components/form-elements/Textarea/variations/SmallWhiteTextarea';
import { TJob } from 'app/types/entities/TJob';
import { useAPI } from '@hooks/useAPI';
import { JobsService } from '../../../API';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  textarea {
    border: 1px solid #cadcf8;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0 0 20px;
  }

  .assignedTo {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 20px 0 10px;
  }

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tr {
      cursor: pointer;
    }

    thead {
      tr {
        cursor: default;

        td,
        th {
          height: auto;
          padding: 10px 0;
        }
      }
    }

    tbody {
      td {
        padding: 10px 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }

  .assignTo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;

    svg {
      cursor: pointer;
    }

    .assignToText {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #00122d;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

type TProps = {
  job: TJob;
  onCancel: () => void;
  onSuccess: () => void;
};

export const EditJobNotesModal = ({ job, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { workspace } = useAuthContext();
  const [notes, setNotes] = React.useState(job.notes ?? '');

  const handleSubmit = async () => {
    const workspaceId = job.workspaceId ?? job.workspace?.id ?? workspace?.id ?? '';
    await call(JobsService.editJobNotes({ wsid: workspaceId, id: job.id, requestBody: { notes } }));
    onSuccess();
  };

  const onInputChange = async (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;
    await setNotes(value);
  };

  return (
    <ModalWrapper>
      <h2>Edit Job Notes</h2>
      <SmallWhiteTextarea onChange={onInputChange} name="notes" label="Type your notes here" value={notes} />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </ModalWrapper>
  );
};
