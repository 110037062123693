import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const Pager = ({ pagesCount, currentPage, onChange, className = 'pager' }: TProps) => {
  let pages = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }
  if (!currentPage) {
    currentPage = 1;
  }

  let minPage = currentPage - 2;
  let maxPage = currentPage + 2;
  if (currentPage < 5) {
    maxPage = 5;
  }
  if (currentPage === 4) {
    maxPage = 6;
  }
  if (currentPage > pagesCount - 4) {
    minPage = pagesCount - 4;
  }
  if (currentPage === pagesCount - 3) {
    minPage = pagesCount - 5;
  }

  const showPrevButton = currentPage > 1;
  const showNextButton = currentPage < pagesCount;

  const showSecondPage = minPage === 3;
  const showPreLastPage = pagesCount - maxPage === 2;

  const showFirstPage = currentPage > 3 && pagesCount > 5;
  const showLastPage = currentPage < pagesCount - 2 && pagesCount > 5;

  const showPrevDots = pagesCount > 6 && currentPage > 5 && !showSecondPage;
  const showNextDots = pagesCount > 6 && currentPage < pagesCount - 4 && !showPreLastPage;

  // Обрезаем лишние страницы справа
  if (pages.findIndex((p) => p === maxPage) > -1) {
    pages = pages.slice(0, pages.findIndex((p) => p === maxPage) + 1);
  }
  // Обрезаем лишние страницы слева
  if (pages.findIndex((p) => p === minPage) > -1) {
    pages = pages.slice(pages.findIndex((p) => p === minPage));
  }

  return pagesCount ? (
    <Wrapper className={className}>
      {showPrevButton ? (
        <span className="page-number" onClick={() => onChange(currentPage - 1)}>
          &laquo;
        </span>
      ) : null}

      {showFirstPage ? (
        <span className="page-number" onClick={() => onChange(1)}>
          1
        </span>
      ) : null}

      {showSecondPage ? (
        <span className="page-number" onClick={() => onChange(2)}>
          2
        </span>
      ) : null}

      {showPrevDots ? <span className="page-number page-dots">...</span> : null}

      {pages.map((p, i) => {
        return (
          <span
            className={+currentPage === p ? 'page-number current' : 'page-number'}
            key={i}
            onClick={() => onChange(p)}
          >
            {p}
          </span>
        );
      })}

      {showNextDots ? <span className="page-number page-dots">...</span> : null}

      {showPreLastPage ? (
        <span className="page-number" onClick={() => onChange(pagesCount - 1)}>
          {pagesCount - 1}
        </span>
      ) : null}

      {showLastPage ? (
        <span className="page-number" onClick={() => onChange(pagesCount)}>
          {pagesCount}
        </span>
      ) : null}

      {showNextButton ? (
        <span className="page-number" onClick={() => onChange(currentPage + 1)}>
          &raquo;
        </span>
      ) : null}
    </Wrapper>
  ) : null;
};
