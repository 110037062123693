import { createContext, useContext, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

type TState = {
  diff: any;
};

const PageContext = createContext<TState>({
  diff: {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const [diff] = useState<any>();

  return <PageContext.Provider value={{ diff }}>{children}</PageContext.Provider>;
};

export const usePageContext = () => useContext(PageContext);
