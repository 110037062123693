import styled from 'styled-components';

export const JobSectionLeft = styled.div`
  width: 170px;
  flex: 0 0 auto;
  padding-right: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #858dbd;
`;
