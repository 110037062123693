import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
  border: 1px dashed #cadcf8;
  border-radius: 5px;

  .InputUpload-label {
    cursor: pointer;
    padding: 15px;
    display: block;
    text-align: center;
    vertical-align: middle;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .InputUpload-label-span {
    color: #c1c1c1;
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    &:hover span {
      text-decoration: none;
    }
  }

  .InputUpload-here-span {
    text-decoration: underline;
    color: #858dbd;
  }

  .InputUpload-input {
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
`;
