import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { adminGetOrganizationTeams } from '@queries/admin/organizations/getOrganizationTeams';
import { adminCreateInvitation } from '@queries/admin/organizations/createInvitation';

import { EmailIcon } from '@components/icons/Email';
import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { Select } from '@components/form-elements/Select';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { TRole } from 'app/types/entities/TRole';
import { EErrorMessages } from '@constants/errorMessages';

const Wrapper = styled.div`
  width: 300px;
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  position: relative;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #858dbd;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    padding: 20px 0 0;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;

    .select {
      height: 40px;
    }

    .add-more {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: #40608f;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  organizationId: string;
};

export const AdminInviteUserModal = ({ onCancel, onSuccess, organizationId }: TProps) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [teamIds, setTeamIds] = useState<string[]>(['']);
  const [orgTeams, setOrgTeams] = useState<TRole[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetch = async () => {
    setIsLoading(true);
    const res = await adminGetOrganizationTeams(organizationId);
    setOrgTeams(res);
    setIsLoading(false);
  };

  const oneMoreTeam = async () => {
    const newTeamIds = [...teamIds, ''];
    setTeamIds(newTeamIds);
  };

  useEffect(() => {
    fetch();
  }, [organizationId]);

  const teams = (orgTeams || [])
    .map((role: TRole) => ({ value: role.id, label: role.name }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  const changeTeamIds = async (index: number, value: string | undefined) => {
    const newTeamIds = [...teamIds];
    if (value) {
      newTeamIds[index] = value;
    } else {
      newTeamIds.splice(index, 1);
    }
    setTeamIds(newTeamIds);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await adminCreateInvitation(organizationId, email, teamIds ?? []);
      onSuccess();
    } catch (error) {
      toast.error(EErrorMessages.DEFAULT);
    }
    setIsSubmitting(false);
  };

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Invite Member</h2>
      <form action="#" onSubmit={handleSubmit}>
        <div className="inputWrapper">
          <Input
            icon={EmailIcon}
            required
            value={email}
            type="email"
            label="Email"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.toLowerCase())}
            error={isSubmitting && !email}
          />
          <Select
            value={teamIds[0]}
            options={teams}
            onChange={(_, value) => changeTeamIds(0, value)}
            label="Role"
            required
            name="role"
          />

          {teamIds.map((teamId, index) => {
            if (index === 0) {
              return;
            }
            const options: TRole[] = (orgTeams ?? []).filter((team) => {
              return !teamIds.includes(team.id) || teamIds[index] === team.id;
            });
            return (
              <Select
                key={`team-${index}`}
                value={teamIds[index]}
                options={options.map((team) => ({ value: team.id, label: team.name })) ?? []}
                onChange={(_, value) => changeTeamIds(index, value)}
                label="Role"
                required
                name="role"
              />
            );
          })}

          {teamIds.length < 5 ? (
            <div className="add-more" onClick={oneMoreTeam}>
              + Add more
            </div>
          ) : null}
        </div>
        <div className="buttonWrapper">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={!teamIds.length || !teamIds[0].length || !email}>
            Invite Member
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};
