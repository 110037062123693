import { Helmet } from 'react-helmet-async';

import { Content } from './components/Content';
import { PageProvider } from '@pages/Admin/SSOProviders/providers/PageProvider';

export const AdminSSOProviderListPage = () => {
  return (
    <>
      <Helmet>
        <title>SSO Providers list - AutoScript Admin</title>
      </Helmet>

      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
