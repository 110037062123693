export const PendingIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z"
      fill="#FFAE34"
      fillOpacity="0.05"
    />
    <path
      d="M50 30C50 41.0457 41.0457 50 30 50C18.9543 50 10 41.0457 10 30C10 18.9543 18.9543 10 30 10C41.0457 10 50 18.9543 50 30Z"
      fill="#FFAE34"
      fillOpacity="0.1"
    />
    <path
      d="M30.6177 16.6001C30.2116 16.6001 29.8244 16.7662 29.5408 17.058C29.2576 17.3495 29.1003 17.7425 29.1003 18.1499C29.1003 18.5572 29.2575 18.9502 29.5407 19.2417L30.3262 20.0499H29.5001C24.0692 20.0499 19.6001 24.6651 19.6001 30.225C19.6001 35.7849 24.0692 40.4001 29.5001 40.4001C34.931 40.4001 39.4001 35.7849 39.4001 30.225C39.4001 29.8175 39.2429 29.4245 38.9596 29.133C38.6759 28.8411 38.2887 28.675 37.8825 28.675C37.4762 28.675 37.089 28.8411 36.8053 29.133C36.522 29.4245 36.3648 29.8175 36.3648 30.225C36.3648 34.0952 33.2339 37.3001 29.5001 37.3001C25.7663 37.3001 22.6354 34.0952 22.6354 30.225C22.6354 26.3548 25.7663 23.1499 29.5001 23.1499H30.3262L29.5407 23.9581L29.5359 23.9631C29.2609 24.2562 29.1105 24.6463 29.1139 25.0492C29.1173 25.452 29.2742 25.8396 29.5543 26.1278C29.8348 26.4163 30.2166 26.5822 30.6182 26.5858C31.0198 26.5893 31.4045 26.4304 31.6899 26.1468L35.0478 22.6917C35.331 22.4002 35.4881 22.0073 35.4881 21.5999C35.4881 21.1925 35.3309 20.7996 35.0477 20.5081L31.6947 17.058C31.4111 16.7662 31.0239 16.6001 30.6177 16.6001Z"
      fill="#FAB957"
    />
  </svg>
);
