import { client } from 'app/http';

type AddPaymentMethodResponse = {
  success: boolean;
};

export const addPaymentMethod = async (
  paymentMethodId: string,
  paymentMethodType: string,
  id: string,
): Promise<AddPaymentMethodResponse> => {
  return await client.post(`payment/billing-account/${id}/payment-method`, {
    paymentMethodId: paymentMethodId,
    paymentMethodType: paymentMethodType,
  });
};
