import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 740px;
  min-width: 460px;
  width: 90%;
  padding: 30px;
  background: #fff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  max-height: 90vh;
  overflow: auto;

  .head {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    .search-icon {
      flex: 0 0 auto;
      svg path {
        fill: #858dbd;
      }
    }
    .input {
      flex: 1 1 auto;
      width: 100%;
      input {
        width: 100%;
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
    .clear-button {
      width: 50px;
      flex: 0 0 auto;
      background: none;
      border: none;
      padding: 0;
      color: #858dbd;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      :hover {
        color: #40608f;
      }
    }
    .close-button {
      width: 20px;
      flex: 0 0 auto;
      background: none;
      border: none;
      padding: 0;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #858dbd;
        }
      }
      :hover {
        svg path {
          fill: #40608f;
        }
      }
    }
  }
  .tags {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: center;

    h4 {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #b4b9d9;
      margin: 0 5px 5px 0;
    }

    .tag {
      cursor: pointer;
      text-align: center;
      position: relative;
      padding: 4px 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #d5def2;
      background: #f8fafb;
      border: 1px solid #d5def2;
      border-radius: 5px;
      margin: 0 5px 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;

      &.active {
        color: #40608f;
        background: #d5def2;
        border: 1px solid #d5def2;
      }
    }
  }

  .results {
    position: relative;
  }
`;
