import { usePageContext } from '../../../../../../../../providers/PageProvider';
import { InfoBlock } from '../../../../styles/InfoBlock';
import { InfoBlockTitle } from '../../../../styles/InfoBlockTitle';
import { InfoBlockContent } from '../../../../styles/InfoBlockContent';

export const MeetingLinkBlock = () => {
  const { job } = usePageContext();

  if (!job || job.meetingType === 'RECORDING_SESSION_IN_PERSON' || !job.meetingLink?.length) {
    return null;
  }

  return (
    <InfoBlock>
      <InfoBlockTitle>Meeting link</InfoBlockTitle>
      <InfoBlockContent>
        <a target="_blank" href={job.meetingLink ?? ''} rel="noreferrer">
          Meeting link to start session
        </a>
      </InfoBlockContent>
    </InfoBlock>
  );
};
