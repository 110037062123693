import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Search as SearchIcon } from '@components/icons/Search';
import { AutoCompleteSelect } from '@components/form-elements/AutocompleteSelect';
import { CustomTable } from '@components/Table';
import { TrashBin } from '@components/icons/TrashBin';
import { Button } from '@components/form-elements/buttons/Button';
import { TTeamMemberItem } from 'app/types/entities/TRole';
import { TeamsService } from '../../../API';
import { useAPI } from '@hooks/useAPI';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
    padding: 0;
  }

  input {
    height: 30px;
  }

  .buttonContainer {
    display: flex;
    gap: 20px;

    button {
      width: 130px;
    }
  }
`;

const TableWrapper = styled.div`
  overflow: auto;
  max-height: 40vh;
`;

type TProps = {
  team: {
    id: string;
    organizationId: string;
  };
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddTeamMembersModal = ({ team, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const [newTeamMembers, setNewTeamMembers] = useState<TTeamMemberItem[]>([]);
  const [availableUsers, setAvailableUsers] = useState<TTeamMemberItem[]>([]);
  const [initialAvailableUserCount, setInitialAvailableUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    call(TeamsService.findAvailableMembers({ id: team.id })).then((users) => {
      setAvailableUsers(users);
      setInitialAvailableUserCount(users.length);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!reload) {
      return;
    }
    call(TeamsService.findAvailableMembers({ id: team.id })).then((users) => {
      setAvailableUsers(users);
    });
  }, [reload]);

  const handleSave = async () => {
    await call(
      TeamsService.updateMembers({ id: team.id, requestBody: { memberIds: newTeamMembers.map((m) => m.id) } }),
    );
    onSuccess();
  };

  const handleAddUserToTeamList = async (userId: string) => {
    setReload(false);

    const newTeamMember = availableUsers.find((u) => u.id === userId);
    if (!newTeamMember) {
      setReload(true);
      return;
    }

    setNewTeamMembers([...newTeamMembers, newTeamMember]);
    setAvailableUsers(availableUsers.filter((u) => u.id !== userId));
    setReload(true);
  };
  const handleDeleteUserFromTeamList = async (userId: string) => {
    setReload(false);

    const availableUser = availableUsers.find((u) => u.id === userId);

    if (!availableUser) {
      setReload(true);
      return;
    }

    setAvailableUsers([...availableUsers, availableUser]);
    setNewTeamMembers(newTeamMembers.filter((u) => u.id !== userId));
    setReload(true);
  };
  const tableHead = [
    {
      render: (membership: TTeamMemberItem) => (
        <p>
          {membership.User.name} {membership.User.lastname}
        </p>
      ),
      headComponent: () => <td>Name</td>,
    },
    {
      render: (membership: TTeamMemberItem) => <p>{membership.User.email}</p>,
      headComponent: () => <td>Email</td>,
    },
    {
      render: (membership: TTeamMemberItem) => (
        <TrashBin onClick={() => handleDeleteUserFromTeamList(membership.id)} cursor="pointer" />
      ),
      headComponent: () => <td></td>,
    },
  ];
  return (
    <Wrapper>
      <h5>Add Team Members</h5>
      <AutoCompleteSelect
        customOpenIcon={SearchIcon}
        customCloseIcon={SearchIcon}
        name="search"
        label="Search users"
        // value={search}
        onChange={(e, userId) => handleAddUserToTeamList(userId)}
        options={availableUsers.map((u) => ({
          label: `${u.User.name} ${u.User.lastname} ${u.User.email}`,
          value: u.id,
        }))}
      />
      {!isLoading && initialAvailableUserCount > 0 ? (
        <TableWrapper>
          <CustomTable data={newTeamMembers} head={tableHead} fullPage={false} whiteBackground={true} />
        </TableWrapper>
      ) : (
        <p>{isLoading ? '' : 'All members of this organization are already members of this team'}</p>
      )}
      <div className="buttonContainer m-auto">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="bold" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Wrapper>
  );
};
