import { TProps } from '../types/TProps';

export const getButtonSize = (size: TProps['size']) => {
  switch (size) {
    case 'small':
      return `
        height: 30px;
      `;
    case 'medium':
      return `
        height: 40px;
      `;
    case 'large':
      return `
        height: 50px;
      `;
    default:
      return `
        height: 30px;
      `;
  }
};
