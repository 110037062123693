import { Helmet } from 'react-helmet-async';

import { Header } from '@components/Header';
import { routes } from '@routes';

import { Content } from './components/Content';
import { PageProvider } from './providers/PageProvider';

export const AdminOrganizationDetailsPage = () => {
  return (
    <>
      <Helmet>
        <title>Organization Details - AutoScript Admin</title>
      </Helmet>
      <Header title="Organization Details" backRoute={routes.adminOrganizationsList.make()} />

      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
