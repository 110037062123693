import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { usePageContext } from '../../../../../../providers/PageProvider';

import { JobSection } from '../../styles/JobSection';
import { Divider } from '../../styles/Divider';
import { JobSectionLeft } from '../../styles/JobSectionLeft';
import { JobSectionRight } from '../../styles/JobSectionRight';
import { InfoBlockWrapper } from '../../styles/InfoBlockWrapper';
import { InfoBlock } from '../../styles/InfoBlock';
import { InfoBlockTitle } from '../../styles/InfoBlockTitle';
import { InfoBlockContent } from '../../styles/InfoBlockContent';

import { MeetingAssignee } from './components/MeetingAssignee';
import { AddressBlock } from './components/AddressBlock';
import { MeetingLinkBlock } from './components/MeetingLinkBlock';

export const ScheduleSection = () => {
  const { job, showSchedule } = usePageContext();

  type meetingType = 'RECORDING_SESSION_REMOTE' | 'RECORDING_SESSION_HYBRID' | 'RECORDING_SESSION_IN_PERSON';
  const meetingTypeNames = {
    RECORDING_SESSION_REMOTE: 'Remote',
    RECORDING_SESSION_HYBRID: 'Hybrid',
    RECORDING_SESSION_IN_PERSON: 'In Person',
  };

  if (!job || !showSchedule) {
    return null;
  }

  return (
    <>
      <JobSection>
        <JobSectionLeft>Schedule</JobSectionLeft>
        <JobSectionRight>
          <InfoBlockWrapper>
            <InfoBlock>
              <InfoBlockTitle>Date and time</InfoBlockTitle>
              <InfoBlockContent>
                <>
                  {getAmericanTime(job.meetingDate ?? '', false)} {job.meetingTime}
                </>
              </InfoBlockContent>
            </InfoBlock>

            <MeetingAssignee />

            <InfoBlock>
              <InfoBlockTitle>Meeting type</InfoBlockTitle>
              <InfoBlockContent>
                {meetingTypeNames[(job.meetingType as meetingType) ?? 'RECORDING_SESSION_REMOTE']}
              </InfoBlockContent>
            </InfoBlock>

            <AddressBlock />

            <MeetingLinkBlock />
          </InfoBlockWrapper>
        </JobSectionRight>
      </JobSection>
      <Divider />
    </>
  );
};
