import { useEffect, useState } from 'react';

import { TProps } from './types/TProps';
import { VolumeIcon } from './icons/Volume';
import { VolumeOffIcon } from './icons/VolumeOff';

export const Volume = ({ audioRef }: TProps) => {
  const [volume] = useState(100);
  const [muteVolume, setMuteVolume] = useState(false);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    audioRef.current.volume = volume / 100;
    audioRef.current.muted = muteVolume;
  }, [volume, audioRef, muteVolume]);

  return (
    <div className="volume">
      <button onClick={() => setMuteVolume(!muteVolume)}>{muteVolume ? <VolumeOffIcon /> : <VolumeIcon />}</button>
      {/* <input*/}
      {/*  type="range"*/}
      {/*  min={0}*/}
      {/*  max={100}*/}
      {/*  value={volume}*/}
      {/*  onChange={(e) => setVolume(Number(e.target.value ?? 0))}*/}
      {/*  style={{*/}
      {/*    background: `linear-gradient(to right, #f50 ${volume}%, #ccc ${volume}%)`,*/}
      {/*  }}*/}
      {/* />*/}
    </div>
  );
};
