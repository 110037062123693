import { TProps } from './types/TProps';
import { StripeWrapper } from './components/StripeWrapper';
import { Form } from './components/Form';

export const PaymentForm = (props: TProps) => {
  return (
    <StripeWrapper>
      <Form {...props} />
    </StripeWrapper>
  );
};
