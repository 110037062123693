import { PagePagination } from '@components/Pagination';

import { usePageContext } from '../../../../providers/PageProvider';

export const Pagination = () => {
  const { users, pageSize, setPageSize, page, setPage } = usePageContext();

  return (
    <PagePagination
      totalItemsCount={users.count ?? 0}
      page={page ?? 1}
      setPage={setPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};
