import { createContext, useContext, useEffect, useState } from 'react';
import { useAPI } from '@hooks/useAPI';

import { AdminService, getSettingsResponse, getSettingsResponseSetting } from 'app/API';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';

type TState = {
  settings: getSettingsResponse | undefined;
  isLoading: boolean;
  openEditSettingModal: (e: React.MouseEvent<SVGElement>, setting: getSettingsResponseSetting) => void;
};

const PageContext = createContext<TState>({
  settings: {} as getSettingsResponse,
  isLoading: false,
  openEditSettingModal: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { openModal } = useModalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<getSettingsResponse>();

  const { call } = useAPI();

  const reloadPage = () => {
    setIsLoading(true);
    call(AdminService.getSettings()).then((res) => {
      setSettings(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    reloadPage();
  }, []);

  const openEditSettingModal = (e: React.MouseEvent<SVGElement>, setting: getSettingsResponseSetting) => {
    e.stopPropagation();
    openModal(ModalNamesEnum.AdminEditSetting, {
      setting,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  return (
    <PageContext.Provider
      value={{
        settings,
        isLoading,
        openEditSettingModal,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
