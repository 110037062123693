import { Button } from '@components/form-elements/buttons/Button';
import { HeaderSwitchWrapper } from '@components/Header/components/HeaderSwitchWrapper';

type TProps = {
  tab: string;
  setTab: (v: string) => void;
};

export const Tabs = ({ tab, setTab }: TProps) => {
  return (
    <HeaderSwitchWrapper key="tabSwitcher">
      <Button
        onClick={() => {
          setTab('words');
        }}
        className={`button ${tab === 'words' ? 'active' : ''}`}
      >
        By Words
      </Button>
      <Button
        onClick={() => {
          setTab('lines');
        }}
        className={`button ${tab === 'lines' ? 'active' : ''}`}
      >
        By Lines
      </Button>
    </HeaderSwitchWrapper>
  );
};
