import { useState } from 'react';

import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input';

import ErrorIcon from './icons/error.svg';
import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const PhoneInput = ({
  icon: Icon,
  label,
  onChange,
  onIconClick,
  value = '',
  placeholder = '',
  required = false,
  disabled = false,
  error = false,
  className = 'form-input',
  defaultCountry = 'US',
  handleBlur,
  size,
}: TProps) => {
  const defaultTitle = `${label ?? placeholder}${required ? ' *' : ''}`;
  const placeholderTitle = placeholder ? placeholder : defaultTitle;
  const [title, setTitle] = useState(defaultTitle);

  const onFocus = () => {
    setTitle(placeholderTitle);
  };

  const onBlur = () => {
    setTitle(defaultTitle);
    handleBlur && handleBlur();
  };

  const renderIcon = () => {
    if (!Icon) {
      return null;
    }
    if (error) {
      return <ErrorIcon onClick={onIconClick} className={`icon${onIconClick ? ' clickable' : ''}`} />;
    }
    return <Icon onClick={onIconClick} className={`icon${onIconClick ? ' clickable' : ''}`} />;
  };

  return (
    <Wrapper className={className}>
      <Input
        initialValueFormat="national"
        international={false}
        className={`input ${Icon || error ? 'with-icon' : ''} ${error ? 'error' : ''}`}
        onChange={(value) => onChange((value || '').toString())}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultCountry={defaultCountry}
        name="phone"
        placeholder={title}
        value={value}
        disabled={disabled}
        required={required}
        size={size}
      />
      {renderIcon()}
    </Wrapper>
  );
};
