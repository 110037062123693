import styled from 'styled-components';

import { TBillingAccount } from 'app/types/entities/TBillingAccount';

const Wrapper = styled.div`
  width: 210px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  .subscriptions-card {
    color: #858dbd;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
  }

  .content {
    padding: 20px 20px 10px;
  }

  .description {
    padding: 10px 0 0;
    color: #858dbd;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.42px;
  }

  .buttonContainer {
    height: 40px;
    width: calc(100% - 40px);
    margin: 0 20px;
    display: block;
    bottom: 0;
    position: absolute;
    border-top: 1px solid #d5ddf2;

    button {
      border-radius: 10px;
      padding: 0;
      height: 40px;
      width: 100%;
      color: #7cb1fe;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

type TProps = {
  billingAccount: TBillingAccount;
  onChange?: () => void;
  className?: string;
  hasPaymentMethod?: boolean;
};

export const NoSubscriptionCard = ({ className = '' }: TProps) => {
  return (
    <Wrapper className={className}>
      <div className="content">
        <h2 className="subscriptions-card">Subscription</h2>
        <p className="description">You currently do not have any active subscriptions</p>
      </div>
    </Wrapper>
  );
};
