import { useEffect, useState } from 'react';
import { dateToXAgo } from '@helpers/dateToXAgo';

type TProps = {
  date: Date;
};

export const TickingDateAgo = ({ date }: TProps) => {
  const [value, setValue] = useState(dateToXAgo(date));
  useEffect(() => {
    const now = Date.now();
    const diff = Math.floor((now - new Date(date).getTime()) / 1000);
    let timeout = 1000;
    // More than 7 days ago
    if (diff > 7 * 24 * 60 * 60) {
      return;
    }
    // More than 1 day ago
    if (diff > 24 * 60 * 60) {
      timeout = 24 * 60 * 60 * 1000;
    }
    // More than hour ago
    if (diff > 60 * 60) {
      timeout = 60 * 60 * 1000;
    }
    // More than minute ago
    if (diff > 60) {
      timeout = 60 * 1000;
    }
    const interval = setInterval(() => setValue(dateToXAgo(date)), timeout);
    return () => clearInterval(interval);
  }, []);

  return <span className="ticking-date-ago">{value}</span>;
};
