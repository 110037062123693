import { client } from 'app/http';

export const getMembers = async (
  page = 1,
  pageSize = 25,
  orderBy = 'createdAt',
  orderType = 'asc',
  search = '',
  orgId = '',
) => {
  try {
    const response = await client.get(
      `/organizations/${orgId}/members?pageNo=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${encodeURIComponent(
        search,
      )}`,
    );
    return response.data;
  } catch (err) {
    console.log('getMembers request failed:', err);
    return [];
  }
};
