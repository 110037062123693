import { Fragment } from 'react';
import { useNavigate } from 'react-router';

import { useAuthContext } from '@providers/AuthProvider';
import { routes } from '@routes';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { GenericPageError } from '@components/GenericPageError';
import { useModalContext } from '@providers/ModalProvider';

import { ErrorClickableSpan } from './styles/ErrorClickableSpan';
import { TProps } from './types/TProps';

export function UnlockModalErrors({
  price,
  onCancel,
  dataLoaded,
  onSuccess,
  jobId,
  jobName,
  isSubmitting,
  paymentMethodId,
}: TProps) {
  const { openModal } = useModalContext();
  const { organization } = useAuthContext();

  const navigate = useNavigate();

  const balance = organization?.billingAccount?.currentBalance ?? 0;
  const notEnoughFunds = (price?.amount ?? 0) > balance;
  const cannotManageBilling = !organization?.permissions?.manageBilling;
  const cannotViewBilling = !organization?.permissions?.viewBilling;
  const noBillingAccount = !organization?.billingAccount?.id;

  const openAddFundsModal = () => {
    openModal(ModalNamesEnum.TopUpCreditBalance, {
      billingAccountId: organization?.billingAccount?.id,
      onCancel,
      initialAmount: Math.round(((price?.amount ?? 0) - balance) / 100),
      onSuccess: () => {
        openModal(ModalNamesEnum.UnlockJob, {
          onCancel,
          onSuccess,
          id: jobId,
          name: jobName,
        });
      },
    });
  };

  const openAddPaymentMethodModal = () => {
    openModal(ModalNamesEnum.AddPaymentMethod, {
      billingAccountId: organization?.billingAccount?.id,
      onCancel,
      onSuccess: () => {
        openModal(ModalNamesEnum.UnlockJob, {
          onCancel,
          onSuccess,
          id: jobId,
          name: jobName,
        });
      },
    });
  };

  const loading = isSubmitting || !dataLoaded;

  let showContactBilling = false;
  let showContactPaymentMethod = false;
  let showContactBalance = false;
  let showNoBilling = false;
  let showNoPaymentMethod = false;
  let showNoBalance = false;
  if (!loading && (cannotManageBilling || cannotViewBilling)) {
    if (noBillingAccount) {
      showContactBilling = true;
    } else if (!paymentMethodId) {
      showContactPaymentMethod = true;
    } else if (notEnoughFunds) {
      showContactBalance = true;
    }
  } else if (!loading) {
    if (noBillingAccount) {
      showNoBilling = true;
    } else if (!paymentMethodId) {
      showNoPaymentMethod = true;
    } else if (notEnoughFunds) {
      showNoBalance = true;
    }
  }

  // If there is not enough available credit
  // AND the user does not have manage billing permissions
  // then display a message to ask the user to contact their organization administrators
  const contactAdminToSetBillingAccountMessage =
    'Please contact Organization Administrator to set up the Billing Account for the Organization';
  const contactAdminToTopUpBalanceMessage = 'Please contact Organization Administrator to top up the account balance';
  const contactAdminToSetPaymentMethodMessage =
    'Please contact Organization Administrator to set up the payment method';

  const setBillingAccountMessage = (
    <Fragment>
      Please&nbsp;
      <ErrorClickableSpan onClick={() => navigate(routes.organizationDetails.make(organization?.slug ?? ''))}>
        set up the Billing Account
      </ErrorClickableSpan>
      &nbsp; for the Organization
    </Fragment>
  );
  const topUpBalanceMessage = (
    <Fragment>
      Your balance is insufficient.&nbsp;
      <ErrorClickableSpan onClick={() => openAddFundsModal()}>Add Funds</ErrorClickableSpan> to continue.
    </Fragment>
  );
  const setPaymentMethodMessage = (
    <Fragment>
      Please&nbsp;
      <ErrorClickableSpan onClick={() => openAddPaymentMethodModal()}>set up a payment method</ErrorClickableSpan>
    </Fragment>
  );

  if (showContactBilling) {
    return <GenericPageError message={contactAdminToSetBillingAccountMessage} />;
  }
  if (showContactBalance) {
    return <GenericPageError message={contactAdminToTopUpBalanceMessage} />;
  }
  if (showContactPaymentMethod && notEnoughFunds) {
    return <GenericPageError message={contactAdminToSetPaymentMethodMessage} />;
  }

  if (showNoBilling) {
    return <GenericPageError message={setBillingAccountMessage} />;
  }
  if (showNoBalance) {
    return <GenericPageError message={topUpBalanceMessage} />;
  }
  if (showNoPaymentMethod && notEnoughFunds) {
    return <GenericPageError message={setPaymentMethodMessage} />;
  }

  return null;
}
