export enum ETRANSACTION_CHARGE_CODES {
  STT_CHARGE = 'GL1000',
  FREE_CREDIT_BALANCE_CHARGE = 'GL1001',
  REALTIME_STT_CHARGE = 'GL2000',
  PARTIAL_REALTIME_STT_CHARGE = 'GL2001',
  JOB_UNLOCK_CHARGE = 'GL2002',
  ATTORNEY_CONNECT_FEE = 'GL3000',
  SCOPIST_CONNECT_FEE = 'GL2100',
  GOODWILL_CREDIT = 'GL4000',
  ADMIN_TRAINING = 'GL4001',
  ADMIN_CREDIT_ADJUSTMENT = 'GL4002',
  ADMIN_FREE_CREDIT_ADJUSTMENT = 'GL4003',
  ADD_FUNDS_MANUAL = 'GL5000',
  ADMIN_REFUND = 'GL5001',
  ACCOUNT_CLOSURE = 'GL5002',
  ADMIN_ACCOUNT_CLOSED_FREE_CREDIT = 'GL5003',
  SALES_PROMOTION = 'GL6000',
  REFUND = 'GL6666',
  REFUND_PARTIAL_REALTIME_STT_CHARGE = 'GL6667',
  REFUND_JOB_UNLOCK_CHARGE = 'GL6668',
  REFUND_FREE_CREDIT = 'GL6250',
  ADD_FUNDS = 'GL7000',
  BILLING_ACCOUNT_MIGRATION = 'GL8000',
  FREE_JOBS_CREDIT = 'GL8001',
  SIGN_UP_CREDIT = 'GL9000',
}
