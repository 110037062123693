import { Header } from '@components/Header';
import { CustomTable } from '@components/Table';
import { TrashBin } from '@components/icons/TrashBin';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { Tabs } from '@pages/Admin/SMSCountries/components/Content/components/Tabs';
import { usePageContext } from '@pages/Admin/SMSWhitelist/providers/PageProvider';
import { TSMSWhitelist } from '@pages/Admin/SMSWhitelist/types/TSMSWhitelist';

import { Wrapper } from './styles/Wrapper';
import { CellContent } from './styles/CellContent';
import { DeleteButton } from './styles/DeleteButton';

export const Content = () => {
  const { smsWhitelist, isLoading, createWhitelist, removeWhitelist } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>#</td>,
      render: (data: TSMSWhitelist, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => <td>Number</td>,
      render: (data: TSMSWhitelist) => <CellContent>{data.number}</CellContent>,
    },
    {
      headComponent: () => <td>Actions</td>,
      render: (data: TSMSWhitelist) => (
        <CellContent>
          <DeleteButton title="Remove" onClick={() => removeWhitelist(data.number)}>
            <TrashBin />
          </DeleteButton>
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Header
        title={'SMS Phone number whitelist'}
        buttonLabel={'Add new phone number'}
        buttonOnClick={() => createWhitelist()}
        leftSideContent={[<Tabs key="SMSTabs" currentTab="SMS Whitelist" />]}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={smsWhitelist} />
      </Wrapper>
    </>
  );
};
