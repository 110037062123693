export const formatCentsPrice = (priceInCents: number) => {
  if (priceInCents < 0) {
    return `-$${numberWithCommas(((priceInCents * -1) / 100).toFixed(2))}`;
  }
  return `$${numberWithCommas((priceInCents / 100).toFixed(2))}`;
};

function numberWithCommas(x: string) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
