import React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0104 11.1109C21.5962 10.5251 21.5962 9.57539 21.0104 8.9896C20.4247 8.40381 19.4749 8.40381 18.8891 8.9896L15 12.8787L11.111 8.98959C10.5252 8.4038 9.57542 8.4038 8.98963 8.98959C8.40385 9.57537 8.40385 10.5251 8.98963 11.1109L12.8787 15L8.98963 18.8891C8.40385 19.4749 8.40385 20.4246 8.98963 21.0104C9.57542 21.5962 10.5252 21.5962 11.111 21.0104L15 17.1213L18.8891 21.0104C19.4749 21.5962 20.4247 21.5962 21.0105 21.0104C21.5962 20.4246 21.5962 19.4749 21.0104 18.8891L17.1214 15L21.0104 11.1109Z"
      fill="white"
    />
  </svg>
);
