export const UserIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8772 16.4381C17.4184 15.3513 16.7525 14.364 15.9167 13.5314C15.0834 12.6963 14.0955 12.0298 13.009 11.5701L13.0016 11.5669L12.9904 11.5625C12.9861 11.5608 12.981 11.5586 12.9751 11.556C12.9709 11.5541 12.9664 11.5519 12.9616 11.5495L12.5186 11.328L12.9201 11.038C14.4106 9.96143 15.3787 8.20861 15.3787 6.23096C15.3787 2.95443 12.7241 0.299805 9.44755 0.299805C6.17102 0.299805 3.51639 2.95443 3.51639 6.23096C3.51639 8.20855 4.48447 9.96153 5.97522 11.0407L6.37595 11.3308L5.93347 11.5521C5.92208 11.5577 5.91208 11.562 5.90468 11.565L5.89374 11.5693L5.88577 11.5727C4.79608 12.0324 3.81855 12.6913 2.97908 13.5332C2.14396 14.3666 1.47759 15.3543 1.0178 16.4408C0.579262 17.4735 0.336785 18.5789 0.302734 19.6998H1.61303C1.68817 17.7194 2.49532 15.867 3.90376 14.4585C5.38463 12.9777 7.35289 12.1621 9.44755 12.1621C11.5422 12.1621 13.5105 12.9777 14.9913 14.4585C16.3998 15.867 17.2069 17.7194 17.2821 19.6998H18.5922C18.5565 18.5719 18.3166 17.4751 17.8772 16.4381ZM6.18014 9.49837C7.05292 10.3712 8.21463 10.8526 9.44755 10.8526C10.6805 10.8526 11.8422 10.3712 12.715 9.49837C13.5877 8.62558 14.0692 7.46388 14.0692 6.23096C14.0692 4.99804 13.5877 3.83634 12.715 2.96355C11.8422 2.09077 10.6805 1.60935 9.44755 1.60935C8.21463 1.60935 7.05292 2.09077 6.18014 2.96355C5.30735 3.83634 4.82594 4.99804 4.82594 6.23096C4.82594 7.46388 5.30735 8.62558 6.18014 9.49837Z"
        fill="#858DBD"
      />
      <path
        d="M5.76728 11.2604C5.76085 11.2557 5.75444 11.2509 5.74803 11.2462L5.74181 11.2416C5.73665 11.2378 5.73149 11.2339 5.72633 11.2301C5.73994 11.2402 5.75359 11.2503 5.76728 11.2604Z"
        fill="#858DBD"
      />
    </svg>
  );
};
