import styled from 'styled-components';

import { TSubscription } from 'app/types/entities/TSubscription';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';

import { ActiveCard } from './Cards/ActiveCard';
import { TrialingCard } from './Cards/TrialingCard';
import { CanceledCard } from './Cards/CanceledCard';
import { PaymentFailedCard } from './Cards/PaymentFailedCard';
import { routes } from '@routes';
import { useNavigate } from 'react-router';

const Wrapper = styled.div`
  width: 210px;
  height: 200px;
  display: flex;
  background: transparent;
  cursor: pointer;

  .divider {
    margin: 10px 0;
  }

  h2.title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #858dbd;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #878787;
  }
`;

type TProps = {
  subscription: TSubscription;
  plan: TSubscriptionProduct;
  reloadPage?: () => void;
};

export const SubscriptionCard = ({ subscription, plan, reloadPage }: TProps) => {
  const navigate = useNavigate();

  const openSubscriptionPage = () => {
    navigate(routes.adminAutoScriptOneSubscriptionDetails.make(subscription.id));
  };

  // Todo: we need to do something here. Coz this is not a failed state
  if (subscription.status === 'incomplete' || subscription.status === 'incomplete_expired') {
    return (
      <Wrapper onClick={openSubscriptionPage}>
        <PaymentFailedCard plan={plan} />
      </Wrapper>
    );
  }

  if (subscription.status === 'canceled') {
    return (
      <Wrapper onClick={openSubscriptionPage}>
        <CanceledCard plan={plan} />
      </Wrapper>
    );
  }

  if (subscription.status === 'past_due' || subscription.status === 'unpaid') {
    return (
      <Wrapper onClick={openSubscriptionPage}>
        <PaymentFailedCard plan={plan} />
      </Wrapper>
    );
  }

  if (subscription.status === 'trialing') {
    return (
      <Wrapper onClick={openSubscriptionPage}>
        <TrialingCard plan={plan} subscription={subscription} reload={reloadPage || (() => {})} />
      </Wrapper>
    );
  }

  // Default is Active
  return (
    <Wrapper onClick={openSubscriptionPage}>
      <ActiveCard plan={plan} subscription={subscription} reload={reloadPage || (() => {})} />
    </Wrapper>
  );
};
