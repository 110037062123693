import styled from 'styled-components';

export const StyledHeader = styled.header`
  height: 96px;
  width: 0;

  .header-wrapper {
    height: 96px;
    width: calc(100% - 240px);
    padding: 0 30px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    flex: 0 0 auto;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    grid-gap: 25px;
    z-index: 19;
  }

  @media (max-width: 1060px) {
    width: auto;
    .header-wrapper {
      width: auto;
      position: relative;
    }
  }

  .left-side,
  .right-side {
    display: flex;
    align-items: center;
    grid-gap: 25px;
  }

  .button-wrapper {
    position: relative;
    left: -10px;
    padding: 10px;
    display: flex;
    gap: 10px;

    button {
      flex: 0 0 auto;
      text-align: center;
      width: fit-content;

      .icon-content {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }
    }
  }

  input {
    padding: 5px 16px;
    height: 30px;
    min-width: 50%;
  }
`;
