import styled from 'styled-components';

type StyledCheckBoxProps = {
  rounded?: boolean;
  disabled?: boolean;
};

export const Wrapper = styled.div<StyledCheckBoxProps>`
  /* The container */
  display: block;
  position: relative;
  padding-left: 25px;
  height: 20px;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: ${(props) => (props.disabled ? '#E4EBF5' : '#fff')};
    border: 1px solid #cadcf8;
    border-radius: ${(props) => (props.rounded ? '50%' : '5px')};
  }

  /* On mouse-over, add a grey background color */

  &:hover input ~ .checkmark {
    background-color: ${(props) => (props.disabled ? '#E4EBF5' : '#cadcf8')};
  }

  /* When the checkbox is checked, add a blue background */

  input:checked ~ .checkmark {
    background-color: ${(props) => (props.disabled ? '#E4EBF5' : '#858dbd')};
  }

  /* Create the checkmark/indicator (hidden when not checked) */

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */

  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */

  .checkmark:after {
    left: 4.5px;
    top: 2px;
    width: 7px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
