import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';

import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { Button } from '@components/form-elements/buttons/Button';
import ASOneDescriptionBlock from './Blocks/ASOneDescriptionBlock';
import RSProDescriptionBlock from './Blocks/RSProDescriptionBlock';

import { SubscriptionCard } from './SubscriptionCard';
import { useAuthContext } from '@providers/AuthProvider';
import { getHourPrice } from '@helpers/getHourPrice';
import { getBillingAccount } from '@queries/billing-account/getBillingAccount';
import { TSubscriptionProduct } from 'app/types/entities/TSubscriptionProduct';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { BillingAccountService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const FreeSeatsDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.01em;
  text-align: left;
`;
const Wrapper = styled.div`
  background: #f8fafb;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 570px;
  position: relative;
  padding: 30px;
  padding-bottom: 0;

  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;

  .cardTitle {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  & .cardContainer {
    display: flex;
    min-width: 100%;
    width: max-content;
    grid-gap: 20px;
    height: 285px;
    padding: 18px 0 0;
  }

  & .card {
    position: relative;

    &.free-seats h3 {
      text-decoration: line-through #b4b9d9;
      color: #b4b9d9;
    }
  }

  & .scrollContainer {
    overflow-x: auto;
    height: max-content;
    padding: 0 0 15px;
    margin: 0 0 20px;
    border-bottom: 1px solid #d5ddf2;
  }

  & .descriptionBlock {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #40608f;

    & ul {
      padding-left: 18px;
      list-style: disc;
    }

    & li {
      padding: 5px 5px 5px 0;
    }
  }

  .buttonModalContainer {
    display: flex;
    position: sticky;
    bottom: 0;
    left: 0;
    background: #f8fafb;
    padding: 20px 0 30px;
    justify-content: space-between;

    width: 100%;

    & button {
      width: max-content;
    }
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: (planDetails: {
    subscriptionProductId: string;
    planName: string;
    price: number;
    freeSeats: number;
  }) => void;
  billingAccountId: string;
  freeSeats: number;
};

export type SubscriptionTypes = 'AutoScriptOne' | 'ReporterStudioPro';

export const ChooseASOneSubscriptionModal = ({ onCancel, onSuccess, billingAccountId }: TProps) => {
  const { call } = useAPI();
  const { openModal } = useModalContext();
  const { updateMe } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  // const [subscriptionType, setSubscriptionType] = useState<SubscriptionTypes>('AutoScriptOne');
  const [subscriptionType] = useState<SubscriptionTypes>('AutoScriptOne');
  const [allPlans, setAllPlans] = useState<TSubscriptionProduct[]>([]);
  const [plans, setPlans] = useState<TSubscriptionProduct[]>([]);
  // const [otherPlans, setOtherPlans] = useState<TSubscriptionProduct[]>([]);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setPlans([]);
      const billingAccount = await getBillingAccount(billingAccountId);
      setBillingAccount(billingAccount);
      const allPlans = await call(BillingAccountService.getAsOnePlans());
      setAllPlans(allPlans);
      setPlans(allPlans.filter((plan: any) => plan.subscriptionProductCategory === subscriptionType));
      // setOtherPlans(allPlans.filter((plan: any) => plan.subscriptionProductCategory !== subscriptionType));
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setPlans(allPlans.filter((plan) => plan.subscriptionProductCategory === subscriptionType));
    // setOtherPlans(allPlans.filter((plan: any) => plan.subscriptionProductCategory !== subscriptionType));
  }, [subscriptionType]);

  const showContactSalesModal = () => {
    openModal(ModalNamesEnum.ContactSales);
  };

  if (!billingAccount?.id) {
    return <></>;
  }

  const selectPlan = async (id: string) => {
    await updateMe();
    const plan = plans.find((item) => item.id === id);

    onSuccess({
      subscriptionProductId: id,
      planName: plan?.name ?? '',
      price: plan?.price ?? 0,
      freeSeats: plan?.freeSeats || 0,
    });
  };

  const activeIds = (billingAccount?.subscriptions ?? [])
    .filter((sub) => {
      return sub.status === 'active';
    })
    .map((sub) => {
      return sub.productId;
    });
  const activePlans = plans.filter((plan) => {
    return activeIds.indexOf(plan.id) > -1;
  });
  const inactivePlans = plans.filter((plan) => {
    return activeIds.indexOf(plan.id) === -1;
  });

  const cardTitle = subscriptionType === 'AutoScriptOne' ? 'AutoScript One' : 'Reporter Studio Pro';

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2 className="cardTitle">{cardTitle} Pricing Plans</h2>

      {/* {otherPlans.length > 0 && <Tabs currentTab={subscriptionType} onChange={setSubscriptionType} />}*/}

      <div className="scrollContainer">
        <div className="cardContainer">
          {activePlans.map((item) => {
            const descriptions = item.description.split('<newLine>');
            return (
              <div key={item.id} className="card">
                <SubscriptionCard
                  title={item.name.replace('AutoScriptOne ', '')}
                  subTitle={getHourPrice(item.capacityInSeconds, item.price)}
                  descriptions={descriptions}
                  verticalDescription
                  buttonLabel="Choose Plan"
                  buttonVariant="text"
                  chosen={true}
                  buttonSize="medium"
                  buttonOnClick={() => selectPlan(item.id)}
                />
              </div>
            );
          })}
          {inactivePlans.map((item) => {
            let descriptions: (string | React.ReactNode)[] = item.description.split('<newLine>');
            if (item.freeSeats) {
              descriptions = [
                <FreeSeatsDescription key={item.id}>
                  For <b>BETA</b> users, first {item.freeSeats} licenses are free for 2024. You only pay for the
                  Realtime speech to text per your plan rate.
                </FreeSeatsDescription>,
              ];
            }
            return (
              <div key={item.id} className={`card ${item.freeSeats ? 'free-seats' : ''}`}>
                <SubscriptionCard
                  title={item.name.replace('AutoScriptOne ', '')}
                  subTitle={getHourPrice(item.capacityInSeconds, item.price)}
                  descriptions={descriptions}
                  verticalDescription
                  buttonLabel="Choose Plan"
                  buttonVariant="text"
                  chosen={false}
                  buttonSize="medium"
                  buttonOnClick={() => selectPlan(item.id)}
                />
              </div>
            );
          })}
        </div>
      </div>

      {subscriptionType === 'AutoScriptOne' ? <ASOneDescriptionBlock /> : <RSProDescriptionBlock />}

      <div className="buttonModalContainer">
        <Button variant="bold" onClick={showContactSalesModal}>
          Contact Our Sales Team
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Wrapper>
  );
};
