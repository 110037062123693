import { createContext, useContext, useEffect, useState } from 'react';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TTransaction } from 'app/types/entities/TTransaction';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { downloadCSV } from '@helpers/downloadCSV';

import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TState = {
  transactions: TPaginatedResponse<TTransaction>;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  filter: any;
  setFilter: (arg: any) => void;
  sortingField: string;
  setSortingField: (v: string) => void;
  sortingReverse: boolean;
  setSortingReverse: (v: boolean) => void;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  page: number;
  setPage: (v: number) => void;
  anchorEl: null | HTMLElement;
  setAnchorEl: (v: null | HTMLElement) => void;
  onClose: () => void;
  reloadPage: () => void;
  exportCSV: () => void;
};

const PageContext = createContext<TState>({
  transactions: {
    data: [],
    count: 0,
  },
  isLoading: false,
  setIsLoading: () => null,
  filter: {},
  setFilter: () => null,
  sortingField: 'createdAt',
  setSortingField: () => null,
  sortingReverse: true,
  setSortingReverse: () => null,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  page: 1,
  setPage: () => null,
  anchorEl: null,
  setAnchorEl: () => null,
  onClose: () => null,
  reloadPage: () => null,
  exportCSV: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [transactions, setTransactions] = useState<TPaginatedResponse<TTransaction>>({
    data: [],
    count: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<TJobsFilter>({
    from: undefined,
    to: undefined,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const reloadPage = async () => {
    setIsLoading(true);
    const fromParam = Math.floor(Number(filter?.from ?? null) / 1000) || '';
    const toParam = Math.floor(Number(filter?.to ?? null) / 1000) || '';
    const transactions = await call(
      AdminService.getTransactionsList({
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search,
        from: fromParam.toString(),
        to: toParam.toString(),
      }),
    );
    setTransactions(transactions);
    setIsLoading(false);
  };

  useEffect(() => {
    reloadPage();
  }, [page, pageSize, sortingField, sortingReverse, search]);

  const onClose = () => {
    setAnchorEl(null);
  };

  const exportCSV = async () => {
    const fromParam = Math.floor(Number(filter?.from ?? null) / 1000) || '';
    const toParam = Math.floor(Number(filter?.to ?? null) / 1000) || '';
    const transactionsForExport = await call(
      AdminService.getTransactionsList({
        pageNo: '1',
        pageSize: transactions.count.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search,
        from: fromParam.toString(),
        to: toParam.toString(),
      }),
    );
    const headers = [
      'ID',
      'Created',
      'Title',
      'Charge ID',
      'Organization',
      'Organization Slug',
      'Organization ID',
      'Amount',
      'Transaction Code',
      'Type',
      'Receipt Url',
      'Associated User',
      'Associated Job',
    ];

    const array = transactionsForExport.data.map((data: TTransaction) => {
      return {
        id: data.id,
        created: getAmericanTime(data.createdAt),
        title: data.title,
        chargeId: data.stripeChargeId ?? data.id,
        organization: data.organization?.name ?? '',
        organizationSlug: data.organization?.slug ?? '',
        organizationId: data.organization?.id ?? '',
        amount: formatCentsPrice(data.amount ?? 0),
        code: data.codeId,
        type: data.code.name,
        receiptUrl: data.stripeReceiptUrl ?? '',
        associatedUser: data.userResponsibleEmail ?? '',
        associatedJob: data.jobId ?? '',
      };
    });
    return downloadCSV(array, headers);
  };

  return (
    <PageContext.Provider
      value={{
        transactions,
        isLoading,
        setIsLoading,
        filter,
        setFilter,
        sortingField,
        setSortingField,
        sortingReverse,
        setSortingReverse,
        search,
        setSearch,
        pageSize,
        setPageSize,
        page,
        setPage,
        anchorEl,
        setAnchorEl,
        onClose,
        reloadPage,
        exportCSV,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
