import styled from 'styled-components';

export const InfoBlockTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #858dbd;
  padding: 0 0 10px;
`;
