import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { CustomTable } from '@components/Table';
import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { usePageContext } from '../../providers/PageProvider';

import { Wrapper } from './styles/Wrapper';
import { Header } from '@components/Header';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';
import { useParams } from 'react-router';
import { Done } from '@components/icons/Done';

export const Content = () => {
  const { isLoading, students, reloadPage } = usePageContext();
  const { openModal } = useModalContext();
  const params = useParams() as { id?: string };

  const createStudent = () => {
    openModal(ModalNamesEnum.AddCourseStudent, {
      courseId: params.id,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  const tableHead = [
    {
      headComponent: () => <td>ID</td>,
      render: (data: any) => <p className="cell">{data?.id}</p>,
    },
    {
      headComponent: () => <td>Name</td>,
      render: (data: any) => <p className="cell">{data?.name}</p>,
    },
    {
      headComponent: () => <td>Created</td>,
      render: (data: any) => {
        return <p className="cell">{getAmericanTime(data.created_at)}</p>;
      },
    },
    {
      headComponent: () => <td>Login</td>,
      render: (data: any) => {
        return <p className="cell">{data.login_id}</p>;
      },
    },
    {
      headComponent: () => <td>ASW Registered</td>,
      render: (data: any) => {
        return <p className="cell">{data.registeredInASW ? <Done width="16px" /> : null}</p>;
      },
    },
    {
      headComponent: () => <td>Farthest license expires</td>,
      render: (data: any) => {
        return (
          <p className="cell">{data.farthestExpirationDate ? getAmericanTime(data.farthestExpirationDate) : null}</p>
        );
      },
    },
  ];

  return (
    <>
      <Header title={'Canvas Course Students List'} buttonLabel={'Add student'} buttonOnClick={() => createStudent()} />
      <Wrapper>
        <LinearProgressLoader active={isLoading} />
        <CustomTable data={students ?? []} head={tableHead} />
      </Wrapper>
    </>
  );
};
