import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { Search as SearchIcon } from '@components/icons/Search';

import { Wrapper } from './styles/Wrapper';

export const Search = () => {
  const { openModal } = useModalContext();

  return (
    <Wrapper>
      <SearchIcon onClick={() => openModal(ModalNamesEnum.SearchModal)} />
    </Wrapper>
  );
};
