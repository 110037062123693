import React from 'react';

export const QuestionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0ZM9 1.8C7.09044 1.8 5.25909 2.55857 3.90883 3.90883C2.55857 5.25909 1.8 7.09044 1.8 9C1.8 10.9096 2.55857 12.7409 3.90883 14.0912C5.25909 15.4414 7.09044 16.2 9 16.2C10.9096 16.2 12.7409 15.4414 14.0912 14.0912C15.4414 12.7409 16.2 10.9096 16.2 9C16.2 7.09044 15.4414 5.25909 14.0912 3.90883C12.7409 2.55857 10.9096 1.8 9 1.8ZM9 12.6C9.23869 12.6 9.46761 12.6948 9.6364 12.8636C9.80518 13.0324 9.9 13.2613 9.9 13.5C9.9 13.7387 9.80518 13.9676 9.6364 14.1364C9.46761 14.3052 9.23869 14.4 9 14.4C8.7613 14.4 8.53239 14.3052 8.3636 14.1364C8.19482 13.9676 8.1 13.7387 8.1 13.5C8.1 13.2613 8.19482 13.0324 8.3636 12.8636C8.53239 12.6948 8.7613 12.6 9 12.6ZM9 4.05C9.75805 4.05002 10.4924 4.31402 11.077 4.79664C11.6616 5.27926 12.0598 5.95038 12.2034 6.69472C12.3469 7.43906 12.2268 8.21015 11.8636 8.87554C11.5004 9.54093 10.9169 10.0591 10.2132 10.341C10.1095 10.3804 10.0158 10.4421 9.9387 10.5219C9.8991 10.5669 9.8928 10.6245 9.8937 10.6839L9.9 10.8C9.89974 11.0294 9.81191 11.25 9.65444 11.4168C9.49696 11.5836 9.28174 11.684 9.05274 11.6975C8.82375 11.7109 8.59826 11.6364 8.42235 11.4892C8.24644 11.3419 8.13339 11.1331 8.1063 10.9053L8.1 10.8V10.575C8.1 9.5373 8.937 8.9145 9.5436 8.6706C9.79048 8.57201 10.0058 8.40804 10.1666 8.19629C10.3273 7.98453 10.4273 7.733 10.4558 7.4687C10.4844 7.2044 10.4404 6.93731 10.3286 6.69613C10.2168 6.45494 10.0414 6.24876 9.82128 6.09973C9.60115 5.9507 9.34457 5.86445 9.07911 5.85024C8.81366 5.83603 8.54934 5.8944 8.31455 6.01908C8.07977 6.14376 7.88338 6.33004 7.74647 6.55792C7.60957 6.7858 7.53733 7.04666 7.5375 7.3125C7.5375 7.55119 7.44268 7.78011 7.2739 7.9489C7.10511 8.11768 6.87619 8.2125 6.6375 8.2125C6.39881 8.2125 6.16989 8.11768 6.0011 7.9489C5.83232 7.78011 5.7375 7.55119 5.7375 7.3125C5.7375 6.44723 6.08123 5.6174 6.69306 5.00556C7.3049 4.39373 8.13473 4.05 9 4.05Z"
      fill="#858DBD"
    />
  </svg>
);
