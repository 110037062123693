import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';

import { AdminIcon } from './icons/admin';
import { StyledAdminLink } from './styles/StyledAdminLink';

export const AdminLink = () => {
  const { me } = useAuthContext();

  if (!me) {
    return null;
  }

  const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;
  if (!isVsAdmin) {
    return null;
  }
  return (
    <StyledAdminLink title="Admin Panel" to={routes.adminDashboard.make()}>
      <AdminIcon />
    </StyledAdminLink>
  );
};
