import styled from 'styled-components';

export const StyledProgressBarTextWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding: 0 0 0 6px;
  color: #858dbd;
`;
