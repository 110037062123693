import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { CustomTable } from '@components/Table';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { Done } from '@components/icons/Done';
import { TableStyledLink } from '@components/Table/StyledLink';

import { usePageContext } from '../../providers/PageProvider';

import { Wrapper } from './styles/Wrapper';
import { routes } from '@routes';

export const Content = () => {
  const { isLoading, courses } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>Name</td>,
      render: (data: any) => <p className="cell">{data?.name}</p>,
    },
    {
      headComponent: () => <td>Students</td>,
      render: (data: any) => {
        return (
          <p className="cell">
            <TableStyledLink to={routes.adminCanvasCourseStudentsPage.make(data.id)}>
              {data?.total_students || 0}
            </TableStyledLink>
          </p>
        );
      },
    },
    {
      headComponent: () => <td>Created</td>,
      render: (data: any) => {
        return <p className="cell">{getAmericanTime(data.created_at)}</p>;
      },
    },
    {
      headComponent: () => <td>Published</td>,
      render: (data: any) => {
        return <p className="cell">{data.is_public ? <Done width="16px" /> : ''}</p>;
      },
    },
  ];

  return (
    <Wrapper>
      <LinearProgressLoader active={isLoading} />
      <CustomTable data={courses ?? []} head={tableHead} />
    </Wrapper>
  );
};
