import styled from 'styled-components';
import { CustomTable } from '@components/Table';

export const AssigneeTable = styled(CustomTable)`
  width: 100%;
  margin: -10px 0 0;

  thead tr {
    border: none;
  }

  thead td,
  thead th {
    padding: 0;
    height: 40px;
  }
`;
