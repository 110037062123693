export const ErrorIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z"
      fill="#FF9191"
      fillOpacity="0.05"
    />
    <path
      d="M50 30C50 41.0457 41.0457 50 30 50C18.9543 50 10 41.0457 10 30C10 18.9543 18.9543 10 30 10C41.0457 10 50 18.9543 50 30Z"
      fill="#FF9191"
      fillOpacity="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 38.5C34.6944 38.5 38.5 34.6944 38.5 30C38.5 25.3056 34.6944 21.5 30 21.5C25.3056 21.5 21.5 25.3056 21.5 30C21.5 34.6944 25.3056 38.5 30 38.5ZM30 41.5C36.3513 41.5 41.5 36.3513 41.5 30C41.5 23.6487 36.3513 18.5 30 18.5C23.6487 18.5 18.5 23.6487 18.5 30C18.5 36.3513 23.6487 41.5 30 41.5Z"
      fill="#FF9191"
    />
    <path
      d="M28.2325 33.8889C27.6467 34.4747 26.697 34.4747 26.1112 33.8889C25.5254 33.3031 25.5254 32.3534 26.1112 31.7676L27.8789 29.9999L26.1112 28.2322C25.5254 27.6464 25.5254 26.6966 26.1112 26.1108C26.697 25.5251 27.6467 25.5251 28.2325 26.1108L30.0002 27.8786L31.7681 26.1107C32.3539 25.5249 33.3036 25.5249 33.8894 26.1107C34.4752 26.6965 34.4752 27.6463 33.8894 28.232L32.1216 29.9999L33.8894 31.7677C34.4752 32.3535 34.4752 33.3032 33.8894 33.889C33.3036 34.4748 32.3539 34.4748 31.7681 33.889L30.0002 32.1212L28.2325 33.8889Z"
      fill="#FF9191"
    />
  </svg>
);
