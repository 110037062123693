import toast, { Toaster } from 'react-hot-toast';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';

import { addPaymentMethod } from '@queries/billing-account/addPaymentMethod';

import AmexIcon from './icons/amex.svg';
import DiscoverIcon from './icons/discover.svg';
import MastercardIcon from './icons/ms.svg';
import VisaIcon from './icons/visa.svg';

import { Button } from '@components/form-elements/buttons/Button';
import { CheckBox } from '@components/form-elements/CheckBox';

import { TProps } from '../../types/TProps';
import { StripeChangeEvent } from './types/StripeChangeEvent';
import { inputOptions } from './helpers/inputOptions';
import { Wrapper } from './styles/Wrapper';
import { StyledSpinner } from './styles/StyledSpinner';
import { StyledCardNumberElement, StyledCVCElement, StyledExpiredElement } from './styles/StyledInputs';

export const Form = ({ onCancel, onSuccess, shouldShowSaveMethodCheckbox, billingAccountId }: TProps) => {
  const stripe = useStripe();
  const element = useElements();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [savePaymentMethod, setSavePaymentMethod] = useState(!shouldShowSaveMethodCheckbox);
  const [focusedElement, setFocusedElement] = useState('');
  const [stripeError, setStripeError] = useState({
    cardNumber: '',
    cardCvc: '',
    cardExpiry: '',
  });

  const cardNumber = element?.getElement('cardNumber');
  const cardCvc = element?.getElement('cardCvc');
  const cardExpiry = element?.getElement('cardExpiry');

  const validate = (e: StripeChangeEvent) => {
    setStripeError((prev) => ({ ...prev, [e.elementType]: e?.error?.message ?? '' }));
  };

  useEffect(() => {
    cardNumber?.on('change', (e) => validate(e));
    cardCvc?.on('change', (e) => validate(e));
    cardExpiry?.on('change', (e) => validate(e));

    return () => {
      cardNumber?.off('change', (e) => validate(e));
      cardCvc?.off('change', (e) => validate(e));
      cardExpiry?.off('change', (e) => validate(e));
    };
  }, [cardNumber, cardCvc, cardExpiry]);

  const showErrorAndCancel = (error: string) => {
    toast.error(error, {
      duration: 5000,
    });
    setTimeout(() => {
      onCancel();
    }, 5000);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setIsSubmitting(true);
    try {
      setIsSubmitted(true);
      e.preventDefault();

      if (!stripe || isSubmitting) {
        return;
      }

      if (!cardNumber) {
        toast.error('Card number field is empty', {
          duration: 5000,
        });
        return;
      }
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
      });
      if (!paymentMethod.paymentMethod) {
        showErrorAndCancel('Something went wrong, please try again later');
        return;
      }

      if (!savePaymentMethod) {
        onSuccess(paymentMethod.paymentMethod.id);
        return;
      }
      await addPaymentMethod(paymentMethod.paymentMethod.id, 'card', billingAccountId);
      onSuccess(paymentMethod.paymentMethod.id);
    } catch (error: unknown) {
      showErrorAndCancel('Card declined');
    }
  };

  const handleChangeCheckbox = () => {
    setSavePaymentMethod(!savePaymentMethod);
  };

  return (
    <Wrapper>
      <form className="payment-form" onSubmit={handleSubmit}>
        <div>
          <h4>Enter Payment Details</h4>
        </div>

        <div>
          <StyledCardNumberElement
            onFocus={() => setFocusedElement('cardNumber')}
            onBlur={() => setFocusedElement('')}
            focused={focusedElement === 'cardNumber'}
            options={{ placeholder: 'Card Number', ...inputOptions }}
            error={!!stripeError.cardNumber}
            id="cardNumber"
          />
          <div className="flex50percents">
            <StyledExpiredElement
              focused={focusedElement === 'cardExpiry'}
              onFocus={() => setFocusedElement('cardExpiry')}
              onBlur={() => setFocusedElement('')}
              options={{ placeholder: 'Expiry date', ...inputOptions }}
              error={!!stripeError.cardExpiry}
              id="cardExpiry"
            />

            <StyledCVCElement
              focused={focusedElement === 'cardCvc'}
              onFocus={() => setFocusedElement('cardCvc')}
              onBlur={() => setFocusedElement('')}
              options={{ placeholder: 'CVC', ...inputOptions }}
              error={!!stripeError.cardCvc}
              id="cardCvc"
            />
          </div>

          <div className="checkBoxWrapper">
            <CheckBox checked={isAgree} onChange={() => setIsAgree(!isAgree)} />I agree to the{' '}
            <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>

          {shouldShowSaveMethodCheckbox ? (
            <div className="checkBoxWrapper">
              <CheckBox checked={savePaymentMethod} onChange={() => handleChangeCheckbox()} />
              Save this method
            </div>
          ) : null}
        </div>

        <div className="iconsWrapper">
          <AmexIcon alt="amex" />
          <MastercardIcon alt="mastercard" />
          <VisaIcon alt="visa" />
          <DiscoverIcon alt="discover" />
        </div>
        <div className="buttonWrapper">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>

          <Button data-testid="submitButton" type="submit" variant="contained" disabled={!isAgree || isSubmitted}>
            {isSubmitted ? <StyledSpinner /> : 'Submit'}
          </Button>
        </div>
      </form>
      <Toaster position="bottom-right" reverseOrder={false} />
    </Wrapper>
  );
};
