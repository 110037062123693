import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  width: 350px;

  & h2 {
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    svg {
      cursor: pointer;
    }

    .dropdown {
      left: 280px;
      top: 20px;
    }
  }

  & .workspaceSettings-inputWrapper {
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #858dbd;
      margin: -10px 0 10px;
      display: block;
    }
  }

  .inputContainer {
    padding: 0 0 20px;
  }

  input {
    height: 30px;
  }

  .workspaceSettings-errorMessage {
    color: #ff2f2f;
    margin-top: -20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
  }

  .workspaceSettings-infoMessage {
    color: #858dbd;
    text-align: center;
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0;
    button {
      width: 130px;
    }
  }

  .largeText {
    text-align: center;
    margin-top: 0;
    font-size: 16px;
    font-weight: 300;
  }
  & .switcher {
    display: flex;
    margin-bottom: 20px;
  }

  .roleEdit {
    display: flex;
  }
`;

export const DatePickerWrapper = styled.div`
  .react-datepicker {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-names {
    margin-bottom: 0;
  }

  .react-datepicker__day-name {
    font-weight: 500 !important;
    color: #858dbd;
  }

  .react-datepicker__day {
    color: #00122d;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border: 1px solid #40608f;
    border-radius: 28px;
    background: transparent;
    width: 28px !important;
    line-height: 28px !important;
    margin: 3px !important;

    &:hover {
      border-radius: 28px;
      background: transparent;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 34px;
    width: 34px;
    margin: 0;
  }

  .react-datepicker-popper {
    z-index: 15;
  }

  .react-datepicker__triangle::before {
    border-bottom-color: transparent !important;
    border-top-color: transparent !important;
  }

  .react-datepicker__triangle::after {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }

  .react-datepicker__header {
    background: #fff;
    border: none;
    padding: 0;
  }

  .react-datepicker__tab-loop {
    position: fixed;
    z-index: 100;
  }

  .react-datepicker__input-container {
    display: flex;
  }
`;
