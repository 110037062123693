import styled from 'styled-components';
import { Pencil } from '@components/icons/Pencil';

export const PencilIcon = styled(Pencil)`
  margin: 0 0 0 10px;
  cursor: pointer;

  &:hover {
    path {
      fill: #40608f;
    }
  }
`;
