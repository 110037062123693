export const EnhancementButton = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3447 7.4799C12.2486 7.38382 12.0928 7.38382 11.9968 7.4799L10.7021 8.77452L12.2251 10.2974L13.5197 9.00281C13.6158 8.90673 13.6158 8.75096 13.5197 8.65488L12.3447 7.4799ZM11.3304 11.1921L9.80747 9.6692L2.33732 17.1393C2.24124 17.2354 2.24124 17.3912 2.33732 17.4873L3.51231 18.6623C3.60838 18.7583 3.76416 18.7583 3.86024 18.6623L11.3304 11.1921ZM11.1021 6.58522C11.6923 5.99503 12.6492 5.99503 13.2394 6.58522L14.4144 7.76021C15.0046 8.3504 15.0046 9.30729 14.4144 9.89749L4.75491 19.5569C4.16472 20.1471 3.20782 20.1471 2.61763 19.5569L1.44265 18.382C0.852451 17.7918 0.852451 16.8349 1.44265 16.2447L11.1021 6.58522Z"
        fill="#858DBD"
        stroke="#858DBD"
        strokeWidth="0.456579"
      />
      <path
        d="M7.71135 3.78995L5.28827 3.10652L7.71135 2.42309L8.39479 0L9.07822 2.42308L11.5013 3.10652L9.07822 3.78995L8.39478 6.21303L7.71135 3.78995ZM17.1565 13.381L19.5795 14.0644L17.1565 14.7478L16.473 17.1709L15.7896 14.7478L13.3665 14.0644L15.7896 13.381L16.473 10.9579L17.1565 13.381ZM21.4565 4.10922L19.0334 4.79265L18.35 7.21574L17.6666 4.79265L15.2435 4.10922L17.6666 3.42578L18.35 1.0027L19.0334 3.42578L21.4565 4.10922Z"
        fill="#858DBD"
      />
    </svg>
  );
};
