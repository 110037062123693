import React from 'react';

export const FileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9 1V4.6C10.9 4.83869 10.9948 5.06761 11.1636 5.2364C11.3324 5.40518 11.5613 5.5 11.8 5.5H15.4M10.9 1H2.8C2.32261 1 1.86477 1.18964 1.52721 1.52721C1.18964 1.86477 1 2.32261 1 2.8V10.45M10.9 1L15.4 5.5M15.4 5.5V10.45M1 10V17.2C1 17.6774 1.18964 18.1352 1.52721 18.4728C1.86477 18.8104 2.32261 19 2.8 19H13.6C14.0774 19 14.5352 18.8104 14.8728 18.4728C15.2104 18.1352 15.4 17.6774 15.4 17.2V10"
      stroke="#858DBD"
      strokeWidth="1.84"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
