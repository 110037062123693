export const Refund = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="refund">
        <path
          id="Vector"
          d="M19 18V17C19 15.9391 18.5786 14.9217 17.8284 14.1716C17.0783 13.4214 16.0609 13 15 13H11M11 13L14 16M11 13L14 10M23 24V8C23 7.46957 22.7893 6.96086 22.4142 6.58579C22.0391 6.21071 21.5304 6 21 6H9C8.46957 6 7.96086 6.21071 7.58579 6.58579C7.21071 6.96086 7 7.46957 7 8V24L11 22L15 24L19 22L23 24Z"
          stroke="#858DBD"
          strokeWidth="1.84"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
