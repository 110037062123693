import imageCompression from 'browser-image-compression';

export async function compressBase64Image(
  base64String: string,
  options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 800,
    useWebWorker: true,
  },
) {
  const img = new Image();
  img.src = base64String;

  const imageBlobPromise = new Promise((resolve) => {
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      canvas.toBlob((blob) => resolve(blob), 'image/jpeg');
    };
  });

  const imageBlob = await imageBlobPromise;
  const compressedBlob = await imageCompression(imageBlob as File, options);

  const compressedBase64 = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(compressedBlob);
  });

  return compressedBase64 as string;
}
