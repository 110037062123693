import styled from 'styled-components';
import { Button } from '@components/form-elements/buttons/Button';

const Wrapper = styled.div`
  width: 300px;
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  position: relative;

  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #858dbd;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    padding: 20px 0 0;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  email: string;
};

export const ResendInviteModal = ({ onCancel, onSuccess, email }: TProps) => {
  return (
    <Wrapper>
      <h2>Confirm</h2>
      <div className=" text-center">Resend the invitation to {email}?</div>
      <form
        action="#"
        onSubmit={() => {
          onSuccess();
        }}
      >
        <div className="buttonWrapper">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Resend</Button>
        </div>
      </form>
    </Wrapper>
  );
};
