import React from 'react';

export const EmailIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.875 0C17.7038 0 18.4987 0.32924 19.0847 0.915291C19.6708 1.50134 20 2.2962 20 3.125V13.125C20 13.9538 19.6708 14.7487 19.0847 15.3347C18.4987 15.9208 17.7038 16.25 16.875 16.25H3.125C2.2962 16.25 1.50134 15.9208 0.915291 15.3347C0.32924 14.7487 0 13.9538 0 13.125V3.125C0 2.2962 0.32924 1.50134 0.915291 0.915291C1.50134 0.32924 2.2962 0 3.125 0H16.875ZM18.75 4.95125L10.3175 9.91375C10.2378 9.96056 10.1487 9.9891 10.0566 9.99731C9.9645 10.0055 9.87175 9.99321 9.785 9.96125L9.6825 9.91375L1.25 4.95375V13.125C1.25 13.6223 1.44754 14.0992 1.79917 14.4508C2.15081 14.8025 2.62772 15 3.125 15H16.875C17.3723 15 17.8492 14.8025 18.2008 14.4508C18.5525 14.0992 18.75 13.6223 18.75 13.125V4.95125ZM16.875 1.25H3.125C2.62772 1.25 2.15081 1.44754 1.79917 1.79917C1.44754 2.15081 1.25 2.62772 1.25 3.125V3.5025L10 8.65L18.75 3.5V3.125C18.75 2.62772 18.5525 2.15081 18.2008 1.79917C17.8492 1.44754 17.3723 1.25 16.875 1.25Z"
        fill="#858DBD"
      />
    </svg>
  );
};
