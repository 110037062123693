import styled from 'styled-components';
import { TUser } from 'app/types/entities/TUser';

export const AvatarBlock = styled.div`
  flex: 0 0 auto;
  padding: 0 0 10px;
  display: flex;
  gap: 20px;
  align-items: flex-start;
`;
export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
`;
export const AvatarName = styled.div`
  width: 50px;
  height: 50px;
  background: #cadcf8;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  text-transform: capitalize;
`;

export const AvatarContent = ({ user }: { user: TUser }) => {
  if (!user) {
    return null;
  }
  const avatarName = `${user?.name[0]}${user?.lastname[0]}`;
  return (
    <AvatarBlock>
      {user?.avatar ? <Avatar src={user.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
    </AvatarBlock>
  );
};
