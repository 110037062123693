import styled from 'styled-components';

export const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  width: 400px;

  & h2 {
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    svg {
      cursor: pointer;
    }

    .dropdown {
      left: 280px;
      top: 20px;
    }
  }

  & .workspaceSettings-inputWrapper {
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #858dbd;
      margin: -10px 0 10px;
      display: block;
    }
  }

  .inputContainer {
    padding: 0 0 20px;
  }

  input {
    height: 30px;
  }

  .workspaceSettings-errorMessage {
    color: #ff2f2f;
    margin-top: -20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
  }

  .workspaceSettings-infoMessage {
    color: #858dbd;
    text-align: center;
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0;
    button {
      width: 130px;
    }
  }

  .largeText {
    text-align: center;
    margin-top: 0;
    font-size: 16px;
    font-weight: 300;
  }
  & .switcher {
    display: flex;
    margin-bottom: 20px;
  }

  .roleEdit {
    display: flex;
  }
`;
