import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(RouterLink)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #858dbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;
