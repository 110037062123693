import styled from 'styled-components';

export const Wrapper = styled.span`
  text-align: left;
  display: inline-flex;
  width: 100%;
  position: relative;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  .input {
    display: inline-flex;
    align-items: center;
    background: #f8fafb;
    border-radius: 5px;
    border: none;
    flex: 1 1 auto;
    max-width: 100%;
    height: 40px;
    padding: 0 16px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;

    input {
      border: none;
      color: #858dbd;
      background: #f8fafb;

      &::placeholder {
        color: #b4b9d9;
        opacity: 1;
        font-weight: 500;
      }

      &:hover,
      &:focus {
        outline: none;
      }
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(202, 220, 248, 1);
    }

    &:hover input {
      color: #b4b9d9;
    }

    &:focus {
      background: #ffffff;

      input {
        background: #ffffff;
      }
    }

    &:disabled {
      background: #f1f5fb !important;
      color: #b4b9d9 !important;
      outline: none !important;

      input {
        background: #f1f5fb;
      }
    }

    &.with-icon {
      padding-right: 45px;
    }

    &.error {
      outline: none;
      box-shadow: 0 0 0 1px #ff2f2f !important;
    }

    .PhoneInputCountry {
      margin-right: 10px;
    }
  }

  .icon {
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: none;

    &.clickable {
      cursor: pointer;
      pointer-events: all;
    }
  }
`;
