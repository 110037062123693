import { client } from 'app/http';
import { ETRANSACTION_CHARGE_CODES } from 'app/types/enums/ETRANSACTION_CHARGE_CODES';

export const createAdminTransaction = async (
  baId: string,
  amount: number,
  code: ETRANSACTION_CHARGE_CODES,
  note: string,
): Promise<{ error: string; errorDetail: string } | null> => {
  const result = await client.post(`/admin/billing-account/${baId}/transaction`, { amount, code, note });
  return result.data;
};
