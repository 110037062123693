import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-column: span 1 / span 1;
  border-radius: 5px;
  display: flex;
  height: 72px;

  .card {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 0;
    white-space: nowrap;
    border-radius: 5px;
  }

  .card-text {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: 20px;
    width: 180px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .entity-name {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    text-transform: uppercase;
    border-radius: 5px;
    height: 72px;
    width: 72px;
  }

  .popover {
    margin: 0;

    & > div {
      background: none;
      overflow: visible;
      margin-top: 0;
    }
  }

  .actions {
    flex-shrink: 0;
    padding: 0 10px 25px 0;
  }

  .no-actions {
    flex-shrink: 0;
    padding: 0 10px 25px 0;
  }

  .options {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 80px;
    overflow: hidden;
    border-radius: 5px;
    display: block;
    position: absolute;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    top: -15px;
    right: 5px;

    li {
      margin: 0;
      padding: 10px;
      width: 80px;
      color: #858dbd;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      text-transform: capitalize;
      text-align: left;
      vertical-align: middle;
      display: flex;
      gap: 10px;

      &:hover {
        background: #f1f5fb;
      }
    }

    li.red:hover {
      background: #ff9191;
      color: #ffffff;

      svg {
        path {
          stroke: #ffffff;
          fill: transparent;
        }
      }
    }

    li.divider {
      height: 0;
      border-bottom: 1px solid #e1e1e1;
      padding: 0;
      margin: 0;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    li {
      svg {
        path {
          fill: #858dbd;
        }
      }
    }

    li.red {
      svg {
        path {
          stroke: #858dbd;
          fill: transparent;
        }
      }
    }
  }
`;
