import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  width: 380px;

  & h4 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    color: #40608f;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  & .checkBoxWrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    color: #858dbd;
    margin-bottom: 20px;

    & a {
      padding-left: 1px;
      padding-right: 1px;
      color: #40608f;

      &:hover {
        text-decoration: none;
      }
    }
  }

  & .buttonWrapper {
    display: flex;
    justify-content: space-evenly;
    grid-gap: 20px;
  }

  & .stripeContainer {
    background: #f8fafb;
    border-radius: 5px;
    height: 30px;
    padding: 7px;
    margin-bottom: 20px;
    width: 100%;
    position: relative;
  }

  & .stripeElement {
    height: 30px;
    background: #f8fafb;
    border-radius: 5px;
    padding: 7px;
    width: 100%;
  }

  & .switcher {
    display: flex;
    margin-bottom: 20px;
  }

  & .flex50percents {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    margin-bottom: 20px;

    .inputWrapper {
      flex: 1 1 auto;

      input {
        width: 100%;
      }
    }
  }

  & .inputWrapper {
    margin-bottom: 20px;
    height: 30px;
  }

  & .iconsWrapper {
    display: flex;
    grid-gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
`;
