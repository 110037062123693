import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { TUtterance } from 'app/types/entities/TUtterance';

type TState = {
  jobId: string;
  isLoading: boolean;
  utterances: TUtterance[];
  tab: string;
  setTab: (v: string) => void;
};

const PageContext = createContext<TState>({
  jobId: '',
  isLoading: false,
  utterances: [],
  tab: 'words',
  setTab: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const params = useParams() as { id: string };
  const jobId = params?.id ?? '';

  const [utterances, setUtterances] = useState<TUtterance[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState('words');

  useEffect(() => {
    load();
  }, [jobId]);

  const load = async () => {
    setIsLoading(true);
    call(AdminService.getJobUtterances({ id: jobId })).then((res: { utterances: TUtterance[] }) => {
      setUtterances(res.utterances || []);
      setIsLoading(false);
    });
  };

  return (
    <PageContext.Provider
      value={{
        jobId,
        isLoading,
        utterances,
        tab,
        setTab,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
