export const TrainingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const colour = props.fill || '#858DBD';

  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Training">
        <rect id="Rectangle 363" x="1" y="1" width="16" height="16" rx="2" stroke={colour} strokeWidth="2" />
        <path id="Polygon 1" d="M13 9L6 5L6 13L13 9Z" fill={colour} />
      </g>
    </svg>
  );
};
