import { useEffect, useState } from 'react';

import { OrganizationsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { useModalContext } from '@providers/ModalProvider';
import { useAuthContext } from '@providers/AuthProvider';

import { Search as SearchIcon } from '@components/icons/Search';
import { SmallWhiteInput } from '@components/Modal/Admin/AdminEditSettingModal/styles/SmallWhiteInput';
import { Button } from '@components/form-elements/buttons/Button';
import { CloseIcon } from '@components/icons/Close';
import { Divider } from '@components/Divider';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

import { JobsList } from './components/Jobs';
import { WorkspacesList } from './components/Workspaces';
import { UsersList } from './components/Users';

import { Wrapper } from './styles/Wrapper';

export const SearchModal = () => {
  const { call } = useAPI();
  const { closeModal } = useModalContext();
  const { me, organization } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [users, setUsers] = useState<any[]>([]);
  const [jobs, setJobs] = useState<any[]>([]);
  const [workspaces, setWorkspaces] = useState<any[]>([]);
  const canManageMembers = !!organization?.permissions?.manageMembers;

  const fetch = async () => {
    setLoading(true);
    const results = await call(OrganizationsService.search({ oid: organization?.id ?? '', search }));
    setJobs(results.jobs);
    setUsers(results.users);
    setWorkspaces(
      me.workspaces.filter(
        (ws) => ws.organizationId === organization?.id && ws.name.toLowerCase().includes(search.toLowerCase()),
      ),
    );
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, [organization, search, tags]);

  const checkTag = (tag: string) => {
    if (tags.includes(tag)) {
      setTags([...tags.filter((t) => t !== tag)]);
    } else {
      setTags([...tags, tag]);
    }
  };

  return (
    <Wrapper>
      <div className="head">
        <SearchIcon className="search-icon" />
        <SmallWhiteInput value={search} className="input" onChange={(e) => setSearch(e.target.value)} label="Search" />
        <Button className="clear-button" onClick={() => setSearch('')}>
          Clear
        </Button>
        <Button className="close-button" onClick={() => closeModal()}>
          <CloseIcon />
        </Button>
      </div>
      <Divider />
      <div className="tags">
        <h4>{"I'm searching for:"}</h4>
        <div className={`tag ${tags.length === 0 ? 'active' : ''}`} onClick={() => setTags([])}>
          All
        </div>
        <div className={`tag ${tags.includes('job') ? 'active' : ''}`} onClick={() => checkTag('job')}>
          Job
        </div>
        {canManageMembers && (
          <div className={`tag ${tags.includes('user') ? 'active' : ''}`} onClick={() => checkTag('user')}>
            User
          </div>
        )}
        <div className={`tag ${tags.includes('workspace') ? 'active' : ''}`} onClick={() => checkTag('workspace')}>
          Workspace
        </div>
      </div>
      <div className="results">
        {loading ? <AbsoluteSpinner /> : null}
        {search.length > 0 ? (
          <>
            {jobs.length > 0 && (!tags.length || tags.includes('job')) && <JobsList jobs={jobs} />}
            {users.length > 0 && canManageMembers && (!tags.length || tags.includes('user')) && (
              <UsersList users={users} />
            )}
            {workspaces.length > 0 && (!tags.length || tags.includes('workspace')) && (
              <WorkspacesList workspaces={workspaces} />
            )}
          </>
        ) : null}
      </div>
    </Wrapper>
  );
};
