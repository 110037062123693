import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  z-index: 10000;
  right: 10px;
  top: 45px;
  width: max-content;

  .option {
    font-family: 'General Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-transform: capitalize;
    color: #858dbd;
    padding: 10px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background: #f1f5fb;
    }
  }

  .option-divider {
    margin: 0 10px;
    border-bottom: 1px solid #e1e1e1;
  }
`;
