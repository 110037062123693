import styled from 'styled-components';

const Tag = styled.p`
  padding: 4px 6px 4px 6px;
  gap: 5px;
  border-radius: 5px 5px 5px 5px;
  opacity: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
`;

type TeamTagProps = {
  team: { name: string; slug?: string; colour?: string };
  active?: boolean;
  onClick: () => any;
  customStyle?: object;
};

export default function TeamTag({
  team,
  active = true,
  onClick,
  customStyle = { name: '', colour: '' },
}: TeamTagProps) {
  return (
    <Tag
      style={{
        backgroundColor: team.colour,
        opacity: active ? '1' : '0.3',
        ...customStyle,
      }}
      className={`mr-2 px-2 py-1 rounded-xl text-white text-sm cursor-pointer flex-shrink-0`}
      onClick={onClick}
    >
      {team.name || team.slug}
    </Tag>
  );
}
