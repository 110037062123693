import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { useAPI } from '@hooks/useAPI';
import { AdminService } from 'app/API';
import { TUser } from 'app/types/entities/TUser';

type TState = {
  user?: TUser;
  isLoading: boolean;
  fetchData: () => void;
};

const PageContext = createContext<TState>({
  user: undefined,
  isLoading: false,
  fetchData: () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();

  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const user = await call(AdminService.getUserDetails({ userId: userId ?? '' }));
    setUser(user.result);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  return (
    <PageContext.Provider
      value={{
        user,
        isLoading,
        fetchData,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
