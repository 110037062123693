import { ChangeEvent } from 'react';
import { TProps } from './types/TProps';

export const FileCheckbox = ({ file, selectedFiles, onChange }: TProps) => {
  const checked = selectedFiles.findIndex((f) => f.id === file.id) > -1;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked, file);
  };

  return (
    <p>
      <input type="checkbox" onChange={handleChange} checked={checked} />
      {file.name}
    </p>
  );
};
