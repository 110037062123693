import { AudioPlayer } from '@components/AudioPlayer';
import { CircularProgress } from '@components/spinners/CircularProgress';
import { useAPI } from '@hooks/useAPI';
import { MediaService } from 'app/API';
import toast from 'react-hot-toast';
import { EErrorMessages } from '@constants/errorMessages';
import { EnhancementButton } from './EnhancementButton';
import { Divider } from '@components/Divider';
import { Done } from '@components/icons/Done/svg';
import { useState } from 'react';

export const AudioWithQualityScore = ({ media, job, manualReload }: any) => {
  const { call } = useAPI();
  const [isSentToDiagnosis, setIsSentToDiagnosis] = useState(!!media.dolbyJobId);
  const qualityInPercentage = Number(media.dolbyDiagnosisScore || 0) * 10;
  let color = 'black';
  let backgroundColor = '#E0E0E0';
  let text = 'Missing audio quality.';

  if (qualityInPercentage < 50 && qualityInPercentage !== 0) {
    color = '#FF2F2F';
    backgroundColor = '#FEE2E2';
    text = 'Low audio quality! You can send it to automatic enhancement.';
  }

  if (qualityInPercentage > 50 && qualityInPercentage < 80) {
    color = '#FF9548';
    backgroundColor = '#FFE5CC';
    text = 'Medium audio quality! You can send it to automatic enhancement.';
  }

  if (qualityInPercentage > 80) {
    color = 'green';
    backgroundColor = '#CCFFCC';
    text = 'Good audio quality. You do not need to send it to automatic enhancement.';
  }

  const sendToDiagnosis = () => {
    if (isSentToDiagnosis) {
      return;
    }

    call(MediaService.sendMediaToDiagnosis({ id: media.id }), {
      onSuccess: () => {
        toast.success('Successfully sent to diagnosis');
        setIsSentToDiagnosis(true);
        manualReload();
      },
      onError: (errorMessage: string) => {
        toast.error(errorMessage);
        setIsSentToDiagnosis(false);
        manualReload();
      },
    });

    setIsSentToDiagnosis(true);
  };

  return (
    <div className="my-2 py-2 w-full">
      <div style={{ color: '#858DBD', fontSize: '12px', lineHeight: '18px' }} className="mb-2">
        {media.fileName}
      </div>
      <div style={{ color: '#858DBD', fontSize: '12px', lineHeight: '18px' }} className="mb-2">
        Enhancement status: {media.dolbyEnhancementStatus}
      </div>
      <div className="flex">
        <AudioPlayer key={media.id} src={`${window.location.origin}/api/media/${job.id}/${media.fileName}`} />
        <EnhancementButton
          className={
            media.dolbyEnhancementStatus === 'none' && qualityInPercentage !== 0
              ? 'enhance-button'
              : 'enhance-disabled-button'
          }
          onClick={() => {
            if (qualityInPercentage === 0) {
              return toast.error('Send to diagnosis first.');
            }
            if (media.dolbyEnhancementStatus !== 'none') {
              return toast.error('Already enhanced or in progress.');
            }
            call(MediaService.enhanceMedia({ id: media.id }), {
              onSuccess: () => {
                toast.success('Audio has been sent to automatic enhancement.');
                manualReload();
              },
              onError: () => toast.error(EErrorMessages.DEFAULT),
            });
          }}
        />
      </div>

      {media.dolbyEnhancementStatus === 'completed' ? (
        <div className="flex items-center">
          <AudioPlayer
            key={media.id}
            src={`${window.location.origin}/api/media/${job.id}/enhanced/${media.fileName}`}
          />
          <div className="flex items-center justify-center ml-[8px] h-full py-5">
            <Done />
          </div>
        </div>
      ) : null}

      {media.dolbyEnhancementStatus !== 'completed' ? (
        <div className="w-full mb-2 rounded-md flex flex-row items-center" style={{ color, backgroundColor }}>
          <CircularProgress
            indicatorWidth={4}
            indicatorColor={color}
            progress={qualityInPercentage}
            size={28}
            className="m-1"
            trackWidth={4}
            trackColor="#FFFF"
            rotate={270}
          />
          <div className="ml-2">{qualityInPercentage} %</div>
          <div className="text-sm ml-2 flex">
            {text}
            {qualityInPercentage === 0 ? (
              <div className={`ml-1 ${!isSentToDiagnosis && 'cursor-pointer underline'}`} onClick={sendToDiagnosis}>
                {isSentToDiagnosis
                  ? 'Diagnosis is in processing...'
                  : 'You should send it to diagnosis before enhancement.'}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      <Divider className="mt-2 w-full" />
    </div>
  );
};
