import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { TransactionStatusModalContent } from '@components/Modal/Billing/TransactionStatusModal/components/Content';

const isTestEnvironment =
  window.location.hostname === 'localhost' ||
  window.location.hostname === 'staging.autoscript.ai' ||
  window.location.hostname === 'demo.autoscript.ai';

const stripeKey = isTestEnvironment
  ? 'pk_test_51JMd0HK6PLb36tFiqfdtEiFseWqiSOzk1m9vmYLgo6vR4ceE4BsLU4DSLuhfeDig50YX1HmFPTCxADnJdWMZeXtq00SMqWPsIH'
  : 'pk_live_51JMd0HK6PLb36tFim4RLWzMJFsa1Op7KiHwv9rKqWz6WKtf8VFN997CfFCmwWJdd1kLCsgGs2Sd613unBUMAY5uv00bejEHItk';
const stripePromise = loadStripe(stripeKey);

type TProps = {
  chargeId: string;
  pics: string;
  onSuccess: () => void;
};

export const TransactionStatusModal = ({ chargeId, pics, onSuccess }: TProps) => {
  return (
    <Elements stripe={stripePromise}>
      <TransactionStatusModalContent chargeId={chargeId} pics={pics} onSuccess={onSuccess} />
    </Elements>
  );
};
