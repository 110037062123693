import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { Dots } from '@components/icons/Dots';
import { DropDown } from '@components/form-elements/DropDown';
import { Option } from '@components/form-elements/DropDown/types/Option';
import { Button } from '@components/form-elements/buttons/Button';
import { Divider } from '@components/Divider';

type WrapperProps = {
  justifyContent?: string;
};

const Wrapper = styled.div<WrapperProps>`
  width: 210px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent || 'space-between'};

  & .content {
    padding: 20px;
    padding-bottom: 10px;
  }

  & .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #858dbd;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .subTitle {
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    color: #7cb1fe;
    margin-top: -5px;

    &.big {
      font-size: 58px;
      line-height: 80px;
    }
  }

  & .descriptionWrapper {
    display: flex;
    grid-gap: 10px;

    .description {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #858dbd;
      margin: 0;
      width: 50%;
      &.full-width {
        width: 100%;
      }
      &.no-description {
        font-size: 14px;
        line-height: 20px;
        padding: 10px 0 0;
        width: 100%;
      }
    }
  }

  & .buttonContainer {
    height: 40px;
    border-top: 1px solid #d5ddf2;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .chosenContainer {
    height: 40px;
    margin: 0;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
    color: #fff;
    background: linear-gradient(90deg, #7aabf3 0%, #2765be 100%);
  }
`;

const DotsButton = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;

  :hover {
    & svg {
      circle {
        fill: #858dbd;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
`;

export type TProps = {
  chosen?: boolean;
  title: string;
  subTitle?: string;
  descriptions?: (string | React.ReactNode)[];
  buttonLabel?: string;
  buttonOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  noDescriptionText?: string;
  dropDownOptions?: Option[];
  className?: string;
  verticalDescription?: boolean;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  buttonSize?: 'small' | 'medium' | 'large';
  disabled?: boolean;
};

export const Card = ({
  chosen,
  title,
  subTitle,
  descriptions,
  noDescriptionText,
  buttonLabel,
  buttonOnClick,
  dropDownOptions,
  className,
  verticalDescription,
  buttonVariant = 'text',
  buttonSize = 'small',
  disabled,
}: TProps) => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <Wrapper className={className} justifyContent={buttonLabel ? 'space-between' : 'flex-start'}>
      <div className="content">
        {dropDownOptions?.length ? (
          <DotsButton onClick={() => setShowDropDown(true)}>
            <Dots />
          </DotsButton>
        ) : null}
        {showDropDown ? <DropDown close={() => setShowDropDown(false)} options={dropDownOptions} /> : null}
        <h2 className="title">{title}</h2>
        {descriptions?.length && <h3 className="title subTitle">{subTitle}</h3>}
        <div className="descriptionWrapper">
          {descriptions?.map((Description, index) => (
            <Fragment key={index}>
              {index === 1 && !verticalDescription ? <Divider vertical /> : null}
              <div
                className={`description ${descriptions.length === 1 || verticalDescription ? 'full-width' : ''}`}
                key={index}
              >
                {typeof Description === 'string' &&
                  Description.split('<newLine>').map((descriptionLine, index) => {
                    return (
                      <Fragment key={index}>
                        <span dangerouslySetInnerHTML={{ __html: descriptionLine }}></span>
                        <br />
                      </Fragment>
                    );
                  })}
                {typeof Description === 'object' && Description}
              </div>
            </Fragment>
          ))}
          {!descriptions?.length && <p className="description no-description">{noDescriptionText}</p>}
        </div>
      </div>

      {chosen ? <div className="chosenContainer">Your Current Plan</div> : null}
      {!chosen && buttonLabel ? (
        <div className="buttonContainer">
          <StyledButton disabled={disabled} size={buttonSize} variant={buttonVariant} onClick={buttonOnClick}>
            {buttonLabel}
          </StyledButton>
        </div>
      ) : null}
    </Wrapper>
  );
};
