export const Jobs = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.73 1.16878C15.3721 0.810929 14.7919 0.810929 14.4341 1.16878C14.0762 1.52662 14.0762 2.10681 14.4341 2.46466L17.9719 6.00243H12.0208C5.87901 6.00243 0.900391 10.9811 0.900391 17.1228V18.1432C0.900391 18.6493 1.31064 19.0596 1.81672 19.0596C2.32279 19.0596 2.73304 18.6493 2.73304 18.1432V17.1228C2.73304 11.9932 6.89116 7.83508 12.0208 7.83508H17.9719L14.4341 11.3729C14.0762 11.7307 14.0762 12.3109 14.4341 12.6687C14.7919 13.0266 15.3721 13.0266 15.73 12.6687L20.832 7.5667C21.1899 7.20885 21.1899 6.62866 20.832 6.27082L15.73 1.16878Z"
        fill="#858DBD"
      />
    </svg>
  );
};
