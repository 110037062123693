import { createContext, useContext, useEffect, useState } from 'react';

import { TWorkspace } from 'app/types/entities/TWorkspace';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';

import { getWorkspacesList, TResponse } from '@pages/Admin/WorkspacesListPage/queries/getWorkspacesList';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

type TState = {
  workspaces: TResponse;
  isLoading: boolean;
  search: string;
  setSearch: (v: string) => void;
  pageSize: number;
  setPageSize: (v: number) => void;
  page: number;
  setPage: (v: number) => void;
  reloadPage: () => void;
  setIsLoading: (v: boolean) => void;
  exportCSV: () => void;
};

const PageContext = createContext<TState>({
  workspaces: {} as TResponse,
  isLoading: false,
  search: '',
  setSearch: () => null,
  pageSize: 25,
  setPageSize: () => null,
  page: 1,
  setPage: () => null,
  reloadPage: () => null,
  setIsLoading: () => null,
  exportCSV: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [reload, setReload] = useState(false);
  const [workspaces, setWorkspaces] = useState<TResponse>({
    data: [],
    count: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    getWorkspacesList(page, pageSize, search).then((res) => {
      setWorkspaces(res);
      setIsLoading(false);
    });
  }, [page, pageSize, reload, search]);

  const reloadPage = () => {
    setPage(1);
    setReload(!reload);
  };

  const exportCSV = async () => {
    const items = await getWorkspacesList(1, workspaces.count, search);
    const headers = ['ID', 'Name', 'URL', 'Organization', 'Created'];
    const array = items.data.map((data: TWorkspace) => {
      return {
        id: data.id,
        name: data.name,
        url: data.slug,
        organization: data.Organization?.name ?? '',
        created: getAmericanTime(data.createdAt),
      };
    });
    return downloadCSV(array, headers);
  };

  return (
    <PageContext.Provider
      value={{
        workspaces,
        isLoading,
        search,
        setSearch,
        pageSize,
        setPageSize,
        page,
        setPage,
        reloadPage,
        setIsLoading,
        exportCSV,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
