import { Link } from 'react-router-dom';
import { MouseEvent, useState } from 'react';

import { Popover } from '@components/Popover';
import { EllipsisVerticalIcon } from '@components/icons/EllipsisVertical';

import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';

export const UniversalNoPictureCard = ({ content, actions }: TProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Wrapper>
      <div className="card">
        <div className="card-text">{content}</div>
        {actions && actions.length ? (
          <div className="actions">
            <button
              type="button"
              className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              <Popover
                className="popover"
                open={anchorEl !== null}
                onClose={() => {
                  setAnchorEl(null);
                }}
                anchorEl={anchorEl}
              >
                <ul className="options">
                  {actions.map((action, index) => {
                    return (
                      <li
                        key={index}
                        className={action.className ?? ''}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (action.onClick) {
                            action.onClick();
                          }
                          setAnchorEl(null);
                        }}
                      >
                        {action.link ? <Link to={action.link}>{action.label}</Link> : null}
                        {!action.link ? action.label : null}
                      </li>
                    );
                  })}
                </ul>
              </Popover>
            </button>
          </div>
        ) : (
          <div className="no-actions"></div>
        )}
      </div>
    </Wrapper>
  );
};
