import styled from 'styled-components';

type TProps = {
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
};

export const Wrapper = styled.span`
  display: inline-block;
  text-align: center;
  position: relative;
  height: 15px;
  width: 25px;
  background-color: ${(props: TProps) => (props.disabled ? '#CADCF8' : props.checked ? '#cadcf8' : '#d7d7d7')};
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  pointer-events: ${(props: TProps) => (props.disabled ? 'none' : 'all')};
  transition: background-color 0.2s ease-in-out;

  .point {
    height: 13px;
    width: 13px;
    border-radius: 10px;
    position: absolute;
    top: 1px;
    background-color: ${(props: TProps) => (props.disabled ? '#F8FAFB' : props.checked ? '#858DBD' : '#fff')};
    left: ${(props: TProps) => (props.checked ? '11px' : '1px')};
    transition:
      background-color 0.2s ease-in-out,
      left 0.2s ease-in-out;
  }
`;
