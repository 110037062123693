export enum JOB_STATUSES_ENUM {
  JOB_STATUS_DRAFT = 'Draft',
  JOB_STATUS_SCHEDULED = 'Scheduled',
  JOB_STATUS_ERROR = 'Error',

  JOB_STATUS_PROCESSING = 'Processing',
  JOB_STATUS_READY_FOR_TRANSCRIBING = 'Ready for Editing',
  JOB_STATUS_TRANSCRIBING = 'Editing',
  JOB_STATUS_READY_FOR_PROOFREADING = 'Ready for Proofreading',
  JOB_STATUS_PROOFING = 'Proofreading',
  JOB_STATUS_READY_FOR_FINAL = 'Ready for Final',
  JOB_STATUS_FINALIZING = 'Finalizing',
  JOB_STATUS_COMPLETE = 'Complete',
}
