import styled from 'styled-components';

export type TransactionType = 'charge' | 'credit' | 'refund' | 'purchase' | 'adjustment' | 'payment' | 'subscription';

const getColor = (status: TransactionType) => {
  switch (status) {
    case 'refund':
    case 'credit':
      return '#16D679';
    case 'adjustment':
      return '#FFAE34';
    case 'charge':
    case 'payment':
    case 'purchase':
    case 'subscription':
      return '#FF2F2F';
    default:
      return '#858DBD';
  }
};

type TProps = {
  type: TransactionType;
  className?: string;
};

const StyledText = styled.p<TProps>`
  color: ${(props) => getColor(props.type)};
  font-weight: 500;
`;

export const TransactionType = ({ type, className = 'type' }: TProps) => (
  <StyledText className={className} type={type}>
    {type}
  </StyledText>
);
