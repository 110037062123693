import styled from 'styled-components';
import { getShortName } from '@helpers/getShortName';
import { TUser } from 'app/types/entities/TUser';
import { TWorkspace } from 'app/types/entities/TWorkspace';

const Wrapper = styled.div`
  padding: 16px 0 20px;
  position: relative;
  display: flex;
  gap: 30px;

  .whiteInput {
    input {
      background: white !important;
    }
  }
`;

const LeftSide = styled.div`
  width: 230px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #878787;
  flex: 0 0 auto;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 0 0 10px;
  }
`;

const RightSide = styled.div`
  display: flex;
  gap: 9px;
  flex-wrap: wrap;

  .workspace {
    display: flex;
    gap: 10px;
    width: 265px;
    background: #ffffff;
    border-radius: 5px;

    .workspace-shortName {
      flex: 0 0 auto;
      width: 72px;
      height: 72px;
      background: #cadcf8;
      border-radius: 5px;
      font-style: normal;
      text-align: center;
      font-weight: 600;
      font-size: 36px;
      line-height: 72px;
      letter-spacing: 0.01em;
      color: #ffffff;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .workspace-right {
      padding: 10px 0;

      .workspace-name {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #40608f;
        width: 173px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .workspace-org {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        width: 173px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

type TProps = {
  user: TUser;
};

export const Workspaces = ({ user }: TProps) => {
  if (!user) {
    return null;
  }

  const renderWorkspace = (workspace: TWorkspace) => {
    const workspaceShortname = getShortName(workspace.name || workspace.slug);
    const org = (user.orgRelations ?? []).find((organization) => organization.id === workspace.organizationId);
    return (
      <div className="workspace" key={workspace.id}>
        <div className="workspace-shortName">{workspaceShortname}</div>
        <div className="workspace-right">
          <div className="workspace-name">{workspace.name}</div>
          {!!org && <div className="workspace-org">{org.name}</div>}
        </div>
      </div>
    );
  };

  return (
    <>
      <Wrapper>
        <LeftSide>
          <h3>Workspace memberships</h3>
        </LeftSide>

        <RightSide>{(user.workspaces ?? []).map((workspace) => renderWorkspace(workspace))}</RightSide>
      </Wrapper>
    </>
  );
};
