import styled from 'styled-components';
import { IconButton } from '@components/form-elements/buttons/IconButton';

export const ShareIconButton = styled(IconButton)`
  width: 120px;
  color: #858dbd;
  align-self: center;

  svg {
    width: 18px;
    height: 18px;
  }

  path {
    fill: #858dbd;
  }

  :hover {
    color: #858dbd;

    path {
      fill: #858dbd;
      color: #858dbd;
    }
  }
`;
