import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  margin: -3px;
  padding: 3px;

  &:hover {
    background: #cadcf8;
    border-radius: 5px;

    svg path {
      fill: #ffffff;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #858dbd;
    }
  }
`;
