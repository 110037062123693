import styled from 'styled-components';

type TProps = {
  draggable: boolean;
  error: boolean;
};

export const Wrapper = styled.div<TProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 5px;
  margin-top: 10px;
  border: ${(props) => (props.error ? '1px dashed #ff2f2f' : '1px dashed #cadcf8')};
  border-radius: 5px;
  min-height: auto;

  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.error ? '#ff9191' : '#858dbd')};

  cursor: ${(props) => (props.draggable ? 'pointer' : 'inherit')};

  .errorText {
    color: #ff2f2f;
    margin: 0 10px 0 0;
  }

  .PreviewFile-errorMessage {
    color: #ff2f2f;
    text-align: left;
    padding: 5px 0 20px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }

  .PreviewFile-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .PreviewFile-left-side-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1 1 auto;

    .PreviewFile-fileIcon {
      color: #fff;
      width: 40px;
      height: 40px;
      display: flex;
      font-size: 14px;
      background-color: ${(props) => (props.error ? '#ff9191' : '#858dbd')};
      align-items: center;
      font-weight: 400;
      line-height: 20px;
      border-radius: 8px;
      justify-content: center;
      flex: 0 0 auto;
    }
  }

  .PreviewFile-file-name-wrapper {
    margin-left: 10px;
    display: flex;
    align-items: start;
    flex-direction: column;
  }

  .PreviewFile-download-button {
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    border: none;

    svg {
      margin: auto;
    }

    path {
      fill: #858dbd;
      color: #858dbd;
    }

    border-radius: 5px;
    justify-content: right;
    align-items: flex-end;

    :hover {
      background: #858dbd;

      path {
        fill: #fff;
        color: #fff;
      }
    }
  }

  .PreviewFile-button {
    display: flex;
    flex: 1;
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    border: none;
    color: #6c6c6c;
    border-radius: 5px;
    justify-content: center;
    align-items: center;

    :hover {
      background: #ff9191;

      path {
        fill: #fff;
        color: #fff;
      }
    }
  }

  .PreviewFile-retryButton {
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
  }

  .PreviewFile-removeButton {
    path {
      fill: ${(props) => (props.error ? '#ff9191' : '#858dbd')};
      color: ${(props) => (props.error ? '#ff9191' : '#858dbd')};
    }
  }

  .PreviewFile-audio-wrapper {
    width: 100%;
    display: flex;
    margin: 20px 10px 0;
  }
`;
