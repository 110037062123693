import styled from 'styled-components';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { PaymentStatuses } from '@constants/enums/paymentStatuses';
import { INVITATION_STATUSES } from '@constants/enums/invitationStatuses';

const getColor = (status: string) => {
  switch (status) {
    case JOB_STATUSES_ENUM.JOB_STATUS_COMPLETE:
    case PaymentStatuses.succeeded:
    case INVITATION_STATUSES.ACCEPTED:
      return '#16D679';
    case JOB_STATUSES_ENUM.JOB_STATUS_PROCESSING:
    case JOB_STATUSES_ENUM.JOB_STATUS_TRANSCRIBING:
    case JOB_STATUSES_ENUM.JOB_STATUS_FINALIZING:
    case JOB_STATUSES_ENUM.JOB_STATUS_PROOFING:
    case JOB_STATUSES_ENUM.JOB_STATUS_READY_FOR_TRANSCRIBING:
    case JOB_STATUSES_ENUM.JOB_STATUS_READY_FOR_PROOFREADING:
    case JOB_STATUSES_ENUM.JOB_STATUS_READY_FOR_FINAL:
    case INVITATION_STATUSES.CREATED:
      return '#FFAE34';
    case JOB_STATUSES_ENUM.JOB_STATUS_ERROR:
    case PaymentStatuses.payment_failed:
    case INVITATION_STATUSES.DECLINED:
      return '#FF2F2F';
    case JOB_STATUSES_ENUM.JOB_STATUS_DRAFT:
      return '#858DBD';
    case JOB_STATUSES_ENUM.JOB_STATUS_SCHEDULED:
      return '#858DBD';
    default:
      return '#858DBD';
  }
};

type TProps = {
  status: string;
  testid?: string;
  className?: string;
};

const StyledText = styled.p<TProps>`
  color: ${(props) => getColor(props.status)};
  font-weight: 500;
`;

export const StatusText = ({ status, testid, className = 'status' }: TProps) => (
  <StyledText className={className} data-testid={testid} status={status}>
    {status}
  </StyledText>
);
