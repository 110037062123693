export const convertDuration = (seconds: number, showHoursOnly = false) => {
  let remainingSeconds: number = seconds;
  let result = '';

  const hours = Math.floor(seconds / 60 / 60);
  result += `${hours < 10 ? '0' : ''}${hours}`;
  remainingSeconds = remainingSeconds - hours * 60 * 60;

  if (showHoursOnly) {
    return result;
  }

  const minutes = Math.floor(remainingSeconds / 60);
  result += `:${minutes < 10 ? '0' : ''}${minutes}`;

  remainingSeconds = Math.floor(remainingSeconds - minutes * 60);

  result += `:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  return result;
};
