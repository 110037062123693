import styled from 'styled-components';

import { SmallWhiteAutoCompleteSelect } from '@components/form-elements/AutocompleteSelect/variations/SmallWhiteAutoCompleteSelect';

export const SmallWhiteSelect = styled(SmallWhiteAutoCompleteSelect)`
  .select {
    padding: 7px 10px;
  }

  .label {
    left: 11px;
  }

  .optionsLabel {
    padding: 8px 0;
  }
`;
