import { useAuthContext } from '@providers/AuthProvider';

import { BackIcon } from './icons/back';
import { TProps } from './types/TProps';
import { StyledLink } from './styles/StyledLink';

export const BackButton = ({ backRoute }: TProps) => {
  const { me } = useAuthContext();

  if (!me) {
    return null;
  }

  if (!backRoute) {
    return null;
  }
  return (
    <StyledLink to={backRoute}>
      <BackIcon />
    </StyledLink>
  );
};
