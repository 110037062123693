export const Move = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 14.25L10.5 14.25M18.75 14.25L16 16.875M18.75 14.25L16 11.625"
        stroke="#858DBD"
        strokeWidth="1.84"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 8.75V6H6V20.25H17.25V19.75"
        stroke="#858DBD"
        strokeWidth="1.84"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 9.75H23.25V24H12V22.5"
        stroke="#858DBD"
        strokeWidth="1.84"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
