import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 150px;

  li {
    margin: 0;
    padding: 10px;
    width: 150px;
    color: #858dbd;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;

    text-transform: capitalize;
    text-align: left;
    vertical-align: middle;
    display: flex;
    gap: 10px;

    &:hover {
      background: #f1f5fb;
    }
  }

  li:last-child:hover {
    background: #ff9191;
    color: #ffffff;

    svg {
      path {
        stroke: #ffffff;
        fill: transparent;
      }
    }
  }

  li.divider {
    height: 0;
    border-bottom: 1px solid #e1e1e1;
    padding: 0;
    margin: 0 10px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  li:last-child {
    svg {
      path {
        stroke: #858dbd;
        fill: transparent;
      }
    }
  }
`;
