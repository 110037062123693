import { Wrapper } from './styles/Wrapper';
import { TProps } from './types/TProps';

export function InputUpload({
  accept = 'audio/x-m4a, audio/mpeg, audio/wav, video/x-msvideo, video/trm,.trm',
  onFiles,
  multiple,
}: TProps) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      onFiles([]);
      event.target.value = '';
      return;
    }
    onFiles(Array.from(event.target.files));
    event.target.value = '';
  };

  return (
    <Wrapper>
      <label className="InputUpload-label">
        <div>
          <span className="InputUpload-label-span">
            Drag and drop or click <span className="InputUpload-here-span">here</span> to upload your files
          </span>
          <input className="InputUpload-input" type="file" multiple={multiple} accept={accept} onChange={onChange} />
        </div>
      </label>
    </Wrapper>
  );
}
