import { ReactNode, useCallback } from 'react';

import { PermissionContext } from './PermissionContext';
import { EPermission } from 'app/types/enums/EPermission';

type PermissionProviderProps = {
  fetchPermission: (p: EPermission) => Promise<boolean>;
  children: ReactNode;
};

type PermissionCache = {
  [key: string]: boolean;
};

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
export const PermissionProvider = ({ fetchPermission, children }: PermissionProviderProps) => {
  const cache: PermissionCache = {};

  const isAllowedTo = useCallback(
    async (permission: EPermission): Promise<boolean> => {
      if (Object.keys(cache).includes(permission)) {
        return cache[permission];
      }
      const isAllowed = await fetchPermission(permission);
      cache[permission] = isAllowed;
      return isAllowed;
    },
    [cache, fetchPermission],
  );

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};
