import { createContext } from 'react';
import { EPermission } from 'app/types/enums/EPermission';

type PermissionContextType = {
  isAllowedTo: (permission: EPermission) => Promise<boolean>;
};

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider
// The permission will not be granted if no provider says otherwise
const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => Promise.resolve(false),
};

// Create the context
export const PermissionContext = createContext<PermissionContextType>(defaultBehaviour);
