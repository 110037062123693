import { createContext, useContext, useEffect, useState } from 'react';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { TSMSWhitelist } from '../types/TSMSWhitelist';

import { useAPI } from '@hooks/useAPI';
import { SmsWhitelistService } from 'app/API';

type TState = {
  smsWhitelist: TSMSWhitelist[];
  isLoading: boolean;
  createWhitelist: () => void;
  removeWhitelist: (number: string) => Promise<void>;
};

const PageContext = createContext<TState>({
  smsWhitelist: [],
  isLoading: false,
  createWhitelist: () => {},
  removeWhitelist: async () => {},
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [smsWhitelist, setSMSWhitelist] = useState<TSMSWhitelist[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadPageTrigger, setReloadPageTrigger] = useState(false);
  const { openModal, closeModal } = useModalContext();

  const load = () => {
    setIsLoading(true);
    call(SmsWhitelistService.findAll()).then((data) => {
      setSMSWhitelist(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    load();
  }, [reloadPageTrigger]);

  const createWhitelist = () => {
    openModal(ModalNamesEnum.CreateNewWhitelistNumber, {
      onSuccess: () => {
        closeModal();
        setReloadPageTrigger(!reloadPageTrigger);
      },
    });
  };

  const removeWhitelist = async (number: string) => {
    const confirmation = window.confirm('Are you sure you want to remove this whitelisted number?');

    if (!confirmation) {
      return;
    }
    await call(SmsWhitelistService.remove({ number }));
    setReloadPageTrigger(!reloadPageTrigger);
  };

  return (
    <PageContext.Provider
      value={{
        smsWhitelist,
        isLoading,
        createWhitelist,
        removeWhitelist,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
