import { useState } from 'react';
import { SideArrow } from '@components/icons/SideArrow';

import { TProps } from './types/TProps';
import { colourSet } from './helpers/colourSet';

export const ColourPicker = ({ colours = colourSet, colour, setColour }: TProps) => {
  const [showPicker, setShowPicker] = useState(false);

  const handlePickColour = (c: string) => {
    setColour(c);
    setShowPicker(false);
  };

  return (
    <div
      className="colours w-full"
      style={{
        height: '30px',
        width: '100%',
      }}
    >
      {!showPicker ? (
        <div className="flex flex-row">
          <div
            style={{
              backgroundColor: colour,
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              border: '1px solid transparent',
              display: 'inline-block',
              margin: '5px',
              cursor: 'pointer',
              outlineOffset: '2px',
            }}
            onClick={() => setShowPicker(true)}
          />
          <SideArrow style={{ marginTop: '10px' }} onClick={() => setShowPicker(true)} />
        </div>
      ) : null}
      {showPicker &&
        colours.map((c) => (
          <div
            key={c}
            style={{
              backgroundColor: c,
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              border: '1px solid transparent',
              display: 'inline-block',
              margin: '5px',
              cursor: 'pointer',
              outlineOffset: '2px',
              outline: c === colour ? '4px solid #E4EBF5' : 'none',
            }}
            onClick={() => handlePickColour(c)}
          />
        ))}
    </div>
  );
};
