import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TWorkspace } from 'app/types/entities/TWorkspace';

import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Button } from '@components/form-elements/buttons/Button';
import { Radio } from '@components/form-elements/Radio';
import { CustomTable } from '@components/Table';
import { Tooltip } from '@components/Tooltip';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';
import { useAPI } from '@hooks/useAPI';
import { AdminService, JobsService } from 'app/API';
import { TOrganization } from 'app/types/entities/TOrganization';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0 0 20px;
  }

  .form-autocomplete {
    .select {
      box-shadow: 0 0 0 1px #858dbd;
    }
  }

  input {
    height: 30px;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
    cursor: pointer;
    margin: 12px 0;

    &.disabled {
      color: #d5def2;
      cursor: auto;
    }

    .text {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px 0 0;

    tbody td > * {
      width: 100%;
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  jobId: string;
  workspaceId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const AdminMoveJobToOrgWsModal = ({ jobId, workspaceId, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(workspaceId);
  const [allOrganizations, setAllOrganizations] = useState<{ value: string | number; label: string }[]>([]);
  const [allWorkspaces, setAllWorkspaces] = useState<TWorkspace[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const workspaces = allWorkspaces.filter((ws) => ws.organizationId === selectedOrganizationId);

  useEffect(() => {
    call(AdminService.getAllOrganizationsList()).then((orgs) => {
      setAllOrganizations(orgs.map((org: TOrganization) => ({ value: org.id, label: `${org.name} (${org.slug})` })));
    });
    call(AdminService.getAllWorkspacesList()).then((orgs) => {
      setAllWorkspaces(orgs);
    });
  }, []);

  const tableHead = [
    {
      render: (data: TWorkspace) => {
        const disabled = workspaceId === data.id;
        if (disabled) {
          return (
            <Tooltip key={data.id} content="Cannot move the job to the same Workspace" placement="bottom">
              <div className="radioWrapper disabled">
                <Radio onChange={() => {}} rounded checked={selectedWorkspaceId === data.id} disabled={true} />
                <span className="text">
                  {data.name} ({data.slug})
                </span>
              </div>
            </Tooltip>
          );
        }
        return (
          <div className="radioWrapper">
            <Radio onChange={() => setSelectedWorkspaceId(data.id)} rounded checked={selectedWorkspaceId === data.id} />
            <span className="text">
              {data.name} ({data.slug})
            </span>
          </div>
        );
      },
      headComponent: () => <td>Workspace Name</td>,
    },
  ];

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await call(JobsService.adminMoveJobToOrgWs({ id: jobId, requestBody: { workspaceId: selectedWorkspaceId ?? '' } }));
    setIsSubmitting(false);
    onSuccess();
    onCancel();
  };

  return (
    <ModalWrapper>
      {isSubmitting ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Move Job to another workspace</h2>

      <SmallWhiteSelect
        name="organizationId"
        label="Organization"
        className="form-autocomplete"
        value={selectedOrganizationId}
        onChange={(_, value) => setSelectedOrganizationId(value)}
        options={allOrganizations}
        required={false}
      />

      <CustomTable data={workspaces} head={tableHead} className="customTable" fullPage={false} whiteBackground={true} />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={selectedWorkspaceId === workspaceId} onClick={handleSubmit}>
          Move
        </Button>
      </div>
    </ModalWrapper>
  );
};
