import { UAParser } from 'ua-parser-js';

export function parseUserAgent() {
  const parser = new UAParser(navigator.userAgent);
  return {
    browser: parser.getBrowser(),
    os: parser.getOS(),
    device: parser.getDevice(),
    userAgent: navigator.userAgent,
  };
}
