type TProps = {
  width?: string;
};

export const DoubleDone = ({ width = '20px' }: TProps) => {
  return (
    <svg width={width} height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.91375 0.285621C9.0859 0.121725 9.31499 0.0311167 9.55268 0.0329151C9.79037 0.0347136 10.0181 0.128778 10.1877 0.295261C10.3574 0.461743 10.4557 0.687621 10.462 0.925232C10.4683 1.16284 10.382 1.3936 10.2214 1.56882L5.34524 7.66706C5.26139 7.75737 5.1602 7.82984 5.0477 7.88015C4.9352 7.93046 4.81372 7.95756 4.69051 7.95984C4.56729 7.96212 4.44489 7.93954 4.33061 7.89343C4.21632 7.84732 4.11251 7.77864 4.02538 7.6915L0.791723 4.45784C0.701671 4.37393 0.629443 4.27274 0.579347 4.16031C0.529251 4.04788 0.502314 3.92651 0.500143 3.80344C0.497971 3.68037 0.52061 3.55813 0.566709 3.444C0.612807 3.32987 0.681421 3.2262 0.768457 3.13916C0.855493 3.05212 0.959168 2.98351 1.0733 2.93741C1.18743 2.89131 1.30967 2.86867 1.43274 2.87085C1.55581 2.87302 1.67718 2.89995 1.78961 2.95005C1.90204 3.00015 2.00323 3.07237 2.08714 3.16243L4.6462 5.72026L8.88931 0.312507C8.89692 0.303072 8.90508 0.294094 8.91375 0.285621ZM7.78942 6.56717L8.91375 7.6915C9.00086 7.77845 9.1046 7.84697 9.21876 7.89297C9.33293 7.93896 9.45519 7.96149 9.57826 7.95921C9.70132 7.95693 9.82266 7.92988 9.93505 7.87969C10.0474 7.8295 10.1486 7.75718 10.2324 7.66706L15.111 1.56882C15.1986 1.4823 15.2679 1.379 15.3147 1.2651C15.3616 1.1512 15.385 1.02903 15.3835 0.905883C15.3821 0.782739 15.3559 0.661147 15.3064 0.548369C15.2569 0.43559 15.1852 0.333938 15.0956 0.249483C15.006 0.165028 14.9002 0.0995009 14.7847 0.0568161C14.6692 0.0141314 14.5463 -0.00483628 14.4232 0.00104628C14.3002 0.00692883 14.1797 0.0375412 14.0688 0.0910544C13.9578 0.144568 13.8588 0.219884 13.7777 0.312507L9.53335 5.72026L8.94063 5.12633L7.7882 6.56717H7.78942Z"
        fill="#858DBD"
      />
    </svg>
  );
};
