import { client } from 'app/http';
import { UploadObjectType } from '@constants/enums/uploadObjectType';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';

export async function getDownloadLink(Id = '', objectType: UploadObjectType, fileWithMeta: TFileWithMeta) {
  if (objectType === UploadObjectType.REPOSITORY) {
    if (!fileWithMeta.meta.Id) {
      return;
    }

    const response = await client.get('repositories/attachments/' + Id + '/download');

    const { data } = response;

    window && window.open(data.url, '_blank');
  }
}
