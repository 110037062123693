import { createContext, useContext, useEffect, useState } from 'react';
import { useAPI } from '@hooks/useAPI';

import { AuthService } from 'app/API';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { refreshToken } from 'app/http';

type TState = {
  isAuthenticated: boolean;
  authenticate: () => void;
  isInitialized: boolean;
  isLoading: boolean;
  logout: () => void;
};

const InitContext = createContext<TState>({
  isAuthenticated: false,
  authenticate: () => null,
  isInitialized: false,
  isLoading: false,
  logout: () => null,
});

export const InitProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (isLoading || isInitialized) {
      return;
    }
    setIsLoading(true);
    call(AuthService.getUserId(), { dontCatchErrors: true }).then(async (id) => {
      if (id && typeof id === 'string') {
        await refreshToken();
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsInitialized(true);
      setIsLoading(false);
    });
  }, []);

  const logout = async () => {
    await call(AuthService.logout());
    document.cookie = 'ftk=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    window.location.href = '/';
  };

  const authenticate = async () => {
    window.location.href = '/';
  };

  return (
    <InitContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        isLoading,
        isInitialized,
        logout,
      }}
    >
      {children}
    </InitContext.Provider>
  );
};

export const useInitContext = () => useContext(InitContext);
