import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAPI } from '@hooks/useAPI';
import { getPaymentMethods } from '@queries/billing-account/getPaymentMethods';
import { TPaymentMethod } from 'app/types/entities/TPaymentMethod';
import { PaymentService } from 'app/API';

import { getAmericanTime } from '@helpers/getAmericanTimezone';

import { CustomTable } from '@components/Table';
import { Button } from '@components/form-elements/buttons/Button';
import { CheckBox } from '@components/form-elements/CheckBox';
import { TrashBin } from '@components/icons/TrashBin';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

const DeleteButton = styled(IconButton)`
  height: 30px;
  width: 30px;

  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const Wrapper = styled.div`
  width: 490px;
  background: #fff;
  position: relative;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  & .buttonWrapper {
    display: flex;
    grid-gap: 10px;
    width: 160px;
    padding: 10px 30px 30px;
    position: sticky;
    bottom: 0;
  }

  & p {
    text-transform: capitalize;
  }

  & h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    color: #40608f;
    padding: 30px 30px 10px;
    margin: 0;
  }

  & .tableWrapper {
    /* padding: 0 30px; */
    overflow: hidden auto;
    height: 380px;
    display: block;

    td:first-child {
      padding-left: 30px;
    }

    td:last-child {
      padding-right: 30px;
    }

    tr {
      position: relative;
      border: none;

      :before {
        content: '';
        position: absolute;
        left: 30px;
        bottom: 0;
        height: 1px;
        width: calc(100% - 60px);
        border-bottom: 1px solid #d5ddf2;
      }
    }

    tr:last-child:not(.theadTr) {
      :before {
        border-bottom: none;
      }
    }

    .centerText {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .defaultMethod {
      background: #f1f5fb;
    }
  }
`;

const DeleteModal = styled.div`
  background: #fff;
  position: relative;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 30px;
  width: 330px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  .buttonWrapper {
    display: flex;
    grid-gap: 10px;
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #00122d;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    text-transform: capitalize;
    color: #858dbd;
    text-align: center;
    margin: 0;
  }
`;

type PaymentMethodDtoWithClassName = TPaymentMethod & {
  className?: string;
};

export const PaymentMethodsHistory = ({
  billingAccountId,
  onCancel,
}: {
  billingAccountId: string;
  onCancel: () => void;
}) => {
  const { call } = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDtoWithClassName[]>([]);
  const [deletePaymentMethod, setDeletePaymentMethod] = useState<PaymentMethodDtoWithClassName>();

  const load = () => {
    setIsLoading(true);
    getPaymentMethods(billingAccountId).then((data) => {
      const newMethods = data.map((item: PaymentMethodDtoWithClassName) => {
        if (item.default) {
          item.className = 'defaultMethod';
        }
        return item;
      });
      setPaymentMethods(newMethods);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    load();
  }, []);

  const handleCancelPaymentMethod = async (paymentMethod: TPaymentMethod) => {
    setIsLoading(true);
    await call(
      PaymentService.billingAccountCancelPaymentMethod({ baId: billingAccountId, paymentMethodId: paymentMethod.id }),
    );
    const newPaymentMethods: PaymentMethodDtoWithClassName[] = await getPaymentMethods(billingAccountId);
    if (paymentMethod.default) {
      if (newPaymentMethods[0]) {
        await call(
          PaymentService.billingAccountSetDefaultPaymentMethod({
            baId: billingAccountId,
            paymentMethodId: newPaymentMethods[0].id,
          }),
        );
        newPaymentMethods[0].default = true;
      }
    }
    const paymentMethodsWithClassName = newPaymentMethods.map((item) => {
      if (item.default) {
        item.className = 'defaultMethod';
      }
      return item;
    });
    setDeletePaymentMethod(undefined);
    setPaymentMethods(paymentMethodsWithClassName);
    setIsLoading(false);
  };

  const handleSetDefaultPaymentMethod = async (paymentMethodId: string) => {
    setIsLoading(true);
    const newMethods = [...paymentMethods];
    const currentMethodIndex = newMethods.findIndex((method) => method.id === paymentMethodId);
    newMethods.forEach((method) => {
      method.default = false;
      method.className = '';
      return method;
    });
    newMethods[currentMethodIndex].default = true;
    newMethods[currentMethodIndex].className = 'defaultMethod';
    setPaymentMethods(newMethods);
    await call(
      PaymentService.billingAccountSetDefaultPaymentMethod({
        baId: billingAccountId,
        paymentMethodId,
      }),
    );
    setIsLoading(false);
  };

  const tableHead = [
    {
      render: (data: TPaymentMethod) => (
        <p className="active" key={data.type}>
          {data.type}
        </p>
      ),
      headComponent: () => <td>TYPE</td>,
    },
    {
      render: (data: TPaymentMethod) => <p key={data.created}>{getAmericanTime(Number(data.created) * 1000)}</p>,
      headComponent: () => <td>CREATED</td>,
    },
    {
      render: (data: TPaymentMethod) => <p key={data.last4}>**** {data.last4}</p>,
      headComponent: () => <td>LAST 4 DIGITS</td>,
    },
    {
      render: (data: TPaymentMethod) => (
        <div className="centerText">
          <CheckBox
            rounded={true}
            key={data.id}
            onChange={() => handleSetDefaultPaymentMethod(data.id)}
            checked={!!data.default}
            disabled={false} // TODO: change logic here with new permissions on Organizations for Billing Accounts
          />
        </div>
      ),
      headComponent: () => <td>DEFAULT</td>,
    },
    {
      render: (data: TPaymentMethod, index: number) => (
        <DeleteButton
          key={index}
          onClick={() => {
            setDeletePaymentMethod(data);
          }}
          disabled={false} // TODO: change logic here with new permissions on Organizations for Billing Accounts
        >
          <TrashBin />
        </DeleteButton>
      ),
      headComponent: () => <td></td>,
    },
  ];

  const deletePaymentMethodDescription = `Are you sure you want to delete ${
    deletePaymentMethod?.expDate?.month
  }/${String(deletePaymentMethod?.expDate?.year).substring(2, 4)} **** ${deletePaymentMethod?.last4} card from
  history?`;

  if (deletePaymentMethod) {
    return (
      <DeleteModal>
        {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
        <h3 className="title">Confirm</h3>
        <p className="description">{deletePaymentMethodDescription}</p>
        <div className="buttonWrapper">
          <Button variant="outlined" onClick={() => setDeletePaymentMethod(undefined)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => handleCancelPaymentMethod(deletePaymentMethod)}>
            Delete
          </Button>
        </div>
      </DeleteModal>
    );
  }

  return (
    <Wrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Payment Method History</h2>
      <div className="tableWrapper">
        <CustomTable data={paymentMethods} head={tableHead} fullPage={false} whiteBackground={true} />
      </div>
      <div className="buttonWrapper">
        <Button onClick={() => onCancel()} variant="outlined">
          Cancel
        </Button>
      </div>
    </Wrapper>
  );
};
