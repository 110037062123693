import { client } from 'app/http';

export const getAvailableMembers = async (
  page = 1,
  pageSize = 25,
  orderBy = 'createdAt',
  orderType = 'asc',
  search = '',
  baId = '',
  subId = '',
) => {
  try {
    const response = await client.get(
      `/billing-account/${baId}/subscription/${subId}/available-members?pageNo=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${encodeURIComponent(
        search,
      )}`,
    );
    return response.data;
  } catch (err) {
    console.log('getAvailableMembers request failed:', err);
    return [];
  }
};
