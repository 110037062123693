import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

import { AdminService } from 'app/API';
import { useModalContext } from '@providers/ModalProvider';
import { useAPI } from '@hooks/useAPI';
import { TOrganization } from 'app/types/entities/TOrganization';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TRole } from 'app/types/entities/TRole';
import { TWorkspace } from 'app/types/entities/TWorkspace';
import { TRepository } from 'app/types/entities/TRepository';

type TState = {
  organization: (TOrganization & { Teams: TRole[]; workspaces: TWorkspace[]; repositories: TRepository[] }) | null;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  reloadPage: () => void;
  openBalanceModal: (billingAccountId: string) => void;
  openRateModal: (billingAccountId: string) => void;
  openASOneRateModal: (billingAccountId: string) => void;
  recalculateBalances: (billingAccountId: string) => void;
};

const PageContext = createContext<TState>({
  organization: null,
  isLoading: false,
  setIsLoading: () => null,
  reloadPage: () => null,
  openBalanceModal: () => null,
  openRateModal: () => null,
  openASOneRateModal: () => null,
  recalculateBalances: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { openModal, closeModal } = useModalContext();
  const { id } = useParams<{ id: string }>();

  const [organization, setOrganization] = useState<
    (TOrganization & { Teams: TRole[]; workspaces: TWorkspace[]; repositories: TRepository[] }) | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);

  const reloadPage = async () => {
    closeModal();
    setIsLoading(true);
    const data = await call(AdminService.getOrganization({ id: id ?? '' }));
    setOrganization(data);
    setIsLoading(false);
  };

  useEffect(() => {
    reloadPage();
  }, [id]);

  const openBalanceModal = (billingAccountId: string) => {
    openModal(ModalNamesEnum.AdminAddBalanceToBillingAccount, {
      billingAccountId,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  const openRateModal = (billingAccountId: string) => {
    openModal(ModalNamesEnum.AdminBillingAccountRate, {
      billingAccountId,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  const openASOneRateModal = (billingAccountId: string) => {
    openModal(ModalNamesEnum.AdminBillingAccountASOneRate, {
      billingAccountId,
      onSuccess: () => {
        reloadPage();
      },
    });
  };

  const triggerRecalculateBalances = async (billingAccountId: string) => {
    await call(AdminService.recalculateBalances({ baId: billingAccountId }));
    reloadPage();
  };

  return (
    <PageContext.Provider
      value={{
        organization,
        isLoading,
        setIsLoading,
        reloadPage,
        openBalanceModal,
        openRateModal,
        openASOneRateModal,
        recalculateBalances: triggerRecalculateBalances,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
