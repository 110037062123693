import { EmailIcon } from '@components/icons/Email';
import { LocationIcon } from '@components/icons/Location';
import { PhoneIcon } from '@components/icons/Phone';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 50%;
  text-align: left;
  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-transform: capitalize;
    color: #40608f;
    margin: 0;
  }
  h4 {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    margin: 12px 0 4px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #878787;
    display: flex;
    grid-gap: 10px;
    align-items: center;
    margin: 12px 0 18px;
    .imgContainer {
      display: block;
      width: 20px;
      height: 20px;
      text-align: center;
    }
  }
  & .largeLineSpace {
    p {
      line-height: 250%;
      margin: 0;
    }
  }
`;

export const ContactInfo = () => (
  <Wrapper>
    <h3>About Us</h3>
    <p>
      VoiceScript is a leading supplier of legal technology and outsourced court reporting and transcription services
      designed to modernize the court reporting industry. Our products and services allow court reporting agencies to
      deliver the industry’s best-in-class digital court reporting services while significantly reducing production
      costs and delivery times for legal transcripts. By partnering with VoiceScript, court reporting agencies can
      quickly scale up their digital court reporting business while dramatically improving their overall profitability.
    </p>
    <h3>Contact Us</h3>

    <h4>Contact</h4>
    <div className="largeLineSpace">
      <p>
        <span className="imgContainer">
          <PhoneIcon />
        </span>
        (772) 621-5205
      </p>
      <p>
        <span className="imgContainer">
          <EmailIcon />
        </span>
        contact@voicescript.ai
      </p>
      <p>
        <span className="imgContainer">
          <LocationIcon />
        </span>
        West Palm Beach, FL, US
      </p>
    </div>

    <h4>Opening hours (EST)</h4>
    <div className="largeLineSpace">
      <p>Mon – Fri: 8:00 AM – 8:00 PM</p>
      <p>Sat: 8:00 AM – 2:00 PM</p>
      <p>Sun: Closed</p>
    </div>
  </Wrapper>
);
