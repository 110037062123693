import { SVGProps } from 'react';

export const SideArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={6} height={10} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.205.235a.75.75 0 0 0 .03 1.06L4.158 5 .235 8.705a.75.75 0 0 0 1.03 1.09l4.5-4.25a.75.75 0 0 0 0-1.09l-4.5-4.25a.75.75 0 0 0-1.06.03Z"
      fill="#858DBD"
    />
  </svg>
);
