import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const Block = ({ title, description, children, className = 'block-component' }: TProps) => {
  return (
    <Wrapper className={className}>
      <div className="leftSide">
        <div className="title">{title}</div>
        {description && <div className="description">{description}</div>}
      </div>
      <div className="rightSide">{children}</div>
    </Wrapper>
  );
};
