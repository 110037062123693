import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';

export const jobCanBeRefunded = (status: keyof typeof JOB_STATUSES_ENUM) => {
  return [
    JOB_STATUSES_ENUM.JOB_STATUS_ERROR,
    JOB_STATUSES_ENUM.JOB_STATUS_READY_FOR_TRANSCRIBING,
    JOB_STATUSES_ENUM.JOB_STATUS_TRANSCRIBING,
    JOB_STATUSES_ENUM.JOB_STATUS_READY_FOR_PROOFREADING,
    JOB_STATUSES_ENUM.JOB_STATUS_PROOFING,
    JOB_STATUSES_ENUM.JOB_STATUS_READY_FOR_FINAL,
    JOB_STATUSES_ENUM.JOB_STATUS_COMPLETE,
  ].includes(JOB_STATUSES_ENUM[status]);
};
