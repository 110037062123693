export const inputOptions = {
  style: {
    base: {
      color: '#00122D',
      fontSize: '12px',
      fontWeight: '500',
      '::placeholder': {
        color: '#858DBD',
        fontFamily: '"General Sans", sans-serif',
        fontSize: '12px',
        fontWeight: '500',
      },
    },
    invalid: {
      color: '#00122D',
    },
  },
};
