import styled from 'styled-components';
import { Spinner } from '@components/spinners/Spinner';

export const StyledSpinner = styled(Spinner)`
  height: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
`;
