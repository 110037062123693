import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
  width: 260px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: default;

  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #00122d;

  h2 {
    color: #858dbd;
    margin: 0 0 10px;
    padding: 0 0 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    border-bottom: 1px solid #e1e1e1;
  }

  .settings-link {
    position: absolute;
    display: block;
    right: 20px;
    top: 18px;
  }

  .no-notifications {
    color: #858dbd;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }

  .notifications-list {
    min-height: 240px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .notification {
    cursor: pointer;
    display: flex;
    gap: 10px;
    .content {
      position: relative;
      overflow: hidden;
      width: 100%;
      .unseen-dot {
        background: #7cb1fe;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        position: absolute;
        right: 4px;
        top: 24px;
      }
      .delete-button {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 0;
        top: 4px;
        padding: 0;
        flex: 0 0 auto;
        border: none;
        svg {
          width: 100%;
          height: 100%;
        }
        :hover {
          svg path {
            stroke: #40608f;
          }
        }
      }
      .org-name {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        padding: 0 0 5px;
        margin: 0;
        color: #858dbd;
      }
      .message {
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px 5px 0;
        margin: 0;
        color: #313131;
      }
      .created {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        margin: 0;
        color: #858dbd;
      }
    }
  }

  .entity-name {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-transform: uppercase;
    border-radius: 5px;
    height: 45px;
    width: 45px;
    background: #cadcf8;
    img {
      border-radius: 5px;
    }
  }

  .notifications-footer {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 0 0;
  }
`;
