import { createContext, useContext, useEffect, useState } from 'react';
import { useAPI } from '@hooks/useAPI';

import { CanvasService } from 'app/API';
import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';

type TState = {
  courses: any[];
  isLoading: boolean;
};

const PageContext = createContext<TState>({
  courses: [],
  isLoading: false,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState<any[]>([]);

  const { call } = useAPI();

  const reloadPage = () => {
    setIsLoading(true);
    call(CanvasService.getCourses()).then((res) => {
      setCourses(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    reloadPage();
  }, []);

  return (
    <PageContext.Provider
      value={{
        courses,
        isLoading,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
