import React, { useEffect } from 'react';
import { debounce } from 'lodash-es';
import styled from 'styled-components';

import { getAvailableMembers } from '@queries/subscriptions/getAvailableMembers';

import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { TrashBin } from '@components/icons/TrashBin';
import { CustomTable } from '@components/Table';

import { Search } from '@components/icons/Search';
import { TUser } from 'app/types/entities/TUser';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
  }

  .assignee-input {
    margin: 20px 0 20px;
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tr {
      cursor: pointer;
    }

    thead {
      tr {
        cursor: default;

        td,
        th {
          height: auto;
          padding: 10px 0;
        }
      }
    }

    tbody {
      td {
        padding: 10px 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }

  .assignTo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 0;

    svg {
      cursor: pointer;
    }

    .assignToText {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #00122d;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

type TProps = {
  baId: string;
  subId: string;
  onCancel: () => void;
  onSuccess: (assignee: TUser | null) => void;
};

export const AddUserLicenseModal = ({ onCancel, onSuccess, baId, subId }: TProps) => {
  const [users, setUsers] = React.useState<TUser[]>([]);
  const [search, setSearch] = React.useState('');
  const [assignee, setAssignee] = React.useState<TUser | null>(null);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      getAvailableMembers(1, 10000, 'name', 'asc', search, baId, subId).then((result) => {
        setUsers(result.data);
      });
    }, 500);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [search]);

  const changeAssignee = (data: TUser | null) => {
    if (!data) {
      setAssignee(null);
      return;
    }
    setAssignee(data);
  };

  const tableHead = [
    {
      render: (data: TUser) => <p onClick={() => changeAssignee(data)}>{data?.name}</p>,
      headComponent: () => <td>Name</td>,
    },
    {
      render: (data: TUser) => <p onClick={() => changeAssignee(data)}>{data?.email}</p>,
      headComponent: () => <td>Email</td>,
    },
  ];

  const renderAssignee = () => {
    if (!assignee) {
      return;
    }
    return (
      <div className="assignTo">
        <div className="assignToText">
          <p>
            {assignee.name || ''} {assignee.lastname || ''}
          </p>
          {assignee.email ? <p>{assignee.email}</p> : ''}
        </div>
        <TrashBin onClick={() => changeAssignee(null)} />
      </div>
    );
  };

  const handleSubmit = async () => {
    onSuccess(assignee);
  };

  return (
    <ModalWrapper>
      <h2>Add License to User</h2>
      {renderAssignee()}
      <Input
        className="assignee-input"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        label="Search user to assign"
        name="assignUser"
        icon={Search}
        value={search}
      />
      <CustomTable data={users} head={tableHead} className="customTable" fullPage={false} whiteBackground={true} />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={!assignee} onClick={handleSubmit}>
          Add User
        </Button>
      </div>
    </ModalWrapper>
  );
};
