import React, { useEffect, useState } from 'react';

import { useModalContext } from '@providers/ModalProvider';

import { createAdminTransaction } from '@queries/admin/transactions/createAdminTransaction';
import { getTransactionCode } from '@queries/admin/transactions/getTransactionCode';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Select } from '@components/form-elements/Select';
import { SmallWhiteTextarea } from '@components/form-elements/Textarea/variations/SmallWhiteTextarea';

import { ModalWrapper } from './styles/ModalWrapper';
import { CurrencyInput } from '@components/form-elements/CurrencyInput';
import { ETRANSACTION_CHARGE_CODES } from 'app/types/enums/ETRANSACTION_CHARGE_CODES';

type TProps = {
  billingAccountId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const AddAdminTransactionModal = ({ billingAccountId, onCancel, onSuccess }: TProps) => {
  const { closeModal } = useModalContext();
  const [amount, setAmount] = useState(10);
  const [code, setCode] = useState<ETRANSACTION_CHARGE_CODES>(ETRANSACTION_CHARGE_CODES.ADMIN_TRAINING);
  const [codeDetails, setCodeDetails] = useState<{
    code: string;
    name: string;
    description: string;
  }>({
    code: '',
    name: '',
    description: '',
  });
  const [note, setNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{
    error: string;
    errorDetail: string;
  } | null>(null);

  // Allowed transaction codes:
  // ETRANSACTION_CHARGE_CODES.ADMIN_TRAINING,
  // ETRANSACTION_CHARGE_CODES.ADMIN_CREDIT_ADJUSTMENT,
  // ETRANSACTION_CHARGE_CODES.ADMIN_FREE_CREDIT_ADJUSTMENT,
  // ETRANSACTION_CHARGE_CODES.ADD_FUNDS_MANUAL,
  // ETRANSACTION_CHARGE_CODES.ADMIN_REFUND,

  const options = [
    {
      value: ETRANSACTION_CHARGE_CODES.ADMIN_TRAINING,
      label: 'Admin Training',
    },
    {
      value: ETRANSACTION_CHARGE_CODES.ADMIN_CREDIT_ADJUSTMENT,
      label: 'Admin Credit Adjustment',
    },
    {
      value: ETRANSACTION_CHARGE_CODES.ADMIN_FREE_CREDIT_ADJUSTMENT,
      label: 'Admin Free Credit Adjustment',
    },
    {
      value: ETRANSACTION_CHARGE_CODES.ADD_FUNDS_MANUAL,
      label: 'Add Funds Manual',
    },
    {
      value: ETRANSACTION_CHARGE_CODES.ADMIN_REFUND,
      label: 'Admin Refund',
    },
  ];

  useEffect(() => {
    const getTransactionCodeDescription = async () => {
      const result = await getTransactionCode(code);
      if (result) {
        setCodeDetails(result);
      }
    };
    getTransactionCodeDescription();
  }, [code]);

  const onSubmit = async () => {
    setError(null);
    setIsLoading(true);
    const result = await createAdminTransaction(billingAccountId, amount * 100, code, note);
    setIsLoading(false);
    if (!result?.error) {
      closeModal();
      onSuccess();
    } else {
      setError(result);
    }
  };

  const onNotesInputChange = async (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = evt.target;
    await setNote(value);
  };

  const isSubmitDisabled = isLoading || amount === 0;

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Create an Admin Transaction</h2>

      <div className="input-wrapper">
        <div className="label">Transaction Type</div>
        <Select
          value={code}
          options={options}
          onChange={(_, value) => setCode(value as ETRANSACTION_CHARGE_CODES)}
          label="Transaction Type"
          required
        />
      </div>

      <div className="py-8">
        <small>Code: {codeDetails.code}</small>
        <p>
          <small>Name: {codeDetails.name}</small>
        </p>

        <p>
          <small>{codeDetails.description}</small>
        </p>
      </div>

      <div className="input-wrapper">
        <div className="label">Amount (USD)</div>

        <div className="flex flex-row">
          <CurrencyInput
            onChange={(v) => {
              setError(null);
              if (!v) {
                setAmount(0);
                return;
              }
              setAmount(v);
            }}
            label="Amount"
            value={amount}
          />
        </div>
      </div>

      <div className="input-wrapper">
        <div className="label">Notes</div>

        <SmallWhiteTextarea
          onChange={onNotesInputChange}
          className="textarea"
          name="note"
          label="Transaction notes and description"
          value={note}
        />
      </div>

      {error ? (
        <div className="error">
          <p>{error.errorDetail}</p>
        </div>
      ) : null}
      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSubmitDisabled} onClick={onSubmit}>
          Create transaction
        </Button>
      </div>
    </ModalWrapper>
  );
};
