import styled from 'styled-components';

export const ErrorClickableSpan = styled.span`
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
