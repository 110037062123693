import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px 0 0;
  .header {
    margin: 0 -30px;
    padding: 0 30px;
    background: #f1f5fb;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #858dbd;
  }
  .items {
    .item {
      padding: 15px 0 0;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #313131;
        padding: 0 0 5px;
      }
      .subtitle {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        color: #858dbd;
      }
    }
  }
`;
