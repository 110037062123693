import { TOrganization } from 'app/types/entities/TOrganization';

import { OrgIcon } from './styles/OrgIcon';

export const OrganizationImage = ({ org }: { org: TOrganization }) => {
  if (!org.image) {
    return <></>;
  }
  return <OrgIcon src={org.image} alt={org.name} />;
};
