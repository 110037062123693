import { TProps } from './types/TProps';
import { Wrapper } from './styles/Wrapper';

export const Textarea = ({
  name = 'textarea',
  label,
  onChange,
  value = '',
  required = false,
  disabled = false,
  error = false,
  className = 'textarea',
}: TProps) => {
  const title = `${label}${required ? ' *' : ''}`;
  return (
    <Wrapper className={`${className}-wrapper`}>
      <textarea
        placeholder={title}
        className={`${error ? `${className} error` : className}`}
        name={name}
        onChange={onChange}
        required={required}
        disabled={disabled}
        value={value}
      />
    </Wrapper>
  );
};
