const RSProDescriptionBlock = () => {
  return (
    <div className="descriptionBlock">
      <ul>
        <li>
          Reporter Studio PRO is our multi-channel recording system, reporter annotation and editing platform that gives
          qualified digital reporters abilities not available through any other digital reporter software.
        </li>
        <li>Channels: 12 separate audio recording channels</li>
        <li>Video Conferencing: Optimized functionality for remote video conferencing</li>
        <li>Automation: Automatic speech recognition for deliverable rough drafts</li>
        <li>Recording: Video recording capabilities</li>
        <li>Annotation: Reporter annotation features with simple to use UI</li>
        <li>Personalization: Customizable hotkey functionality</li>
      </ul>
    </div>
  );
};

export default RSProDescriptionBlock;
