import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { type GetManyForUserResponseDto, JobsService, LayoutsService } from 'app/API';
import { useAPI } from '@hooks/useAPI';
import { useAuthContext } from '@providers/AuthProvider';

import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Button } from '@components/form-elements/buttons/Button';
import { SmallWhiteSelect } from '@components/form-elements/Select/variations/SmallWhiteSelect';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  h2 {
    color: #40608f;
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 0 0 20px;
  }

  .select {
    background: #f8fafb;
    height: 30px;
    margin: 0 0 20px;
  }

  .footer-description {
    margin: 0 0 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  id: string;
};

export const ChooseCloudLayoutModal = ({ id, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { workspace, organization } = useAuthContext();
  const [selectedCloudLayout, setSelectedCloudLayout] = useState('null');
  const [isLoading, setIsLoading] = useState(false);
  const [layouts, setLayouts] = useState<GetManyForUserResponseDto[] | undefined>([]);

  const submit = async () => {
    setIsLoading(true);
    await call(
      JobsService.updateLayout({
        id,
        wsid: workspace?.id ?? '',
        requestBody: { layoutId: selectedCloudLayout ?? 'null' },
      }),
    );
    setIsLoading(false);
    onSuccess();
  };

  const layoutsList = [
    {
      label: 'None',
      value: 'null',
    },
    ...(layouts || []).map((l) => ({
      value: l.id,
      label: l.name,
    })),
  ];

  useEffect(() => {
    (async () => {
      if (!workspace?.id || !organization?.id) {
        setIsLoading(true);
        return;
      }
      const jobData = await call(JobsService.getJobById({ id, wsid: workspace.id }));
      setSelectedCloudLayout(jobData?.layoutId || 'null');

      const layouts = await call(LayoutsService.getOrganizationLayouts({ oid: organization.id }));
      setLayouts(layouts);

      setIsLoading(false);
    })();
  }, []);

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Insert layout</h2>

      <div className="description">
        You can choose a layout for this Job. It will be automatically applied to the transcript.
      </div>

      <SmallWhiteSelect
        name="layoutId"
        className="layout-select"
        label="Cloud Layout"
        value={selectedCloudLayout}
        disabled={layoutsList.length < 2 || isLoading}
        onChange={(_, value) => setSelectedCloudLayout(value)}
        options={layoutsList}
        required={false}
      />

      <div className="footer-description">
        * If you don’t have a cloud layout available, please visit this page for instructions to create cloud layouts
        for use in job processing.
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={!selectedCloudLayout.length || isLoading} onClick={submit}>
          Apply
        </Button>
      </div>
    </ModalWrapper>
  );
};
