import { Helmet } from 'react-helmet-async';

import { Header } from '@components/Header';
import { TrashBin } from '@components/icons/TrashBin';
import { CustomTable } from '@components/Table';

import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { SMSCountry, usePageContext } from '../../providers/PageProvider';

import { Tabs } from './components/Tabs';
import { Wrapper } from './styles/Wrapper';
import { CellContent } from './styles/CellContent';
import { DeleteButton } from './styles/DeleteButton';

export const Content = () => {
  const { removeCountry, createCountry, smsCountries, isLoading } = usePageContext();

  const tableHead = [
    {
      headComponent: () => <td>#</td>,
      render: (data: SMSCountry, index: number) => <CellContent>{index + 1}</CellContent>,
    },
    {
      headComponent: () => <td>Country</td>,
      render: (data: SMSCountry) => (
        <CellContent>
          {' '}
          {data.code ? getUnicodeFlagIcon(data.code) : null} {data.code}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td>Prefix</td>,
      render: (data: SMSCountry) => <CellContent>{data.prefix}</CellContent>,
    },

    {
      headComponent: () => <td>Status</td>,
      render: (data: SMSCountry) => <CellContent>{data.status}</CellContent>,
    },
    {
      headComponent: () => <td>Actions</td>,
      render: (data: SMSCountry) => (
        <CellContent>
          <DeleteButton title="Remove" onClick={() => removeCountry(data.code)}>
            <TrashBin />
          </DeleteButton>
        </CellContent>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>SMS Countries list - AutoScript Admin</title>
      </Helmet>
      <Header
        title={'SMS OTP Country Rules'}
        buttonLabel={'Create new rule'}
        buttonOnClick={() => createCountry()}
        leftSideContent={[<Tabs key="SMSTabs" currentTab="SMS Countries" />]}
      />

      <LinearProgressLoader active={isLoading} />
      <Wrapper>
        <CustomTable head={tableHead} data={smsCountries} />
      </Wrapper>
    </>
  );
};
