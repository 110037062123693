import { client } from 'app/http';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TWorkspace } from 'app/types/entities/TWorkspace';

export type TResponse = TPaginatedResponse<TWorkspace>;

export const getWorkspacesList = async (
  page = 1,
  pageSize = 25,
  search = '',
): Promise<TPaginatedResponse<TWorkspace>> => {
  const data = await client.get(
    `/admin/workspaces?pageNo=${page}&pageSize=${pageSize}&search=${encodeURIComponent(search)}`,
  );
  return data.data;
};
