export const PositiveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z"
        fill="#16D679"
        fillOpacity="0.05"
        className="positive-icon__external-circle"
      />
      <path
        d="M49.8309 29.9999C49.8309 40.952 40.9525 49.8305 30.0004 49.8305C19.0483 49.8305 10.1699 40.952 10.1699 29.9999C10.1699 19.0479 19.0483 10.1694 30.0004 10.1694C40.9525 10.1694 49.8309 19.0479 49.8309 29.9999Z"
        fill="#16D679"
        fillOpacity="0.1"
        className="positive-icon__internal-circle"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4918 21.8643C24.7178 21.8643 20.8477 25.7344 20.8477 30.5084C20.8477 35.2824 24.7178 39.1525 29.4918 39.1525C34.2658 39.1525 38.1359 35.2824 38.1359 30.5084C38.1359 29.6659 38.8188 28.983 39.6613 28.983C40.5037 28.983 41.1867 29.6659 41.1867 30.5084C41.1867 36.9673 35.9507 42.2033 29.4918 42.2033C23.0329 42.2033 17.7969 36.9673 17.7969 30.5084C17.7969 24.0495 23.0329 18.8135 29.4918 18.8135C30.3343 18.8135 31.0172 19.4964 31.0172 20.3389C31.0172 21.1814 30.3343 21.8643 29.4918 21.8643ZM39.5996 20.7857C40.1953 20.19 41.1612 20.19 41.7569 20.7857C42.3526 21.3814 42.3526 22.3472 41.7569 22.943L30.0698 34.6301L24.928 30.131C24.2939 29.5762 24.2297 28.6125 24.7845 27.9785C25.3392 27.3444 26.3029 27.2802 26.937 27.835L29.9307 30.4545L39.5996 20.7857Z"
        fill="#16D679"
        className="positive-icon__icon"
      />
    </svg>
  );
};
