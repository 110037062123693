import React, { useState } from 'react';
import styled from 'styled-components';

import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

import { EmailIcon } from '@components/icons/Email';
import { UserIcon } from '@components/icons/User';
import { PhoneIcon } from '@components/icons/Phone';
import { Case as CaseIcon } from '@components/icons/Case';
import { GenericPageError } from '@components/GenericPageError';
import { StyledSelect } from '@pages/Anonymous/RegistrationPage/components/Content/styles/StyledSelect';
import { accountTypes } from '@constants/accountTypes';

const InputWrapper = styled.div`
  padding: 20px 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  width: auto;
  padding: 30px 0 0;
`;

const Wrapper = styled.div`
  background: #fff;
  width: 330px;
  padding: 30px;
  position: relative;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  & .page-error {
    margin: 15px 7px 0 7px;
    display: block;
  }

  & h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #858dbd;
    margin: 0 0 10px;
    text-align: center;
  }
`;

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

export const CreateUserModal = ({ onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [accountType, setAccountType] = useState('');
  const [loading, setLoading] = useState(false);

  const isValid = email && firstName && secondName;

  const accountTypeOptions = Object.entries(accountTypes).map(([key, value]) => {
    return { value: key, label: value };
  });

  const handleSubmit = async (e: React.FormEvent) => {
    setErrorMessage('');
    if (!isValid) {
      return;
    }
    setLoading(true);
    e.preventDefault();
    const response = await call(
      AdminService.createUser({
        requestBody: {
          email,
          name: firstName,
          lastName: secondName,
          accountType,
          phone,
          company,
        },
      }),
    );
    if (response?.success === false && response?.message) {
      setErrorMessage(response?.message);
      setLoading(false);
      return;
    }
    setLoading(false);
    onSuccess();
  };

  return (
    <Wrapper>
      {loading && <AbsoluteSpinner overlay={true} />}
      <h2>Create New User</h2>
      <GenericPageError message={errorMessage} />
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
            name="firstName"
            label="First name"
            type="text"
            value={firstName}
            required={true}
            icon={UserIcon}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="lastName"
            label="Last name"
            type="text"
            value={secondName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSecondName(event.target.value)}
            required={true}
            icon={UserIcon}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="email"
            label="Email"
            type="email"
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value.toLowerCase())}
            required={true}
            icon={EmailIcon}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="phone"
            label="Phone"
            type="phone"
            value={phone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)}
            icon={PhoneIcon}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="company"
            label="Company"
            value={company}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCompany(event.target.value)}
            icon={CaseIcon}
          />
        </InputWrapper>

        <InputWrapper>
          <StyledSelect
            name="accountType"
            label="Account Type"
            value={accountType ?? ''}
            onChange={(name, value) => {
              setAccountType(value);
            }}
            options={accountTypeOptions}
            required={true}
            hideSelectedOption={true}
          />
        </InputWrapper>

        <ButtonWrapper>
          <Button onClick={onCancel} variant="outlined" size="small">
            Cancel
          </Button>
          <Button size="small" type="submit" disabled={!isValid}>
            Create User
          </Button>
        </ButtonWrapper>
      </form>
    </Wrapper>
  );
};
