import React from 'react';
import { Wrapper } from './styles/Wrapper';

type TProps = {
  children: React.ReactNode;
  content?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

export const Tooltip = ({ children, content, placement = 'right' }: TProps): React.ReactElement => {
  return (
    <Wrapper placement={placement}>
      {children}
      <div className="tooltiptext">{content}</div>
    </Wrapper>
  );
};
