import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { TPageProviderProps } from 'app/types/providers/TPageProviderProps';
import { TUser } from 'app/types/entities/TUser';

import { AdminService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

type TState = {
  id: string;
  users: TUser[];
  isLoading: boolean;
  changeEnabled: (user: TUser) => void;
};

const PageContext = createContext<TState>({
  id: '',
  users: [],
  isLoading: false,
  changeEnabled: () => null,
});

export const PageProvider = ({ children }: TPageProviderProps) => {
  const { call } = useAPI();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<TUser[]>([]);

  const fetch = async () => {
    const res = await call(
      AdminService.getOrganizationUsersList({
        oid: id ?? '',
        pageNo: '1',
        pageSize: '1000',
        orderBy: 'createdAt',
        orderType: 'desc',
        search: '',
      }),
    );
    setUsers(res.data);
  };

  useEffect(() => {
    fetch();
  }, [id]);

  const changeEnabled = async (user: TUser) => {
    setIsLoading(true);
    const disabled = !user.disabled;
    await call(AdminService.setUserDisabled({ uid: user.id, requestBody: { disabled } }));
    await fetch();
    setIsLoading(false);
  };

  return (
    <PageContext.Provider
      value={{
        id: id ?? '',
        users,
        isLoading,
        changeEnabled,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
