import styled, { css } from 'styled-components';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

export type TProps = {
  focused: boolean;
  error: boolean;
};

const stripeInputStyles = css<TProps>`
  height: 30px;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
  box-sizing: border-box;
  background: ${({ focused }) => (focused ? '#fff' : '#f8fafb')};
  border: ${({ focused }) => (focused ? '1px solid #CADCF8' : '1px solid transparent')};
  box-shadow: none;
  outline: none;
  ${({ error }) =>
    error && 'border: 1px solid #FF2F2F; box-shadow: 0 2px 4px rgba(255, 47, 47, 0.2); border-radius: 5px;'};

  :hover {
    border: 1px solid #cadcf8;
  }
`;

export const StyledCardNumberElement = styled(CardNumberElement)<TProps>`
  ${stripeInputStyles}
`;

export const StyledCVCElement = styled(CardCvcElement)<TProps>`
  ${stripeInputStyles}
`;

export const StyledExpiredElement = styled(CardExpiryElement)<TProps>`
  ${stripeInputStyles}
`;
