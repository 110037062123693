import styled from 'styled-components';

import { WhiteTextarea } from './WhiteTextarea';

export const SmallWhiteTextarea = styled(WhiteTextarea)`
  textarea {
    padding: 7px 10px;
  }

  label {
    left: 11px;
    top: 9px;
  }
`;
