import { client } from 'app/http';

export const adminGetOrganizationTeams = async (orgId: string) => {
  try {
    const response = await client.get(`/admin/${orgId}/teams`);
    return response.data;
  } catch (err) {
    console.log('adminGetOrganizationTeams request failed:', err);
    return [];
  }
};
