import { useState } from 'react';

import { AdminService, getSettingsResponseSetting } from 'app/API';
import { useModalContext } from '@providers/ModalProvider';
import { useAPI } from '@hooks/useAPI';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';

import { ModalWrapper } from './styles/ModalWrapper';
import { SmallWhiteInput } from '@components/Modal/Admin/AdminEditSettingModal/styles/SmallWhiteInput';
import { useAuthContext } from '@providers/AuthProvider';
import { GenericPageError } from '@components/GenericPageError';
import toast from 'react-hot-toast';

type TProps = {
  onCancel: () => void;
  onSuccess: () => void;
  setting: getSettingsResponseSetting;
};

export const AdminEditSettingModal = ({ onCancel, onSuccess, setting }: TProps) => {
  const { me } = useAuthContext();
  const { call } = useAPI();
  const { closeModal } = useModalContext();
  const [value, setValue] = useState(setting.value);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (setting.name === 'TRANSCRIPTION_MODEL' && !['1', '2'].includes(value)) {
      toast.error('TRANSCRIPTION_MODEL should has value 1 or 2');
      return;
    }
    setIsLoading(true);
    await call(AdminService.updateSetting({ name: setting.name, requestBody: { value } }));
    setIsLoading(false);
    closeModal();
    onSuccess();
  };

  const isSubmitDisabled = isLoading || !value || !value.length;
  const isVsAdmin = me?.roles?.ROLE_VS_ADMIN;
  const isVsBilling = me?.roles?.ROLE_BILLING_ADMIN;

  if (!isVsAdmin || (!isVsBilling && setting?.name === 'SIGNUP_CREDIT_AMOUNT')) {
    return (
      <ModalWrapper>
        <GenericPageError message="You don't have enough permissions to edit this setting" />

        <div className="buttonGroup">
          <Button onClick={onCancel}>Ok</Button>
        </div>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Edit {setting.name} Setting</h2>

      <div className="input-wrapper">
        <div className="label">Value</div>

        <div className="flex flex-row">
          <SmallWhiteInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
            label="Value"
            value={value}
          />
        </div>
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSubmitDisabled} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </ModalWrapper>
  );
};
