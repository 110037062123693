import styled from 'styled-components';

export const StyledInput = styled.span`
  text-align: left;
  display: inline-flex;
  width: 100%;
  position: relative;

  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  input {
    display: inline-block;
    color: #858dbd;
    background: #f8fafb;
    border-radius: 5px;
    border: none;
    flex: 1 1 auto;
    max-width: 100%;
    height: 40px;
    padding: 0 16px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;

    &::placeholder {
      color: #858dbd;
      opacity: 1;
      font-weight: 500;
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(202, 220, 248, 1);
    }

    &:hover {
      //color: #858dbd;
    }

    &:focus {
      background: #ffffff;
      color: #40608f;
    }

    &:disabled {
      background: #f1f5fb !important;
      color: #b4b9d9 !important;
      outline: none !important;
    }

    &.with-icon {
      padding-right: 45px;
    }

    &.error {
      outline: none;
      box-shadow: 0 0 0 1px #ff2f2f !important;
    }
  }

  .icon {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0px, -50%);
    pointer-events: none;
    user-select: none;
    width: 20px;
    height: 20px;

    &.clickable {
      cursor: pointer;
      pointer-events: all;
    }
  }
`;
