import { useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { addBalanceToBillingAccount } from '@queries/admin/billing-accounts/addBalanceToBillingAccount';
import { CurrencyInput } from '@components/form-elements/CurrencyInput';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 400px;

  h2 {
    color: #858dbd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin: 0;
    padding: 0 0 30px;
    text-align: center;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .input,
    .textarea {
      margin: 0 0 20px;
    }
  }

  .card,
  .rate {
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0 0 20px;

    &.red {
      color: red;
    }
  }

  .description {
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding: 0 0 10px;

    p {
      padding: 0 0 2px 8px;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .error {
    color: #fe8080;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding: 0 0 10px;

    p {
      padding: 0 0 2px 8px;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  billingAccountId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const AdminAddBalanceToBillingAccountModal = ({ billingAccountId, onCancel, onSuccess }: TProps) => {
  const { closeModal } = useModalContext();
  const [amount, setAmount] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{
    error: string;
    errorDetail: string;
  } | null>(null);

  const onSubmit = async () => {
    setError(null);
    setIsLoading(true);
    const result = await addBalanceToBillingAccount(billingAccountId, amount * 100);
    setIsLoading(false);
    if (!result.error) {
      closeModal();
      onSuccess();
    } else {
      setError(result);
    }
  };

  const isSubmitDisabled = isLoading || amount === 0 || amount > 50;

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>Add Goodwill Credit</h2>
      <div className="input-wrapper">
        <div className="label">Amount (USD)</div>
        <div className="description">
          Maximum amount is $50.00. <br />
          This will be added to the separate Free Credit balance
        </div>
        <div className="flex flex-row">
          <CurrencyInput
            onChange={(v) => {
              setError(null);
              if (!v) {
                setAmount(0);
                return;
              }
              setAmount(v);
            }}
            label="Amount"
            value={amount}
          />
        </div>
      </div>

      {error ? (
        <div className="error">
          <p>{error.errorDetail}</p>
        </div>
      ) : null}
      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isSubmitDisabled} onClick={onSubmit}>
          Add funds
        </Button>
      </div>
    </ModalWrapper>
  );
};
