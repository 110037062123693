import { debounce } from 'lodash-es';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAuthContext } from '@providers/AuthProvider';
import { editAssignee } from '@queries/repositories/editAssignee';

import { Button } from '@components/form-elements/buttons/Button';
import { Input } from '@components/form-elements/Input';
import { Search } from '@components/icons/Search';
import { TrashBin } from '@components/icons/TrashBin';
import { CustomTable } from '@components/Table';
import { TRepository } from 'app/types/entities/TRepository';
import { TUser } from 'app/types/entities/TUser';
import { getMembers } from '@queries/organizations/getMembers';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 487px;

  table td {
    max-width: 150px;
  }

  .form-input {
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #40608f;
    margin: 0;
  }

  .assignedTo {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
    padding: 20px 0 10px;
  }

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    color: #858dbd;
  }

  .radioWrapper {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    height: 24px;
  }

  .customTable {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    tr {
      cursor: pointer;
    }

    thead {
      tr {
        cursor: default;

        td,
        th {
          height: auto;
          padding: 10px 0;
        }
      }
    }

    tbody {
      td {
        padding: 10px 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }

  .assignTo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;

    svg {
      cursor: pointer;
    }

    .assignToText {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #00122d;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

type TProps = {
  repositoryId: string;
  repository: TRepository;
  onCancel: () => void;
  onSuccess: (assignee: TUser | null) => void;
};

export const AssignRepositoryModal = ({ repositoryId, repository, onCancel, onSuccess }: TProps) => {
  const [users, setUsers] = React.useState<TUser[]>([]);
  const { organization } = useAuthContext();
  const [search, setSearch] = React.useState('');
  const [assignee, setAssignee] = React.useState<TUser | null>(repository.assignee ?? null);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      getMembers(1, 10000, 'name', 'asc', search, organization?.id as string).then((result) => {
        setUsers(result.data);
      });
    }, 500);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [search]);

  const changeAssignee = (data: TUser | null) => {
    if (!data) {
      setAssignee(null);
      return;
    }
    setAssignee(data);
  };

  const tableHead = [
    {
      render: (data: TUser) => <p onClick={() => changeAssignee(data)}>{data?.name}</p>,
      headComponent: () => <td>Name</td>,
    },
    {
      render: (data: TUser) => <p onClick={() => changeAssignee(data)}>{data?.email}</p>,
      headComponent: () => <td>Email</td>,
    },
  ];

  const renderAssignee = () => {
    if (!assignee) {
      return;
    }
    return (
      <div className="assignTo">
        <div className="assignToText">
          <p>
            {assignee.name || ''} {assignee.lastname || ''}
          </p>
          {assignee.email ? <p>{assignee.email}</p> : ''}
        </div>
        <TrashBin onClick={() => changeAssignee(null)} />
      </div>
    );
  };

  const handleSubmit = async () => {
    await editAssignee(repositoryId, assignee?.id ?? null);
    onSuccess(assignee);
  };

  return (
    <ModalWrapper>
      <h2>Edit assignee</h2>
      <p className="assignedTo">{repository.assigneeId ? 'Currently assigned to' : 'Currently not assigned'}</p>
      {renderAssignee()}
      <Input
        onChange={(e) => setSearch(e.target.value)}
        label="Search user to assign"
        name="assignUser"
        icon={Search}
        value={search}
      />
      <CustomTable data={users} head={tableHead} className="customTable" fullPage={false} whiteBackground={true} />

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save</Button>
      </div>
    </ModalWrapper>
  );
};
