import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';

import { usePageContext } from '../../../../../../providers/PageProvider';

import { AvatarContent } from '../../components/AvatarContent';
import { JobSection } from '../../styles/JobSection';
import { JobSectionLeft } from '../../styles/JobSectionLeft';
import { FullWidthSectionRight } from '../../styles/FullWidthSectionRight';
import { StyledLink } from '../../styles/StyledLink';

import { CommentsList } from './styles/CommentsList';
import { TickingDateAgo } from '@components/Date/TickingDateAgo';

export const CommentsSection = () => {
  const { job } = usePageContext();
  const { me } = useAuthContext();

  if (!job || !job?.comments?.length) {
    return null;
  }

  return (
    <JobSection>
      <JobSectionLeft>Comments</JobSectionLeft>
      <FullWidthSectionRight>
        <CommentsList>
          {job.comments?.map((comment) => (
            <div key={comment.id} className="comment">
              <AvatarContent user={comment.creator} />
              <div className="comment-right">
                <div className="comment-title">
                  <span className="comment-username">
                    {comment.creator.id === me.id ? (
                      <StyledLink to={routes.accountPage.make()}>
                        {comment.creator.name} {comment.creator.lastname}
                      </StyledLink>
                    ) : (
                      <StyledLink to={routes.adminUserDetails.make(comment.creator.id)}>
                        {comment.creator.name} {comment.creator.lastname}
                      </StyledLink>
                    )}
                  </span>
                  <span className="comment-date">
                    <TickingDateAgo date={comment.createdAt} />
                  </span>
                </div>
                <div className="comment-text">
                  {comment.comment.split('\n').map((c, i) => (
                    <p key={i}>{c}</p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </CommentsList>
      </FullWidthSectionRight>
    </JobSection>
  );
};
